import React, { useEffect, useState } from "react";
import { myReviews, deleteReviews, updateReviews } from "../../Redux/Action";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import reviews from "../../images/NoReview.svg";
import orderimage from "../../images/orders.svg";
import ReviewModal from "./ReviewModal";

const ReviewsList = () => {
  function numberWithCommas(x) {
    return x.toString().split(",")[0].length > 3
      ? x
          .toString()
          .substring(0, x.toString().split(".")[0].length - 3)
          .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          "," +
          x.toString().substring(x.toString().split(".")[0].length - 3)
      : x.toString();
  }
  const token = localStorage.getItem("AuthTok");
  if (token === null || token === "undefined" || token === "") {
    window.location.href = "/login";
  }
  const [load, setLoad] = useState(true);
  const [show, setShow] = useState(true);
  const [render, setRender] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(myReviews())
      .then((res) => {
        setLoad(false);
      })
      .catch(() => {});
  }, [render]);

  const myReview = useSelector(({ myReviews }) => myReviews.payload);

  return load ? (
    <div
      style={{
        height: "400px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : (
    <div>
      <div className="container">
        <div className="row">
          <div className="register-in-title">
            {Array.isArray(myReview) ? (
              <h3 style={{ textAlign: "center", marginBottom: "25px" }}>
                Reviews List
              </h3>
            ) : (
              false
            )}
          </div>
          <div className="col-12">
            <form action="#">
              <div className="cart-table-content">
                <div className="table-content table-responsive">
                  <table>
                    {Array.isArray(myReview) ? (
                      <thead>
                        <tr>
                          <th className="width-thumbnail">S.No</th>

                          <th className="width-price">Product Name</th>
                          <th className="width-thumbnail">Product Image</th>

                          {/* <th className="width-subtotal">      Actual Price</th> */}
                          <th className="width-subtotal"> Review</th>
                          <th className="width-subtotal"> Rating</th>
                          <th className="width-subtotal"> Action</th>
                        </tr>
                      </thead>
                    ) : (
                      <h3>
                        {" "}
                        <center>
                          <img
                            src={reviews}
                            // className='mt-5 mb-5 pb-5 pt-5'
                            style={{ width: "40%" }}
                          />
                        </center>
                        <span
                          style={{
                            fontSize: "20px",
                            fontWeight: "bold",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          No Review Found
                        </span>
                      </h3>
                    )}
                    <tbody>
                      <>
                        {Array.isArray(myReview) && myReview.length !== 0
                          ? myReview?.map((data, i) => (
                              <tr>
                                <td className="product-name">{i + 1}</td>
                                <td className="product-name">
                                  <h5 style={{ textTransform: "capitalize" }}>
                                    <Link to={"/productDetails/" + data.id}>
                                      {data.productName}
                                    </Link>
                                  </h5>
                                </td>
                                <td className="product-thumbnail">
                                  <Link to={"/productDetails/" + data.id}>
                                    <img
                                      src={
                                        // process.env.REACT_APP_BASE_URL+
                                        process.env.REACT_APP_BASE_URL +
                                        data.variantImage
                                      }
                                      alt=""
                                    />
                                  </Link>
                                </td>

                                {/* <td className="product-name">
                          <h5>
                            <a
                            //   href="product-details.html"
                              style={{
                                textTransform: "capitalize",
                              }}
                            >
                             ₹  {data?.actualPrice}
                            </a>
                          </h5>
                        </td> */}
                                <td className="product-name">
                                  <h5>
                                    <a
                                      //   href="product-details.html"
                                      style={{
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {data?.review}
                                    </a>
                                  </h5>
                                </td>
                                <td className="product-name">
                                  <h5>
                                    <a
                                      //   href="product-details.html"
                                      style={{
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {data?.rating}
                                    </a>
                                  </h5>
                                </td>
                                <td
                                  className="product-name"
                                  style={{
                                    display: "flex",
                                    paddingTop: "30px",
                                    marginLeft: "15px",
                                  }}
                                >
                                  {/* <span

                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    > */}
                                  <span
                                    //  onClick={ Updated(data.id)}
                                    style={{
                                      cursor: "pointer",
                                      paddingLeft: "10px",
                                    }}
                                    // onClick={() => {
                                    //   if (show !== i)
                                    //     setShow(i);
                                    // }}
                                  >
                                    <Link to={"/updateReview/" + data.id}>
                                      <i
                                        style={{
                                          fontSize: "23px",
                                          marginTop: "17px",
                                          // backgroundColor: "#0000001c",
                                          borderRadius: "0",
                                          padding: "10px",
                                        }}
                                        className="bx bx-edit-alt"
                                      ></i>
                                    </Link>
                                  </span>
                                </td>
                              </tr>
                            ))
                          : false}
                      </>
                    </tbody>
                  </table>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewsList;
