import React,{ useState,useEffect} from 'react'
import { Link } from "react-router-dom";  
import {getAllReviews} from "../../Redux/Action"  
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";
import { useSelector, useDispatch } from "react-redux";
import { FaStar } from "react-icons/fa";
import { AiOutlineStar } from "react-icons/ai";
const Blog = () => {
    const dispatch = useDispatch();
    const [load, setLoad] = useState(true);
    useEffect(() => {
        dispatch(getAllReviews())
        setLoad(false);
     }, [])
     
     const totalReviews = useSelector(({ getAllReviews }) => getAllReviews.payload);



     if(window.$){
        var sliderActiveSeven = new window.Swiper('.testimonial-active', {
            loop: true,
            spaceBetween: 30,
            centeredSlides: true,
            breakpoints: {
                320: {
                    slidesPerView: 1
                },
                576: {
                    slidesPerView: 1
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                992: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                1200: {
                    slidesPerView: 3
                }
            },
        });
    }


    useEffect(() => {
        
    }, []);
    useEffect(() => {
        if(window.$ && 
        window.$('.count').counterUp({
            delay: 10,
            time: 2000
        }));
    }, [window.$]);
  return (
    <>
      <div className="main-wrapper main-wrapper-2 mt-5">

      <div className="blog-area  "  >
            <div className="container">
            <div id="hp-ctn-howItWorks"  >
        <a href="/contactUs">
          <p className="c" >Bulk&nbsp;Orders </p>
          </a>
        </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="blog-wrap mb-50" data-aos="fade-up" data-aos-delay="200">
                            <div className="blog-img-date-wrap mb-25">
                                <div className="blog-img">
                                    <Link to="/blogdetails"><img src="assets/images/blog/blog1.jpg" alt="" height="395px"/></Link>
                                </div>
                                <div className="blog-date">
                                    <h5>05 <span>May</span></h5>
                                </div>
                            </div>
                            <div className="blog-content">
                                <div className="blog-meta">
                                    <ul>
                                        <li><a href="#">Bamboo Bedsheets</a>,</li>
                                        <li>By:<a href="#"> Admin</a></li>
                                    </ul>
                                </div>
                                <h3><a href="blog-details.html">Lorem ipsum dolor consectet.</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipi elit, sed do eiusmod tempor incididunt ut labo et dolore magna aliqua.</p>
                                <div className="blog-btn-2 btn-hover">
                                    <Link className="btn hover-border-radius theme-color" to="/blogdetails">Read More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="blog-wrap mb-50" data-aos="fade-up" data-aos-delay="400">
                            <div className="blog-img-date-wrap mb-25">
                                <div className="blog-img">
                                    <Link to="/blogdetails"><img src="assets/images/blog/blog2Le.webp" alt="" height="395px"/></Link>
                                </div>
                                <div className="blog-date">
                                    <h5>03 <span>Jun.</span></h5>
                                </div>
                            </div>
                            <div className="blog-content">
                                <div className="blog-meta">
                                    <ul>
                                        <li><a href="#">Lenen Bedsheets</a>,</li>
                                        <li>By:<a href="#"> Admin</a></li>
                                    </ul>
                                </div>
                                <h3><a href="blog-details.html">Duis et volutpat pellentesque.</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipi elit, sed do eiusmod tempor incididunt ut labo et dolore magna aliqua.</p>
                                <div className="blog-btn-2 btn-hover">
                                    <Link to="/blogdetails"className="btn hover-border-radius theme-color" >Read More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="blog-wrap mb-50" data-aos="fade-up" data-aos-delay="600">
                            <div className="blog-img-date-wrap mb-25">
                                <div className="blog-img">
                                    <Link to="/blogdetails"><img src="assets/images/blog/blog3cot.jpeg" height="395px" alt=""/></Link>
                                </div>
                                <div className="blog-date">
                                    <h5>07 <span>Feb.</span></h5>
                                </div>
                            </div>
                            <div className="blog-content">
                                <div className="blog-meta">
                                    <ul>
                                        <li><a href="#">Cotton Bedsheets</a>,</li>
                                        <li>By:<a href="#"> Admin</a></li>
                                    </ul>
                                </div>
                                <h3><a href="blog-details.html">Vivamus vitae dolor convallis.</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipi elit, sed do eiusmod tempor incididunt ut labo et dolore magna aliqua.</p>
                                <div className="blog-btn-2 btn-hover">
                                    <Link to="/blogdetails" className="btn hover-border-radius theme-color" >Read More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="blog-wrap mb-50" data-aos="fade-up" data-aos-delay="200">
                            <div className="blog-img-date-wrap mb-25">
                                <div className="blog-img">
                                    <Link to="/blogdetails"><img src="assets/images/blog/blog4pol.jpeg" alt="" height="395px"/></Link>
                                </div>
                                <div className="blog-date">
                                    <h5>08 <span>Dec.</span></h5>
                                </div>
                            </div>
                            <div className="blog-content">
                                <div className="blog-meta">
                                    <ul>
                                        <li><a href="#">Polyster Bedsheets</a>,</li>
                                        <li>By:<a href="#"> Admin</a></li>
                                    </ul>
                                </div>
                                <h3><a href="blog-details.html">Vivamus amet tristique orci.</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipi elit, sed do eiusmod tempor incididunt ut labo et dolore magna aliqua.</p>
                                <div className="blog-btn-2 btn-hover">
                                    <Link to="/blogdetails" className="btn hover-border-radius theme-color" >Read More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="blog-wrap mb-50" data-aos="fade-up" data-aos-delay="400">
                            <div className="blog-img-date-wrap mb-25">
                                <div className="blog-img">
                                    <Link to="/blogdetails"><img src="assets/images/blog/blog5silk.jpeg" height="395px"alt=""/></Link>
                                </div>
                                <div className="blog-date">
                                    <h5>02 <span>Nov.</span></h5>
                                </div>
                            </div>
                            <div className="blog-content">
                                <div className="blog-meta">
                                    <ul>
                                        <li><a href="#">Silk bedsheets</a>,</li>
                                        <li>By:<a href="#"> Admin</a></li>
                                    </ul>
                                </div>
                                <h3><a href="blog-details.html">Pellentesque pretium place.</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipi elit, sed do eiusmod tempor incididunt ut labo et dolore magna aliqua.</p>
                                <div className="blog-btn-2 btn-hover">
                                    <Link to="/blogdetails" className="btn hover-border-radius theme-color" >Read More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="blog-wrap mb-50" data-aos="fade-up" data-aos-delay="600">
                            <div className="blog-img-date-wrap mb-25">
                                <div className="blog-img">
                                    <Link to="/blogdetails"><img src="assets/images/blog/blog6mic.webp" height="395px" alt=""/></Link>
                                </div>
                                <div className="blog-date">
                                    <h5>09 <span>Oct.</span></h5>
                                </div>
                            </div>
                            <div className="blog-content">
                                <div className="blog-meta">
                                    <ul>
                                        <li><a href="#">MicroFiber Bedsheets</a>,</li>
                                        <li>By:<a href="#"> Admin</a></li>
                                    </ul>
                                </div>
                                <h3><a href="blog-details.html">Sed euismod tristique dolor.</a></h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipi elit, sed do eiusmod tempor incididunt ut labo et dolore magna aliqua.</p>
                                <div className="blog-btn-2 btn-hover">
                                    <Link to="/blogdetails" className="btn hover-border-radius theme-color" >Read More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="pagination-style-1" data-aos="fade-up" data-aos-delay="200">
                    <ul>
                        <li><a className="active" href="#">1</a></li>
                        <li><a href="#">2</a></li>
                        <li><a href="#">3</a></li>
                        <li><a className="next" href="#"><i className=" ti-angle-double-right "></i></a></li>
                    </ul>
                </div> */}
            </div>
        </div>


        {/* About us */}
     
        
        <div className="testimonial-area pb-100">
            <div className="container">
                <div className="section-title-2 st-border-center text-center mb-75" data-aos="fade-up" data-aos-delay="200">
                    <h2>Testimonial</h2>
                </div>
                <div className="testimonial-active swiper-container">
                {load ? 
    <div
      style={{
        height: "400px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
   : <div className="swiper-wrapper">
   {Array.isArray(totalReviews) && totalReviews.length !== 0 ? (
totalReviews?.map((data,i) => (
   <div className="swiper-slide">

       <div className="single-testimonial" data-aos="fade-up" data-aos-delay="200">
           <div className="testimonial-img">
               <img src={data.customerImage} width="30%" alt=""/>
           </div>
           {Array.from({ length: 5 }, (datas, i) =>
                 i < data?.rating ? (
                   <span>
                     <FaStar
                       style={{
                         color: "#DD3D05",
                       }}
                     />
                   </span>
                 ) : (
                   <span style={{ margin: "2px" }}>
                     <AiOutlineStar />
                   </span>
                 )
               )}
           <p>{data.review}</p>
           <div className="testimonial-info">
               <h4>{data.customerName}</h4>
               <span> Our Client.</span>
           </div>
       </div>
   </div>
    ))
    ) : false

    }     
  
  
  
</div>}
                    
                </div>
            </div>
        </div>





{/* <div className="testimonial-area pb-100">
            <div className="container">
                <div className="section-title-2 st-border-center text-center mb-75" data-aos="fade-up" data-aos-delay="200">
                    <h2>Testimonial</h2>
                </div>
                <div className="testimonial-active swiper-container">
                <div className="swiper-wrapper">
                <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 4500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        // navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
   {Array.isArray(totalReviews) && totalReviews.length !== 0 ? (
totalReviews?.map((data,i) => (
    <SwiperSlide>

       <div className="single-testimonial" data-aos="fade-up" data-aos-delay="200">
           <div className="testimonial-img">
               <img src={data.customerImage} width="30%" alt=""/>
           </div>
           {Array.from({ length: 5 }, (datas, i) =>
                 i < data?.rating ? (
                   <span>
                     <FaStar
                       style={{
                         color: "#DD3D05",
                       }}
                     />
                   </span>
                 ) : (
                   <span style={{ margin: "2px" }}>
                     <AiOutlineStar />
                   </span>
                 )
               )}
           <p>{data?.review}</p>
           <div className="testimonial-info">
               <h4>{data?.customerName}</h4>
               <span> Our Client.</span>
           </div>
       </div>
   </SwiperSlide>
    ))
    ) : false

    }     
  </Swiper>
  
  
</div>
                    
                </div>
            </div>
        </div> */}







        <div className="funfact-area bg-img pt-100 pb-70" style={{backgroundImage:"url(assets/images/bg/bg-4.png)"}}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                        <div className="single-funfact text-center mb-30" data-aos="fade-up" data-aos-delay="200">
                            <div className="funfact-img">
                                <img src="assets/images/icon-img/client.png" alt=""/>
                            </div>
                            <h2 className="count">120</h2>
                            <span>Happy Clients</span>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                        <div className="single-funfact text-center mb-30" data-aos="fade-up" data-aos-delay="400">
                            <div className="funfact-img">
                                <img src="assets/images/icon-img/award.png" alt=""/>
                            </div>
                            <h2 className="count">90</h2>
                            <span>Award Winning</span>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                        <div className="single-funfact text-center mb-30" data-aos="fade-up" data-aos-delay="600">
                            <div className="funfact-img">
                                <img src="assets/images/icon-img/item.png" alt=""/>
                            </div>
                            <h2 className="count">230</h2>
                            <span>Totel Items</span>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                        <div className="single-funfact text-center mb-30 mrgn-none" data-aos="fade-up" data-aos-delay="800">
                            <div className="funfact-img">
                                <img src="assets/images/icon-img/cup.png" alt=""/>
                            </div>
                            <h2 className="count">350</h2>
                            <span>Cups of Coffee</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="team-area pt-100 pb-70">
            <div className="container">
                <div className="section-title-2 st-border-center text-center mb-75" data-aos="fade-up" data-aos-delay="200">
                    <h2>Our Staff</h2>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                        <div className="single-team-wrap text-center mb-30" data-aos="fade-up" data-aos-delay="200">
                            <img src="assets/images/team/team-1.png" alt=""/>
                            <div className="team-info-position">
                                <div className="team-info">
                                    <h3>Kari Rasmus</h3>
                                    <span>Sales Man</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                        <div className="single-team-wrap text-center mb-30" data-aos="fade-up" data-aos-delay="400">
                            <img src="assets/images/team/team-2.png" alt=""/>
                            <div className="team-info-position">
                                <div className="team-info">
                                    <h3>Kari Rasmus</h3>
                                    <span>Sales Man</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                        <div className="single-team-wrap text-center mb-30" data-aos="fade-up" data-aos-delay="600">
                            <img src="assets/images/team/team-3.png" alt=""/>
                            <div className="team-info-position">
                                <div className="team-info">
                                    <h3>Kari Rasmus</h3>
                                    <span>Sales Man</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="subscribe-area pb-100">
            <div className="container">
                <div className="section-title-3 text-center mb-55" data-aos="fade-up" data-aos-delay="200">
                    <h2>Join With Us!</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit seddo eiusmod tempor incididunt ut labore </p>
                </div>
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                        <div id="mc_embed_signup" className="subscribe-form" data-aos="fade-up" data-aos-delay="400">
                            <form id="mc-embedded-subscribe-form" className="validate subscribe-form-style" novalidate="" target="_blank" name="mc-embedded-subscribe-form" method="post" action="https://devitems.us11.list-manage.com/subscribe/post?u=6bbb9b6f5827bd842d9640c82&amp;id=05d85f18ef">
                                <div id="mc_embed_signup_scroll" className="mc-form">
                                    <input className="email" type="email" required="" placeholder="Email address…" name="EMAIL" value=""/>
                                    <div className="mc-news" aria-hidden="true">
                                        <input type="text" value="" tabIndex="-1" name="b_6bbb9b6f5827bd842d9640c82_05d85f18ef"/>
                                    </div>
                                    <div className="clear">
                                        <input id="mc-embedded-subscribe" className="button" type="submit" name="subscribe" value="Subscribe Now"/>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
       
        
    </>
  )
}

export default Blog
