import React from 'react'
import orderSuccess from "../../images/success.svg";
import {useNavigate} from "react-router-dom"

const OrderSuccess = () => {
  const navigate = useNavigate();
  return (
    <>
      <div >
      <center>
                                        <img
                                          src={orderSuccess}
                                          className="mb-4"
                                         
                                          style={{ width: "30%" }}
                                          alt="order success"
                                        />
                                          <div className="single-product-cart mt-3" style={{display: "flex",justifyContent: "center"}}>
                                    <a style={{fontSize:"16px",paddingTop:"10px",paddingBottom:"10px",paddingleft:"20px",color:"white",marginLeft:"12px",borderRadius:"20px"}}  onClick={()=>navigate("/orders")}>Track Orders <span></span></a>
                                    <a style={{fontSize:"16px",paddingTop:"10px",paddingBottom:"10px",paddingleft:"20px",color:"white",marginLeft:"12px",borderRadius:"20px"}} onClick={()=>navigate("/products")}>Keep Shopping <span></span></a>
                                
                                    
                                      
                                </div>
                                      </center>
      </div>
    </>
  )
}

export default OrderSuccess
