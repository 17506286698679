import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { orderReturn } from "../../Redux/ActionType";
import { Link } from "react-router-dom";
import { getOrdersDetails, orderReturns } from "../../Redux/Action";
import TrackingOrder from "./TrackingOrder";
import ReviewContainer from "./ReviewContainer";
import orderimage from "../../images/orders.svg";
import moment from "moment";
import BankDetails from "./BankDetails";
const OrdersDetails = () => {
  const token = localStorage.getItem("AuthTok");
  if (token === null || token === "undefined" || token === "") {
    window.location.href = "/login";
  }
  const current = new Date();
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;
  function numberWithCommas(x) {
    return x.toString().split(",")[0].length > 3
      ? x
          .toString()
          .substring(0, x.toString().split(".")[0].length - 3)
          .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          "," +
          x.toString().substring(x.toString().split(".")[0].length - 3)
      : x.toString();
  }
  let navigate = useNavigate();
  const [load, setLoad] = useState(true);
  const [show, setShow] = useState(false);
  const [reviewIndex, setReviewIndex] = useState(0);
  const [index, setIndex] = useState("");
  const [display, setDisplay] = useState(true);

  const { id } = useParams();
  const [getid, setGetid] = useState({
    orderId: id,
  });
  const dispatch = useDispatch();
  console.log("date");
  useEffect(() => {
    dispatch(getOrdersDetails(getid))
      .then((res) => {
        console.log("response", res.orderInfo.createdAt);
        setDisplay(moment(res.orderInfo.createdAt).add(6, "day"));
        setLoad(false);
      })
      .catch(() => {});
  }, []);

  const returns = useSelector(({ orderReturns }) => orderReturns.payload);
  // useEffect(() => {
  //   window.setTimeout(function () {
  //     dispatch({ type: orderReturn.return.success });
  //     window
  //       .$(".alert")
  //       .fadeTo(500, 0)
  //       .slideUp(500, function () {
  //         window.$(this).remove();
  //       });
  //   }, 120000);

  // }, [returns]);
  const currentTime = moment();
  const getDetails = useSelector(
    ({ getOrdersDetails }) => getOrdersDetails.payload
  );
  return load ? (
    <div
      style={{
        height: "400px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : (
    <div>
      <div className="breadcrumb-area bg-gray-4 ">
        <div className="container">
          <div className="breadcrumb-content text-center">
            <h2
              data-aos="fade-up"
              data-aos-delay="200"
              style={{ textTransform: "uppercase" }}
            >
              Orders
            </h2>
            <ul data-aos="fade-up" data-aos-delay="400">
              <li>
                <Link to="/profile">Profile</Link>
              </li>
              <li>
                <i className="ti-angle-right"></i>
              </li>
              <li>OrderDetails </li>
            </ul>
          </div>
        </div>
      </div>

      <div>
        <TrackingOrder />
      </div>
      <div className="container">
        <div className="row">
          <div className="register-in-title">
            <h3 style={{ textAlign: "center", marginBottom: "25px" }}>
              Order Details
            </h3>
            <div>
              {/* {returns ? (
                                        <div
                                          style={{ marginTop: "-20px" }}
                                          className="alert alert-warning alert-dismissible fade show"
                                          role="alert"
                                          mt-3
                                        >
                                         <span style={{textAlign:"center",fontWeight:"600"}}> Return Intiated Successfully Click <a href="/returns">Return & Refunds</a></span>
                                          <button
                                            type="button"
                                            className="btn-close"
                                            data-dismiss="alert"
                                            aria-label="Close"
                                            // onClick={()=>dispatch({type:addCart.add.success,data:false})}
                                          ></button>
                                        </div>
                                      ) : (
                                        false
                                      )} */}
            </div>
          </div>
          <div className="col-12">
            <form action="#">
              <div className="cart-table-content">
                <div className="table-content table-responsive">
                  <table>
                    {Array.isArray(getDetails?.productInfo) ? (
                      <thead>
                        <tr>
                          {/* <th className="width-thumbnail">S.No</th> */}

                          {/* {profileList?.profilePic  ? */}
                          <th className="width-price">S.No</th>

                          {/* : false} */}
                          <th className="width-price"> Name</th>
                          <th className="width-thumbnail"> Product</th>
                          {/* <th className="width-quantity">  District</th> */}
                          <th className="width-price"> Unit Price</th>
                          {/* <th className="width-price">    Courier Id & Name</th> */}
                          <th className="width-price"> Delivery Type</th>
                          <th className="width-price"> Total</th>
                          {getDetails?.orderInfo?.orderStatus ===
                          "delivered" ? (
                            <th className="width-price"> Action</th>
                          ) : (
                            false
                          )}
                          {getDetails?.orderInfo?.orderStatus === "accepted" ||
                          getDetails?.orderInfo?.orderStatus === "packaging" ? (
                            <th className="width-price"> Action</th>
                          ) : (
                            false
                          )}
                        </tr>
                      </thead>
                    ) : (
                      false
                    )}
                    <tbody>
                      <>
                        {Array.isArray(getDetails?.productInfo) &&
                        getDetails?.productInfo?.length !== 0 ? (
                          getDetails?.productInfo?.map((data, i) => (
                            <tr>
                              <td className="product-name">{i + 1}</td>
                              <td className="product-name">
                                <h5>
                                  <a
                                    style={{
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {data.productName}
                                  </a>
                                </h5>
                              </td>
                              <td className="product-thumbnail">
                                <a>
                                  <img
                                    src={
                                      process.env.REACT_APP_BASE_URL +
                                      data.variantImage
                                    }
                                    alt=""
                                  />
                                </a>
                              </td>

                              <td className="product-name">
                                <h5>
                                  <a
                                    //   href="product-details.html"
                                    style={{
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {data.units}
                                  </a>
                                </h5>
                              </td>
                              {/* <td className="product-name">
                          <h5 >
                            <a
                            //   href="product-details.html"
                              style={{
                                textTransform: "capitalize",
                              }}
                            >
                               {getDetails?.orderInfo?.trackingInfo}
                            </a>
                          </h5>
                        </td> */}
                              <td className="product-name">
                                <h5>
                                  <a
                                    //   href="product-details.html"
                                    style={{
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {getDetails?.orderInfo?.deliveryType}
                                  </a>
                                </h5>
                              </td>
                              <td className="product-name">
                                <h5>
                                  <a
                                    //   href="product-details.html"
                                    style={{
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    ₹ {numberWithCommas(data.totalPrice)}
                                  </a>
                                </h5>
                              </td>
                              {getDetails?.orderInfo?.orderStatus ===
                              "delivered" ? (
                                <td className="product-name">
                                  <h5>
                                    <span className="unit-amount">
                                      {data.isReviewed === 1 ? (
                                        <span
                                          className="badge bg-danger rounded-pill"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            setReviewIndex(i);
                                            if (show === i) setShow("");
                                            else setShow(i);
                                          }}
                                        >
                                          {show === i
                                            ? "Close Review"
                                            : "Write Review"}
                                        </span>
                                      ) : (
                                        // <span
                                        // style={{background:"#DD3D05",border:"#DD3D05",borderRadius:"20px"}}
                                        //   className="btns btn-dark"
                                        //   onClick={() => {
                                        //     setReviewIndex(i);
                                        //     if (show === i) setShow("");
                                        //     else setShow(i);
                                        //   }}
                                        // >

                                        //   {show === i
                                        //     ? "Close Review"
                                        //     : "Write Review"}
                                        // </span>

                                        // <button
                                        // className="btn " style={{background:"#DD3D05",color:"white",border:"none",marginTop:"15px",marginBottom:"15px",padding:"0px!important"}}

                                        // >
                                        // <a href="/reviewsTotal" style={{color:"white"}}> Go To Reviews</a>
                                        // </button>
                                        false
                                      )}
                                      {data.isReviewed === 2 ? (
                                        <span className="badge rounded-pill bg-success">
                                          {" "}
                                          <a
                                            href="/reviewsTotal"
                                            style={{ color: "white" }}
                                          >
                                            {" "}
                                            Go To Reviews
                                          </a>
                                        </span>
                                      ) : (
                                        false
                                      )}
                                      {/* {data.isReturn === 1?<span
                                  style={{background:"#DD3D05",border:"#DD3D05",borderRadius:"20px",marginLeft:"5px"}}
                                    className="btns btn-dark"
                                    onClick={() => {
                                      setReviewIndex(i);
                                      if (show === i) setShow("");
                                      else setShow(i);
                                    }}
                                  >
                                    Return Policies
                                  </span>:false}
                                  */}
                                      {currentTime >= display &&
                                      data.isReturn === 1 ? (
                                        <span
                                          style={{
                                            cursor: "pointer",
                                            marginLeft: "5px",
                                          }}
                                          onClick={() => {
                                            // setIndex(i);
                                            navigate(
                                              "/bankDetails/" + id + "/" + i
                                            );
                                            // dispatch(orderReturns({orderId:id,orderIndex:i})).then((res)=> {

                                            // })
                                          }}
                                          className="badge bg-danger rounded-pill"
                                        >
                                          {" "}
                                          Return
                                        </span>
                                      ) : (
                                        false
                                      )}
                                    </span>
                                  </h5>
                                </td>
                              ) : (
                                false
                              )}
                              <td>
                                {getDetails?.orderInfo?.orderStatus ===
                                  "accepted" ||
                                getDetails?.orderInfo?.orderStatus ===
                                  "packaging" ? (
                                  <span
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "600",
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {/* To Cancel Or Modify Order - <a href="mailto:Support@bedwell.in ?body=This is only a test!" style={{textDecoration:"underline"}}> Click Here</a> */}

                                    <a
                                      href={`mailto:Support@bedwell.in ?body= Order Id : ${
                                        getid.orderId
                                      } %0D%0A%0D%0AProduct Name :  ${
                                        data.productName
                                      } %0D%0A%0D%0ATotal Price : ₹  ${numberWithCommas(
                                        data.totalPrice
                                      )}`}
                                      style={{ textDecoration: "underline" }}
                                    >
                                      {" "}
                                      <span className="badge bg-danger">
                                        Cancel Order
                                      </span>
                                    </a>
                                  </span>
                                ) : (
                                  false
                                )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <center>
                            <img
                              src={orderimage}
                              className="mt-5 mb-5 pb-5 pt-5"
                              style={{ width: "25%" }}
                            />
                            <h3> No Orders Found</h3>{" "}
                          </center>
                        )}
                      </>
                    </tbody>
                  </table>
                </div>
              </div>
            </form>
            {show || show === 0 ? (
              <ReviewContainer
                order={getDetails?.productInfo?.[show]}
                reviewIndex={reviewIndex}
              />
            ) : (
              false
            )}
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default OrdersDetails;
