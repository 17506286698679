import React, { useState,useEffect } from "react";
import {useDispatch,useSelector } from 'react-redux'
import { FaStar } from "react-icons/fa";
import {  addReviews,getOrdersDetails } from "../../Redux/Action";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "./TrackingOrder.css";

const colors = {
    orange: "#DD3D05",
    grey: "#a9a9a9"  
};

function ReviewContainer(props) {
 
  const [load, setLoad] = useState(true)
  const {id}=useParams()
  const {id1}=useParams()
  const [getid, setGetid] = useState({
    orderId: id,
  });
  const [currentValue, setCurrentValue] = useState(0);
  const[review,setReview]=useState("")
  const dispatch = useDispatch()
  const [sent, Setsent] = useState({
    productId: props.order.productId,
    variantId:props.order.variantId ,
    reviewIndex:props.reviewIndex,
    orderId: id,
    rating:currentValue,
    review: review,
  });

  const [hoverValue, setHoverValue] = useState(undefined);
  const stars = Array(5).fill(0)
  const handleClick = value => {
    setCurrentValue(value)
  }
  const getDetails = useSelector(
    ({ getOrdersDetails }) => getOrdersDetails.payload
  );

  useEffect(() => {
    dispatch(getOrdersDetails(getid)).then(res => setLoad(false)).catch(res => setLoad(false))
  }, []);

  const handleMouseOver = newHoverValue => {
    setHoverValue(newHoverValue)
  };

  const handleMouseLeave = () => {
    setHoverValue(undefined)
  }

const AdddReview = () => {
  dispatch(addReviews({...sent,rating:currentValue,review:review})
 
  );
};
  return (
    <div style={styles.container}>
          
                          <>
      <h2 style={{textTransform: "capitalize" }}>  Ratings for {props.order.productName} </h2>
                          </>
   
      <div style={styles.stars}>
        {stars.map((_, index) => {
          return (
            <FaStar
              key={index}
              size={20}
              onClick={() => handleClick(index + 1)}
              onMouseOver={() => handleMouseOver(index + 1)}
              onMouseLeave={handleMouseLeave}
              color={(hoverValue || currentValue) > index ? colors.orange : colors.grey}
              style={{
                marginRight: 10,
                cursor: "pointer"
              }}
            />
          )
        })}
      </div>
      <textarea
        placeholder="What's your experience?"
        onChange={e=>setReview(e.target.value)}
        value={review}
        style={styles.textarea}
      />

{/* <div className="form-group shadow-textarea">
  <label for="exampleFormControlTextarea6">"What's your experience?"</label>
  <textarea className="form-control z-depth-1 mt-3 mb-3" id="exampleFormControlTextarea6" rows="3" placeholder="What's your experience"
  onChange={e=>setReview(e.target.value)}
  value={review}
  ></textarea>
</div> */}
      <button
      
      type="submit" className="btn btn-dark" 
        style={styles.button}
        onClick={AdddReview}
      >
        Submit
      </button>
      {/* <button  onClick={AdddReview}className="btn btn-dark" style={{background:"#e97730",border:"none",marginTop:"5px",marginBottom:"25px"}}>
      Submit
                        <span></span>
                      </button> */}
    </div>
  );
};


const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop:"25px"
  },
  stars: {
    display: "flex",
    flexDirection: "row",
  },
  textarea: {
    border: "1px solid #a9a9a9",
    borderRadius: 5,
    padding: 10,
    margin: "20px 0",
    minHeight: 100,
    width: 300
  },
  button: {
    background:"#DD3D05",
    border: "1px solid #DD3D05",
    borderRadius: 5,
    width: 300,
    padding: 10,
    marginBottom:"35px"
  }

};




export default ReviewContainer;