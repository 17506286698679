import React, { useEffect, useState } from "react";
import "./TrackingOrder.css";
import { useParams } from "react-router-dom";
import { getOrdersDetails } from "../../Redux/Action";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
function TrackingOrder() {
  const [load, setLoad] = useState(true);
  const { id } = useParams();
  const [getid, setGetid] = useState({
    orderId: id,
  });
  // const [shipmentPartner, setShipmentPartner] = useState("");
  // const [shipmentId, setTrackingId] = useState("");
  const [tracking, setTracking] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOrdersDetails(getid))
      .then((res) => {
        setTracking(getdetails?.orderInfo?.trackingInfo.split(","));
        setLoad(false);
      })
      .catch(() => {});
  }, []);
  const token = localStorage.getItem("AuthTok");
  if (token === null || token === "undefined" || token === "") {
    window.location.href = "/login";
  }
  const shipementPartner = tracking[1];
  const shipementId = tracking[0];
  console.log(shipementPartner, "ship");
  const getdetails = useSelector(
    ({ getOrdersDetails }) => getOrdersDetails.payload
  );
  console.log("orderdata", getdetails.productInfo[0].totalPrice);
  return load ? (
    <div
      style={{
        height: "400px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : (
    <div className="container">
      <div className="row d-flex align-item-center justify-content-center">
        <div className="col-12 col-md-10 hh-grayBox pt45 pb20">
          <div className="row justify-content-between">
            <div
              className={
                getdetails?.orderInfo?.orderStatus === "accepted" ||
                getdetails?.orderInfo?.orderStatus === "packaging" ||
                getdetails?.orderInfo?.orderStatus === "dispatched" ||
                getdetails?.orderInfo?.orderStatus === "delivered"
                  ? "order-tracking completed"
                  : "order-tracking"
              }
            >
              <span className="is-complete"></span>
              <p>
                {" "}
                1 <br />
                <span>
                  {getdetails?.orderInfo?.orderStatus === "accepted"
                    ? moment(getdetails?.orderInfo?.createdAt).format(
                        "MMM D ddd Y"
                      )
                    : false}
                </span>
              </p>
            </div>
            <div
              className={
                getdetails?.orderInfo?.orderStatus === "packaging" ||
                getdetails?.orderInfo?.orderStatus === "dispatched" ||
                getdetails?.orderInfo?.orderStatus === "delivered"
                  ? "order-tracking completed"
                  : "order-tracking"
              }
            >
              <span className="is-complete"></span>
              <p>
                {" "}
                2 <br />
                <span>
                  {getdetails?.orderInfo?.orderStatus === "packaging"
                    ? moment(getdetails?.orderInfo?.createdAt).format(
                        "MMM D ddd Y"
                      )
                    : false}
                </span>
              </p>
            </div>
            <div
              className={
                getdetails?.orderInfo?.orderStatus === "dispatched" ||
                getdetails?.orderInfo?.orderStatus === "delivered"
                  ? "order-tracking completed"
                  : "order-tracking"
              }
            >
              <span className="is-complete"></span>
              <p>
                {" "}
                3 <br />
                <span>
                  {getdetails?.orderInfo?.orderStatus === "dispatched"
                    ? moment(getdetails?.orderInfo?.createdAt).format(
                        "MMM D ddd Y"
                      )
                    : false}
                </span>
              </p>
            </div>
            <div
              className={
                getdetails?.orderInfo?.orderStatus === "delivered"
                  ? "order-tracking completed"
                  : "order-tracking"
              }
            >
              <span className="is-complete"></span>
              <p>
                {" "}
                4 <br />
                <span>
                  {getdetails?.orderInfo?.orderStatus === "delivered"
                    ? moment(getdetails?.orderInfo?.createdAt).format(
                        "MMM D ddd Y"
                      )
                    : false}
                </span>
              </p>
            </div>
          </div>
          <div
            className="mt-5"
            style={{ textAlign: "center", fontSize: "18px" }}
          >
            <span> 1 - Acccepted , </span>
            <span> 2 - Packaging , </span>
            <span> 3 - Dispatched & </span>
            <span> 4 - Delivered </span>
          </div>
          {getdetails?.orderInfo?.trackingInfo !== "0" ? (
            <div className="mt-3" style={{ textAlign: "center" }}>
              <span style={{ fontSize: "18px", textTransform: "capitalize" }}>
                Shipment Tracking Id : {shipementId}
              </span>
              <br />
              <span style={{ fontSize: "18px", textTransform: "capitalize" }}>
                Shipment Partner : {shipementPartner}
              </span>
            </div>
          ) : (
            <div className="mt-3" style={{ textAlign: "center" }}>
              <span style={{ fontSize: "18px", textTransform: "capitalize" }}>
                Shipment Tracking Id : Not Added &nbsp;
              </span>
              <span style={{ fontSize: "18px", textTransform: "capitalize" }}>
                Shipment Partner: Not Added
              </span>
            </div>
          )}

          {/* {getdetails?.orderInfo?.orderStatus === "accepted"?
                <div className="mt-3" style={{textAlign:"center"}}>
                  <span style={{fontSize:"16px",fontWeight:"600",textTransform:"capitalize"}}>

                
                 
                 To Cancel Or Modify Order - <a href={`mailto:Support@bedwell.in ?body= Order Id : ${getid.orderId} %0D%0A%0D%0AProduct Name :  ${getdetails.productInfo[0].productName} %0D%0A%0D%0ATotal Price : ₹  ${getdetails.productInfo[0].totalPrice}`} style={{textDecoration:"underline"}}> Click Here</a>
                  </span>
                </div>:false
                } */}
        </div>
      </div>
    </div>
  );
}

export default TrackingOrder;
