import React, { useState, useEffect } from "react";
import {getProfiles} from "../../Redux/Action"
import AddressForm from "../Profiles/AddressForm";
import Order from "../Orders/OrdersList"
import ListReviews from "../Orders/ReviewsList"
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { authenticateLogin, registerUser,logoutUser } from "../../Redux/Action";
const Orders = () => {
  const [load, setLoad] = useState(true);
  const dispatch = useDispatch();
  const token =localStorage.getItem("AuthTok");
  if(token === null || token === "undefined" || token === ""){
    window.location.href = "/login";
  }

  useEffect(() => {
   dispatch(getProfiles())
   .then((res)=>{
    setLoad(false);
  })
    .catch(() => {
      console.log("Waiting For Network");
    });
  }, []);

  
  const profileList = useSelector(({ getProfiles }) => getProfiles.payload);
 
 //logout user
 const logout = () => {
  dispatch(logoutUser());
  localStorage.removeItem("AuthTok");
  
  window.location.href = "/login";
};

  return load ? (
    <div
      style={{
        height: "400px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : (
    
    <>
      <div className="main-wrapper main-wrapper-2">
      <div className="breadcrumb-area bg-gray-4  ">
            <div className="container" >
                <div className="breadcrumb-content text-center pt-5 pb-5">
                    <h2 data-aos="fade-up" data-aos-delay="200">Orders</h2>
                    <ul data-aos="fade-up" data-aos-delay="400">
                        <li><Link to="/">Home</Link></li>
                        <li><i className="ti-angle-right"></i></li>
                        <li>Orders List  </li>
                    </ul>
                </div>
            </div>
      
        </div>
       
        <div className="login-register-area pb-100" s >
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-12 offset-lg-2">
          
              
                  <div className="tab-content">
                
                    <Order/>
               
                   
                   
                 
                  </div>
            
              </div>
            </div>
          </div>
        </div>
        {/* <footer className="footer-area">
            <div className="bg-gray-2">
                <div className="container">
                    <div className="footer-top pt-80 pb-35">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="footer-widget footer-about mb-40">
                                    <div className="footer-logo">
                                        <a href="index-2.html"><img src="assets/images/logo/logo.png" alt="logo"/></a>
                                    </div>
                                    <p>Lorem ipsum dolor sit amet, cons adipi elit, sed do eiusmod tempor incididunt ut aliqua.</p>
                                    <div className="payment-img">
                                        <a href="#"><img src="assets/images/icon-img/payment.png" alt="logo"/></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="footer-widget footer-widget-margin-1 footer-list mb-40">
                                    <h3 className="footer-title">Information</h3>
                                    <ul>
                                        <li><a href="about-us.html">About Us</a></li>
                                        <li><a href="#">Delivery Information</a></li>
                                        <li><a href="#">Privacy Policy</a></li>
                                        <li><a href="#">Terms & Conditions</a></li>
                                        <li><a href="#">Customer Service</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                                <div className="footer-widget footer-list mb-40">
                                    <h3 className="footer-title">My Accound</h3>
                                    <ul>
                                        <li><a href="my-account.html">My Account</a></li>
                                        <li><a href="#">Order History</a></li>
                                        <li><a href="wishlist.html">Wish List</a></li>
                                        <li><a href="#">Newsletter</a></li>
                                        <li><a href="#">Order History</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="footer-widget footer-widget-margin-2 footer-address mb-40">
                                    <h3 className="footer-title">Get in touch</h3>
                                    <ul>
                                        <li><span>Address: </span>Your address goes here </li>
                                        <li><span>Telephone Enquiry:</span> (012) 345 6789</li>
                                        <li><span>Email: </span>demo@example.com</li>
                                    </ul>
                                    <div className="open-time">
                                        <p>Open : <span>8:00 AM</span> - Close : <span>18:00 PM</span></p>
                                        <p>Saturday - Sunday : Close</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-gray-3">
                <div className="container">
                    <div className="footer-bottom copyright text-center bg-gray-3">
                        <p>Copyright ©2021 All rights reserved | Made with <i className="fa fa-heart"></i> by <a href="https://hasthemes.com/"> HasThemes</a>.</p>
                    </div>
                </div>
            </div>
        </footer> */}
        {/* <!-- Mobile Menu start --> */}
        {/* <div className="off-canvas-active">
            <a className="off-canvas-close"><i className=" ti-close "></i></a>
            <div className="off-canvas-wrap">
                <div className="welcome-text off-canvas-margin-padding">
                    <p>Default Welcome Msg! </p>
                </div>
                <div className="mobile-menu-wrap off-canvas-margin-padding-2">
                    <div id="mobile-menu" className="slinky-mobile-menu text-left">
                        <ul>
                            <li>
                                <a href="#">HOME</a>
                                <ul>
                                    <li><a href="index-2.html">Home version 1 </a></li>
                                    <li><a href="index-3.html">Home version 2</a></li>
                                    <li><a href="index-4.html">Home version 3</a></li>
                                    <li><a href="index-5.html">Home version 4</a></li>
                                    <li><a href="index-6.html">Home version 5</a></li>
                                    <li><a href="index-7.html">Home version 6</a></li>
                                    <li><a href="index-8.html">Home version 7</a></li>
                                    <li><a href="index-9.html">Home version 8</a></li>
                                </ul>
                            </li>
                            <li>
                                <a href="#">SHOP</a>
                                <ul>
                                    <li>
                                        <a href="#">Shop Layout</a>
                                        <ul>
                                            <li><a href="shop.html">Standard Style</a></li>
                                            <li><a href="shop-sidebar.html">Shop Grid Sidebar</a></li>
                                            <li><a href="shop-list.html">Shop List Style</a></li>
                                            <li><a href="shop-list-sidebar.html">Shop List Sidebar</a></li>
                                            <li><a href="shop-right-sidebar.html">Shop Right Sidebar</a></li>
                                            <li><a href="shop-location.html">Store Location</a></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a href="#">Product Layout</a>
                                        <ul>
                                            <li><a href="product-details.html">Tab Style 1</a></li>
                                            <li><a href="product-details-2.html">Tab Style 2</a></li>
                                            <li><a href="product-details-gallery.html">Gallery style </a></li>
                                            <li><a href="product-details-affiliate.html">Affiliate style</a></li>
                                            <li><a href="product-details-group.html">Group Style</a></li>
                                            <li><a href="product-details-fixed-img.html">Fixed Image Style </a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <a href="#">PAGES </a>
                                <ul>
                                    <li><a href="about-us.html">About Us </a></li>
                                    <li><a href="cart.html">Cart Page</a></li>
                                    <li><a href="checkout.html">Checkout </a></li>
                                    <li><a href="my-account.html">My Account</a></li>
                                    <li><a href="wishlist.html">Wishlist </a></li>
                                    <li><a href="compare.html">Compare </a></li>
                                    <li><a href="contact-us.html">Contact us </a></li>
                                    <li><a href="login-register.html">Login / Register </a></li>
                                </ul>
                            </li>
                            <li>
                                <a href="#">BLOG </a>
                                <ul>
                                    <li><a href="blog.html">Blog Standard </a></li>
                                    <li><a href="blog-sidebar.html">Blog Sidebar</a></li>
                                    <li><a href="blog-details.html">Blog Details</a></li>
                                </ul>
                            </li>
                            <li>
                                <a href="about-us.html">ABOUT US</a>
                            </li>
                            <li>
                                <a href="contact-us.html">CONTACT US</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="language-currency-wrap language-currency-wrap-modify">
                    <div className="currency-wrap border-style">
                        <a className="currency-active" href="#">$ Dollar (US) <i className=" ti-angle-down "></i></a>
                        <div className="currency-dropdown">
                            <ul>
                                <li><a href="#">Taka (BDT) </a></li>
                                <li><a href="#">Riyal (SAR) </a></li>
                                <li><a href="#">Rupee (INR) </a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="language-wrap">
                        <a className="language-active" href="#"><img src="assets/images/icon-img/flag.png" alt=""/> English <i className=" ti-angle-down "></i></a>
                        <div className="language-dropdown">
                            <ul>
                                <li><a href="#"><img src="assets/images/icon-img/flag.png" alt=""/>English </a></li>
                                <li><a href="#"><img src="assets/images/icon-img/spanish.png" alt=""/>Spanish</a></li>
                                <li><a href="#"><img src="assets/images/icon-img/arabic.png" alt=""/>Arabic </a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
      </div>
    </>
  );
};

export default Orders;

