import React from "react";
import NotFounded from "../images/NotFound.svg";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <>
      <div>
        <center>
          <img
            src={NotFounded}
            className="mb-4"
            style={{ width: "30%" }}
            alt="order success"
          />
          <div
            class="single-product-cart mt-3"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <a
              style={{
                fontSize: "16px",
                paddingTop: "10px",
                paddingBottom: "10px",
                paddingleft: "20px",
                color: "white",
                marginLeft: "12px",
              }}
              onClick={() => navigate("/")}
            >
              Back to Home <span></span>
            </a>
          </div>
        </center>
      </div>
    </>
  );
};

export default NotFound;
