import React, { useState, useEffect } from "react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AiOutlineStar } from "react-icons/ai";
import { FaStar } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { updateReviews, deleteReviews } from "../../Redux/Action";

const UpdateReview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [review, setReview] = useState("");
  const [reviewRating, setReviewRating] = useState([]);
  const token = localStorage.getItem("AuthTok");

  if (token === null || token === "undefined" || token === "") {
    window.location.href = "/login";
  }

  const [load, setLoad] = useState(true);

  useEffect(() => {
    dispatch(updateReviews({ reviewId: id }))
      .then((res) => {
        setReview(res?.data?.data[0]?.review);
        setReviewRating(res.data.data);
        setLoad(false);
      })
      .catch(() => {});
  }, [dispatch, id]);

  const EditReview = () => {
    const updatedRating = reviewRating.length > 0 ? reviewRating[0].rating : 0;

    const updatedReviewData = {
      action: "edit",
      id: id,
      rating: updatedRating,
      review: review,
    };

    dispatch(deleteReviews(updatedReviewData))
      .then((res) => {
        Swal.fire({
          icon: "success",
          timer: 4000,
          buttons: false,
          showConfirmButton: false,
          text: res.data.data,
        }).then(() => {
          navigate("/reviewsTotal", { replace: true });
        });
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          timer: 4000,
          buttons: false,
          showConfirmButton: false,
          text: err.response.data.error.message,
        }).then(() => {
          navigate("/profile", { replace: true });
        });
      });
  };

  const handleRating = (reviewIndex, newRating) => {
    const updatedRatings = reviewRating.map((rating, index) =>
      index === reviewIndex ? { ...rating, rating: newRating } : rating
    );
    setReviewRating(updatedRatings);
  };

  return load ? (
    <div
      style={{
        height: "400px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : (
    <div style={styles.container}>
      <div className="breadcrumb-area bg-gray-4 ">
        <div className="container">
          <div className="breadcrumb-content text-center mb-5">
            <h2
              data-aos="fade-up"
              data-aos-delay="200"
              style={{ textTransform: "uppercase" }}
            >
              Review
            </h2>
            <ul data-aos="fade-up" data-aos-delay="400">
              <li>
                <Link to="/reviewsTotal">Reviews</Link>
              </li>
              <li>
                <i className="ti-angle-right"></i>
              </li>
              <li>Update Review </li>
            </ul>
          </div>
        </div>
      </div>
      <h2> Ratings </h2>
      {Array.isArray(reviewRating) &&
        reviewRating.map((R, reviewIndex) => (
          <div style={styles.stars} key={reviewIndex}>
            {Array.from({ length: 5 }, (data, i) => (
              <span
                style={{ margin: "2px", cursor: "pointer" }}
                onClick={() => handleRating(reviewIndex, i + 1)}
                key={i}
              >
                {i < R.rating ? (
                  <FaStar style={{ color: "#DD3D05" }} />
                ) : (
                  <AiOutlineStar />
                )}
              </span>
            ))}
          </div>
        ))}

      <textarea
        placeholder="What's your experience?"
        name="review"
        onChange={(e) => setReview(e.target.value)}
        value={review}
        style={styles.textarea}
      />
      <button style={styles.button} onClick={EditReview}>
        Update
      </button>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  stars: {
    display: "flex",
    flexDirection: "row",
  },
  textarea: {
    border: "1px solid #a9a9a9",
    borderRadius: 5,
    padding: 10,
    margin: "20px 0",
    minHeight: 100,
    width: 300,
  },
  button: {
    border: "1px solid #a9a9a9",
    borderRadius: "20px",
    width: 300,
    padding: 10,
    background: "#DD3D05",
    fontWeight: 600,
    color: "white",
  },
};

export default UpdateReview;
