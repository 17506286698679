import React, { useState, useEffect } from "react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Link, useNavigate } from "react-router-dom";
import {
  authenticateLogin,
  registerUser,
  logoutUser,
} from "../../Redux/Action";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const Register = () => {
  const today = moment();
  // const disableFutureDt = current => {
  //   return current.isBefore(today)
  // }
  //login
  const dispatch = useDispatch();

  const [count, setCount] = useState(true);
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();
  const [passworderror, setPassworderror] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    if (user.password.length > 3) {
      dispatch(authenticateLogin(user)).then((res) => {
        if (res.data.data === "Wrong Email/Password. Try Again!") {
          setPassworderror(res.data.data);
        } else if (user.password.length < 8) {
          setPassworderror("Min Password length should be 8 letters");
          setTimeout(() => {
            setPassworderror("");
          }, 5000);
        } else {
          window.location.href = "/login";
        }
      });
    } else {
      setPassworderror("Min Password length should be 8 letters");
    }
  };
  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const handleClickcouont = () => {
    setCount(!count);
  };

  //register user

  const [userDetails, setUserDetails] = useState({
    userName: "",
    email: "",
    password: "",
    phone: "",
    dob: "",
    gender: "",
    fcmToken: "wertyuio45frtgyh",
  });
  const [err, setErr] = useState("");
  const [errL, setErrL] = useState("");
  const Swal = require("sweetalert2");
  const handleRegisterSubmit = (e) => {
    e.preventDefault();
    e.currentTarget.reset();
    dispatch(registerUser(userDetails));
    if (userDetails.phone.length !== 10) {
      setErr("Mobile Number Should be 10");
      setTimeout(() => {
        setErr("");
      }, 5000);
    } else if (userDetails.password.length < 5) {
      setErrL("Minimum Password Length Required");
      setTimeout(() => {
        setErrL("");
      }, 5000);
    } else {
      dispatch(registerUser(userDetails))
        .then((res) => {
          Swal.fire({
            icon: "success",
            timer: 4000,
            buttons: false,
            showConfirmButton: false,
            text: res.data.data,
          }).then(() => {
            navigate("/login", { replace: true });
          });
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            timer: 4000,
            buttons: false,
            showConfirmButton: false,
            text: err.response.data.error.message,
          });
        });
    }
  };
  const handleRegisterChange = (e) => {
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
  };

  //logout user
  const logout = () => {
    dispatch(logoutUser());
    localStorage.removeItem("AuthTok");

    window.location.href = "/";
  };

  return (
    <>
      <div className="login-register-area pb-5 pt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12 offset-lg-2">
              <div className="login-register-wrapper">
                <div className="login-register-tab-list nav">
                  <a className="active" data-bs-toggle="tab" href="#lg1">
                    <h4 style={{ textTransform: "uppercase" }}> Register </h4>
                  </a>
                  <a data-bs-toggle="tab" href="#lg2">
                    <h4 style={{ textTransform: "uppercase" }}> Login </h4>
                  </a>
                </div>
                <div className="tab-content">
                  <div id="lg1" className="tab-pane active">
                    <div className="login-form-container">
                      <div className="login-register-form">
                        <form onSubmit={handleRegisterSubmit} method="post">
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="userName"
                                  value={userDetails.userName}
                                  onChange={handleRegisterChange}
                                  className="form-control"
                                  placeholder="Username"
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <input
                                  type="email"
                                  name="email"
                                  value={userDetails.email}
                                  onChange={handleRegisterChange}
                                  className="form-control"
                                  placeholder="Email"
                                  required
                                />
                              </div>
                            </div>

                            <div className="col-lg-6 mt-3">
                              <div className="form-group">
                                <input
                                  type="password"
                                  name="password"
                                  value={userDetails.password}
                                  onChange={handleRegisterChange}
                                  className="form-control"
                                  placeholder="Password"
                                  required
                                />
                                <span style={{ color: "red" }}>{errL} </span>
                              </div>
                            </div>
                            <div className="col-lg-6 mt-3">
                              <div className="form-group">
                                <input
                                  type="text"
                                  maxlength="10"
                                  minlength="10"
                                  name="phone"
                                  value={userDetails.phone}
                                  onChange={handleRegisterChange}
                                  className="form-control"
                                  placeholder="Phone"
                                  required
                                />
                                <span
                                  style={{ color: "red", marginTop: "15px" }}
                                >
                                  {err}{" "}
                                </span>
                              </div>
                            </div>
                            <div className="col-lg-6 mt-3">
                              <div className="form-group">
                                <input
                                  type="date"
                                  max={today.format("YYYY-MM-DD")}
                                  min="1940-01-02"
                                  name="dob"
                                  value={userDetails.dob}
                                  onChange={handleRegisterChange}
                                  className="form-control"
                                  placeholder="Date Of Birth"
                                  required
                                />
                              </div>
                            </div>

                            <div
                              style={{
                                marginBottom: "20px",
                                marginLeft: "11px",
                              }}
                            >
                              <input
                                type="checkbox"
                                style={{ marginTop: "-20px" }}
                                required
                              />

                              <span style={{ marginLeft: "20px" }}>
                                I have read , understood and agree with{" "}
                                <span
                                  style={{ color: "#e97730", fontSize: "15px" }}
                                >
                                  <a
                                    style={{ cursor: "pointer" }}
                                    href="/privacy"
                                  >
                                    {" "}
                                    Privacy Policy,{" "}
                                  </a>
                                  <a
                                    style={{ cursor: "pointer" }}
                                    href="/refund"
                                  >
                                    Refund Policy &
                                  </a>
                                  <a
                                    style={{ cursor: "pointer" }}
                                    href="/terms"
                                  >
                                    &nbsp; Terms & Conditions.
                                  </a>
                                </span>
                              </span>
                            </div>

                            {/* <div className="col-lg-6 mt-3">
                              <select
                                className="form-select"
                                // style={{ maxWidth: "500px" }}
                                value={userDetails.gender}
                                onChange={handleRegisterChange}
                                name="gender"
                                aria-label="Default select example"
                                style={{
                                  maxWidth: "600px",
                                  marginLeft: "1px",
                                  height: "48px",
                                }}
                              >
                                <option selected> Choose Gender</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="others">Transgender</option>
                              </select>
                            </div> */}

                            {/* <div className="form-check">
    <input type="checkbox" className="form-check-input" id="exampleCheck1"/>
    <label className="form-check-label" for="exampleCheck1">Creating an account means you’re okay with our Terms of Service, Privacy Policy, and our default Notification Settings.</label>
  </div> */}

                            <div className="button-box btn-hover">
                              <button type="submit">Register</button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div id="lg2" className="tab-pane">
                    <div className="login-form-container">
                      <div className="login-register-form">
                        <form onSubmit={handleSubmit} method="post">
                          <input
                            type="text"
                            name="email"
                            placeholder="Enter your Email Id"
                            onChange={handleChange}
                            value={user.email}
                            required
                          />
                          <input
                            type="password"
                            name="password"
                            placeholder="Enter YourPassword"
                            onChange={handleChange}
                            value={user.password}
                            required
                          />
                          <span style={{ color: "brown", marginLeft: "20px" }}>
                            {passworderror}
                          </span>
                          <div className="login-toggle-btn">
                            <Link to="/forgotPassword">Forgot Password?</Link>
                          </div>
                          <div className="button-box btn-hover">
                            <button type="submit">Login</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
