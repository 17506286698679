import React, { useState, useEffect } from "react";
import { Link,useNavigate } from "react-router-dom";
import { getProfiles, updateProfiles } from "../../Redux/Action";
import { useSelector, useDispatch } from "react-redux";
import { BsPencil } from "react-icons/bs";
import test from "../../images/test.jpg"
import Swal from "sweetalert2/dist/sweetalert2.js";

const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      paddingTop: "0px",
      paddingBottom: "0px",
    },
    preview: {
      display: "flex",
      flexDirection: "column",
    },
    image: { width: "100px", height: "100px", borderRadius: "50%" },
    // editimage:{
    //   color: "white",
    //   background: "black",
    //   width: "30px",
    //   padding: "7px",
    //   borderRadius: "50%",
    //   display: "flex",
    //   marginTop: "-70px",
    //   marginLeft: "230px",
    // }
  };
  






const UpdateProfile = () => {
  const token =localStorage.getItem("AuthTok");
  if(token === null || token === "undefined" || token === ""){
    window.location.href = "/login";
  }
    const [load, setLoad] = useState(true);
    const [user, setUser] = useState({
      image: "",
      username: "",
      alaisname: "",
      dob: "",
      sex: "",
    });
    const navigate = useNavigate();
    const [prevImg, setPrevImg] = useState("../images/profilepictrue.png");
    const [ifImage, setIfImg] = useState(false);
    
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getProfiles()).then(
          (res) => {
            setUser({
              ...user,
              username: res.data.data.userName,
              phone: res.data.data.phone,
              dob: res.data.data.dob,
              sex: res.data.data.gender,
            });
            if (res.data.data.profilePic) setPrevImg(res.data.data.profilePic);
          },
          dispatch(getProfiles()).then((res) => setLoad(false))
        );
      }, []);

      const imageChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setIfImg(true);
          setPrevImg(URL.createObjectURL(e.target.files[0]));
          setUser({ ...user, [e.target.name]: e.target.files[0] });
        }
      };
      const handleChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
      };
      const handleSubmit = (e) => {

        e.preventDefault();
       
        const formData = new FormData();
        Object.entries(user).map(([key, value]) => formData.append(key, value));
        dispatch(updateProfiles(formData)).then((res) => {


          
            setUser({
                image: "",
                username: "",
                phone: "",
                dob: "",
                sex: "",
              });

              Swal.fire({
                icon: "success",
                timer: 4000,
                buttons: false,
                showConfirmButton: false,
                text: res.data.data,
              }).then(() => {
                navigate("/profile", { replace: true });
            })
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            timer: 4000,
            buttons: false,
            showConfirmButton: false,
            text: err.response.data.error.message,
          })
        });
    //    window.location.href="/profile"
      };


  return load ? (
    <div
      style={{
        height: "400px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : (
    <>
       <div class="breadcrumb-area bg-gray-4">
            <div class="container">
                <div class="breadcrumb-content text-center">
                    <h2 data-aos="fade-up" data-aos-delay="200">UPDATE</h2>
                    <ul data-aos="fade-up" data-aos-delay="400">
                        <li><Link to="/profile">Profile</Link></li>
                        <li><i class="ti-angle-right"></i></li>
                        <li>Update Profile  </li>
                    </ul>
                </div>
            </div>
            {/* <div class="breadcrumb-img-1" data-aos="fade-right" data-aos-delay="200">
                <img src="/assets/images/banner/breadcrumb-1.png" alt=""/>
            </div>
            <div class="breadcrumb-img-2" data-aos="fade-left" data-aos-delay="200">
                <img src="/assets/images/banner/breadcrumb-2.png" alt=""/>
            </div> */}
        </div>
          <section class="register-area" >
        <div class="container col-lg-4 d-flex justify-content-start">
          <div class="register-form">
          
            
            <form onSubmit={handleSubmit}>
              <div class="row">
                {/* <div class="form-group">
                  <div>
                    <div style={styles.container}>
                      <div style={styles.preview}>
                        <img src={ifImage ?prevImg:
                          // process.env.REACT_APP_BASE_URL+
                          prevImg} style={styles.image} alt={user.username} />
                      </div>

                      <input
                        accept="image/*"
                        type="file"
                        name="image"
                        onChange={imageChange}
                        style={{
                          marginTop: "20px",
                          marginLeft: "85px",
                          width: "30px",
                          position: "absolute",
                          borderRadius: "50%",
                          opacity: "0",
                        }}
                      />
                      <div>
                        <span className="imageedit" style={{ color: "white" }}>
                          <BsPencil />
                        </span>
                      </div>
                    </div>
                  </div>
                </div> */}

                <div class="form-group">
                  <label className="my-3" style={{ fontWeight: "bold" }}>
                    User Name
                  </label>
                  <input
                    style={{marginBottom:"10px"}}
                    type="text"
                    name="username"
                    onChange={handleChange}
                    value={user.username}
                    class="form-control"
                    placeholder="User Name"
                    required
                  />
                </div>
              
                <div class="form-group">
                  <label className="mb-3" style={{ fontWeight: "bold" }}>
                    Dob
                  </label>
                  <input
                    style={{marginBottom:"10px"}}
                    type="Date"
                    name="dob"
                    value={user.dob}
                    onChange={handleChange}
                    class="form-control"
                    required
                  />
                </div>
                <div class="form-group">
                  <label className="mb-3" style={{ fontWeight: "bold" }}>
                    Phone number
                  </label>
                  <input type="tel" maxlength="10" 
                                   id="zipCode"
                                  name="phone"
                                  class="form-control  js-input-mobile"
                                  value={user.phone}
                                  onChange={handleChange}
                                  placeholder="Enter Your  Contact No"
                                  disabled
                                  required/>
                </div>

                {/* <label className="mb-3" style={{ fontWeight: "bold" }}>
                  Gender
                </label>
                <select
                  class="form-select"
                  // style={{ maxWidth: "500px" }}
                  value={user.sex}
                  onChange={handleChange}
                  name="sex"
                  aria-label="Default select example"
                  style={{
                    maxWidth: "610px",
                    marginLeft: "13px",
                    height: "49px",
                  }}
                >
                  <option selected> Choose Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="others">Transgender</option>
                </select> */}
                <div class="col-lg-12">
                  <div class="send-btn">
                    <center>
                      <button type="submit" class="btn btn-dark" style={{background:"#DD3D05",border:"none",borderRadius:"20px",marginTop:"25px",marginBottom:"25px"}}>
                        Udpate Profile
                        <span></span>
                      </button>
                     
                    </center>
                  </div>
                </div>
              </div>
            </form>


















            
          </div>
        </div>
      </section>
    </>
  )
}

export default UpdateProfile