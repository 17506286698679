import React, { useEffect, useState } from "react";
import { getUserAddress,deleteUserAddress } from "../../Redux/Action";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Link,useNavigate } from "react-router-dom";
const AddressList = () => {
  const navigate = useNavigate();
    const [error, setError] = useState("");
  const dispatch = useDispatch();
  const token =localStorage.getItem("AuthTok");
  if(token === null || token === "undefined" || token === ""){
    window.location.href = "/login";
  }
  useEffect(() => {
    dispatch(getUserAddress());
  }, []);

  const Address = useSelector(({ getUserAddress }) => getUserAddress.payload);

  return (
    <div>
  <div className="container">
  <div className="register-in-title">
              <h3 style={{ textAlign: "center",marginBottom:"15px" }}>Address List</h3>
            </div>
            <div className="row">
              <div className="col-12">
                <form action="#">
                  <div className="cart-table-content">
                    <div className="table-content table-responsive">
                     
                        <table>
                          <thead>
                            <tr>
                              {/* <th className="width-thumbnail">S.No</th> */}
                              
                              {/* {profileList?.profilePic  ? */}
                              <th className="width-price">S.No</th>

                              {/* : false} */}
                              <th className="width-price">  Address</th>
                              {/* <th className="width-quantity">  District</th> */}
                             
                              <th className="width-price">  Primary</th>
                              <th className="width-price"> Address Type</th>
                              <th className="width-price">Action</th>
                             
                            </tr>
                          </thead>
                          <tbody>
                           
                                <>
                                {Array.isArray(Address) && Address.length !== 0
              ? Address?.map((data, i) => (
                                  <tr>
                                   <td  className="product-name" >{i + 1}</td>
                                   
                                    <td className="product-name">
                                      <h5 style={{textTransform: "capitalize"}}>
                                        <a
                                         
                                          
                                        >
                                          {
                                          data?.city +" ," + data?.district + " ," 
                                          + data?.landmark + " " + data?.state + ", " +data?.street + ", "+data?.zipcode
                                          
                                          
                                          
                                          
                                          }
                                        </a>
                                      </h5>
                                    </td>
                                  
                                    <td className="product-name">
                                      <h5>
                                        <a
                                       
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {data?.primary}
                                        </a>
                                      </h5>
                                    </td>
                                    <td className="product-name">
                                      <h5>
                                        <a
                                       
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {data?.addressType}
                                        </a>
                                      </h5>
                                    </td>
                                    <td className="product-name">
                                      <div className="row" style={{justifyContent: "center"}}>
                                        <div className="col-lg-4">
                                        <span className="badge bg-success">
                          <Link to={"/updateAddress/" + data?.id} style={{ color: "white" }}>
                          <i className='bx bxs-edit' style={{fontSize:"20px",}}></i>
                          </Link>
                        </span>
                                        </div>
                                        <div className="col-lg-4">
                                        <span className="badge bg-danger" style={{marginTop:"15px!important"}}>
                        <a
                          style={{ color: "white" }}
                          onClick={() => {
                            dispatch(
                                deleteUserAddress({
                                addressId: data.id,
                                action: "delete",
                              })
                            ).then((res) => Swal.fire({
                              icon: "success",
                              timer: 4000,
                              buttons: false,
                              showConfirmButton: false,
                              text: res.data.data,
                            }) )
                            
                            .catch((err) => {
                              Swal.fire({
                                icon: "error",
                                timer: 4000,
                                buttons: false,
                                showConfirmButton: false,
                                text: err.response.data.error.message,
                              })
                            });
                          }}
                        >
                          <span style={{fontSize:"20px"}}className="iconify" data-icon="clarity:trash-line"></span>

                        </a>
                      </span>
                      
                                        </div>
                                      </div>
                                  
                      
                                    </td>
                                    
                                    
                                 
                               
                          
                                  
                                  </tr>
                                  
                                   ))
                                   :<tr > 
                                       <td colspan="7" style={{color:"red",marginLeft:"50px",textAlign:"center",fontWeight:"600"}}>
                                       <span> No Address Found </span>
                                           </td>
                                   </tr>}
                                </>
                              
                          </tbody>
                        </table>
                   
                    </div>
                  </div>
                 
                </form>
              </div>
            </div>
       
          </div>

    </div>
  )
}

export default AddressList