import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Swal from "sweetalert2";
import reviews from "../images/NoReview.svg";
import { useSelector, useDispatch } from "react-redux";
import { FreeMode, Thumbs } from "swiper";
import "swiper/css";
import products from "../images/noproductsfound.svg";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/swiper-bundle.min.css";
// import "swiper/swiper-bundle.min.css";
// import "swiper/swiper.min.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import { Autoplay, Pagination, Navigation } from "swiper";
import "./styles.css";
// import "./product.css"
import {
  authenticateLogin,
  getSingleProduct,
  getRelatedProducts,
  getRecommendProducts,
  addWishLists,
  addCarts,
  customCarts,
  productSpecifications,
  getCategories,
  getCartCounts,
  getReviews,
  buyNows,
} from "../Redux/Action";
import { Carousel } from "react-responsive-carousel";
import { addCart, customCart, addWishList, buyNow } from "../Redux/ActionType";

import { FaStar } from "react-icons/fa";
import { AiOutlineStar } from "react-icons/ai";
import { AiFillShopping } from "react-icons/ai";
import { BsShareFill } from "react-icons/bs";
import { MdContentCopy } from "react-icons/md";

const Token = localStorage.getItem("AuthTok");
const ProductDetails = () => {
  const [load, setLoad] = useState(true);

  // for modal load
  const [loader, setLoader] = useState(true);

  const [length, setLength] = useState("");
  const [lengthData, setLengthData] = useState("");
  const [length1, setLength1] = useState("");
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [width1, setWidth1] = useState("");
  const [height1, setHeight1] = useState("");
  const [finalPrice, setFinalPrice] = useState("");
  const [finalPrice1, setFinalPrice1] = useState("");
  const [priceError, setPriceError] = useState("");
  const [interWidth, setInterWidth] = useState("");
  const [interLength, setInterLength] = useState("");
  const [limit, setLimit] = useState("");
  const [inches, setInches] = useState("");
  const [inputData, setInputData] = useState({ length: "", width: "" });
  const [inputData1, setInputData1] = useState({
    length1: "",
    width1: "",
    height1: "",
  });
  const [lengthMetrices, setLengthMetrices] = useState([]);
  const [lengthMetrices1, setLengthMetrices1] = useState([]);
  const [widthMetrices, setWidthMetrices] = useState([]);
  const [widthMetrices1, setWidthMetrices1] = useState([]);
  const [heightMetrices, setHeightMetrices] = useState([]);
  const [heightMetrices1, setHeightMetrices1] = useState([]);
  const [minLengthData, setMinLengthData] = useState("");
  const [minLengthData1, setMinLengthData1] = useState("");
  const [maxLengthData, setMaxLengthData] = useState("");
  const [maxLengthData1, setMaxLengthData1] = useState("");
  const [minWidthData, setMinWidthData] = useState("");
  const [minWidthData1, setMinWidthData1] = useState("");
  const [maxWidthData, setMaxWidthData] = useState("");
  const [maxWidthData1, setMaxWidthData1] = useState("");
  const [minHeightData, setMinHeightData] = useState("");
  const [minHeightData1, setMinHeightData1] = useState("");
  const [maxHeightData, setmaxHeightData] = useState("");
  const [maxHeightData1, setmaxHeightData1] = useState("");
  const [minRate, setMinrate] = useState("");
  const [flatMinRate, setFlatMinRate] = useState("");
  const [fitMinRate, setFitMinRate] = useState("");
  const [maxRate, setMaxrate] = useState("");
  const [flatMaxRate, setFlatMaxRate] = useState("");
  const [fitMaxRate, setFitMaxRate] = useState("");
  const [lengthTop, setLengthTop] = useState("");
  const [lengthMiddle, setLengthMiddle] = useState("");
  const [lengthBottom, setLengthBottom] = useState("");
  const [maxsheetLimit, setMaxSheetLimit] = useState("");
  const [midmattresHeight, setMidMattresHeight] = useState("");
  // const [minRate,setMinRate]=useState("");
  // const [maxRate,setMaxRate]=useState("");

  //customization value declare end

  const [cartId, getCartId] = useState({
    variantColor: "",
    variantId: "",
    units: 0,
  });
  const [customization, setCustomization] = useState({
    formula: "",
    userInput: "",
    customize: "",
    variantColor: "",
    variantId: "",
    units: 0,
  });

  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  // let sampleData="1";

  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      Width: 100,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    menuPaper: {
      height: 100,
      width: 100,
    },
  }));

  const classes = useStyles();
  let value = 2;
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const Swal = require("sweetalert2");
  const [passworderror, setPassworderror] = useState("");
  const { id } = useParams();
  const dispatch = useDispatch();

  const [userInput, setUserInput] = useState({
    length1: "",
    width1: "",
    height1: "",
  });

  const [userInput1, setUserInput1] = useState({ length: "", width: "" });
  const [userInput2, setUserInput2] = useState({ width: "", length: "" });

  const [quantity, setQuantity] = useState(1);
  const [change, setChange] = useState(0);
  const [Price, setPrice] = useState(0);

  const [Err, setErr] = useState("");
  const [changeVariant, setChangeVariant] = useState(0);
  const [render, setRender] = useState(false);
  console.log(changeVariant);
  const copyData = (urlPath) => {
    navigator.clipboard.writeText(urlPath);
    window.$("h5").text("URL copied!");
    setTimeout(() => {
      window.$("h5").text("");
    }, 2000);
  };

  // useEffect(() => {
  //   window.setTimeout(function () {

  //     window
  //       .$(".alert")
  //       .fadeTo(500, 0)
  //       .slideUp(500, function () {
  //         window.$(this).remove();
  //       });
  //   }, 3000);

  // }, [Err]);
  // useEffect(() => {
  //   let minLength =20;

  //   let maxLength =25;

  //   let arr=[];

  //   for (let i=minLength;i <= maxLength; i++){
  //     arr.push(i);
  //     setLengthMetrices(arr);

  //    }
  // }, [])

  // Calculations:
  const category = useSelector(({ getCategories }) => getCategories.payload);

  // const newData = Array.isArray(category) && category?.length !==0 ? category?.map((data,i)=>{
  //   setData(data);

  // }):false;

  //fetching payload datas

  const sproduct = useSelector(
    ({ getSingleProduct }) => getSingleProduct.payload
  );
  // const jj = useSelector((store) => console.log(store.getSingleProduct, "bb"));
  // console.log(sproduct);
  const rProducts = useSelector(
    ({ getRelatedProducts }) => getRelatedProducts.payload
  );
  const recommendProducts = useSelector(
    ({ getRecommendProducts }) => getRecommendProducts.payload
  );
  const productspecs = useSelector(
    ({ productSpecifications }) => productSpecifications.payload
  );

  const review = useSelector(({ getReviews }) => getReviews.payload);

  const proid = id;
  const [pid, setPid] = useState({
    productId: proid,
  });

  //modal api datas
  const SingleProduct = (proId) => {
    setPid({ productId: proId });
    setLoader(true);
    dispatch(getSingleProduct({ productId: proId })).then((res) => {
      console.log(res.data.data);
      console.log("It working");
      setLoader(false);

      // setVariantData(variant[0].customizationInputs.minLength);
    });

    // .then(res => setLoad(false));
  };

  const priceClick = (e) => {
    e.preventDefault();
    // if ((length > lengthMiddle) && (length <= lengthTop)) {

    //   setFinalPrice(width * maxRate);
    // } else if ((length <= lengthMiddle) && (length >= lengthBottom)) {

    //   setFinalPrice(width * minRate);
    // } else {

    //   setErr("User Size is Not Reachable,Please Try again later");

    //   setTimeout(() => {
    //     setErr("");
    //   }, 5000);
    // }

    //Flat sheets formula starts

    if (length === "" || inputData.length === "") {
      setErr("Length is Required");
      setTimeout(() => {
        setErr("");
      }, 5000);
    } else if (width === "" || inputData.width === "") {
      setErr("Width is Required");
      setTimeout(() => {
        setErr("");
      }, 5000);
    } else {
      if (inches === "cm") {
        if (inputData.length > lengthMiddle) {
          setFinalPrice(inputData.width * maxRate);
        } else if (
          inputData.length <= lengthMiddle &&
          inputData.length <= lengthBottom
        ) {
          setFinalPrice(inputData.width * minRate);
        } else {
          setErr("User Size is Not Reachable,Please Try again later");
          setTimeout(() => {
            setErr("");
          }, 5000);
        }
      } else {
        if (length > lengthMiddle) {
          setFinalPrice(width * maxRate);
        } else if (length <= lengthMiddle && length <= lengthBottom) {
          setFinalPrice(width * minRate);
        } else {
          setErr("User Size is Not Reachable,Please Try again later");
          setTimeout(() => {
            setErr("");
          }, 5000);
        }
      }
    }
    //Flat sheets formula ends
  };

  // if(inches === "cm"){

  //   setLimit(inputData1.length1 + (4 * inputData1.height1));
  //   setInterWidth(inputData1.width1 + (4 * inputData1.height1));
  //   setInterLength(inputData1.length1 + (4 * inputData1.height1));
  // }
  // else{

  //   setLimit(length1 + (4 * height1));
  //   setInterWidth(width1 + (4 * height1));
  //   setInterLength(length1 + (4 * height1));
  // }

  //get data from store
  const carts = useSelector(({ addCarts }) => addCarts.payload);
  const custom = useSelector(({ customCarts }) => customCarts.payload);
  const wish = useSelector(({ addWishLists }) => addWishLists.payload);
  const buy = useSelector(({ buyNows }) => buyNows.payload);

  // get details from api

  // useEffect(() => {
  //   const promises = [
  //     dispatch(productSpecifications(pid)),
  //     dispatch(getRelatedProducts()),
  //     dispatch(getRecommendProducts(pid)),
  //     dispatch(getReviews(pid)),
  //     dispatch(getCategories()),
  //   ];

  //   Promise.allSettled(promises)
  //     .then((res) => {
  //       console.log("all settelled result", res);

  //       setRender(true);
  //     })
  //     .catch((e) => {
  //       console.log("some error ocoured");
  //     });

  //   // cleaning not fullfilled promises if unmounted
  //   return () => {
  //     promises.forEach((promise) => {
  //       if (typeof promise.cancel === "function") {
  //         promise.cancel();
  //       }
  //     });
  //   };
  // }, []);

  // getting page product
  useEffect(() => {
    const promises = [
      dispatch(getRelatedProducts()),
      dispatch(getRecommendProducts(pid)),
      dispatch(getReviews(pid)),
      dispatch(getCategories()),
    ];

    dispatch(getSingleProduct(pid)).then((res) => {
      const data = res.data.data[0];
      const customizationInputs = data.customizationInputs;

      dispatch(
        productSpecifications({ productId: pid.productId, variantId: data.id })
      );

      Promise.allSettled(promises)
        .then((res) => {
          console.log("all settled result", res);
        })
        .catch((e) => {
          console.log("some error occurred", e);
        });

      setLoad(false);
      setRender(true);

      // Set pre values
      setMinLengthData(customizationInputs.minLength);
      setMinLengthData1(customizationInputs.minLength * 2.54);

      let minLength1 = Math.round(customizationInputs.minLength * 2.54);
      let maxLength1 = Math.round(customizationInputs.maxLength * 2.54);

      setMaxLengthData(customizationInputs.maxLength);
      setMaxLengthData1(customizationInputs.maxLength * 2.54);

      setMinWidthData(customizationInputs.minWidth);
      setMinWidthData1(Math.round(customizationInputs.minWidth * 2.54));
      let minWidth1 = Math.round(customizationInputs.minWidth * 2.54);

      setMaxWidthData(customizationInputs.maxWidth);
      setMaxWidthData1(Math.round(customizationInputs.maxWidth * 2.54));
      let maxWidth1 = Math.round(customizationInputs.maxWidth * 2.54);

      setMinHeightData(customizationInputs.minHeight);
      setMinHeightData1(Math.round(customizationInputs.minHeight * 2.54));
      let minHeight1 = Math.round(customizationInputs.minHeight * 2.54);

      setmaxHeightData(customizationInputs.maxHeight);
      setmaxHeightData1(Math.round(customizationInputs.maxHeight * 2.54));
      let maxHeight1 = Math.round(customizationInputs.maxHeight * 2.54);

      setMinrate(Number(customizationInputs.minRate));
      setFlatMinRate(Number(customizationInputs.flatMinRate));
      setFitMinRate(Number(customizationInputs.fitMinRate));
      setMaxrate(Number(customizationInputs.maxRate));
      setFlatMaxRate(Number(customizationInputs.flatMaxRate));
      setFitMaxRate(Number(customizationInputs.fittedMaxRate));
      setLengthTop(Number(customizationInputs.lengthTop));
      setLengthMiddle(Number(customizationInputs.lengthMiddle));
      setLengthBottom(Number(customizationInputs.lengthBottom));
      setMaxSheetLimit(Number(customizationInputs.maxSheetLimit));
      setMidMattresHeight(Number(customizationInputs.midMatresHeight));

      const lenArr = Array.from(
        {
          length:
            Number(customizationInputs.maxLength) -
            Number(customizationInputs.minLength) +
            1,
        },
        (_, i) => i + Number(customizationInputs.minLength)
      );
      setLengthMetrices(lenArr);

      const lenArr1 = Array.from(
        { length: maxLength1 - minLength1 + 1 },
        (_, i) => i + minLength1
      );
      setLengthMetrices1(lenArr1);

      const widArr = Array.from(
        {
          length:
            Number(customizationInputs.maxWidth) -
            Number(customizationInputs.minWidth) +
            1,
        },
        (_, i) => i + Number(customizationInputs.minWidth)
      );
      setWidthMetrices(widArr);

      const widArr1 = Array.from(
        { length: maxWidth1 - minWidth1 + 1 },
        (_, i) => i + minWidth1
      );
      setWidthMetrices1(widArr1);

      const heiArr = Array.from(
        {
          length:
            Number(customizationInputs.maxHeight) -
            Number(customizationInputs.minHeight) +
            1,
        },
        (_, i) => i + Number(customizationInputs.minHeight)
      );
      setHeightMetrices(heiArr);

      const heiArr1 = Array.from(
        { length: maxHeight1 - minHeight1 + 1 },
        (_, i) => i + minHeight1
      );
      setHeightMetrices1(heiArr1);

      // console.log("test", customizationInputs.minLength);
    });

    // setting pre values end

    // cleaning not fullfilled promises if unmounted
    return () => {
      promises.forEach((promise) => {
        if (typeof promise.cancel === "function") {
          promise.cancel();
        }
      });
    };
  }, []);

  // some jquery js to load at initial via useEffect
  useEffect(() => {
    window.setTimeout(function () {
      dispatch({ type: addCart.add.success });
      window
        .$(".alert")
        .fadeTo(500, 0)
        .slideUp(500, function () {
          window.$(this).remove();
        });
    }, 6000);
    window.setTimeout(function () {
      dispatch({ type: customCart.add.success });
      window
        .$(".alert3")
        .fadeTo(500, 0)
        .slideUp(500, function () {
          window.$(this).remove();
        });
    }, 6000);

    window.setTimeout(function () {
      dispatch({ type: addWishList.wishList.success });
      window
        .$(".alert1")
        .fadeTo(500, 0)
        .slideUp(500, function () {
          window.$(this).remove();
        });
    }, 6000);
  }, [carts, wish, custom]);
  useEffect(() => {
    /*----------------------------------------
		Product details small img slider 1
    -----------------------------------------*/
    if (window.$) {
      var productDetailsSmallOne = new window.Swiper(
        ".product-details-small-img-slider-1",
        {
          loop: false,
          spaceBetween: 12,
          slidesPerView: 4,
          direction: "vertical",
          navigation: {
            nextEl: ".pd-next",
            prevEl: ".pd-prev",
          },
          breakpoints: {
            0: {
              slidesPerView: 2,
            },
            576: {
              slidesPerView: 4,
            },
            992: {
              slidesPerView: 3,
            },
            1200: {
              slidesPerView: 4,
            },
          },
        }
      );
    }

    /*----------------------------------------
		Product details big img slider 1
    -----------------------------------------*/
    if (window.$) {
      var productDetailsBigThree = new window.Swiper(
        ".product-details-big-img-slider-1",
        {
          autoplay: false,
          delay: 5000,
          slidesPerView: 1,
          loop: false,
          thumbs: {
            swiper: productDetailsSmallOne,
          },
        }
      );
    }

    /*----------------------------------------
		Product details small img slider 2
    -----------------------------------------*/
    if (window.$) {
      var productDetailsSmallTwo = new window.Swiper(
        ".product-details-small-img-slider-2",
        {
          loop: false,
          spaceBetween: 20,
          slidesPerView: 4,
          navigation: {
            nextEl: ".pd-next-2",
            prevEl: ".pd-prev-2",
          },
          breakpoints: {
            0: {
              slidesPerView: 2,
            },
            479: {
              slidesPerView: 3,
            },
            576: {
              slidesPerView: 4,
            },
            992: {
              slidesPerView: 3,
            },
            1200: {
              slidesPerView: 4,
            },
          },
        }
      );
    }

    /*----------------------------------------
		Product details big img slider 2
    -----------------------------------------*/
    if (window.$) {
      var productDetailsBigTwo = new window.Swiper(
        ".product-details-big-img-slider-2",
        {
          autoplay: false,
          delay: 5000,
          slidesPerView: 1,
          loop: false,
          thumbs: {
            swiper: productDetailsSmallTwo,
          },
        }
      );
    }

    // Related product active
    if (window.$) {
      var relatedProductActive = new window.Swiper(".related-product-active", {
        loop: true,
        spaceBetween: 30,
        breakpoints: {
          320: {
            slidesPerView: 1,
          },
          576: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
          },
          992: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 4,
          },
        },
      });
    }
    if (window.$) {
    }
  }, []);

  // handlers and submits

  //userLogin handleChange
  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  //userlogin form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (user.password.length > 3) {
      dispatch(authenticateLogin(user)).then((res) => {
        if (res.data.data === "Wrong Email/Password. Try Again!") {
          setPassworderror(res.data.data);
        } else {
          window.location.href = "/ProductDetails/" + id;
        }
      });
    } else {
      setPassworderror("password must be  greater than 3 letters");
    }
  };

  const Clicktobuy = () => {
    cartId.variantId = sproduct[0]?.id;
    cartId.units = quantity;
    // cartId.variantColor = JSON.parse(sproduct[changeVariant]?.variantColor)[
    //   change
    // ];
    dispatch(buyNows(cartId)).then((res) => {
      var config = {
        root: "",
        style: {
          bodyBackgroundColor: "#fafafb",
          bodyColor: "",
          themeBackgroundColor: "#0FB8C9",
          themeColor: "#ffffff",
          headerBackgroundColor: "#284055",
          headerColor: "#ffffff",
          errorColor: "",
          successColor: "",
          card: {
            padding: "",
            backgroundColor: "",
          },
        },
        data: {
          orderId: res.data.data.orderId,
          token: res.data.data.txnToken,
          tokenType: "TXN_TOKEN",
          amount: res.data.data.discountPrice /* update amount */,
        },
        payMode: {
          labels: {},
          filter: {
            exclude: [],
          },
          order: ["CC", "DC", "NB", "UPI", "PPBL", "PPI", "BALANCE"],
        },
        website: "WEBSTAGING",
        flow: "DEFAULT",
        merchant: {
          mid: "sKxlKi69872184882034",
          redirect: false,
        },
        handler: {
          transactionStatus: function transactionStatus(paymentStatus) {},
          notifyMerchant: function notifyMerchant(eventName, data) {},
        },
      };
      if (window.Paytm && window.Paytm.CheckoutJS) {
        window.Paytm.CheckoutJS.init(config)
          .then(function onSuccess() {
            window.Paytm.CheckoutJS.invoke();
          })
          .catch(function onError(error) {});
      }
    });
  };

  const handleClick = () => {
    cartId.variantId = sproduct[changeVariant].id;
    cartId.units = quantity;
    // cartId.variantColor = JSON.parse(sproduct[changeVariant].variantColor)[
    //   change
    // ];

    dispatch(addCarts(cartId)).then((res) => {});
  };

  const handleClick1 = () => {
    if (inches === "cm") {
      customization.userInput = inputData;
      customization.formula = sproduct[changeVariant].productFormula;
      customization.customize = "yes";
      customization.variantId = sproduct[changeVariant].id;
      customization.units = quantity;
      // customization.variantColor = JSON.parse(sproduct[changeVariant].variantColor)[
      //   change
      // ];
    } else {
      customization.userInput = userInput1;
      customization.formula = sproduct[changeVariant].productFormula;
      customization.customize = "yes";
      customization.variantId = sproduct[changeVariant].id;
      customization.units = quantity;
      // customization.variantColor = JSON.parse(sproduct[changeVariant].variantColor)[
      //   change
      // ];
    }
    dispatch(customCarts(customization)).then((res) => {});
  };

  const handleClick2 = () => {
    if (inches === "cm") {
      customization.userInput = inputData1;
      customization.formula = sproduct[changeVariant].productFormula;
      customization.customize = "yes";
      customization.variantId = sproduct[changeVariant].id;
      customization.units = quantity;
      // customization.variantColor = JSON.parse(sproduct[changeVariant].variantColor)[
      //   change
      // ];
    } else {
      customization.userInput = userInput;
      customization.formula = sproduct[changeVariant].productFormula;
      customization.customize = "yes";
      customization.variantId = sproduct[changeVariant].id;
      customization.units = quantity;
      // customization.variantColor = JSON.parse(sproduct[changeVariant].variantColor)[
      //   change
      // ];
    }

    dispatch(customCarts(customization)).then((res) => {});
  };

  const handleClick3 = () => {
    if (inches === "cm") {
      customization.userInput = inputData;
      customization.formula = sproduct[changeVariant].productFormula;
      customization.customize = "yes";
      customization.variantId = sproduct[changeVariant].id;
      customization.units = quantity;
      // customization.variantColor = JSON.parse(sproduct[changeVariant].variantColor)[
      //   change
      // ];
    } else {
      customization.userInput = userInput2;
      customization.formula = sproduct[changeVariant].productFormula;
      customization.customize = "yes";
      customization.variantId = sproduct[changeVariant].id;
      customization.units = quantity;
      // customization.variantColor = JSON.parse(sproduct[changeVariant].variantColor)[
      //   change
      // ];
    }
    dispatch(customCarts(customization)).then((res) => {});
  };

  // utilis
  const Truncate = (string, number) => {
    if (!string) {
      return null;
    }
    if (string.length <= number) {
      return string;
    }
    return string.slice(0, number) + "...";
  };
  function formatAsPercent(num) {
    return new Intl.NumberFormat("default", {
      style: "percent",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    }).format(num / 100);
  }
  function numberWithCommas(x) {
    return x.toString().split(",")[0].length > 3
      ? x
          .toString()
          .substring(0, x.toString().split(".")[0].length - 3)
          .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          "," +
          x.toString().substring(x.toString().split(".")[0].length - 3)
      : x.toString();
  }

  // size tab switcher
  const SwitchCase = (props) => {
    //Mattress Protector
    switch (props.value) {
      case "2":
        const eventClick1 = (e) => {
          //Mattress Protector formula starts
          if (length === "" || inputData.length === "") {
            setErr("Length is Required");
            setTimeout(() => {
              setErr("");
            }, 5000);
          } else if (width === "" || inputData.width === "") {
            setErr("Width is Required");
            setTimeout(() => {
              setErr("");
            }, 5000);
          } else {
            if (inches === "cm") {
              setFinalPrice(Math.round(inputData.width) * maxRate);
            } else {
              setFinalPrice(width * maxRate);
            }
          }
        };

        return (
          <>
            <div class="login-form-container">
              <div class="login-register-form">
                <form method="">
                  <div className="row">
                    <div className="col-lg-3">
                      <FormControl
                        className={classes.formControl}
                        style={{
                          marginTop: "-3px",
                          width: "100%",
                          marginLeft: "6px",
                          marginBottom: "10px",
                        }}
                      >
                        <InputLabel id="demo-multiple-name-label">
                          Length
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          name="length"
                          value={length}
                          onChange={(event) => {
                            setLength(event.target.value);
                            setInputData({
                              ...inputData,
                              [event.target.name]: event.target.value / 2.54,
                            });
                            setUserInput2({
                              ...userInput2,
                              [event.target.name]: event.target.value,
                            });
                          }}
                          input={<OutlinedInput label="Name" />}
                          MenuProps={{ classes: { paper: classes.menuPaper } }}
                          // MenuProps={MenuProps}
                        >
                          {inches === "cm"
                            ? lengthMetrices1.map((name) => (
                                <MenuItem
                                  style={{ height: "45px" }}
                                  key={name}
                                  value={name}
                                  // style={getStyles(name, personName, theme)}
                                >
                                  {name}
                                </MenuItem>
                              ))
                            : lengthMetrices.map((name) => (
                                <MenuItem
                                  style={{ height: "45px" }}
                                  key={name}
                                  value={name}
                                  // style={getStyles(name, personName, theme)}
                                >
                                  {name}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-lg-3">
                      <FormControl
                        className={classes.formControl}
                        style={{
                          marginTop: "-3px",
                          width: "100%",
                          marginLeft: "6px",
                          marginBottom: "10px",
                        }}
                      >
                        <InputLabel id="demo-multiple-name-label">
                          Width
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          name="width"
                          value={width}
                          onChange={(event) => {
                            setWidth(event.target.value);
                            setInputData({
                              ...inputData,
                              [event.target.name]: event.target.value / 2.54,
                            });
                            setUserInput2({
                              ...userInput2,
                              [event.target.name]: event.target.value,
                            });
                          }}
                          input={<OutlinedInput label="Name" />}
                          MenuProps={{ classes: { paper: classes.menuPaper } }}
                          // MenuProps={MenuProps}
                        >
                          {inches === "cm"
                            ? widthMetrices1.map((name) => (
                                <MenuItem
                                  style={{ height: "45px" }}
                                  key={name}
                                  value={name}
                                  // style={getStyles(name, personName, theme)}
                                >
                                  {name}
                                </MenuItem>
                              ))
                            : widthMetrices.map((name) => (
                                <MenuItem
                                  style={{ height: "45px" }}
                                  key={name}
                                  value={name}
                                  // style={getStyles(name, personName, theme)}
                                >
                                  {name}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </div>

                    <div className="col-lg-3">
                      <select
                        class="form-select  shadow-none test"
                        id="drop"
                        name="inches"
                        value={inches}
                        onChange={(event) => {
                          setInches(event.target.value);
                        }}
                        aria-label="Default select example"
                        style={{
                          marginLeft: "7px",
                          height: "49px",
                          marginBottom: "15px",
                          background: "none",
                        }}
                      >
                        <option selected> Inch</option>
                        <option value="cm">Cm </option>
                      </select>
                    </div>

                    <div className="col-lg-3">
                      <button
                        type="button"
                        class="btn btn-danger mt-2"
                        onClick={eventClick1}
                        style={{
                          background: "#DD3D05",
                          cursor: "pointer",
                          borderRadius: "20px",
                          marginLeft: "11px",
                        }}
                      >
                        {" "}
                        <span style={{ fontWeight: "600" }}>Submit</span>
                      </button>
                    </div>
                  </div>

                  {Err ? <div class="error-text mt-2 mb-3">{Err}</div> : false}

                  {Err ? (
                    false
                  ) : (
                    <div
                      class="mb-4"
                      style={{
                        width: "92px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <span
                        class="price1"
                        style={{
                          marginLeft: "32px",
                          fontWeight: "500",
                          fontSize: "35px",
                        }}
                      >
                        {finalPrice
                          ? "₹" + numberWithCommas(Math.round(finalPrice))
                          : false}
                        {/* {(width === "") & (length === "") ? 0 : finalPrice} */}
                      </span>
                    </div>
                  )}
                </form>

                <div
                  class="wishshare"
                  style={{
                    display: "flex",
                    marginLeft: "10px",
                    marginBottom: "35px",
                  }}
                >
                  <div class="product-quality " style={{ textAlign: "center" }}>
                    <span
                      style={{
                        display: "flex",
                        padding: "2px 5px",
                        marginTop: "10px",
                        // marginBottom: "5px",
                      }}
                    >
                      <span
                        class="minus-btn"
                        onClick={() => {
                          return quantity > 1
                            ? setQuantity(quantity - 1)
                            : false;
                        }}
                      >
                        <i class="bx bx-minus"></i>
                      </span>
                      <input
                        type="text"
                        min="1"
                        disabled
                        style={{
                          outline: "none",
                          border: "none",
                          background: "none",
                          textAlign: "center",
                          marginTop: "-3px",
                        }}
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                      />

                      <span
                        class="plus-btn"
                        onClick={() => {
                          setQuantity(quantity + 1);
                        }}
                      >
                        <i class="bx bx-plus"></i>
                      </span>
                    </span>
                  </div>

                  <div
                    class="single-product-wishlist"
                    style={{ marginLeft: "20px", marginTop: "12px" }}
                  >
                    <a
                      href="#"
                      title="wishlist"
                      onClick={() => {
                        dispatch(
                          addWishLists({
                            productId: sproduct[changeVariant].productId,
                          })
                        ).then((res) => {});
                      }}
                    >
                      {sproduct[changeVariant]?.favourites === 0 ? (
                        <i class="bx bx-heart" style={{ fontSize: "26px" }}></i>
                      ) : (
                        <i
                          style={{ fontSize: "26px" }}
                          class="bx bxs-heart"
                        ></i>
                      )}
                    </a>
                  </div>
                  <div
                    class="single-product-cart btn-hover"
                    style={{ marginLeft: "20px", marginTop: "12px" }}
                  >
                    <span
                      style={{ cursor: "pointer", marginLeft: "-28px" }}
                      name="share"
                      onClick={(e) => {
                        e.preventDefault();
                        copyData(
                          process.env.REACT_APP_HOME_URL +
                            "/productDetails/" +
                            id
                        );
                      }}
                    >
                      {" "}
                      <MdContentCopy style={{ fontSize: "25px" }} />
                    </span>
                  </div>

                  <div
                    class="product-details-action-wrap"
                    style={{ marginLeft: "3px" }}
                  >
                    {Token ? (
                      <button
                        type="button"
                        class="btn btn-danger"
                        onClick={handleClick3}
                        style={{
                          background: "#DD3D05",
                          cursor: "pointer",
                          borderRadius: "20px",
                        }}
                      >
                        <span className="icons" style={{ fontWeight: "600" }}>
                          {" "}
                          Add to Cart
                        </span>
                        <i
                          style={{
                            marginLeft: "5px",
                            fontSize: "15px",
                          }}
                          class="fa fa-shopping-cart cartIcon"
                          aria-hidden="true"
                        ></i>
                      </button>
                    ) : (
                      <a data-bs-toggle="modal" data-bs-target="#loginModal">
                        <button
                          type="button"
                          class="btn btn-danger"
                          style={{
                            background: "#DD3D05",
                            cursor: "pointer",
                            borderRadius: "20px",
                          }}
                        >
                          <span className="icons" style={{ fontWeight: "600" }}>
                            {" "}
                            Add to Cart
                          </span>
                          <i
                            style={{
                              marginLeft: "5px",
                              fontSize: "15px",
                            }}
                            class="fa fa-shopping-cart cartIcon"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </a>
                    )}
                    <button
                      type="button"
                      class="btn btn-danger"
                      onClick={Clicktobuy}
                      style={{
                        background: "#DD3D05",
                        cursor: "pointer",
                        borderRadius: "20px",
                        marginLeft: "20px",
                      }}
                    >
                      <span className="icons" style={{ fontWeight: "600" }}>
                        Buy Now
                      </span>
                      <AiFillShopping
                        style={{
                          fontSize: "22px",
                          marginTop: "-5px",
                        }}
                      />
                    </button>
                  </div>
                </div>
                <div style={{ textAlign: "center" }}>
                  <span style={{ color: "#DD3D05", fontWeight: "600" }}>
                    No Return Policy available for custom products
                  </span>
                </div>
                <h5
                  style={{
                    marginLeft: "10px",
                    marginTop: "12px",
                    fontSize: "15px",
                  }}
                ></h5>

                <div>
                  {custom ? (
                    <div
                      style={{ marginTop: "20px", marginLeft: "10px" }}
                      class="alert alert-warning alert-dismissible fade show"
                      role="alert"
                      mt-3
                    >
                      {custom === "Already Added to Cart" ||
                      custom === "Added to Cart" ? (
                        <Link to="/cart">
                          {" "}
                          {custom} -{" "}
                          <span style={{ textDecoration: "underline" }}>
                            {" "}
                            Go to Cart
                          </span>{" "}
                        </Link>
                      ) : (
                        custom
                      )}
                      <button
                        type="button"
                        class="btn-close"
                        //   onClick={()=>dispatch({type:addCart.add.success,data:false})}
                        data-dismiss="alert3"
                      ></button>
                    </div>
                  ) : (
                    false
                  )}
                </div>
                <div>
                  {wish ? (
                    <div
                      style={{ marginLeft: "10px" }}
                      class="alert alert-warning alert-dismissible fade show"
                      role="alert"
                      mt-3
                    >
                      {wish}
                      <button
                        type="button"
                        class="btn-close"
                        data-dismiss="alert1"
                        //   onClick={()=>dispatch({type:addWishList.wishList.success,data:false})}
                        aria-label="Close"
                      ></button>
                    </div>
                  ) : (
                    false
                  )}
                </div>
              </div>
            </div>
          </>
        );
      //pillow protector
      case "3":
        const eventClick2 = (e) => {
          console.log("click event workeddd");
          //pillow protector formula starts

          if (length === "" || inputData.length === "") {
            setErr("Length is Required");
            setTimeout(() => {
              setErr("");
            }, 5000);
          } else if (width === "" || inputData.width === "") {
            setErr("Width is Required");
            setTimeout(() => {
              setErr("");
            }, 5000);
          } else {
            if (inches === "cm") {
              console.log("cm", Math.round(inputData.width));
              let interWidth = Math.round(inputData.width) * 2 + 2;
              setFinalPrice(interWidth * maxRate);
            } else {
              console.log("inch", width);
              let interWidth1 = width * 2 + 2;
              setFinalPrice(interWidth1 * maxRate);
            }
          }

          //pillow protector formula ends
        };

        return (
          <>
            <div class="login-form-container">
              <div class="login-register-form">
                <form method="">
                  <div className="row">
                    <div className="col-lg-3">
                      {/* <select
                  class="form-select  shadow-none test" 
                  id="drop"
                  name="length"
                  value={length}
                  onChange={(event) => {
                    setLength(event.target.value);
                    setInputData({...inputData,[event.target.name]: event.target.value*2.54});
                    setUserInput2({ ...userInput2, [event.target.name]: event.target.value });
                  }}
                  
                  aria-label="Default select example"
                  style={{marginLeft:"7px",height:"49px",marginBottom:"15px",background:"none"}}
                  >
                <option selected> Length</option>
                  <option value="30">30 </option>
                  <option value="40">40 </option>
                  <option value="50">50 </option>
                  <option value="60">60 </option>
                  <option value="70">70</option>
                  <option value="80">80</option>
                  <option value="90">90</option>
                  <option value="100">100</option>
                </select> */}
                      <FormControl
                        className={classes.formControl}
                        style={{
                          marginTop: "-3px",
                          width: "100%",
                          marginLeft: "6px",
                          marginBottom: "10px",
                        }}
                      >
                        <InputLabel id="demo-multiple-name-label">
                          {" "}
                          Length
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          name="length"
                          value={length}
                          onChange={(event) => {
                            setLength(event.target.value);
                            setInputData({
                              ...inputData,
                              [event.target.name]: event.target.value / 2.54,
                            });
                            setUserInput2({
                              ...userInput2,
                              [event.target.name]: event.target.value,
                            });
                          }}
                          input={<OutlinedInput label="Name" />}
                          MenuProps={{ classes: { paper: classes.menuPaper } }}
                          // MenuProps={MenuProps}
                        >
                          {inches === "cm"
                            ? lengthMetrices1.map((name) => (
                                <MenuItem
                                  style={{ height: "45px" }}
                                  key={name}
                                  value={name}
                                  // style={getStyles(name, personName, theme)}
                                >
                                  {name}
                                </MenuItem>
                              ))
                            : lengthMetrices.map((name) => (
                                <MenuItem
                                  style={{ height: "45px" }}
                                  key={name}
                                  value={name}
                                  // style={getStyles(name, personName, theme)}
                                >
                                  {name}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-lg-3">
                      {/* <select
                  class="form-select  shadow-none test" 
                  id="drop"
                  name="width"
                  value={width}
                  onChange={(event) => {
                    setWidth(event.target.value);
                    setInputData({...inputData,[event.target.name]: event.target.value*2.54});
                    setUserInput2({ ...userInput2, [event.target.name]: event.target.value });
                  }}
                  
                  aria-label="Default select example"
                  style={{marginLeft:"7px",height:"49px",marginBottom:"15px",background:"none"}}
                  >
               <option selected> Width</option>
                  <option value="30">30 </option>
                  <option value="40">40 </option>
                  <option value="50">50 </option>
                  <option value="60">60 </option>
                  <option value="70">70</option>
                  <option value="80">80</option>
                  <option value="90">90</option>
                  <option value="100">100</option>
                </select> */}
                      <FormControl
                        className={classes.formControl}
                        style={{
                          marginTop: "-3px",
                          width: "100%",
                          marginLeft: "6px",
                          marginBottom: "10px",
                        }}
                      >
                        <InputLabel id="demo-multiple-name-label">
                          {" "}
                          Width
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          name="width"
                          value={width}
                          onChange={(event) => {
                            setWidth(event.target.value);
                            setInputData({
                              ...inputData,
                              [event.target.name]: event.target.value / 2.54,
                            });
                            setUserInput2({
                              ...userInput2,
                              [event.target.name]: event.target.value,
                            });
                          }}
                          input={<OutlinedInput label="Name" />}
                          MenuProps={{ classes: { paper: classes.menuPaper } }}
                          // MenuProps={MenuProps}
                        >
                          {inches === "cm"
                            ? widthMetrices1.map((name) => (
                                <MenuItem
                                  style={{ height: "45px" }}
                                  key={name}
                                  value={name}
                                  // style={getStyles(name, personName, theme)}
                                >
                                  {name}
                                </MenuItem>
                              ))
                            : widthMetrices.map((name) => (
                                <MenuItem
                                  style={{ height: "45px" }}
                                  key={name}
                                  value={name}
                                  // style={getStyles(name, personName, theme)}
                                >
                                  {name}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-lg-3">
                      <select
                        class="form-select  shadow-none test"
                        id="drop"
                        name="inches"
                        value={inches}
                        onChange={(event) => {
                          setInches(event.target.value);
                        }}
                        aria-label="Default select example"
                        style={{
                          marginLeft: "7px",
                          height: "49px",
                          marginBottom: "15px",
                          background: "none",
                        }}
                      >
                        <option selected> Inch</option>
                        <option value="cm">Cm </option>
                      </select>
                    </div>
                    <div className="col-lg-3">
                      <button
                        type="button"
                        class="btn btn-danger mt-2"
                        onClick={eventClick2}
                        style={{
                          background: "#DD3D05",
                          cursor: "pointer",
                          borderRadius: "20px",
                          marginLeft: "11px",
                        }}
                      >
                        {" "}
                        <span style={{ fontWeight: "600" }}>Submit</span>
                      </button>
                    </div>
                  </div>

                  {Err ? <div class="error-text mt-2 mb-3">{Err}</div> : false}

                  {Err ? (
                    false
                  ) : (
                    <div
                      class="mb-4"
                      style={{
                        width: "92px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <span
                        class="price1"
                        style={{
                          // marginLeft:"32px",
                          fontWeight: "500",
                          fontSize: "35px",
                        }}
                      >
                        {finalPrice
                          ? "₹" + numberWithCommas(Math.round(finalPrice))
                          : false}
                        {/* {(width === "") & (length === "") ? 0 : finalPrice} */}
                      </span>
                    </div>
                  )}
                </form>
                <div
                  class="wishshare"
                  style={{
                    display: "flex",
                    marginLeft: "10px",
                    marginBottom: "35px",
                  }}
                >
                  <div class="product-quality " style={{ textAlign: "center" }}>
                    <span
                      style={{
                        display: "flex",
                        padding: "2px 5px",
                        marginTop: "10px",
                        // marginBottom: "5px",
                      }}
                    >
                      <span
                        class="minus-btn"
                        onClick={() => {
                          return quantity > 1
                            ? setQuantity(quantity - 1)
                            : false;
                        }}
                      >
                        <i class="bx bx-minus"></i>
                      </span>
                      <input
                        type="text"
                        min="1"
                        disabled
                        style={{
                          outline: "none",
                          border: "none",
                          background: "none",
                          textAlign: "center",
                          marginTop: "-3px",
                        }}
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                      />

                      <span
                        class="plus-btn"
                        onClick={() => {
                          setQuantity(quantity + 1);
                        }}
                      >
                        <i class="bx bx-plus"></i>
                      </span>
                    </span>
                  </div>

                  <div
                    class="single-product-wishlist"
                    style={{ marginLeft: "20px", marginTop: "12px" }}
                  >
                    <a
                      href="#"
                      title="wishlist"
                      onClick={() => {
                        dispatch(
                          addWishLists({
                            productId: sproduct[changeVariant].productId,
                          })
                        ).then((res) => {});
                      }}
                    >
                      {sproduct[changeVariant]?.favourites === 0 ? (
                        <i class="bx bx-heart" style={{ fontSize: "26px" }}></i>
                      ) : (
                        <i
                          style={{ fontSize: "26px" }}
                          class="bx bxs-heart"
                        ></i>
                      )}
                    </a>
                  </div>
                  <div
                    class="single-product-cart btn-hover"
                    style={{ marginLeft: "20px", marginTop: "12px" }}
                  >
                    <span
                      style={{ cursor: "pointer", marginLeft: "-28px" }}
                      name="share"
                      onClick={(e) => {
                        e.preventDefault();
                        copyData(
                          process.env.REACT_APP_HOME_URL +
                            "/productDetails/" +
                            id
                        );
                      }}
                    >
                      {" "}
                      <MdContentCopy style={{ fontSize: "25px" }} />
                    </span>
                  </div>

                  <div
                    class="product-details-action-wrap"
                    style={{ marginLeft: "3px" }}
                  >
                    {Token ? (
                      <button
                        type="button"
                        class="btn btn-danger"
                        onClick={handleClick3}
                        style={{
                          background: "#DD3D05",
                          cursor: "pointer",
                          borderRadius: "20px",
                        }}
                      >
                        <span className="icons" style={{ fontWeight: "600" }}>
                          {" "}
                          Add to Cart
                        </span>
                        <i
                          style={{
                            marginLeft: "5px",
                            fontSize: "15px",
                          }}
                          class="fa fa-shopping-cart cartIcon"
                          aria-hidden="true"
                        ></i>
                      </button>
                    ) : (
                      <a data-bs-toggle="modal" data-bs-target="#loginModal">
                        <button
                          type="button"
                          class="btn btn-danger"
                          style={{
                            background: "#DD3D05",
                            cursor: "pointer",
                            borderRadius: "20px",
                          }}
                        >
                          <span className="icons" style={{ fontWeight: "600" }}>
                            {" "}
                            Add to Cart
                          </span>
                          <i
                            style={{
                              marginLeft: "5px",
                              fontSize: "15px",
                            }}
                            class="fa fa-shopping-cart cartIcon"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </a>
                    )}
                    <button
                      type="button"
                      class="btn btn-danger"
                      onClick={Clicktobuy}
                      style={{
                        background: "#DD3D05",
                        cursor: "pointer",
                        borderRadius: "20px",
                        marginLeft: "20px",
                      }}
                    >
                      <span className="icons" style={{ fontWeight: "600" }}>
                        Buy Now
                      </span>
                      <AiFillShopping
                        style={{
                          fontSize: "22px",
                          marginTop: "-5px",
                        }}
                      />
                    </button>
                  </div>
                </div>
                <div style={{ textAlign: "center" }}>
                  <span style={{ color: "#DD3D05", fontWeight: "600" }}>
                    No Return Policy available for custom products
                  </span>
                </div>
                <h5
                  style={{
                    marginLeft: "10px",
                    marginTop: "12px",
                    fontSize: "15px",
                  }}
                ></h5>

                <div>
                  {custom ? (
                    <div
                      style={{ marginTop: "20px", marginLeft: "10px" }}
                      class="alert alert-warning alert-dismissible fade show"
                      role="alert"
                      mt-3
                    >
                      {custom === "Already Added to Cart" ||
                      custom === "Added to Cart" ? (
                        <Link to="/cart">
                          {" "}
                          {custom} -{" "}
                          <span style={{ textDecoration: "underline" }}>
                            {" "}
                            Go to Cart
                          </span>{" "}
                        </Link>
                      ) : (
                        custom
                      )}
                      <button
                        type="button"
                        class="btn-close"
                        //   onClick={()=>dispatch({type:addCart.add.success,data:false})}
                        data-dismiss="alert3"
                      ></button>
                    </div>
                  ) : (
                    false
                  )}
                </div>
                <div>
                  {wish ? (
                    <div
                      style={{ marginLeft: "10px" }}
                      class="alert alert-warning alert-dismissible fade show"
                      role="alert"
                      mt-3
                    >
                      {wish}
                      <button
                        type="button"
                        class="btn-close"
                        data-dismiss="alert1"
                        //   onClick={()=>dispatch({type:addWishList.wishList.success,data:false})}
                        aria-label="Close"
                      ></button>
                    </div>
                  ) : (
                    false
                  )}
                </div>
              </div>
            </div>
          </>
        );

      //Matress comforter
      case "4":
        const eventClick3 = (e) => {
          //Matress comforter formula starts

          if (length === "" || inputData.length === "") {
            setErr("Length is Required");
            setTimeout(() => {
              setErr("");
            }, 5000);
          } else if (width === "" || inputData.width === "") {
            setErr("Width is Required");
            setTimeout(() => {
              setErr("");
            }, 5000);
          } else {
            if (inches === "cm") {
              setFinalPrice(Math.round(inputData.width) * maxRate);
            } else {
              setFinalPrice(width * maxRate);
            }
          }
          //Matress comforter formula ends
        };

        return (
          <>
            <div class="login-form-container">
              <div class="login-register-form">
                <form method="">
                  <div className="row">
                    <div className="col-lg-3">
                      {/* <select
                  class="form-select  shadow-none test" 
                  id="drop"
                  name="length"
                  value={length}
                  onChange={(event) => {
                    setLength(event.target.value);
                    setInputData({...inputData,[event.target.name]: event.target.value*2.54});
                    setUserInput2({ ...userInput2, [event.target.name]: event.target.value });
                  }}
                  
                  aria-label="Default select example"
                  style={{marginLeft:"7px",height:"49px",marginBottom:"15px",background:"none"}}
                  >
                <option selected> Length</option>
                  <option value="30">30 </option>
                  <option value="40">40 </option>
                  <option value="50">50 </option>
                  <option value="60">60 </option>
                  <option value="70">70</option>
                  <option value="80">80</option>
                  <option value="90">90</option>
                  <option value="100">100</option>
                </select> */}

                      <FormControl
                        className={classes.formControl}
                        style={{
                          marginTop: "-3px",
                          width: "100%",
                          marginLeft: "6px",
                          marginBottom: "10px",
                        }}
                      >
                        <InputLabel id="demo-multiple-name-label">
                          Length
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          name="length"
                          value={length}
                          onChange={(event) => {
                            setLength(event.target.value);
                            setInputData({
                              ...inputData,
                              [event.target.name]: event.target.value / 2.54,
                            });
                            setUserInput2({
                              ...userInput2,
                              [event.target.name]: event.target.value,
                            });
                          }}
                          input={<OutlinedInput label="Name" />}
                          MenuProps={{ classes: { paper: classes.menuPaper } }}
                          // MenuProps={MenuProps}
                        >
                          {inches === "cm"
                            ? lengthMetrices1.map((name) => (
                                <MenuItem
                                  style={{ height: "45px" }}
                                  key={name}
                                  value={name}
                                  // style={getStyles(name, personName, theme)}
                                >
                                  {name}
                                </MenuItem>
                              ))
                            : lengthMetrices.map((name) => (
                                <MenuItem
                                  style={{ height: "45px" }}
                                  key={name}
                                  value={name}
                                  // style={getStyles(name, personName, theme)}
                                >
                                  {name}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-lg-3">
                      {/* <select
                  class="form-select  shadow-none test" 
                  id="drop"
                  name="width"
                  value={width}
                  onChange={(event) => {
                    setWidth(event.target.value);
                    setInputData({...inputData,[event.target.name]: event.target.value*2.54});
                    setUserInput2({ ...userInput2, [event.target.name]: event.target.value });
                  }}
                  
                  aria-label="Default select example"
                  style={{marginLeft:"7px",height:"49px",marginBottom:"15px",background:"none"}}
                  >
               <option selected> Width</option>
                  <option value="30">30 </option>
                  <option value="40">40 </option>
                  <option value="50">50 </option>
                  <option value="60">60 </option>
                  <option value="70">70</option>
                  <option value="80">80</option>
                  <option value="90">90</option>
                  <option value="100">100</option>
                </select>
                  */}

                      <FormControl
                        className={classes.formControl}
                        style={{
                          marginTop: "-3px",
                          width: "100%",
                          marginLeft: "6px",
                          marginBottom: "10px",
                        }}
                      >
                        <InputLabel id="demo-multiple-name-label">
                          Width
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          name="width"
                          value={width}
                          onChange={(event) => {
                            setWidth(event.target.value);
                            setInputData({
                              ...inputData,
                              [event.target.name]: event.target.value / 2.54,
                            });
                            setUserInput2({
                              ...userInput2,
                              [event.target.name]: event.target.value,
                            });
                          }}
                          input={<OutlinedInput label="Name" />}
                          MenuProps={{ classes: { paper: classes.menuPaper } }}
                          // MenuProps={MenuProps}
                        >
                          {inches === "cm"
                            ? widthMetrices1.map((name) => (
                                <MenuItem
                                  style={{ height: "45px" }}
                                  key={name}
                                  value={name}
                                  // style={getStyles(name, personName, theme)}
                                >
                                  {name}
                                </MenuItem>
                              ))
                            : widthMetrices.map((name) => (
                                <MenuItem
                                  style={{ height: "45px" }}
                                  key={name}
                                  value={name}
                                  // style={getStyles(name, personName, theme)}
                                >
                                  {name}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </div>

                    <div className="col-lg-3">
                      <select
                        class="form-select  shadow-none test"
                        id="drop"
                        name="inches"
                        value={inches}
                        onChange={(event) => {
                          setInches(event.target.value);
                        }}
                        aria-label="Default select example"
                        style={{
                          marginLeft: "7px",
                          height: "49px",
                          marginBottom: "15px",
                          background: "none",
                        }}
                      >
                        <option selected> Inch</option>
                        <option value="cm">Cm </option>
                      </select>
                    </div>

                    <div className="col-lg-3">
                      <button
                        type="button"
                        class="btn btn-danger mt-2"
                        onClick={eventClick3}
                        style={{
                          background: "#DD3D05",
                          cursor: "pointer",
                          borderRadius: "20px",
                          marginLeft: "11px",
                        }}
                      >
                        {" "}
                        <span style={{ fontWeight: "600" }}>Submit</span>
                      </button>
                    </div>
                  </div>

                  {Err ? <div class="error-text mt-2 mb-3">{Err}</div> : false}

                  {Err ? (
                    false
                  ) : (
                    <div
                      class="mb-4"
                      style={{
                        width: "92px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <span
                        class="price1"
                        style={{
                          // marginLeft:"32px",
                          fontWeight: "500",
                          fontSize: "35px",
                        }}
                      >
                        {finalPrice
                          ? "₹" + numberWithCommas(Math.round(finalPrice))
                          : false}
                        {/* {(width === "") & (length === "") ? 0 : finalPrice} */}
                      </span>
                    </div>
                  )}
                </form>

                <div
                  class="wishshare"
                  style={{
                    display: "flex",
                    marginLeft: "10px",
                    marginBottom: "35px",
                  }}
                >
                  <div class="product-quality " style={{ textAlign: "center" }}>
                    <span
                      style={{
                        display: "flex",
                        padding: "2px 5px",
                        marginTop: "10px",
                        // marginBottom: "5px",
                      }}
                    >
                      <span
                        class="minus-btn"
                        onClick={() => {
                          return quantity > 1
                            ? setQuantity(quantity - 1)
                            : false;
                        }}
                      >
                        <i class="bx bx-minus"></i>
                      </span>
                      <input
                        type="text"
                        min="1"
                        disabled
                        style={{
                          outline: "none",
                          border: "none",
                          background: "none",
                          textAlign: "center",
                          marginTop: "-3px",
                        }}
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                      />

                      <span
                        class="plus-btn"
                        onClick={() => {
                          setQuantity(quantity + 1);
                        }}
                      >
                        <i class="bx bx-plus"></i>
                      </span>
                    </span>
                  </div>

                  <div
                    class="single-product-wishlist"
                    style={{ marginLeft: "20px", marginTop: "12px" }}
                  >
                    <a
                      href="#"
                      title="wishlist"
                      onClick={() => {
                        dispatch(
                          addWishLists({
                            productId: sproduct[changeVariant].productId,
                          })
                        ).then((res) => {});
                      }}
                    >
                      {sproduct[changeVariant]?.favourites === 0 ? (
                        <i class="bx bx-heart" style={{ fontSize: "26px" }}></i>
                      ) : (
                        <i
                          style={{ fontSize: "26px" }}
                          class="bx bxs-heart"
                        ></i>
                      )}
                    </a>
                  </div>
                  <div
                    class="single-product-cart btn-hover"
                    style={{ marginLeft: "20px", marginTop: "12px" }}
                  >
                    <span
                      style={{ cursor: "pointer", marginLeft: "-28px" }}
                      name="share"
                      onClick={(e) => {
                        e.preventDefault();
                        copyData(
                          process.env.REACT_APP_HOME_URL +
                            "/productDetails/" +
                            id
                        );
                      }}
                    >
                      {" "}
                      <MdContentCopy style={{ fontSize: "25px" }} />
                    </span>
                  </div>

                  <div
                    class="product-details-action-wrap"
                    style={{ marginLeft: "3px" }}
                  >
                    {Token ? (
                      <button
                        type="button"
                        class="btn btn-danger"
                        onClick={handleClick3}
                        style={{
                          background: "#DD3D05",
                          cursor: "pointer",
                          borderRadius: "20px",
                        }}
                      >
                        <span className="icons" style={{ fontWeight: "600" }}>
                          {" "}
                          Add to Cart
                        </span>
                        <i
                          style={{
                            marginLeft: "5px",
                            fontSize: "15px",
                          }}
                          class="fa fa-shopping-cart cartIcon"
                          aria-hidden="true"
                        ></i>
                      </button>
                    ) : (
                      <a data-bs-toggle="modal" data-bs-target="#loginModal">
                        <button
                          type="button"
                          class="btn btn-danger"
                          style={{
                            background: "#DD3D05",
                            cursor: "pointer",
                            borderRadius: "20px",
                          }}
                        >
                          <span className="icons" style={{ fontWeight: "600" }}>
                            {" "}
                            Add to Cart
                          </span>
                          <i
                            style={{
                              marginLeft: "5px",
                              fontSize: "15px",
                            }}
                            class="fa fa-shopping-cart cartIcon"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </a>
                    )}
                    <button
                      type="button"
                      class="btn btn-danger"
                      onClick={Clicktobuy}
                      style={{
                        background: "#DD3D05",
                        cursor: "pointer",
                        borderRadius: "20px",
                        marginLeft: "20px",
                      }}
                    >
                      <span className="icons" style={{ fontWeight: "600" }}>
                        Buy Now
                      </span>
                      <AiFillShopping
                        style={{
                          fontSize: "22px",
                          marginTop: "-5px",
                        }}
                      />
                    </button>
                  </div>
                </div>
                <div style={{ textAlign: "center" }}>
                  <span style={{ color: "#DD3D05", fontWeight: "600" }}>
                    No Return Policy available for custom products
                  </span>
                </div>
                <h5
                  style={{
                    marginLeft: "10px",
                    marginTop: "12px",
                    fontSize: "15px",
                  }}
                ></h5>

                <div>
                  {custom ? (
                    <div
                      style={{ marginTop: "20px", marginLeft: "10px" }}
                      class="alert alert-warning alert-dismissible fade show"
                      role="alert"
                      mt-3
                    >
                      {custom === "Already Added to Cart" ||
                      custom === "Added to Cart" ? (
                        <Link to="/cart">
                          {" "}
                          {custom} -{" "}
                          <span style={{ textDecoration: "underline" }}>
                            {" "}
                            Go to Cart
                          </span>{" "}
                        </Link>
                      ) : (
                        custom
                      )}
                      <button
                        type="button"
                        class="btn-close"
                        //   onClick={()=>dispatch({type:addCart.add.success,data:false})}
                        data-dismiss="alert3"
                      ></button>
                    </div>
                  ) : (
                    false
                  )}
                </div>
                <div>
                  {wish ? (
                    <div
                      style={{ marginLeft: "10px" }}
                      class="alert alert-warning alert-dismissible fade show"
                      role="alert"
                      mt-3
                    >
                      {wish}
                      <button
                        type="button"
                        class="btn-close"
                        data-dismiss="alert1"
                        //   onClick={()=>dispatch({type:addWishList.wishList.success,data:false})}
                        aria-label="Close"
                      ></button>
                    </div>
                  ) : (
                    false
                  )}
                </div>
              </div>
            </div>
          </>
        );

      //Dohar
      case "5":
        const clickEvent = (e) => {
          e.preventDefault();

          //Dohar formula start

          if (lengthData === "" || inputData.length === "") {
            setErr("Length is Required");
            setTimeout(() => {
              setErr("");
            }, 5000);
          } else if (width === "" || inputData.width === "") {
            setErr("Width is Required");
            setTimeout(() => {
              setErr("");
            }, 5000);
          } else {
            if (inches === "cm") {
              // console.log("cm data length",inputData.length)
              // console.log("cm data width",inputData.width)
              // if(inputData.length === "50"){

              //   let interWidthData =inputData.width *2.40;
              //   setFinalPrice(interWidthData * minRate);
              // }
              //    else if(inputData.length === "60"){
              //     let interWidthData1 =width *2.40;
              //   setFinalPrice(interWidthData1 * maxRate);
              // }
              if (inputData.length === 90) {
                console.log("input Data lenth");
                let interWidthData = Math.round(inputData.width) * 2.4;
                setFinalPrice(Number(interWidthData * minRate));
              } else if (inputData.length === 100) {
                let interWidthData1 = Math.round(inputData.width) * 2.4;
                setFinalPrice(Number(interWidthData1 * maxRate));
              } else {
                setErr("User Size is not reachable..pls try again");
                setTimeout(() => {
                  setErr("");
                  setFinalPrice("");
                }, 5000);
              }
            } else {
              // if(lengthData === "50"){

              //   let interWidthData =width *2.40;
              //   setFinalPrice(interWidthData * minRate);
              // }
              // else if(lengthData === "60"){
              //   let interWidthDat1 =width *2.40;
              //   setFinalPrice(interWidthDat1 * maxRate);
              // }
              if (lengthData === "90") {
                let interWidthData = Number(width) * 2.4;
                console.log("90works", Number(interWidthData) * minRate);
                setFinalPrice(Number(interWidthData) * minRate);
              } else if (lengthData === "100") {
                let interWidthDat1 = width * 2.4;
                setFinalPrice(Number(interWidthDat1 * maxRate));
              } else {
                // console.log("errorelse  ")
                setErr("User Size is not reachable..pls try again");
                setTimeout(() => {
                  setErr("");
                  setFinalPrice("");
                }, 5000);
              }
            }
          }
          //Dohar formula End
        };

        return (
          <>
            <div class="login-form-container">
              <div class="login-register-form">
                <form method="">
                  <div className="row">
                    <div className="col-lg-3">
                      <select
                        class="form-select  shadow-none test"
                        id="drop"
                        name="length"
                        value={userInput2.length}
                        onChange={(event) => {
                          setLengthData(event.target.value);
                          setInputData({
                            ...inputData,
                            [event.target.name]: event.target.value / 2.54,
                          });
                          setUserInput2({
                            ...userInput2,
                            [event.target.name]: event.target.value,
                          });
                        }}
                        aria-label="Default select example"
                        style={{
                          marginLeft: "7px",
                          height: "49px",
                          marginBottom: "15px",
                          background: "none",
                        }}
                      >
                        <option selected> Length </option>
                        {inches === "cm" ? (
                          <>
                            <option value="228.6">229 </option>
                            <option value="254">254</option>
                          </>
                        ) : (
                          <>
                            <option value="90">90 </option>
                            <option value="100">100</option>
                          </>
                        )}
                      </select>
                      {/* <FormControl className={classes.formControl} style={{marginTop:"-3px",width:"100%",marginLeft:"6px",marginBottom:"10px"}}>
        <InputLabel id="demo-multiple-name-label">Length</InputLabel>
        <Select
       
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          name="length"
          value={length}
          onChange={(event) => {
            console.log("event",event.target.value);
            setLength(event.target.value);
            setInputData({...inputData,[event.target.name]: event.target.value*2.54});
            setUserInput2({ ...userInput2, [event.target.name]: event.target.value });
            }}
          input={<OutlinedInput label="Name" />}
          MenuProps={{ classes: { paper: classes.menuPaper } }}
          // MenuProps={MenuProps}
        >
          {lengthMetrices.map((name) => (
            <MenuItem
            style={{height:"45px"}}
              key={name}
              value={name}
             
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl> */}
                    </div>
                    <div className="col-lg-3">
                      {/* <select
              size="5"
                  class="form-select  shadow-none test" 
                  id="drop"
                  name="width"
                  value={width}
                  // size="20"
                  onChange={(event) => {
                    
                    setWidth(event.target.value);
                    setInputData({...inputData,[event.target.name]: event.target.value*2.54});
                    setUserInput2({ ...userInput2, [event.target.name]: event.target.value });
                  }}
                  
                  aria-label="Default select example"
                  style={{marginLeft:"7px",height:"49px",marginBottom:"15px",background:"none"}}
                  >
               <option  selected > Width</option>
              
                  {Array.isArray(test) && lengthMetrices.map((datas)=>
<option value={datas}>{datas} </option>
)}
                </select> */}

                      <FormControl
                        className={classes.formControl}
                        style={{
                          marginTop: "-3px",
                          width: "100%",
                          marginLeft: "6px",
                          marginBottom: "10px",
                        }}
                      >
                        <InputLabel id="demo-multiple-name-label">
                          Width
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          name="width"
                          value={width}
                          onChange={(event) => {
                            setWidth(event.target.value);
                            setInputData({
                              ...inputData,
                              [event.target.name]: event.target.value / 2.54,
                            });
                            setUserInput2({
                              ...userInput2,
                              [event.target.name]: event.target.value,
                            });
                          }}
                          input={<OutlinedInput label="Name" />}
                          MenuProps={{ classes: { paper: classes.menuPaper } }}
                          // MenuProps={MenuProps}
                        >
                          {inches === "cm"
                            ? widthMetrices1.map((name) => (
                                <MenuItem
                                  style={{ height: "45px" }}
                                  key={name}
                                  value={name}
                                  // style={getStyles(name, personName, theme)}
                                >
                                  {name}
                                </MenuItem>
                              ))
                            : widthMetrices.map((name) => (
                                <MenuItem
                                  style={{ height: "45px" }}
                                  key={name}
                                  value={name}
                                  // style={getStyles(name, personName, theme)}
                                >
                                  {name}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </div>

                    <div className="col-lg-3">
                      <select
                        class="form-select  shadow-none test"
                        id="drop"
                        name="inches"
                        value={inches}
                        onChange={(event) => {
                          setInches(event.target.value);
                        }}
                        aria-label="Default select example"
                        style={{
                          marginLeft: "7px",
                          height: "49px",
                          marginBottom: "15px",
                          background: "none",
                        }}
                      >
                        <option selected> Inch</option>
                        <option value="cm">Cm </option>
                      </select>
                    </div>

                    <div className="col-lg-3">
                      <button
                        type="button"
                        class="btn btn-danger mt-2"
                        onClick={clickEvent}
                        style={{
                          background: "#DD3D05",
                          cursor: "pointer",
                          borderRadius: "20px",
                          marginLeft: "11px",
                        }}
                      >
                        {" "}
                        <span style={{ fontWeight: "600" }}>Submit</span>
                      </button>
                    </div>
                  </div>

                  {Err ? (
                    <div class="error-text mt-2 mb-3">{Err}</div>
                  ) : (
                    <div
                      class="mb-4"
                      style={{
                        width: "92px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <span
                        class="price1"
                        style={{
                          // marginLeft:"32px",
                          fontWeight: "500",
                          fontSize: "35px",
                        }}
                      >
                        {finalPrice
                          ? "₹" + numberWithCommas(Math.round(finalPrice))
                          : false}
                        {/* {(width === "") & (length === "") ? 0 : finalPrice} */}
                      </span>
                    </div>
                  )}

                  {/* {Err ? (
                    "false"
                  ) : (
                  <div class="mb-4" style={{width:"92px",display:"flex",justifyContent:"center"}} >
                    <span
                      class="price1"
                      style={{
                        
                       
                        fontWeight: "500",
                        fontSize: "18px",
                      }}
                    >
                
                      {finalPrice ? "₹" +Math.round(finalPrice):false}
                   
                    </span>
                  </div>
                  )} */}
                </form>
                <div
                  class="wishshare"
                  style={{
                    display: "flex",
                    marginLeft: "10px",
                    marginBottom: "35px",
                  }}
                >
                  <div class="product-quality " style={{ textAlign: "center" }}>
                    <span
                      style={{
                        display: "flex",
                        padding: "2px 5px",
                        marginTop: "10px",
                        // marginBottom: "5px",
                      }}
                    >
                      <span
                        class="minus-btn"
                        onClick={() => {
                          return quantity > 1
                            ? setQuantity(quantity - 1)
                            : false;
                        }}
                      >
                        <i class="bx bx-minus"></i>
                      </span>
                      <input
                        type="text"
                        min="1"
                        disabled
                        style={{
                          outline: "none",
                          border: "none",
                          background: "none",
                          textAlign: "center",
                          marginTop: "-3px",
                        }}
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                      />

                      <span
                        class="plus-btn"
                        onClick={() => {
                          setQuantity(quantity + 1);
                        }}
                      >
                        <i class="bx bx-plus"></i>
                      </span>
                    </span>
                  </div>

                  <div
                    class="single-product-wishlist"
                    style={{ marginLeft: "20px", marginTop: "12px" }}
                  >
                    <a
                      href="#"
                      title="wishlist"
                      onClick={() => {
                        dispatch(
                          addWishLists({
                            productId: sproduct[changeVariant].productId,
                          })
                        ).then((res) => {});
                      }}
                    >
                      {sproduct[changeVariant]?.favourites === 0 ? (
                        <i class="bx bx-heart" style={{ fontSize: "26px" }}></i>
                      ) : (
                        <i
                          style={{ fontSize: "26px" }}
                          class="bx bxs-heart"
                        ></i>
                      )}
                    </a>
                  </div>
                  <div
                    class="single-product-cart btn-hover"
                    style={{ marginLeft: "20px", marginTop: "12px" }}
                  >
                    <span
                      style={{ cursor: "pointer", marginLeft: "-28px" }}
                      name="share"
                      onClick={(e) => {
                        e.preventDefault();
                        copyData(
                          process.env.REACT_APP_HOME_URL +
                            "/productDetails/" +
                            id
                        );
                      }}
                    >
                      {" "}
                      <MdContentCopy style={{ fontSize: "25px" }} />
                    </span>
                  </div>

                  <div
                    class="product-details-action-wrap"
                    style={{ marginLeft: "3px" }}
                  >
                    {Token ? (
                      <button
                        type="button"
                        class="btn btn-danger"
                        onClick={handleClick3}
                        style={{
                          background: "#DD3D05",
                          cursor: "pointer",
                          borderRadius: "20px",
                        }}
                      >
                        <span className="icons" style={{ fontWeight: "600" }}>
                          {" "}
                          Add to Cart
                        </span>
                        <i
                          style={{
                            marginLeft: "5px",
                            fontSize: "15px",
                          }}
                          class="fa fa-shopping-cart cartIcon"
                          aria-hidden="true"
                        ></i>
                      </button>
                    ) : (
                      <a data-bs-toggle="modal" data-bs-target="#loginModal">
                        <button
                          type="button"
                          class="btn btn-danger"
                          style={{
                            background: "#DD3D05",
                            cursor: "pointer",
                            borderRadius: "20px",
                          }}
                        >
                          <span className="icons" style={{ fontWeight: "600" }}>
                            {" "}
                            Add to Cart
                          </span>
                          <i
                            style={{
                              marginLeft: "5px",
                              fontSize: "15px",
                            }}
                            class="fa fa-shopping-cart cartIcon"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </a>
                    )}
                    <button
                      type="button"
                      class="btn btn-danger"
                      onClick={Clicktobuy}
                      style={{
                        background: "#DD3D05",
                        cursor: "pointer",
                        borderRadius: "20px",
                        marginLeft: "20px",
                      }}
                    >
                      <span className="icons" style={{ fontWeight: "600" }}>
                        Buy Now
                      </span>
                      <AiFillShopping
                        style={{
                          fontSize: "22px",
                          marginTop: "-5px",
                        }}
                      />
                    </button>
                  </div>
                </div>

                <div style={{ textAlign: "center" }}>
                  <span style={{ color: "#DD3D05", fontWeight: "600" }}>
                    No Return Policy available for custom products
                  </span>
                </div>
                <h5
                  style={{
                    marginLeft: "10px",
                    marginTop: "12px",
                    fontSize: "15px",
                  }}
                ></h5>
                <div>
                  {custom ? (
                    <div
                      style={{ marginTop: "20px", marginLeft: "10px" }}
                      class="alert alert-warning alert-dismissible fade show"
                      role="alert"
                      mt-3
                    >
                      {custom === "Already Added to Cart" ||
                      custom === "Added to Cart" ? (
                        <Link to="/cart">
                          {" "}
                          {custom} -{" "}
                          <span style={{ textDecoration: "underline" }}>
                            {" "}
                            Go to Cart
                          </span>{" "}
                        </Link>
                      ) : (
                        custom
                      )}
                      <button
                        type="button"
                        class="btn-close"
                        //   onClick={()=>dispatch({type:addCart.add.success,data:false})}
                        data-dismiss="alert3"
                      ></button>
                    </div>
                  ) : (
                    false
                  )}
                </div>
                <div>
                  {wish ? (
                    <div
                      style={{ marginLeft: "10px" }}
                      class="alert alert-warning alert-dismissible fade show"
                      role="alert"
                      mt-3
                    >
                      {wish}
                      <button
                        type="button"
                        class="btn-close"
                        data-dismiss="alert1"
                        //   onClick={()=>dispatch({type:addWishList.wishList.success,data:false})}
                        aria-label="Close"
                      ></button>
                    </div>
                  ) : (
                    false
                  )}
                </div>
              </div>
            </div>
          </>
        );
      //Fitted sheets
      case "6":
        const clickEvent2 = (e) => {
          console.log("Click events Works");
          e.preventDefault();

          //Fitted sheets formula starts

          if (length1 === "" || inputData1.length1 === "") {
            setPriceError("Length is Required");
            setTimeout(() => {
              setPriceError("");
            }, 5000);
          } else if (width1 === "" || inputData1.width1 === "") {
            setPriceError("Width is Required");
            setTimeout(() => {
              setPriceError("");
            }, 5000);
          } else if (height1 === "" || inputData1.height1 === "") {
            setPriceError("Height is Required");
            setTimeout(() => {
              setPriceError("");
            }, 5000);
          } else {
            if (inches === "cm") {
              let limit =
                Math.round(inputData1.length1) +
                4 * Math.round(inputData1.height1);
              let interWidth =
                Math.round(inputData1.width1) +
                4 * Math.round(inputData1.height1);
              let interLength =
                Math.round(inputData1.length1) +
                4 * Math.round(inputData1.height1);

              if (limit <= maxsheetLimit) {
                if (Math.round(inputData1.height1) < midmattresHeight) {
                  setFinalPrice1(
                    Number(
                      (interWidth + 4 * Math.round(inputData1.height1)) *
                        maxRate
                    )
                  );
                } else {
                  setFinalPrice1(
                    Number(
                      (interWidth + 2 * Math.round(inputData1.height1)) *
                        maxRate
                    )
                  );
                }
              } else {
                setPriceError("User size is not reachable, please try again");
                setTimeout(() => {
                  setPriceError("");
                  setFinalPrice1("");
                }, 5000);
              }

              // if (limit <= maxsheetLimit) {
              //   if (Math.round(inputData1.height1) < midmattresHeight) {
              //     setFinalPrice1(
              //       Number(
              //         (interWidth + Math.round(inputData1.height1)) * maxRate
              //       )
              //     );
              //   } else if (
              //     Math.round(inputData1.height1) >= midmattresHeight &&
              //     Math.round(inputData1.height1) <= maxHeightData
              //   ) {
              //     setFinalPrice1(Number(interWidth * maxRate));
              //   } else {
              //     setPriceError(
              //       "User size is not reachable, please try again "
              //     );
              //     setTimeout(() => {
              //       setPriceError("");
              //       setFinalPrice1("");
              //     }, 5000);
              //   }
              // } else {
              //   setPriceError("User size is not reachable, please try again");
              //   setTimeout(() => {
              //     setPriceError("");
              //     setFinalPrice1("");
              //   }, 5000);
              // }
            } else {
              let limit1 = length1 + 4 * height1;
              let interWidth1 = width1 + 4 * height1;
              let interLength1 = length1 + 4 * height1;

              if (limit1 <= maxsheetLimit) {
                if (height1 < midmattresHeight) {
                  setFinalPrice1((interWidth1 + height1) * maxRate);
                } else if (
                  height1 >= midmattresHeight &&
                  height1 <= maxHeightData
                ) {
                  setFinalPrice1(interWidth1 * maxRate);
                } else {
                  setPriceError(
                    "User size is not reachable, please try again "
                  );
                  setTimeout(() => {
                    setPriceError("");
                    setFinalPrice1("");
                  }, 5000);
                }
              } else {
                setPriceError("User size is not reachable, please try again");
                setTimeout(() => {
                  setPriceError("");
                  setFinalPrice1("");
                }, 5000);
              }
            }
          }

          //Fitted sheets formula ends
        };

        return (
          <>
            <div class="login-form-container">
              <div class="login-register-form">
                <form method="">
                  <div
                    class="fittedRow"

                    // className="row"
                  >
                    {/* <div className="col-lg-2">  */}
                    {/* <select
                  class="form-select  shadow-none test" 
                  id="drop"
                  name="length1"
                  value={length1}
                  required
                  
                  onChange={(event) => {
                    setLength1(event.target.value);
                    setInputData1({...inputData1,[event.target.name]: event.target.value*2.54})
                    setUserInput({ ...userInput, [event.target.name]: event.target.value });
                  }}
                  
                  aria-label="Default select example"
                  style={{marginLeft:"9px",height:"49px",marginBottom:"15px",background:"none"}}
                  
                  >
                  <option selected> Length</option>
                  <option value="30">30 </option>
                  <option value="40">40 </option>
                  <option value="50">50 </option>
                  <option value="60">60 </option>
                  <option value="70">70</option>
                  <option value="80">80</option>
                  <option value="90">90</option>
                  <option value="100">100</option>
                 
               
                </select> */}

                    <FormControl
                      className={classes.formControl}
                      style={{
                        marginTop: "-3px",
                        width: "100%",
                        marginLeft: "6px",
                        marginBottom: "10px",
                      }}
                    >
                      <InputLabel id="demo-multiple-name-label">
                        Length
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        name="length1"
                        value={length1}
                        onChange={(event) => {
                          setLength1(event.target.value);
                          setInputData1({
                            ...inputData1,
                            [event.target.name]: event.target.value / 2.54,
                          });
                          setUserInput({
                            ...userInput,
                            [event.target.name]: event.target.value,
                          });
                        }}
                        input={<OutlinedInput label="Name" />}
                        MenuProps={{ classes: { paper: classes.menuPaper } }}
                        // MenuProps={MenuProps}
                      >
                        {inches === "cm"
                          ? lengthMetrices1.map((name) => (
                              <MenuItem
                                style={{ height: "45px" }}
                                key={name}
                                value={name}
                                // style={getStyles(name, personName, theme)}
                              >
                                {name}
                              </MenuItem>
                            ))
                          : lengthMetrices.map((name) => (
                              <MenuItem
                                style={{ height: "45px" }}
                                key={name}
                                value={name}
                                // style={getStyles(name, personName, theme)}
                              >
                                {name}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>

                    {/* <select
                  class="form-select  shadow-none test" 
                  id="drop"
                  name="width1"
                  value={width1}
                  required
                  
                  onChange={(event) => {
                    setWidth1(event.target.value);
                    setInputData1({...inputData1,[event.target.name]: event.target.value*2.54})
                    setUserInput({ ...userInput, [event.target.name]: event.target.value });
                  }}
                  
                  aria-label="Default select example"
                  style={{marginLeft:"9px",height:"49px",marginBottom:"15px",background:"none"}}
                  
                  >
                  <option selected> width</option>
                  <option value="30">30 </option>
                  <option value="40">40 </option>
                  <option value="50">50 </option>
                  <option value="60">60 </option>
                  <option value="70">70</option>
                  <option value="80">80</option>
                  <option value="90">90</option>
                  <option value="100">100</option>
                 
               
                </select> */}

                    <FormControl
                      className={classes.formControl}
                      style={{
                        marginTop: "-3px",
                        width: "100%",
                        marginLeft: "6px",
                        marginBottom: "10px",
                      }}
                    >
                      <InputLabel id="demo-multiple-name-label">
                        Width
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        name="width1"
                        value={width1}
                        onChange={(event) => {
                          setWidth1(event.target.value);
                          setInputData1({
                            ...inputData1,
                            [event.target.name]: event.target.value / 2.54,
                          });
                          setUserInput({
                            ...userInput,
                            [event.target.name]: event.target.value,
                          });
                        }}
                        input={<OutlinedInput label="Name" />}
                        MenuProps={{ classes: { paper: classes.menuPaper } }}
                        // MenuProps={MenuProps}
                      >
                        {inches === "cm"
                          ? widthMetrices1.map((name) => (
                              <MenuItem
                                style={{ height: "45px" }}
                                key={name}
                                value={name}
                                // style={getStyles(name, personName, theme)}
                              >
                                {name}
                              </MenuItem>
                            ))
                          : widthMetrices.map((name) => (
                              <MenuItem
                                style={{ height: "45px" }}
                                key={name}
                                value={name}
                                // style={getStyles(name, personName, theme)}
                              >
                                {name}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>

                    {/* <input
                    type="text"
                    name="width1"
                    placeholder="EnterWidth"
                    onChange={(event) => {
                      setWidth1(event.target.value);
                      setInputData1({...inputData1,[event.target.name]: event.target.value*2.54})
                      setUserInput({ ...userInput, [event.target.name]: event.target.value });
                    }}
                    value={width1}
                    required
                  /> */}
                    {/* </div> */}
                    {/* <div className="col-lg-2"> */}
                    {/* <select
                  class="form-select  shadow-none test" 
                  id="drop"
                  name="height1"
                  value={height1}
                  required
                  
                  onChange={(event) => {
                    setHeight1(event.target.value);
                    setInputData1({...inputData1,[event.target.name]: event.target.value*2.54})
                    setUserInput({ ...userInput, [event.target.name]: event.target.value });
                  }}
                  
                  aria-label="Default select example"
                  style={{marginLeft:"9px",height:"49px",marginBottom:"15px",background:"none"}}
                  
                  >
                  <option selected>Height</option>
                  <option value="30">30 </option>
                  <option value="40">40 </option>
                  <option value="50">50 </option>
                  <option value="60">60 </option>
                  <option value="70">70</option>
                  <option value="80">80</option>
                  <option value="90">90</option>
                  <option value="100">100</option>
                 
               
                </select> */}
                    <FormControl
                      className={classes.formControl}
                      style={{
                        marginTop: "-3px",
                        width: "100%",
                        marginLeft: "6px",
                        marginBottom: "10px",
                      }}
                    >
                      <InputLabel id="demo-multiple-name-label">
                        Thickness
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        name="height1"
                        value={height1}
                        onChange={(event) => {
                          setHeight1(event.target.value);
                          setInputData1({
                            ...inputData1,
                            [event.target.name]: event.target.value / 2.54,
                          });
                          setUserInput({
                            ...userInput,
                            [event.target.name]: event.target.value,
                          });
                        }}
                        input={<OutlinedInput label="Name" />}
                        MenuProps={{ classes: { paper: classes.menuPaper } }}
                        // MenuProps={MenuProps}
                      >
                        {inches === "cm"
                          ? heightMetrices1.map((name) => (
                              <MenuItem
                                style={{ height: "45px" }}
                                key={name}
                                value={name}
                                // style={getStyles(name, personName, theme)}
                              >
                                {name}
                              </MenuItem>
                            ))
                          : heightMetrices.map((name) => (
                              <MenuItem
                                style={{ height: "45px" }}
                                key={name}
                                value={name}
                                // style={getStyles(name, personName, theme)}
                              >
                                {name}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>

                    {/* <input
                    type="text"
                    name="height1"
                    placeholder="EnterHeight"
                    onChange={(event) => {
                      setHeight1(event.target.value);
                      setInputData1({...inputData1,[event.target.name]: event.target.value*2.54})
                      setUserInput({ ...userInput, [event.target.name]: event.target.value });
                    }}
                    value={height1}
                    required
                  /> */}
                    {/* </div> */}
                    {/* <div className="col-lg-2"> */}

                    <select
                      class="form-select  shadow-none test"
                      id="drop"
                      name="inches"
                      value={inches}
                      onChange={(event) => {
                        setInches(event.target.value);
                      }}
                      aria-label="Default select example"
                      style={{
                        marginLeft: "7px",
                        height: "49px",
                        marginBottom: "15px",
                        background: "none",
                      }}
                    >
                      <option selected>Inch</option>
                      <option value="cm">Cm </option>
                    </select>

                    {/* </div> */}
                    {/* <div className="col-lg-2"> */}
                    <button
                      type="button"
                      class="btn btn-danger mt-2"
                      onClick={clickEvent2}
                      style={{
                        background: "#DD3D05",
                        cursor: "pointer",
                        borderRadius: "20px",
                        marginLeft: "9px",
                        height: "37px",
                      }}
                    >
                      {" "}
                      <span style={{ fontWeight: "600" }}>Submit</span>
                    </button>
                    {/* </div> */}
                  </div>

                  {priceError ? (
                    <div class="error-text  mb-4">{priceError}</div>
                  ) : (
                    <div
                      class="mb-4"
                      style={{
                        width: "92px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <span
                        style={{
                          marginLeft: "32px",
                          fontWeight: "500",
                          fontSize: "35px",
                        }}
                      >
                        {finalPrice1
                          ? "₹" + numberWithCommas(Math.round(finalPrice1))
                          : false}
                        {/* {(width === "") & (length === "") ? 0 : finalPrice} */}
                      </span>
                    </div>
                  )}
                </form>
                <div
                  class="wishshare"
                  style={{
                    display: "flex",
                    marginLeft: "10px",
                    marginBottom: "35px",
                  }}
                >
                  <div class="product-quality " style={{ textAlign: "center" }}>
                    <span
                      style={{
                        display: "flex",
                        padding: "2px 5px",
                        marginTop: "10px",
                        // marginBottom: "5px",
                      }}
                    >
                      <span
                        class="minus-btn"
                        onClick={() => {
                          return quantity > 1
                            ? setQuantity(quantity - 1)
                            : false;
                        }}
                      >
                        <i class="bx bx-minus"></i>
                      </span>
                      <input
                        type="text"
                        min="1"
                        disabled
                        style={{
                          outline: "none",
                          border: "none",
                          background: "none",
                          textAlign: "center",
                          marginTop: "-3px",
                        }}
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                      />

                      <span
                        class="plus-btn"
                        onClick={() => {
                          setQuantity(quantity + 1);
                        }}
                      >
                        <i class="bx bx-plus"></i>
                      </span>
                    </span>
                  </div>

                  <div
                    class="single-product-wishlist"
                    style={{ marginLeft: "20px", marginTop: "12px" }}
                  >
                    <a
                      href="#"
                      title="wishlist"
                      onClick={() => {
                        dispatch(
                          addWishLists({
                            productId: sproduct[changeVariant].productId,
                          })
                        ).then((res) => {});
                      }}
                    >
                      {sproduct[changeVariant]?.favourites === 0 ? (
                        <i class="bx bx-heart" style={{ fontSize: "26px" }}></i>
                      ) : (
                        <i
                          style={{ fontSize: "26px" }}
                          class="bx bxs-heart"
                        ></i>
                      )}
                    </a>
                  </div>
                  <div
                    class="single-product-cart btn-hover"
                    style={{ marginLeft: "20px", marginTop: "12px" }}
                  >
                    <span
                      style={{ cursor: "pointer", marginLeft: "-28px" }}
                      name="share"
                      onClick={(e) => {
                        e.preventDefault();
                        copyData(
                          process.env.REACT_APP_HOME_URL +
                            "/productDetails/" +
                            id
                        );
                      }}
                    >
                      {" "}
                      <MdContentCopy style={{ fontSize: "25px" }} />
                    </span>
                  </div>

                  <div
                    class="product-details-action-wrap"
                    style={{ marginLeft: "3px" }}
                  >
                    {Token ? (
                      <button
                        type="button"
                        class="btn btn-danger"
                        onClick={handleClick2}
                        style={{
                          background: "#DD3D05",
                          cursor: "pointer",
                          borderRadius: "20px",
                        }}
                      >
                        <span className="icons" style={{ fontWeight: "600" }}>
                          {" "}
                          Add to Cart
                        </span>
                        <i
                          style={{
                            marginLeft: "5px",
                            fontSize: "15px",
                          }}
                          class="fa fa-shopping-cart cartIcon"
                          aria-hidden="true"
                        ></i>
                      </button>
                    ) : (
                      <a data-bs-toggle="modal" data-bs-target="#loginModal">
                        <button
                          type="button"
                          class="btn btn-danger"
                          style={{
                            background: "#DD3D05",
                            cursor: "pointer",
                            borderRadius: "20px",
                          }}
                        >
                          <span className="icons" style={{ fontWeight: "600" }}>
                            {" "}
                            Add to Cart
                          </span>
                          <i
                            style={{
                              marginLeft: "5px",
                              fontSize: "15px",
                            }}
                            class="fa fa-shopping-cart cartIcon"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </a>
                    )}
                    <button
                      type="button"
                      class="btn btn-danger"
                      onClick={Clicktobuy}
                      style={{
                        background: "#DD3D05",
                        cursor: "pointer",
                        borderRadius: "20px",
                        marginLeft: "20px",
                      }}
                    >
                      <span className="icons" style={{ fontWeight: "600" }}>
                        Buy Now
                      </span>
                      <AiFillShopping
                        style={{
                          fontSize: "22px",
                          marginTop: "-5px",
                        }}
                      />
                    </button>
                  </div>
                </div>

                <div style={{ textAlign: "center" }}>
                  <span style={{ color: "#DD3D05", fontWeight: "600" }}>
                    No Return Policy available for custom products
                  </span>
                </div>
                <h5
                  style={{
                    marginLeft: "10px",
                    marginTop: "12px",
                    fontSize: "15px",
                  }}
                ></h5>
                <div>
                  {custom ? (
                    <div
                      style={{ marginTop: "20px", marginLeft: "10px" }}
                      class="alert alert-warning alert-dismissible fade show"
                      role="alert"
                      mt-3
                    >
                      {custom === "Already Added to Cart" ||
                      custom === "Added to Cart" ? (
                        <Link to="/cart">
                          {" "}
                          {custom} -{" "}
                          <span style={{ textDecoration: "underline" }}>
                            {" "}
                            Go to Cart
                          </span>{" "}
                        </Link>
                      ) : (
                        custom
                      )}
                      <button
                        type="button"
                        class="btn-close"
                        //   onClick={()=>dispatch({type:addCart.add.success,data:false})}
                        data-dismiss="alert3"
                      ></button>
                    </div>
                  ) : (
                    false
                  )}
                </div>
                <div>
                  {wish ? (
                    <div
                      style={{ marginLeft: "10px" }}
                      class="alert alert-warning alert-dismissible fade show"
                      role="alert"
                      mt-3
                    >
                      {wish}
                      <button
                        type="button"
                        class="btn-close"
                        data-dismiss="alert1"
                        //   onClick={()=>dispatch({type:addWishList.wishList.success,data:false})}
                        aria-label="Close"
                      ></button>
                    </div>
                  ) : (
                    false
                  )}
                </div>
              </div>
            </div>
          </>
        );
      //Flat sheets

      case "7":
        const clickEventtt = (e) => {
          console.log("click event works");
          e.preventDefault();

          //Flat sheets formula starts

          if (length === "" || inputData.length === "") {
            setErr("Length is Required");
            setTimeout(() => {
              setErr("");
            }, 5000);
          } else if (width === "" || inputData.width === "") {
            setErr("Width is Required");
            setTimeout(() => {
              setErr("");
            }, 5000);
          } else {
            if (inches === "cm") {
              if (
                Math.round(inputData.length) > lengthMiddle &&
                Math.round(inputData.length) <= lengthTop
              ) {
                setFinalPrice(Number(inputData.width * maxRate));
              } else if (
                Math.round(inputData.length) <= lengthMiddle &&
                Math.round(inputData.length) >= lengthBottom
              ) {
                console.log("price works");
                setFinalPrice(Number(inputData.width) * minRate);
              } else {
                setErr("User Size is Not Reachable,Please Try again later");
                setTimeout(() => {
                  setErr("");
                  setFinalPrice("");
                }, 5000);
              }
            } else {
              if (length > lengthMiddle && length <= lengthTop) {
                setFinalPrice(Number(width * maxRate));
              } else if (length <= lengthMiddle && length >= lengthBottom) {
                setFinalPrice(Number(width * minRate));
              } else {
                setErr("User Size is Not Reachable,Please Try again later");
                setTimeout(() => {
                  setErr("");
                  setFinalPrice("");
                }, 5000);
              }
            }
          }
          //Flat sheets formula ends
        };

        return (
          <>
            <div class="login-form-container">
              <div class="login-register-form">
                <form method="">
                  <div className="row">
                    <div className="col-lg-3">
                      {/* <select
                  class="form-select  shadow-none test" 
                  id="drop"
                  name="length"
                  value={length}
                  required
                  
                  onChange={(event) => {
                    setLength(event.target.value);
                    
                      setInputData({...inputData,[event.target.name]: event.target.value*2.54});
                      setUserInput1({ ...userInput1, [event.target.name]: event.target.value });
                    
                  }}
                  
                  aria-label="Default select example"
                  style={{marginLeft:"9px",height:"49px",marginBottom:"15px",background:"none"}}
                  
                  >
                  <option selected> Length</option>
                  <option value="30">30 </option>
                  <option value="40">40 </option>
                  <option value="50">50 </option>
                  <option value="60">60 </option>
                  <option value="70">70</option>
                  <option value="80">80</option>
                  <option value="90">90</option>
                  <option value="100">100</option>
                 
               
                </select> */}

                      <FormControl
                        className={classes.formControl}
                        style={{
                          marginTop: "-3px",
                          width: "100%",
                          marginLeft: "6px",
                          marginBottom: "10px",
                        }}
                      >
                        <InputLabel id="demo-multiple-name-label">
                          Length
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          name="length"
                          value={length}
                          onChange={(event) => {
                            setLength(event.target.value);

                            setInputData({
                              ...inputData,
                              [event.target.name]: event.target.value / 2.54,
                            });
                            setUserInput1({
                              ...userInput1,
                              [event.target.name]: event.target.value,
                            });
                          }}
                          input={<OutlinedInput label="Name" />}
                          MenuProps={{ classes: { paper: classes.menuPaper } }}
                          // MenuProps={MenuProps}
                        >
                          {inches === "cm"
                            ? lengthMetrices1.map((name) => (
                                <MenuItem
                                  style={{ height: "45px" }}
                                  key={name}
                                  value={name}
                                  // style={getStyles(name, personName, theme)}
                                >
                                  {name}
                                </MenuItem>
                              ))
                            : lengthMetrices.map((name) => (
                                <MenuItem
                                  style={{ height: "45px" }}
                                  key={name}
                                  value={name}
                                  // style={getStyles(name, personName, theme)}
                                >
                                  {name}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-lg-3">
                      <FormControl
                        className={classes.formControl}
                        style={{
                          marginTop: "-3px",
                          width: "100%",
                          marginLeft: "6px",
                          marginBottom: "10px",
                        }}
                      >
                        <InputLabel id="demo-multiple-name-label">
                          Width
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          name="width"
                          value={width}
                          onChange={(event) => {
                            setWidth(event.target.value);
                            // console.log("inchesss",inches)

                            setInputData({
                              ...inputData,
                              [event.target.name]: event.target.value / 2.54,
                            });
                            setUserInput1({
                              ...userInput1,
                              [event.target.name]: event.target.value,
                            });
                          }}
                          input={<OutlinedInput label="Name" />}
                          MenuProps={{ classes: { paper: classes.menuPaper } }}
                          // MenuProps={MenuProps}
                        >
                          {inches === "cm"
                            ? widthMetrices1.map((name) => (
                                <MenuItem
                                  style={{ height: "45px" }}
                                  key={name}
                                  value={name}
                                  // style={getStyles(name, personName, theme)}
                                >
                                  {name}
                                </MenuItem>
                              ))
                            : widthMetrices.map((name) => (
                                <MenuItem
                                  style={{ height: "45px" }}
                                  key={name}
                                  value={name}
                                  // style={getStyles(name, personName, theme)}
                                >
                                  {name}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                      {/* <input
                    type="text"
                    name="width"
                    placeholder="Width"
                    onChange={(event) => {
                      setWidth(event.target.value);
// console.log("inchesss",inches)
                   
setInputData({...inputData,[event.target.name]: event.target.value*2.54})
                      setUserInput1({ ...userInput1, [event.target.name]: event.target.value });
                    
                    
                       
                     
                    }}
                    value={width}
                    required
                  /> */}
                    </div>
                    <div className="col-lg-3">
                      <select
                        class="form-select  shadow-none test"
                        id="drop"
                        name="inches"
                        value={inches}
                        onChange={(event) => {
                          setInches(event.target.value);
                        }}
                        aria-label="Default select example"
                        style={{
                          marginLeft: "9px",
                          height: "49px",
                          marginBottom: "15px",
                          background: "none",
                        }}
                      >
                        <option selected> Inch</option>
                        <option value="cm">Cm</option>
                      </select>
                    </div>
                    <div className="col-lg-3">
                      <button
                        type="button"
                        class="btn btn-danger mt-2"
                        onClick={clickEventtt}
                        style={{
                          background: "#DD3D05",
                          cursor: "pointer",
                          borderRadius: "20px",
                          marginLeft: "11px",
                        }}
                      >
                        {" "}
                        <span style={{ fontWeight: "600" }}>Submit</span>
                      </button>
                    </div>
                  </div>

                  {Err ? <div class="error-text mt-2 mb-3">{Err}</div> : false}

                  {Err ? (
                    false
                  ) : (
                    <div
                      class="mb-4"
                      style={{
                        width: "92px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <span
                        class="price1"
                        style={{
                          // marginLeft:"32px",
                          fontWeight: "500",
                          fontSize: "32px",
                        }}
                      >
                        {finalPrice
                          ? "₹" + numberWithCommas(finalPrice)
                          : false}
                        {/* {(width === "") & (length === "") ? 0 : finalPrice} */}
                      </span>
                    </div>
                  )}
                </form>
                <div
                  class="wishshare"
                  style={{
                    display: "flex",
                    marginLeft: "10px",
                    marginBottom: "35px",
                  }}
                >
                  <div class="product-quality " style={{ textAlign: "center" }}>
                    <span
                      style={{
                        display: "flex",
                        padding: "2px 5px",
                        marginTop: "10px",
                        // marginBottom: "5px",
                      }}
                    >
                      <span
                        class="minus-btn"
                        onClick={() => {
                          return quantity > 1
                            ? setQuantity(quantity - 1)
                            : false;
                        }}
                      >
                        <i class="bx bx-minus"></i>
                      </span>
                      <input
                        type="text"
                        min="1"
                        disabled
                        style={{
                          outline: "none",
                          border: "none",
                          background: "none",
                          textAlign: "center",
                          marginTop: "-3px",
                        }}
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                      />

                      <span
                        class="plus-btn"
                        onClick={() => {
                          setQuantity(quantity + 1);
                        }}
                      >
                        <i class="bx bx-plus"></i>
                      </span>
                    </span>
                  </div>

                  <div
                    class="single-product-wishlist"
                    style={{ marginLeft: "20px", marginTop: "12px" }}
                  >
                    <a
                      href="#"
                      title="wishlist"
                      onClick={() => {
                        dispatch(
                          addWishLists({
                            productId: sproduct[changeVariant].productId,
                          })
                        ).then((res) => {});
                      }}
                    >
                      {sproduct[changeVariant]?.favourites === 0 ? (
                        <i class="bx bx-heart" style={{ fontSize: "26px" }}></i>
                      ) : (
                        <i
                          style={{ fontSize: "26px" }}
                          class="bx bxs-heart"
                        ></i>
                      )}
                    </a>
                  </div>
                  <div
                    class="single-product-cart btn-hover"
                    style={{ marginLeft: "20px", marginTop: "12px" }}
                  >
                    <span
                      style={{ cursor: "pointer", marginLeft: "-28px" }}
                      name="share"
                      onClick={(e) => {
                        e.preventDefault();
                        copyData(
                          process.env.REACT_APP_HOME_URL +
                            "/productDetails/" +
                            id
                        );
                      }}
                    >
                      {" "}
                      <MdContentCopy style={{ fontSize: "25px" }} />
                    </span>
                  </div>

                  <div
                    class="product-details-action-wrap"
                    style={{ marginLeft: "3px" }}
                  >
                    {Token ? (
                      <button
                        type="button"
                        class="btn btn-danger"
                        onClick={handleClick1}
                        style={{
                          background: "#DD3D05",
                          cursor: "pointer",
                          borderRadius: "20px",
                        }}
                      >
                        <span className="icons" style={{ fontWeight: "600" }}>
                          {" "}
                          Add to Cart
                        </span>
                        <i
                          style={{
                            marginLeft: "5px",
                            fontSize: "15px",
                          }}
                          class="fa fa-shopping-cart cartIcon"
                          aria-hidden="true"
                        ></i>
                      </button>
                    ) : (
                      <a data-bs-toggle="modal" data-bs-target="#loginModal">
                        <button
                          type="button"
                          class="btn btn-danger"
                          style={{
                            background: "#DD3D05",
                            cursor: "pointer",
                            borderRadius: "20px",
                          }}
                        >
                          <span className="icons" style={{ fontWeight: "600" }}>
                            {" "}
                            Add to Cart
                          </span>
                          <i
                            style={{
                              marginLeft: "5px",
                              fontSize: "15px",
                            }}
                            class="fa fa-shopping-cart cartIcon"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </a>
                    )}
                    <button
                      type="button"
                      class="btn btn-danger"
                      onClick={Clicktobuy}
                      style={{
                        background: "#DD3D05",
                        cursor: "pointer",
                        borderRadius: "20px",
                        marginLeft: "20px",
                      }}
                    >
                      <span className="icons" style={{ fontWeight: "600" }}>
                        Buy Now
                      </span>
                      <AiFillShopping
                        style={{
                          fontSize: "22px",
                          marginTop: "-5px",
                        }}
                      />
                    </button>
                  </div>
                </div>

                <div style={{ textAlign: "center" }}>
                  <span style={{ color: "#DD3D05", fontWeight: "600" }}>
                    No Return Policy available for custom products
                  </span>
                </div>
                <h5
                  style={{
                    marginLeft: "10px",
                    marginTop: "12px",
                    fontSize: "15px",
                  }}
                ></h5>
                <div>
                  {custom ? (
                    <div
                      style={{ marginTop: "20px", marginLeft: "10px" }}
                      class="alert alert-warning alert-dismissible fade show"
                      role="alert"
                      mt-3
                    >
                      {custom === "Already Added to Cart" ||
                      custom === "Added to Cart" ? (
                        <Link to="/cart">
                          {" "}
                          {custom} -{" "}
                          <span style={{ textDecoration: "underline" }}>
                            {" "}
                            Go to Cart
                          </span>{" "}
                        </Link>
                      ) : (
                        custom
                      )}
                      <button
                        type="button"
                        class="btn-close"
                        //   onClick={()=>dispatch({type:addCart.add.success,data:false})}
                        data-dismiss="alert3"
                      ></button>
                    </div>
                  ) : (
                    false
                  )}
                </div>
                <div>
                  {wish ? (
                    <div
                      style={{ marginLeft: "10px" }}
                      class="alert alert-warning alert-dismissible fade show"
                      role="alert"
                      mt-3
                    >
                      {wish}
                      <button
                        type="button"
                        class="btn-close"
                        data-dismiss="alert1"
                        //   onClick={()=>dispatch({type:addWishList.wishList.success,data:false})}
                        aria-label="Close"
                      ></button>
                    </div>
                  ) : (
                    false
                  )}
                </div>
              </div>
            </div>
          </>
        );

      //Flat sheets clone clone for formula -1
      case "8":
        const clickEventFlat = (e) => {
          console.log("click event works");
          e.preventDefault();

          //Flat sheets formula starts

          if (length === "" || inputData.length === "") {
            setErr("Length is Required");
            setTimeout(() => {
              setErr("");
            }, 5000);
          } else if (width === "" || inputData.width === "") {
            setErr("Width is Required");
            setTimeout(() => {
              setErr("");
            }, 5000);
          } else {
            if (inches === "cm") {
              if (
                Math.round(inputData.length) > lengthMiddle &&
                Math.round(inputData.length) <= lengthTop
              ) {
                setFinalPrice(Number(inputData.width * flatMaxRate));
              } else if (
                Math.round(inputData.length) <= lengthMiddle &&
                Math.round(inputData.length) >= lengthBottom
              ) {
                console.log("price works");
                setFinalPrice(Number(inputData.width) * flatMinRate);
              } else {
                setErr("User Size is Not Reachable,Please Try again later");
                setTimeout(() => {
                  setErr("");
                  setFinalPrice("");
                }, 5000);
              }
            } else {
              if (length > lengthMiddle && length <= lengthTop) {
                setFinalPrice(Number(width * flatMaxRate));
              } else if (length <= lengthMiddle && length >= lengthBottom) {
                setFinalPrice(Number(width * flatMinRate));
              } else {
                setErr("User Size is Not Reachable,Please Try again later");
                setTimeout(() => {
                  setErr("");
                  setFinalPrice("");
                }, 5000);
              }
            }
          }
          //Flat sheets formula ends
        };

        return (
          <>
            <div class="login-form-container">
              <div class="login-register-form">
                <form method="">
                  <div className="row">
                    <div className="col-lg-3">
                      {/* <select
                    class="form-select  shadow-none test" 
                    id="drop"
                    name="length"
                    value={length}
                    required
                    
                    onChange={(event) => {
                      setLength(event.target.value);
                      
                        setInputData({...inputData,[event.target.name]: event.target.value*2.54});
                        setUserInput1({ ...userInput1, [event.target.name]: event.target.value });
                      
                    }}
                    
                    aria-label="Default select example"
                    style={{marginLeft:"9px",height:"49px",marginBottom:"15px",background:"none"}}
                    
                    >
                    <option selected> Length</option>
                    <option value="30">30 </option>
                    <option value="40">40 </option>
                    <option value="50">50 </option>
                    <option value="60">60 </option>
                    <option value="70">70</option>
                    <option value="80">80</option>
                    <option value="90">90</option>
                    <option value="100">100</option>
                   
                 
                  </select> */}

                      <FormControl
                        className={classes.formControl}
                        style={{
                          marginTop: "-3px",
                          width: "100%",
                          marginLeft: "6px",
                          marginBottom: "10px",
                        }}
                      >
                        <InputLabel id="demo-multiple-name-label">
                          Length
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          name="length"
                          value={length}
                          onChange={(event) => {
                            setLength(event.target.value);

                            setInputData({
                              ...inputData,
                              [event.target.name]: event.target.value / 2.54,
                            });
                            setUserInput1({
                              ...userInput1,
                              [event.target.name]: event.target.value,
                            });
                          }}
                          input={<OutlinedInput label="Name" />}
                          MenuProps={{ classes: { paper: classes.menuPaper } }}
                          // MenuProps={MenuProps}
                        >
                          {inches === "cm"
                            ? lengthMetrices1.map((name) => (
                                <MenuItem
                                  style={{ height: "45px" }}
                                  key={name}
                                  value={name}
                                  // style={getStyles(name, personName, theme)}
                                >
                                  {name}
                                </MenuItem>
                              ))
                            : lengthMetrices.map((name) => (
                                <MenuItem
                                  style={{ height: "45px" }}
                                  key={name}
                                  value={name}
                                  // style={getStyles(name, personName, theme)}
                                >
                                  {name}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-lg-3">
                      <FormControl
                        className={classes.formControl}
                        style={{
                          marginTop: "-3px",
                          width: "100%",
                          marginLeft: "6px",
                          marginBottom: "10px",
                        }}
                      >
                        <InputLabel id="demo-multiple-name-label">
                          Width
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          name="width"
                          value={width}
                          onChange={(event) => {
                            setWidth(event.target.value);
                            // console.log("inchesss",inches)

                            setInputData({
                              ...inputData,
                              [event.target.name]: event.target.value / 2.54,
                            });
                            setUserInput1({
                              ...userInput1,
                              [event.target.name]: event.target.value,
                            });
                          }}
                          input={<OutlinedInput label="Name" />}
                          MenuProps={{ classes: { paper: classes.menuPaper } }}
                          // MenuProps={MenuProps}
                        >
                          {inches === "cm"
                            ? widthMetrices1.map((name) => (
                                <MenuItem
                                  style={{ height: "45px" }}
                                  key={name}
                                  value={name}
                                  // style={getStyles(name, personName, theme)}
                                >
                                  {name}
                                </MenuItem>
                              ))
                            : widthMetrices.map((name) => (
                                <MenuItem
                                  style={{ height: "45px" }}
                                  key={name}
                                  value={name}
                                  // style={getStyles(name, personName, theme)}
                                >
                                  {name}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                      {/* <input
                      type="text"
                      name="width"
                      placeholder="Width"
                      onChange={(event) => {
                        setWidth(event.target.value);
  // console.log("inchesss",inches)
                     
  setInputData({...inputData,[event.target.name]: event.target.value*2.54})
                        setUserInput1({ ...userInput1, [event.target.name]: event.target.value });
                      
                      
                         
                       
                      }}
                      value={width}
                      required
                    /> */}
                    </div>
                    <div className="col-lg-3">
                      <select
                        class="form-select  shadow-none test"
                        id="drop"
                        name="inches"
                        value={inches}
                        onChange={(event) => {
                          setInches(event.target.value);
                        }}
                        aria-label="Default select example"
                        style={{
                          marginLeft: "9px",
                          height: "49px",
                          marginBottom: "15px",
                          background: "none",
                        }}
                      >
                        <option selected> Inch</option>
                        <option value="cm">Cm</option>
                      </select>
                    </div>
                    <div className="col-lg-3">
                      <button
                        type="button"
                        class="btn btn-danger mt-2"
                        onClick={clickEventFlat}
                        style={{
                          background: "#DD3D05",
                          cursor: "pointer",
                          borderRadius: "20px",
                          marginLeft: "11px",
                        }}
                      >
                        {" "}
                        <span style={{ fontWeight: "600" }}>Submit</span>
                      </button>
                    </div>
                  </div>

                  {Err ? <div class="error-text mt-2 mb-3">{Err}</div> : false}

                  {Err ? (
                    false
                  ) : (
                    <div
                      class="mb-4"
                      style={{
                        width: "92px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <span
                        class="price1"
                        style={{
                          // marginLeft:"32px",
                          fontWeight: "500",
                          fontSize: "35px",
                        }}
                      >
                        {finalPrice
                          ? "₹" + numberWithCommas(finalPrice)
                          : false}
                        {/* {(width === "") & (length === "") ? 0 : finalPrice} */}
                      </span>
                    </div>
                  )}
                </form>
                <div
                  class="wishshare"
                  style={{
                    display: "flex",
                    marginLeft: "10px",
                    marginBottom: "35px",
                  }}
                >
                  <div class="product-quality " style={{ textAlign: "center" }}>
                    <span
                      style={{
                        display: "flex",
                        padding: "2px 5px",
                        marginTop: "10px",
                        // marginBottom: "5px",
                      }}
                    >
                      <span
                        class="minus-btn"
                        onClick={() => {
                          return quantity > 1
                            ? setQuantity(quantity - 1)
                            : false;
                        }}
                      >
                        <i class="bx bx-minus"></i>
                      </span>
                      <input
                        type="text"
                        min="1"
                        disabled
                        style={{
                          outline: "none",
                          border: "none",
                          background: "none",
                          textAlign: "center",
                          marginTop: "-3px",
                        }}
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                      />

                      <span
                        class="plus-btn"
                        onClick={() => {
                          setQuantity(quantity + 1);
                        }}
                      >
                        <i class="bx bx-plus"></i>
                      </span>
                    </span>
                  </div>

                  <div
                    class="single-product-wishlist"
                    style={{ marginLeft: "20px", marginTop: "12px" }}
                  >
                    <a
                      href="#"
                      title="wishlist"
                      onClick={() => {
                        dispatch(
                          addWishLists({
                            productId: sproduct[changeVariant].productId,
                          })
                        ).then((res) => {});
                      }}
                    >
                      {sproduct[changeVariant]?.favourites === 0 ? (
                        <i class="bx bx-heart" style={{ fontSize: "26px" }}></i>
                      ) : (
                        <i
                          style={{ fontSize: "26px" }}
                          class="bx bxs-heart"
                        ></i>
                      )}
                    </a>
                  </div>
                  <div
                    class="single-product-cart btn-hover"
                    style={{ marginLeft: "20px", marginTop: "12px" }}
                  >
                    <span
                      style={{ cursor: "pointer", marginLeft: "-28px" }}
                      name="share"
                      onClick={(e) => {
                        e.preventDefault();
                        copyData(
                          process.env.REACT_APP_HOME_URL +
                            "/productDetails/" +
                            id
                        );
                      }}
                    >
                      {" "}
                      <MdContentCopy style={{ fontSize: "25px" }} />
                    </span>
                  </div>

                  <div
                    class="product-details-action-wrap"
                    style={{ marginLeft: "3px" }}
                  >
                    {Token ? (
                      <button
                        type="button"
                        class="btn btn-danger"
                        onClick={handleClick1}
                        style={{
                          background: "#DD3D05",
                          cursor: "pointer",
                          borderRadius: "20px",
                        }}
                      >
                        <span className="icons" style={{ fontWeight: "600" }}>
                          {" "}
                          Add to Cart
                        </span>
                        <i
                          style={{
                            marginLeft: "5px",
                            fontSize: "15px",
                          }}
                          class="fa fa-shopping-cart cartIcon"
                          aria-hidden="true"
                        ></i>
                      </button>
                    ) : (
                      <a data-bs-toggle="modal" data-bs-target="#loginModal">
                        <button
                          type="button"
                          class="btn btn-danger"
                          style={{
                            background: "#DD3D05",
                            cursor: "pointer",
                            borderRadius: "20px",
                          }}
                        >
                          <span className="icons" style={{ fontWeight: "600" }}>
                            {" "}
                            Add to Cart
                          </span>
                          <i
                            style={{
                              marginLeft: "5px",
                              fontSize: "15px",
                            }}
                            class="fa fa-shopping-cart cartIcon"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </a>
                    )}
                    <button
                      type="button"
                      class="btn btn-danger"
                      onClick={Clicktobuy}
                      style={{
                        background: "#DD3D05",
                        cursor: "pointer",
                        borderRadius: "20px",
                        marginLeft: "20px",
                      }}
                    >
                      <span className="icons" style={{ fontWeight: "600" }}>
                        Buy Now
                      </span>
                      <AiFillShopping
                        style={{
                          fontSize: "22px",
                          marginTop: "-5px",
                        }}
                      />
                    </button>
                  </div>
                </div>

                <div style={{ textAlign: "center" }}>
                  <span style={{ color: "#DD3D05", fontWeight: "600" }}>
                    No Return Policy available for custom products
                  </span>
                </div>
                <h5
                  style={{
                    marginLeft: "10px",
                    marginTop: "12px",
                    fontSize: "15px",
                  }}
                ></h5>
                <div>
                  {custom ? (
                    <div
                      style={{ marginTop: "20px", marginLeft: "10px" }}
                      class="alert alert-warning alert-dismissible fade show"
                      role="alert"
                      mt-3
                    >
                      {custom === "Already Added to Cart" ||
                      custom === "Added to Cart" ? (
                        <Link to="/cart">
                          {" "}
                          {custom} -{" "}
                          <span style={{ textDecoration: "underline" }}>
                            {" "}
                            Go to Cart
                          </span>{" "}
                        </Link>
                      ) : (
                        custom
                      )}
                      <button
                        type="button"
                        class="btn-close"
                        //   onClick={()=>dispatch({type:addCart.add.success,data:false})}
                        data-dismiss="alert3"
                      ></button>
                    </div>
                  ) : (
                    false
                  )}
                </div>
                <div>
                  {wish ? (
                    <div
                      style={{ marginLeft: "10px" }}
                      class="alert alert-warning alert-dismissible fade show"
                      role="alert"
                      mt-3
                    >
                      {wish}
                      <button
                        type="button"
                        class="btn-close"
                        data-dismiss="alert1"
                        //   onClick={()=>dispatch({type:addWishList.wishList.success,data:false})}
                        aria-label="Close"
                      ></button>
                    </div>
                  ) : (
                    false
                  )}
                </div>
              </div>
            </div>
          </>
        );

      //small Dohar
      case "9":
        const doharEvent = (e) => {
          e.preventDefault();

          //Dohar formula start

          if (lengthData === "" || inputData.length === "") {
            setErr("Length is Required");
            setTimeout(() => {
              setErr("");
            }, 5000);
          } else if (width === "" || inputData.width === "") {
            setErr("Width is Required");
            setTimeout(() => {
              setErr("");
            }, 5000);
          } else {
            if (inches === "cm") {
              console.log("cm data length", inputData.length);
              console.log("cm data width", inputData.width);
              // if(inputData.length === "50"){

              //   let interWidthData =inputData.width *2.40;
              //   setFinalPrice(interWidthData * minRate);
              // }
              //    else if(inputData.length === "60"){
              //     let interWidthData1 =width *2.40;
              //   setFinalPrice(interWidthData1 * maxRate);
              // }
              if (inputData.length === 50) {
                console.log("input Data lenth");
                let interWidthData = Math.round(inputData.width) * 2.4;
                setFinalPrice(Number(interWidthData * minRate));
              } else if (inputData.length === 60) {
                let interWidthData1 = Math.round(inputData.width) * 2.4;
                setFinalPrice(Number(interWidthData1 * maxRate));
              } else {
                setErr("User Size is not reachable..pls try again");
                setTimeout(() => {
                  setErr("");
                  setFinalPrice("");
                }, 5000);
              }
            } else {
              // if(lengthData === "50"){

              //   let interWidthData =width *2.40;
              //   setFinalPrice(interWidthData * minRate);
              // }
              // else if(lengthData === "60"){
              //   let interWidthDat1 =width *2.40;
              //   setFinalPrice(interWidthDat1 * maxRate);
              // }
              if (lengthData === "50") {
                let interWidthData = Number(width) * 2.4;
                console.log("90works", Number(interWidthData) * minRate);
                setFinalPrice(Number(interWidthData) * minRate);
              } else if (lengthData === "60") {
                let interWidthDat1 = width * 2.4;
                setFinalPrice(Number(interWidthDat1 * maxRate));
              } else {
                // console.log("errorelse  ")
                setErr("User Size is not reachable..pls try again");
                setTimeout(() => {
                  setErr("");
                  setFinalPrice("");
                }, 5000);
              }
            }
          }
          //Dohar formula End
        };

        return (
          <>
            <div class="login-form-container">
              <div class="login-register-form">
                <form method="">
                  <div className="row">
                    <div className="col-lg-3">
                      <select
                        class="form-select  shadow-none test"
                        id="drop"
                        name="length"
                        value={userInput2.length}
                        onChange={(event) => {
                          setLengthData(event.target.value);
                          setInputData({
                            ...inputData,
                            [event.target.name]: event.target.value / 2.54,
                          });
                          setUserInput2({
                            ...userInput2,
                            [event.target.name]: event.target.value,
                          });
                        }}
                        aria-label="Default select example"
                        style={{
                          marginLeft: "7px",
                          height: "49px",
                          marginBottom: "15px",
                          background: "none",
                        }}
                      >
                        <option selected> Length </option>
                        {inches === "cm" ? (
                          <>
                            <option value="127">127 </option>
                            <option value="152.4">152</option>
                          </>
                        ) : (
                          <>
                            <option value="50">50 </option>
                            <option value="60">60</option>
                          </>
                        )}
                      </select>
                      {/* <FormControl className={classes.formControl} style={{marginTop:"-3px",width:"100%",marginLeft:"6px",marginBottom:"10px"}}>
          <InputLabel id="demo-multiple-name-label">Length</InputLabel>
          <Select
         
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            name="length"
            value={length}
            onChange={(event) => {
              console.log("event",event.target.value);
              setLength(event.target.value);
              setInputData({...inputData,[event.target.name]: event.target.value*2.54});
              setUserInput2({ ...userInput2, [event.target.name]: event.target.value });
              }}
            input={<OutlinedInput label="Name" />}
            MenuProps={{ classes: { paper: classes.menuPaper } }}
            // MenuProps={MenuProps}
          >
            {lengthMetrices.map((name) => (
              <MenuItem
              style={{height:"45px"}}
                key={name}
                value={name}
               
              >
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}
                    </div>
                    <div className="col-lg-3">
                      {/* <select
                size="5"
                    class="form-select  shadow-none test" 
                    id="drop"
                    name="width"
                    value={width}
                    // size="20"
                    onChange={(event) => {
                      
                      setWidth(event.target.value);
                      setInputData({...inputData,[event.target.name]: event.target.value*2.54});
                      setUserInput2({ ...userInput2, [event.target.name]: event.target.value });
                    }}
                    
                    aria-label="Default select example"
                    style={{marginLeft:"7px",height:"49px",marginBottom:"15px",background:"none"}}
                    >
                 <option  selected > Width</option>
                
                    {Array.isArray(test) && lengthMetrices.map((datas)=>
  <option value={datas}>{datas} </option>
  )}
                  </select> */}

                      <FormControl
                        className={classes.formControl}
                        style={{
                          marginTop: "-3px",
                          width: "100%",
                          marginLeft: "6px",
                          marginBottom: "10px",
                        }}
                      >
                        <InputLabel id="demo-multiple-name-label">
                          Width
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          name="width"
                          value={width}
                          onChange={(event) => {
                            setWidth(event.target.value);
                            setInputData({
                              ...inputData,
                              [event.target.name]: event.target.value / 2.54,
                            });
                            setUserInput2({
                              ...userInput2,
                              [event.target.name]: event.target.value,
                            });
                          }}
                          input={<OutlinedInput label="Name" />}
                          MenuProps={{ classes: { paper: classes.menuPaper } }}
                          // MenuProps={MenuProps}
                        >
                          {inches === "cm"
                            ? widthMetrices1.map((name) => (
                                <MenuItem
                                  style={{ height: "45px" }}
                                  key={name}
                                  value={name}
                                  // style={getStyles(name, personName, theme)}
                                >
                                  {name}
                                </MenuItem>
                              ))
                            : widthMetrices.map((name) => (
                                <MenuItem
                                  style={{ height: "45px" }}
                                  key={name}
                                  value={name}
                                  // style={getStyles(name, personName, theme)}
                                >
                                  {name}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </div>

                    <div className="col-lg-3">
                      <select
                        class="form-select  shadow-none test"
                        id="drop"
                        name="inches"
                        value={inches}
                        onChange={(event) => {
                          setInches(event.target.value);
                        }}
                        aria-label="Default select example"
                        style={{
                          marginLeft: "7px",
                          height: "49px",
                          marginBottom: "15px",
                          background: "none",
                        }}
                      >
                        <option selected> Inch</option>
                        <option value="cm">Cm </option>
                      </select>
                    </div>

                    <div className="col-lg-3">
                      <button
                        type="button"
                        class="btn btn-danger mt-2"
                        onClick={doharEvent}
                        style={{
                          background: "#DD3D05",
                          cursor: "pointer",
                          borderRadius: "20px",
                          marginLeft: "11px",
                        }}
                      >
                        {" "}
                        <span style={{ fontWeight: "600" }}>Submit</span>
                      </button>
                    </div>
                  </div>

                  {Err ? (
                    <div class="error-text mt-2 mb-3">{Err}</div>
                  ) : (
                    <div
                      class="mb-4"
                      style={{
                        width: "92px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <span
                        class="price1"
                        style={{
                          // marginLeft:"32px",
                          fontWeight: "500",
                          fontSize: "35px",
                        }}
                      >
                        {finalPrice
                          ? "₹" + numberWithCommas(Math.round(finalPrice))
                          : false}
                        {/* {(width === "") & (length === "") ? 0 : finalPrice} */}
                      </span>
                    </div>
                  )}

                  {/* {Err ? (
                      "false"
                    ) : (
                    <div class="mb-4" style={{width:"92px",display:"flex",justifyContent:"center"}} >
                      <span
                        class="price1"
                        style={{
                          
                         
                          fontWeight: "500",
                          fontSize: "18px",
                        }}
                      >
                  
                        {finalPrice ? "₹" +Math.round(finalPrice):false}
                     
                      </span>
                    </div>
                    )} */}
                </form>
                <div
                  class="wishshare"
                  style={{
                    display: "flex",
                    marginLeft: "10px",
                    marginBottom: "35px",
                  }}
                >
                  <div class="product-quality " style={{ textAlign: "center" }}>
                    <span
                      style={{
                        display: "flex",
                        padding: "2px 5px",
                        marginTop: "10px",
                        // marginBottom: "5px",
                      }}
                    >
                      <span
                        class="minus-btn"
                        onClick={() => {
                          return quantity > 1
                            ? setQuantity(quantity - 1)
                            : false;
                        }}
                      >
                        <i class="bx bx-minus"></i>
                      </span>
                      <input
                        type="text"
                        min="1"
                        disabled
                        style={{
                          outline: "none",
                          border: "none",
                          background: "none",
                          textAlign: "center",
                          marginTop: "-3px",
                        }}
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                      />

                      <span
                        class="plus-btn"
                        onClick={() => {
                          setQuantity(quantity + 1);
                        }}
                      >
                        <i class="bx bx-plus"></i>
                      </span>
                    </span>
                  </div>

                  <div
                    class="single-product-wishlist"
                    style={{ marginLeft: "20px", marginTop: "12px" }}
                  >
                    <a
                      href="#"
                      title="wishlist"
                      onClick={() => {
                        dispatch(
                          addWishLists({
                            productId: sproduct[changeVariant].productId,
                          })
                        ).then((res) => {});
                      }}
                    >
                      {sproduct[changeVariant]?.favourites === 0 ? (
                        <i class="bx bx-heart" style={{ fontSize: "26px" }}></i>
                      ) : (
                        <i
                          style={{ fontSize: "26px" }}
                          class="bx bxs-heart"
                        ></i>
                      )}
                    </a>
                  </div>
                  <div
                    class="single-product-cart btn-hover"
                    style={{ marginLeft: "20px", marginTop: "12px" }}
                  >
                    <span
                      style={{ cursor: "pointer", marginLeft: "-28px" }}
                      name="share"
                      onClick={(e) => {
                        e.preventDefault();
                        copyData(
                          process.env.REACT_APP_HOME_URL +
                            "/productDetails/" +
                            id
                        );
                      }}
                    >
                      {" "}
                      <MdContentCopy style={{ fontSize: "25px" }} />
                    </span>
                  </div>

                  <div
                    class="product-details-action-wrap"
                    style={{ marginLeft: "3px" }}
                  >
                    {Token ? (
                      <button
                        type="button"
                        class="btn btn-danger"
                        onClick={handleClick3}
                        style={{
                          background: "#DD3D05",
                          cursor: "pointer",
                          borderRadius: "20px",
                        }}
                      >
                        <span className="icons" style={{ fontWeight: "600" }}>
                          {" "}
                          Add to Cart
                        </span>
                        <i
                          style={{
                            marginLeft: "5px",
                            fontSize: "15px",
                          }}
                          class="fa fa-shopping-cart cartIcon"
                          aria-hidden="true"
                        ></i>
                      </button>
                    ) : (
                      <a data-bs-toggle="modal" data-bs-target="#loginModal">
                        <button
                          type="button"
                          class="btn btn-danger"
                          style={{
                            background: "#DD3D05",
                            cursor: "pointer",
                            borderRadius: "20px",
                          }}
                        >
                          <span className="icons" style={{ fontWeight: "600" }}>
                            {" "}
                            Add to Cart
                          </span>
                          <i
                            style={{
                              marginLeft: "5px",
                              fontSize: "15px",
                            }}
                            class="fa fa-shopping-cart cartIcon"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </a>
                    )}
                    <button
                      type="button"
                      class="btn btn-danger"
                      onClick={Clicktobuy}
                      style={{
                        background: "#DD3D05",
                        cursor: "pointer",
                        borderRadius: "20px",
                        marginLeft: "20px",
                      }}
                    >
                      <span className="icons" style={{ fontWeight: "600" }}>
                        Buy Now
                      </span>
                      <AiFillShopping
                        style={{
                          fontSize: "22px",
                          marginTop: "-5px",
                        }}
                      />
                    </button>
                  </div>
                </div>

                <div style={{ textAlign: "center" }}>
                  <span style={{ color: "#DD3D05", fontWeight: "600" }}>
                    No Return Policy available for custom products
                  </span>
                </div>
                <h5
                  style={{
                    marginLeft: "10px",
                    marginTop: "12px",
                    fontSize: "15px",
                  }}
                ></h5>
                <div>
                  {custom ? (
                    <div
                      style={{ marginTop: "20px", marginLeft: "10px" }}
                      class="alert alert-warning alert-dismissible fade show"
                      role="alert"
                      mt-3
                    >
                      {custom === "Already Added to Cart" ||
                      custom === "Added to Cart" ? (
                        <Link to="/cart">
                          {" "}
                          {custom} -{" "}
                          <span style={{ textDecoration: "underline" }}>
                            {" "}
                            Go to Cart
                          </span>{" "}
                        </Link>
                      ) : (
                        custom
                      )}
                      <button
                        type="button"
                        class="btn-close"
                        //   onClick={()=>dispatch({type:addCart.add.success,data:false})}
                        data-dismiss="alert3"
                      ></button>
                    </div>
                  ) : (
                    false
                  )}
                </div>
                <div>
                  {wish ? (
                    <div
                      style={{ marginLeft: "10px" }}
                      class="alert alert-warning alert-dismissible fade show"
                      role="alert"
                      mt-3
                    >
                      {wish}
                      <button
                        type="button"
                        class="btn-close"
                        data-dismiss="alert1"
                        //   onClick={()=>dispatch({type:addWishList.wishList.success,data:false})}
                        aria-label="Close"
                      ></button>
                    </div>
                  ) : (
                    false
                  )}
                </div>
              </div>
            </div>
          </>
        );

      //Fitted sheets clone for formula -1
      case "10":
        const clickEventClone = (e) => {
          console.log("Click events Works");
          e.preventDefault();

          //Fitted sheets formula starts

          if (length1 === "" || inputData1.length1 === "") {
            setPriceError("Length is Required");
            setTimeout(() => {
              setPriceError("");
            }, 5000);
          } else if (width1 === "" || inputData1.width1 === "") {
            setPriceError("Width is Required");
            setTimeout(() => {
              setPriceError("");
            }, 5000);
          } else if (height1 === "" || inputData1.height1 === "") {
            setPriceError("Height is Required");
            setTimeout(() => {
              setPriceError("");
            }, 5000);
          } else {
            if (inches === "cm") {
              let limit =
                Math.round(inputData1.length1) +
                4 * Math.round(inputData1.height1);
              let interWidth =
                Math.round(inputData1.width1) +
                4 * Math.round(inputData1.height1);
              let interLength =
                Math.round(inputData1.length1) +
                4 * Math.round(inputData1.height1);

              if (limit <= maxsheetLimit) {
                if (Math.round(inputData1.height1) < midmattresHeight) {
                  setFinalPrice1(
                    Number(
                      (interWidth + 4 * Math.round(inputData1.height1)) *
                        maxRate
                    )
                  );
                } else {
                  setFinalPrice1(
                    Number(
                      (interWidth + 2 * Math.round(inputData1.height1)) *
                        maxRate
                    )
                  );
                }
              } else {
                setPriceError("User size is not reachable, please try again");
                setTimeout(() => {
                  setPriceError("");
                  setFinalPrice1("");
                }, 5000);
              }

              // if (limit <= maxsheetLimit) {
              //   if (Math.round(inputData1.height1) < midmattresHeight) {
              //     setFinalPrice1(
              //       Number(
              //         (interWidth + Math.round(inputData1.height1)) * fitMaxRate
              //       )
              //     );
              //   } else if (
              //     Math.round(inputData1.height1) >= midmattresHeight &&
              //     Math.round(inputData1.height1) <= maxHeightData
              //   ) {
              //     setFinalPrice1(Number(interWidth * fitMaxRate));
              //   } else {
              //     setPriceError(
              //       "User size is not reachable, please try again "
              //     );
              //     setTimeout(() => {
              //       setPriceError("");
              //       setFinalPrice1("");
              //     }, 5000);
              //   }
              // } else {
              //   setPriceError("User size is not reachable, please try again");
              //   setTimeout(() => {
              //     setPriceError("");
              //     setFinalPrice1("");
              //   }, 5000);
              // }
            } else {
              let limit1 = length1 + 4 * height1;
              let interWidth1 = width1 + 4 * height1;
              let interLength1 = length1 + 4 * height1;

              if (limit1 <= maxsheetLimit) {
                if (height1 < midmattresHeight) {
                  setFinalPrice1((interWidth1 + height1) * fitMaxRate);
                } else if (
                  height1 >= midmattresHeight &&
                  height1 <= maxHeightData
                ) {
                  setFinalPrice1(interWidth1 * fitMaxRate);
                } else {
                  setPriceError(
                    "User size is not reachable, please try again "
                  );
                  setTimeout(() => {
                    setPriceError("");
                    setFinalPrice1("");
                  }, 5000);
                }
              } else {
                setPriceError("User size is not reachable, please try again");
                setTimeout(() => {
                  setPriceError("");
                  setFinalPrice1("");
                }, 5000);
              }
            }
          }

          //Fitted sheets formula ends
        };

        return (
          <>
            <div class="login-form-container">
              <div class="login-register-form">
                <form method="">
                  <div
                    class="fittedRow"

                    // className="row"
                  >
                    {/* <div className="col-lg-2">  */}
                    {/* <select
                      class="form-select  shadow-none test" 
                      id="drop"
                      name="length1"
                      value={length1}
                      required
                      
                      onChange={(event) => {
                        setLength1(event.target.value);
                        setInputData1({...inputData1,[event.target.name]: event.target.value*2.54})
                        setUserInput({ ...userInput, [event.target.name]: event.target.value });
                      }}
                      
                      aria-label="Default select example"
                      style={{marginLeft:"9px",height:"49px",marginBottom:"15px",background:"none"}}
                      
                      >
                      <option selected> Length</option>
                      <option value="30">30 </option>
                      <option value="40">40 </option>
                      <option value="50">50 </option>
                      <option value="60">60 </option>
                      <option value="70">70</option>
                      <option value="80">80</option>
                      <option value="90">90</option>
                      <option value="100">100</option>
                     
                   
                    </select> */}

                    <FormControl
                      className={classes.formControl}
                      style={{
                        marginTop: "-3px",
                        width: "100%",
                        marginLeft: "6px",
                        marginBottom: "10px",
                      }}
                    >
                      <InputLabel id="demo-multiple-name-label">
                        Length
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        name="length1"
                        value={length1}
                        onChange={(event) => {
                          setLength1(event.target.value);
                          setInputData1({
                            ...inputData1,
                            [event.target.name]: event.target.value / 2.54,
                          });
                          setUserInput({
                            ...userInput,
                            [event.target.name]: event.target.value,
                          });
                        }}
                        input={<OutlinedInput label="Name" />}
                        MenuProps={{ classes: { paper: classes.menuPaper } }}
                        // MenuProps={MenuProps}
                      >
                        {inches === "cm"
                          ? lengthMetrices1.map((name) => (
                              <MenuItem
                                style={{ height: "45px" }}
                                key={name}
                                value={name}
                                // style={getStyles(name, personName, theme)}
                              >
                                {name}
                              </MenuItem>
                            ))
                          : lengthMetrices.map((name) => (
                              <MenuItem
                                style={{ height: "45px" }}
                                key={name}
                                value={name}
                                // style={getStyles(name, personName, theme)}
                              >
                                {name}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>

                    {/* <select
                      class="form-select  shadow-none test" 
                      id="drop"
                      name="width1"
                      value={width1}
                      required
                      
                      onChange={(event) => {
                        setWidth1(event.target.value);
                        setInputData1({...inputData1,[event.target.name]: event.target.value*2.54})
                        setUserInput({ ...userInput, [event.target.name]: event.target.value });
                      }}
                      
                      aria-label="Default select example"
                      style={{marginLeft:"9px",height:"49px",marginBottom:"15px",background:"none"}}
                      
                      >
                      <option selected> width</option>
                      <option value="30">30 </option>
                      <option value="40">40 </option>
                      <option value="50">50 </option>
                      <option value="60">60 </option>
                      <option value="70">70</option>
                      <option value="80">80</option>
                      <option value="90">90</option>
                      <option value="100">100</option>
                     
                   
                    </select> */}

                    <FormControl
                      className={classes.formControl}
                      style={{
                        marginTop: "-3px",
                        width: "100%",
                        marginLeft: "6px",
                        marginBottom: "10px",
                      }}
                    >
                      <InputLabel id="demo-multiple-name-label">
                        Width
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        name="width1"
                        value={width1}
                        onChange={(event) => {
                          setWidth1(event.target.value);
                          setInputData1({
                            ...inputData1,
                            [event.target.name]: event.target.value / 2.54,
                          });
                          setUserInput({
                            ...userInput,
                            [event.target.name]: event.target.value,
                          });
                        }}
                        input={<OutlinedInput label="Name" />}
                        MenuProps={{ classes: { paper: classes.menuPaper } }}
                        // MenuProps={MenuProps}
                      >
                        {inches === "cm"
                          ? widthMetrices1.map((name) => (
                              <MenuItem
                                style={{ height: "45px" }}
                                key={name}
                                value={name}
                                // style={getStyles(name, personName, theme)}
                              >
                                {name}
                              </MenuItem>
                            ))
                          : widthMetrices.map((name) => (
                              <MenuItem
                                style={{ height: "45px" }}
                                key={name}
                                value={name}
                                // style={getStyles(name, personName, theme)}
                              >
                                {name}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>

                    {/* <input
                        type="text"
                        name="width1"
                        placeholder="EnterWidth"
                        onChange={(event) => {
                          setWidth1(event.target.value);
                          setInputData1({...inputData1,[event.target.name]: event.target.value*2.54})
                          setUserInput({ ...userInput, [event.target.name]: event.target.value });
                        }}
                        value={width1}
                        required
                      /> */}
                    {/* </div> */}
                    {/* <div className="col-lg-2"> */}
                    {/* <select
                      class="form-select  shadow-none test" 
                      id="drop"
                      name="height1"
                      value={height1}
                      required
                      
                      onChange={(event) => {
                        setHeight1(event.target.value);
                        setInputData1({...inputData1,[event.target.name]: event.target.value*2.54})
                        setUserInput({ ...userInput, [event.target.name]: event.target.value });
                      }}
                      
                      aria-label="Default select example"
                      style={{marginLeft:"9px",height:"49px",marginBottom:"15px",background:"none"}}
                      
                      >
                      <option selected>Height</option>
                      <option value="30">30 </option>
                      <option value="40">40 </option>
                      <option value="50">50 </option>
                      <option value="60">60 </option>
                      <option value="70">70</option>
                      <option value="80">80</option>
                      <option value="90">90</option>
                      <option value="100">100</option>
                     
                   
                    </select> */}
                    <FormControl
                      className={classes.formControl}
                      style={{
                        marginTop: "-3px",
                        width: "100%",
                        marginLeft: "6px",
                        marginBottom: "10px",
                      }}
                    >
                      <InputLabel id="demo-multiple-name-label">
                        Depth
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        name="height1"
                        value={height1}
                        onChange={(event) => {
                          setHeight1(event.target.value);
                          setInputData1({
                            ...inputData1,
                            [event.target.name]: event.target.value / 2.54,
                          });
                          setUserInput({
                            ...userInput,
                            [event.target.name]: event.target.value,
                          });
                        }}
                        input={<OutlinedInput label="Name" />}
                        MenuProps={{ classes: { paper: classes.menuPaper } }}
                        // MenuProps={MenuProps}
                      >
                        {inches === "cm"
                          ? heightMetrices1.map((name) => (
                              <MenuItem
                                style={{ height: "45px" }}
                                key={name}
                                value={name}
                                // style={getStyles(name, personName, theme)}
                              >
                                {name}
                              </MenuItem>
                            ))
                          : heightMetrices.map((name) => (
                              <MenuItem
                                style={{ height: "45px" }}
                                key={name}
                                value={name}
                                // style={getStyles(name, personName, theme)}
                              >
                                {name}
                              </MenuItem>
                            ))}
                      </Select>
                    </FormControl>

                    {/* <input
                        type="text"
                        name="height1"
                        placeholder="EnterHeight"
                        onChange={(event) => {
                          setHeight1(event.target.value);
                          setInputData1({...inputData1,[event.target.name]: event.target.value*2.54})
                          setUserInput({ ...userInput, [event.target.name]: event.target.value });
                        }}
                        value={height1}
                        required
                      /> */}
                    {/* </div> */}
                    {/* <div className="col-lg-2"> */}

                    <select
                      class="form-select  shadow-none test"
                      id="drop"
                      name="inches"
                      value={inches}
                      onChange={(event) => {
                        setInches(event.target.value);
                      }}
                      aria-label="Default select example"
                      style={{
                        marginLeft: "7px",
                        height: "49px",
                        marginBottom: "15px",
                        background: "none",
                      }}
                    >
                      <option selected>Inch</option>
                      <option value="cm">Cm </option>
                    </select>

                    {/* </div> */}
                    {/* <div className="col-lg-2"> */}
                    <button
                      type="button"
                      class="btn btn-danger mt-2"
                      onClick={clickEventClone}
                      style={{
                        background: "#DD3D05",
                        cursor: "pointer",
                        borderRadius: "20px",
                        marginLeft: "9px",
                        height: "37px",
                      }}
                    >
                      {" "}
                      <span style={{ fontWeight: "600" }}>Submit</span>
                    </button>
                    {/* </div> */}
                  </div>

                  {priceError ? (
                    <div class="error-text  mb-4">{priceError}</div>
                  ) : (
                    <div
                      class="mb-4"
                      style={{
                        width: "92px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <span
                        style={{
                          // marginLeft:"32px",
                          fontWeight: "500",
                          fontSize: "35px",
                        }}
                      >
                        {finalPrice1
                          ? "₹" + numberWithCommas(Math.round(finalPrice1))
                          : false}
                        {/* {(width === "") & (length === "") ? 0 : finalPrice} */}
                      </span>
                    </div>
                  )}
                </form>
                <div
                  class="wishshare"
                  style={{
                    display: "flex",
                    marginLeft: "10px",
                    marginBottom: "35px",
                  }}
                >
                  <div class="product-quality " style={{ textAlign: "center" }}>
                    <span
                      style={{
                        display: "flex",
                        padding: "2px 5px",
                        marginTop: "10px",
                        // marginBottom: "5px",
                      }}
                    >
                      <span
                        class="minus-btn"
                        onClick={() => {
                          return quantity > 1
                            ? setQuantity(quantity - 1)
                            : false;
                        }}
                      >
                        <i class="bx bx-minus"></i>
                      </span>
                      <input
                        type="text"
                        min="1"
                        disabled
                        style={{
                          outline: "none",
                          border: "none",
                          background: "none",
                          textAlign: "center",
                          marginTop: "-3px",
                        }}
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                      />

                      <span
                        class="plus-btn"
                        onClick={() => {
                          setQuantity(quantity + 1);
                        }}
                      >
                        <i class="bx bx-plus"></i>
                      </span>
                    </span>
                  </div>

                  <div
                    class="single-product-wishlist"
                    style={{ marginLeft: "20px", marginTop: "12px" }}
                  >
                    <a
                      href="#"
                      title="wishlist"
                      onClick={() => {
                        dispatch(
                          addWishLists({
                            productId: sproduct[changeVariant].productId,
                          })
                        ).then((res) => {});
                      }}
                    >
                      {sproduct[changeVariant]?.favourites === 0 ? (
                        <i class="bx bx-heart" style={{ fontSize: "26px" }}></i>
                      ) : (
                        <i
                          style={{ fontSize: "26px" }}
                          class="bx bxs-heart"
                        ></i>
                      )}
                    </a>
                  </div>
                  <div
                    class="single-product-cart btn-hover"
                    style={{ marginLeft: "20px", marginTop: "12px" }}
                  >
                    <span
                      style={{ cursor: "pointer", marginLeft: "-28px" }}
                      name="share"
                      onClick={(e) => {
                        e.preventDefault();
                        copyData(
                          process.env.REACT_APP_HOME_URL +
                            "/productDetails/" +
                            id
                        );
                      }}
                    >
                      {" "}
                      <MdContentCopy style={{ fontSize: "25px" }} />
                    </span>
                  </div>

                  <div
                    class="product-details-action-wrap"
                    style={{ marginLeft: "3px" }}
                  >
                    {Token ? (
                      <button
                        type="button"
                        class="btn btn-danger"
                        onClick={handleClick2}
                        style={{
                          background: "#DD3D05",
                          cursor: "pointer",
                          borderRadius: "20px",
                        }}
                      >
                        <span className="icons" style={{ fontWeight: "600" }}>
                          {" "}
                          Add to Cart
                        </span>
                        <i
                          style={{
                            marginLeft: "5px",
                            fontSize: "15px",
                          }}
                          class="fa fa-shopping-cart cartIcon"
                          aria-hidden="true"
                        ></i>
                      </button>
                    ) : (
                      <a data-bs-toggle="modal" data-bs-target="#loginModal">
                        <button
                          type="button"
                          class="btn btn-danger"
                          style={{
                            background: "#DD3D05",
                            cursor: "pointer",
                            borderRadius: "20px",
                          }}
                        >
                          <span className="icons" style={{ fontWeight: "600" }}>
                            {" "}
                            Add to Cart
                          </span>
                          <i
                            style={{
                              marginLeft: "5px",
                              fontSize: "15px",
                            }}
                            class="fa fa-shopping-cart cartIcon"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </a>
                    )}
                    <button
                      type="button"
                      class="btn btn-danger"
                      onClick={Clicktobuy}
                      style={{
                        background: "#DD3D05",
                        cursor: "pointer",
                        borderRadius: "20px",
                        marginLeft: "20px",
                      }}
                    >
                      <span className="icons" style={{ fontWeight: "600" }}>
                        Buy Now
                      </span>
                      <AiFillShopping
                        style={{
                          fontSize: "22px",
                          marginTop: "-5px",
                        }}
                      />
                    </button>
                  </div>
                </div>

                <div style={{ textAlign: "center" }}>
                  <span style={{ color: "#DD3D05", fontWeight: "600" }}>
                    No Return Policy available for custom products
                  </span>
                </div>
                <h5
                  style={{
                    marginLeft: "10px",
                    marginTop: "12px",
                    fontSize: "15px",
                  }}
                ></h5>
                <div>
                  {custom ? (
                    <div
                      style={{ marginTop: "20px", marginLeft: "10px" }}
                      class="alert alert-warning alert-dismissible fade show"
                      role="alert"
                      mt-3
                    >
                      {custom === "Already Added to Cart" ||
                      custom === "Added to Cart" ? (
                        <Link to="/cart">
                          {" "}
                          {custom} -{" "}
                          <span style={{ textDecoration: "underline" }}>
                            {" "}
                            Go to Cart
                          </span>{" "}
                        </Link>
                      ) : (
                        custom
                      )}
                      <button
                        type="button"
                        class="btn-close"
                        //   onClick={()=>dispatch({type:addCart.add.success,data:false})}
                        data-dismiss="alert3"
                      ></button>
                    </div>
                  ) : (
                    false
                  )}
                </div>
                <div>
                  {wish ? (
                    <div
                      style={{ marginLeft: "10px" }}
                      class="alert alert-warning alert-dismissible fade show"
                      role="alert"
                      mt-3
                    >
                      {wish}
                      <button
                        type="button"
                        class="btn-close"
                        data-dismiss="alert1"
                        //   onClick={()=>dispatch({type:addWishList.wishList.success,data:false})}
                        aria-label="Close"
                      ></button>
                    </div>
                  ) : (
                    false
                  )}
                </div>
              </div>
            </div>
          </>
        );

      default:
        return "Formula is not Valid";
    }
  };

  // Customization module end

  return load ? (
    <div
      style={{
        height: "400px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : (
    <>
      {sproduct === "No Variants Found" ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          No Product detail found
        </div>
      ) : (
        <div class="main-wrapper main-wrapper-2">
          {
            <div class="breadcrumb-area bg-gray-4 pt-5 pb-5">
              {/* <div class="container">
              <div class="breadcrumb-content text-center">
                <h2 data-aos="fade-up" data-aos-delay="200">
                  Details
                </h2>
                <ul data-aos="fade-up" data-aos-delay="400">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <i class="ti-angle-right"></i>
                  </li>
                  <li>Product Details </li>
                </ul>
              </div>
            </div> */}

              {/* <div class="breadcrumb-img-1" data-aos="fade-right" data-aos-delay="200">
                <img src="/assets/images/banner/breadcrumb-1.png" alt=""/>
            </div>
            <div class="breadcrumb-img-2" data-aos="fade-left" data-aos-delay="200">
                <img src="/assets/images/banner/breadcrumb-2.png" alt=""/>
            </div> */}
            </div>
          }

          <div id="hp-ctn-howItWorks">
            <a href="/contactUs">
              <p class="c">Bulk&nbsp;Orders </p>
            </a>
          </div>

          {/* product details section */}
          <div class="product-details-area pb-100 ">
            <div class="container">
              <div className="row">
                {/* left section */}
                <div class="col-md-5">
                  <div
                    class="product-details-img-wrap"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    {/* top swiper box */}
                    <div class="swiper-container product-details-big-img-slider-2 pd-big-img-style">
                      <div class="swiper-wrapper">
                        <div class="swiper-slide">
                          <div class="easyzoom-style">
                            <div class="easyzoom easyzoom--overlay">
                              <Swiper
                                style={{
                                  "--swiper-navigation-color": "#fff",
                                  "--swiper-pagination-color": "#fff",
                                }}
                                spaceBetween={10}
                                navigation={true}
                                thumbs={{ swiper: thumbsSwiper }}
                                modules={[FreeMode, Navigation, Thumbs]}
                                className="mySwiper2 custom-swiper" // Add custom class
                              >
                                {JSON.parse(
                                  sproduct[changeVariant].variantImage
                                ).map((data, i) => (
                                  <div className="swiper-slide" key={i}>
                                    <div className="product-details-small-img">
                                      <SwiperSlide>
                                        <img
                                          src={
                                            process.env.REACT_APP_BASE_URL +
                                            data
                                          }
                                          onMouseOver={() => setChange(i)}
                                          onClick={() => setChange(i)}
                                          alt="Product Thumbnail"
                                          style={{
                                            paddingRight: "15px",
                                            cursor: "pointer",
                                            objectFit: "cover",
                                            display: "block",
                                            width: "100%",
                                          }}
                                        />
                                      </SwiperSlide>
                                    </div>
                                  </div>
                                ))}
                              </Swiper>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* navigation swiper box */}
                    <div className="product-details-small-img-wrap my-2">
                      <Swiper
                        pagination={{
                          clickable: true,
                        }}
                        onSwiper={setThumbsSwiper}
                        spaceBetween={10}
                        slidesPerView={4}
                        freeMode={true}
                        watchSlidesProgress={true}
                        modules={[FreeMode, Navigation, Thumbs, Pagination]}
                        className="mySwiper"
                      >
                        {JSON.parse(sproduct[changeVariant].variantImage).map(
                          (data, i) => (
                            <SwiperSlide key={i} navigation>
                              <img
                                src={`${process.env.REACT_APP_BASE_URL}${data}`}
                                width="90%"
                                onClick={() => setChange(i)}
                                alt="Product Thumbnail"
                                style={{
                                  cursor: "pointer",
                                }}
                              />
                            </SwiperSlide>
                          )
                        )}
                      </Swiper>
                    </div>
                  </div>
                </div>

                {/* right section */}
                <div class="col-md-6">
                  <div
                    class="product-details-content"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <h2 style={{ textTransform: "capitalize" }}>
                      {sproduct[changeVariant].productName}
                      {/* <span style={{ textTransform: "capitalize" }}>
                        ({sproduct[changeVariant].variantName})
                      </span> */}
                    </h2>

                    <div class="login-register-area  ">
                      <div class="container">
                        <div class="row">
                          {/* TABS */}
                          <div class="login-register-wrapper">
                            {/* TAB TITLES */}
                            <div
                              class="login-register-tab-list nav"
                              style={{
                                marginTop: "35px",
                                marginBottom: "18px",
                              }}
                            >
                              {/* tab 1 name */}
                              <a
                                class="active"
                                data-bs-toggle="tab"
                                href="#lg1"
                              >
                                <h4> Regular Size </h4>
                              </a>

                              {sproduct[changeVariant] &&
                              sproduct[changeVariant].customize === "yes" ? (
                                sproduct[changeVariant].productFormula ===
                                "1" ? (
                                  <>
                                    {/* tab 3 name */}
                                    <a data-bs-toggle="tab" href="#lg3">
                                      <h4>Custom Sheet Size</h4>
                                    </a>
                                    {/* tab 4 name */}
                                    <a data-bs-toggle="tab" href="#lg4">
                                      <h4>Fitted sheets</h4>
                                    </a>
                                  </>
                                ) : (
                                  <a data-bs-toggle="tab" href="#lg2">
                                    {/* tab 2 name */}
                                    <h4>Custom Size</h4>
                                  </a>
                                )
                              ) : null}
                            </div>

                            {/* TAB CONTENT */}
                            <div
                              class="tab-content"
                              style={{
                                marginTop: "35px",
                                marginBottom: "-18px",
                                marginLeft: "-140px",
                              }}
                            >
                              {/* TAB 1 Regular Size*/}
                              <div
                                id="lg1"
                                class="tab-pane active"
                                style={{ marginLeft: "150px" }}
                              >
                                <div
                                  className="test "
                                  style={{
                                    marginBottom: "35px",
                                    marginTop: "25px",
                                  }}
                                >
                                  {Array.isArray(sproduct) &&
                                    sproduct.map((data, index) => (
                                      <button
                                        key={data.id}
                                        onClick={() => {
                                          setChangeVariant(index);
                                          dispatch(
                                            productSpecifications({
                                              productId: pid.productId,
                                              variantId: data.id,
                                            })
                                          );
                                        }}
                                        className="btn btn-light"
                                        style={
                                          sproduct[changeVariant]
                                            ?.variantName === data?.variantName
                                            ? {
                                                border: "1px solid #DD3D05",
                                                background: "#DD3D05",
                                                color: "white",
                                                borderRadius: "20px",
                                                textTransform: "capitalize",
                                              }
                                            : {
                                                marginRight: "10px",
                                                textTransform: "capitalize",
                                                borderRadius: "20px",
                                              }
                                        }
                                      >
                                        {data?.variantName}
                                      </button>
                                    ))}
                                </div>

                                <div class="product-details-price mt-4">
                                  <span
                                    class="old-price"
                                    style={{
                                      fontSize: "35px",
                                      marginLeft: "15px",
                                    }}
                                  >
                                    ₹
                                    {numberWithCommas(
                                      sproduct?.[changeVariant]?.actualPrice
                                    )}
                                  </span>
                                  <span
                                    class="new-price"
                                    style={{ fontSize: "35px" }}
                                  >
                                    ₹
                                    {numberWithCommas(
                                      sproduct?.[changeVariant]?.discountPrice
                                    )}
                                  </span>
                                </div>

                                <div
                                  class="wishshare"
                                  style={{
                                    display: "flex",
                                    marginLeft: "10px",
                                    marginBottom: "35px",
                                  }}
                                >
                                  <div
                                    class="product-quality "
                                    style={{ textAlign: "center" }}
                                  >
                                    <span
                                      style={{
                                        display: "flex",
                                        padding: "5px 10px",
                                        marginTop: "8px",
                                        marginBottom: "5px",
                                      }}
                                    >
                                      <span
                                        class="minus-btn"
                                        onClick={() => {
                                          return quantity > 1
                                            ? setQuantity(quantity - 1)
                                            : false;
                                        }}
                                      >
                                        <i class="bx bx-minus"></i>
                                      </span>
                                      <input
                                        type="text"
                                        min="1"
                                        disabled
                                        style={{
                                          outline: "none",
                                          border: "none",
                                          background: "none",
                                          textAlign: "center",
                                          marginTop: "-3px",
                                        }}
                                        value={quantity}
                                        onChange={(e) =>
                                          setQuantity(e.target.value)
                                        }
                                      />

                                      <span
                                        class="plus-btn"
                                        onClick={() => {
                                          setQuantity(quantity + 1);
                                        }}
                                      >
                                        <i class="bx bx-plus"></i>
                                      </span>
                                    </span>
                                  </div>

                                  <div
                                    class="single-product-wishlist"
                                    style={{
                                      marginLeft: "20px",
                                      marginTop: "12px",
                                    }}
                                  >
                                    <a
                                      href="#"
                                      title="wishlist"
                                      onClick={() => {
                                        dispatch(
                                          addWishLists({
                                            productId:
                                              sproduct[changeVariant].productId,
                                          })
                                        ).then((res) => {});
                                      }}
                                    >
                                      {sproduct[changeVariant]?.favourites ===
                                      0 ? (
                                        <i
                                          class="bx bx-heart"
                                          style={{ fontSize: "26px" }}
                                        ></i>
                                      ) : (
                                        <i
                                          style={{ fontSize: "26px" }}
                                          class="bx bxs-heart"
                                        ></i>
                                      )}
                                    </a>
                                  </div>
                                  <div
                                    class="single-product-cart btn-hover"
                                    style={{
                                      marginLeft: "20px",
                                      marginTop: "12px",
                                    }}
                                  >
                                    <span
                                      style={{
                                        cursor: "pointer",
                                        marginLeft: "-28px",
                                      }}
                                      name="share"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        copyData(
                                          process.env.REACT_APP_BASE_URL +
                                            "/productDetails/" +
                                            id
                                        );
                                      }}
                                    >
                                      {" "}
                                      <MdContentCopy
                                        style={{ fontSize: "25px" }}
                                      />
                                    </span>
                                  </div>

                                  <div
                                    class="product-details-action-wrap"
                                    style={{ marginLeft: "3px" }}
                                  >
                                    {Token ? (
                                      <button
                                        type="button"
                                        class="btn btn-danger"
                                        onClick={handleClick}
                                        style={{
                                          background: "#DD3D05",
                                          cursor: "pointer",
                                          borderRadius: "20px",
                                        }}
                                      >
                                        <span
                                          className="icons"
                                          style={{ fontWeight: "600" }}
                                        >
                                          {" "}
                                          Add to Cart
                                        </span>
                                        <i
                                          style={{
                                            marginLeft: "5px",
                                            fontSize: "15px",
                                          }}
                                          class="fa fa-shopping-cart cartIcon"
                                          aria-hidden="true"
                                        ></i>
                                      </button>
                                    ) : (
                                      <a
                                        data-bs-toggle="modal"
                                        data-bs-target="#loginModal"
                                      >
                                        <button
                                          type="button"
                                          class="btn btn-danger"
                                          style={{
                                            background: "#DD3D05",
                                            cursor: "pointer",
                                            borderRadius: "20px",
                                          }}
                                        >
                                          <span
                                            className="icons"
                                            style={{ fontWeight: "600" }}
                                          >
                                            {" "}
                                            Add to Cart
                                          </span>
                                          <i
                                            style={{
                                              marginLeft: "5px",
                                              fontSize: "15px",
                                            }}
                                            class="fa fa-shopping-cart cartIcon"
                                            aria-hidden="true"
                                          ></i>
                                        </button>
                                      </a>
                                    )}
                                    <button
                                      type="button"
                                      class="btn btn-danger"
                                      onClick={Clicktobuy}
                                      style={{
                                        background: "#DD3D05",
                                        cursor: "pointer",
                                        borderRadius: "20px",
                                        marginLeft: "20px",
                                      }}
                                    >
                                      <span
                                        className="icons"
                                        style={{ fontWeight: "600" }}
                                      >
                                        Buy Now
                                      </span>
                                      <AiFillShopping
                                        style={{
                                          fontSize: "22px",
                                          marginTop: "-5px",
                                        }}
                                      />
                                    </button>
                                    {/* </div> */}
                                    {/* <div 

>

{Token ?

  <i
  onClick={handleClick1}
          style={{
            marginLeft: "5px",
            fontSize: "15px",
            cursor:"pointer"
          }}
          class="fa fa-shopping-cart"
          aria-hidden="true"
        ></i>
  
  
  :<a data-bs-toggle="modal"
        data-bs-target="#loginModal">
       
  <i
          style={{
            marginLeft: "5px",
            fontSize: "15px",
          }}
          class="fa fa-shopping-cart"
          aria-hidden="true"
        ></i>

          </a>}
         
          <AiFillShopping
          onClick={Clicktobuy}
          style={{
            fontSize: "22px",
            marginTop: "-5px",
            cursor:"pointer"
          }}
        />
  
  
 
</div> */}
                                  </div>
                                </div>
                                <div style={{ textAlign: "center" }}>
                                  <span
                                    style={{
                                      color: "#DD3D05",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {" "}
                                    Return Policy available for 6 days
                                  </span>
                                </div>
                                <h5
                                  style={{
                                    marginLeft: "10px",
                                    marginTop: "12px",
                                    fontSize: "15px",
                                  }}
                                ></h5>
                                {/* <div class="product-color product-color-active product-details-color">
                                <span>Color :</span>
                                {JSON.parse(sproduct[changeVariant].variantColor).map(
                        (data, i) => (
                          <a
                            style={{ width: "40px" }}
                            className="imageborder active"
                          >
                            <span
                              style={{
                                width: "30px",
                                height: "30px",
                                background: data,
                                // border: "1px solid black",
                                borderRadius: "50%",
                              }}
                              onClick={() => setChange(i)}
                            ></span>
                          </a>
                        )
                      )}
                            </div> */}
                                <div>
                                  {carts ? (
                                    <div
                                      style={{
                                        marginTop: "20px",
                                        marginLeft: "10px",
                                      }}
                                      class="alert alert-warning alert-dismissible fade show"
                                      role="alert"
                                      mt-3
                                    >
                                      {carts === "Already Added to Cart" ||
                                      carts === "Added to Cart" ? (
                                        <Link to="/cart">
                                          {" "}
                                          {carts} -{" "}
                                          <span
                                            style={{
                                              textDecoration: "underline",
                                            }}
                                          >
                                            {" "}
                                            Go to Cart
                                          </span>{" "}
                                        </Link>
                                      ) : (
                                        carts
                                      )}
                                      <button
                                        type="button"
                                        class="btn-close"
                                        //   onClick={()=>dispatch({type:addCart.add.success,data:false})}
                                        data-dismiss="alert"
                                      ></button>
                                    </div>
                                  ) : (
                                    false
                                  )}
                                </div>
                                <div>
                                  {wish ? (
                                    <div
                                      style={{ marginLeft: "10px" }}
                                      class="alert alert-warning alert-dismissible fade show"
                                      role="alert"
                                      mt-3
                                    >
                                      {wish}
                                      <button
                                        type="button"
                                        class="btn-close"
                                        data-dismiss="alert1"
                                        //   onClick={()=>dispatch({type:addWishList.wishList.success,data:false})}
                                        aria-label="Close"
                                      ></button>
                                    </div>
                                  ) : (
                                    false
                                  )}
                                </div>
                                <div
                                  class="product-details-action-wrap"
                                  style={{ marginLeft: "-10px" }}
                                >
                                  {/* <div class="button-box btn-hover">
                            <button type="submit">Add To Cart<i
                                          style={{
                                            marginLeft: "5px",
                                            fontSize: "15px",
                                          }}
                                          class="fa fa-shopping-cart"
                                          aria-hidden="true"
                                        ></i></button>
                          </div>
                                   <div class="button-box btn-hover">
                            <button type="submit">Buy Now<span>
                                        {" "}
                                        <AiFillShopping
                                          style={{
                                            fontSize: "22px",
                                            marginTop: "-5px",
                                          }}
                                        />
                                      </span></button>
                          </div> */}
                                  {/* <div class="single-product-cart btn-hover">
                                    {Token ? (
                                      <a className="cartbtn"
                                        style={{
                                          fontSize: "18px",
                                          borderRadius: "20px",
                                          paddingTop: "10px",
                                          paddingBottom: "10px",
                                          color: "white",
                                          borderRadius: "20px",
                                        }}
                                        onClick={handleClick}
                                      >
                                        Add to Cart{" "}
                                        <i
                                          style={{
                                            marginLeft: "5px",
                                            fontSize: "15px",
                                          }}
                                          class="fa fa-shopping-cart"
                                          aria-hidden="true"
                                        ></i>
                                      </a>
                                    ) : (
                                      <a
                                      
                                        data-bs-toggle="modal"
                                        data-bs-target="#loginModal"
                                        style={{
                                          fontSize: "18px",
                                          borderRadius: "20px",
                                          paddingTop: "10px",
                                          paddingBottom: "10px",
                                          paddingleft: "20px",
                                          borderRadius: "20px",
                                        }}
                                        href="#"
                                      >
                                        Add to Cart
                                        <i
                                          style={{
                                            marginLeft: "5px",
                                            fontSize: "15px",
                                          }}
                                          class="fa fa-shopping-cart"
                                          aria-hidden="true"
                                        ></i>
                                      </a>
                                    )}
                                  </div> */}

                                  {/* <div class="single-product-cart btn-hover">
                                    <a
                                    className="cartbtn"
                                      style={{
                                        fontSize: "17px",
                                        paddingTop: "10px",
                                        borderRadius: "20px",
                                        paddingBottom: "10px",
                                        paddingleft: "20px",
                                        color: "white",
                                      }}
                                      onClick={Clicktobuy}
                                    >
                                      Buy Now{" "}
                                      <span>
                                        {" "}
                                        <AiFillShopping
                                          style={{
                                            fontSize: "22px",
                                            marginTop: "-5px",
                                          }}
                                        />
                                      </span>
                                    </a>
                                  </div> */}
                                </div>

                                <div class="product-details-meta ">
                                  <ul>
                                    {/* <li><span class="title">SKU:</span> Ch-256xl</li>
                                    <li><span class="title">Category:</span>
                                        <ul>
                                            <li><a href="#">Office</a>,</li>
                                            <li><a href="#">Home</a></li>
                                        </ul>
                                    </li>
                                    <li><span class="title" >Tags:</span>
                                        <ul class="tag">
                                            <li><a href="#">Furniture</a></li>
                                        </ul>
                                    </li> */}

                                    <li
                                      class="single-product-cart btn-hover mt-5"
                                      style={{ marginLeft: "-12px" }}
                                    >
                                      {/* <a style={{fontSize:"18px",paddingTop:"10px",paddingBottom:"10px",paddingleft:"20px",color:"white",width:"34%"}} onClick={Clicktobuy}>
                                      <span style={{width:"15%",color:"white",marginLeft:"55px"}}>
                                     
                                       Buy Now
                                      </span>

                                       </a> */}
                                      {/* {buy? (
                      <div
    
                        class="alert alert-warning alert-dismissible fade show"
                        role="alert"
                        mt-3
                      >
                        {buy}
                        <button
                       
                          type="button"
                          class="btn-close"
                          data-dismiss="alert2"
                        
                        //   onClick={()=>dispatch({type:addWishList.wishList.success,data:false})}
                          aria-label="Close"
                        ></button>
                      </div>
                    ) :(false)} */}
                                    </li>

                                    {/* <li class="single-product-cart btn-hover" >
                                    <a  href="#"><span style={{color:"white",textAlign:"center",maxWidth:"500px"}}>Buy Now</span></a>
                                </li>
                                    */}
                                  </ul>
                                </div>
                                {/* <div class="social-icon-style-4">
                                <a href="#"><i class="fa fa-facebook"></i></a>
                                <a href="#"><i class="fa fa-instagram"></i></a>
                                <a href="#"><i class="fa fa-twitter"></i></a>
                        
                            </div> */}
                              </div>

                              {/* TAB 2  Custom Size*/}
                              <div
                                id="lg2"
                                class="tab-pane"
                                style={{ marginLeft: "150px" }}
                              >
                                <div
                                  className="test "
                                  style={{
                                    marginBottom: "35px",
                                  }}
                                >
                                  {Array.isArray(sproduct)
                                    ? sproduct?.map((data, index) => (
                                        <button
                                          onClick={() =>
                                            setChangeVariant(index)
                                          }
                                          className="btn btn-light "
                                          style={
                                            sproduct[changeVariant]
                                              ?.variantName ===
                                            data?.variantName
                                              ? {
                                                  display: "none",
                                                  border: "1px solid c",
                                                  background: "#DD3D05",
                                                  color: "white",
                                                  borderRadius: "20px",
                                                }
                                              : {
                                                  display: "none",
                                                  marginRight: "10px",
                                                  textTransform: "capitalize",
                                                  borderRadius: "0px",
                                                }
                                          }
                                        >
                                          {data?.variantName}
                                        </button>
                                      ))
                                    : false}
                                </div>
                                <SwitchCase
                                  // value="5"
                                  value={sproduct[changeVariant].productFormula}
                                />
                              </div>

                              {/* TAB 3 Custom Sheet Size  */}
                              <div
                                id="lg3"
                                class="tab-pane"
                                style={{ marginLeft: "150px" }}
                              >
                                <SwitchCase value="8" /> {/* flat sheets */}
                              </div>

                              {/* TAB 4 Fitted sheets */}
                              <div
                                id="lg4"
                                class="tab-pane"
                                style={{ marginLeft: "150px" }}
                              >
                                <SwitchCase value="10" /> {/* fitted sheets */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <center className="pb-5 mb-5">
                  <img
                    alt="No Products Found"
                    src={products}
                    className="mt-5 mb-5"
                    style={{ width: "25%" }}
                  />
                  <h3 className="container">"No Products Found"</h3>
                </center> */}
            </div>
          </div>

          {/* static service related section */}

          <div class="description-review-area pb-85 bg-white">
            <div class="container">
              <div
                class="description-review-topbar nav"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <a class="active" data-bs-toggle="tab" href="#des-details1">
                  {" "}
                  Description{" "}
                </a>
                <a data-bs-toggle="tab" href="#des-details4">
                  {" "}
                  MoreInfo{" "}
                </a>
                <a data-bs-toggle="tab" href="#des-details2" class="">
                  {" "}
                  Specifications{" "}
                </a>
                <a data-bs-toggle="tab" href="#des-details3" class="">
                  {" "}
                  Reviews{" "}
                </a>
              </div>
              <div class="tab-content">
                <div id="des-details1" class="tab-pane active">
                  <div class="product-description-content text-center">
                    <p
                      data-aos="fade-up"
                      data-aos-delay="400"
                      style={{ textAlign: "justify" }}
                    >
                      {productspecs?.productDescription ? (
                        productspecs?.productDescription
                      ) : (
                        <center>
                          <b style={{ color: "black" }}>
                            No Product Description Found
                          </b>
                        </center>
                      )}
                    </p>
                    {/* <p data-aos="fade-up" data-aos-delay="600">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut per unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo</p> */}
                  </div>
                </div>

                <div id="des-details4" class="tab-pane ">
                  <div class="product-description-content text-center">
                    <p
                      data-aos="fade-up"
                      data-aos-delay="400"
                      style={{ textAlign: "justify" }}
                    >
                      {productspecs?.moreInfo &&
                      productspecs?.moreInfo !== "No Info Available" ? (
                        productspecs?.moreInfo
                      ) : (
                        <center>
                          <b style={{ color: "black" }}>No Information Found</b>
                        </center>
                      )}{" "}
                    </p>
                  </div>
                </div>
                <div id="des-details2" class="tab-pane">
                  <div class="specification-wrap table-responsive">
                    {productspecs?.productSpecification &&
                    JSON.parse(productspecs?.productSpecification)?.length >
                      1 ? (
                      <table>
                        <tbody>
                          {JSON.parse(productspecs?.productSpecification)?.map(
                            (SP) => (
                              <tr>
                                <td
                                  class="width1"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {SP?.productTitle}
                                </td>
                                <td style={{ textTransform: "capitalize" }}>
                                  {" "}
                                  {SP?.productAnswer}
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    ) : (
                      <center>
                        <b style={{ color: "black", fontSize: "16px" }}>
                          No Specification Found
                        </b>
                      </center>
                    )}
                  </div>
                </div>
                <div id="des-details3" class="tab-pane">
                  <div class="row  d-flex justify-content-center">
                    {Array.isArray(review?.reviews) ? (
                      <div className="col-lg-2 col-md-12 ">
                        <div className="mt-2 ">
                          <p
                            style={{ marginTop: "15px", marginBottom: "45px" }}
                          >
                            <b>USER REVIEWS</b>
                          </p>
                          <p
                            style={{
                              fontSize: "90px",
                              // marginLeft:"25px",marginBottom:"35px",marginTop:"35px"
                            }}
                          >
                            {review?.reviewData?.overallRatings}
                          </p>
                          <div className="mb-5 mt-5">
                            {Array.from({ length: 5 }, (data, i) =>
                              i < review?.reviewData?.overallRatings ? (
                                <span style={{ margin: "2px" }}>
                                  <FaStar
                                    style={{
                                      color: "#DD3D05",
                                    }}
                                  />
                                </span>
                              ) : (
                                <span style={{ margin: "2px" }}>
                                  <AiOutlineStar />
                                </span>
                              )
                            )}

                            <p className="mt-4">
                              Based On{" "}
                              <span
                                style={{ fontSize: "16px", fontWeight: "600" }}
                              >
                                {review?.reviewData?.noOfRatings}
                              </span>{" "}
                              <span>
                                <b>Reviews</b>
                              </span>{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      false
                    )}
                    {review?.reviewData?.overallRatings ? (
                      <div class="col-lg-3 col-md-12 ">
                        <div class="mt-3">
                          <span style={{ fontSize: "12px" }}>
                            5 <i class="bx bx-star"></i>
                          </span>{" "}
                          <div class="progress">
                            <div
                              class="progress-bar bg-success"
                              role="progressbar"
                              style={{
                                width: review?.reviewData?.excellent + "%",
                                background: "#DD3D05",
                                color: "white",
                              }}
                            >
                              {review?.reviewData?.excellent + "%"}
                            </div>
                          </div>
                          <span style={{ fontSize: "12px" }}>
                            4 <i class="bx bx-star"></i>
                          </span>
                          <div class="progress">
                            <div
                              class="progress-bar bg-primary"
                              role="progressbar"
                              style={{
                                width: review?.reviewData?.best + "%",
                                background: "#DD3D05",
                                color: "white",
                              }}
                            >
                              {review?.reviewData?.best + "%"}
                            </div>
                          </div>
                          <span style={{ fontSize: "12px" }}>
                            3 <i class="bx bx-star"></i>
                          </span>
                          <div class="progress">
                            <div
                              class="progress-bar bg-info"
                              role="progressbar"
                              style={{
                                width: review?.reviewData?.good + "%",
                                background: "#DD3D05",
                                color: "white",
                              }}
                            >
                              {review?.reviewData?.good + "%"}
                            </div>
                          </div>
                          <span style={{ fontSize: "12px" }}>
                            2 <i class="bx bx-star"></i>
                          </span>{" "}
                          <div class="progress">
                            <div
                              class="progress-bar bg-warning"
                              role="progressbar"
                              style={{
                                width: review?.reviewData?.good + "%",
                                background: "#DD3D05",
                                color: "white",
                              }}
                            >
                              {review?.reviewData?.poor + "%"}
                            </div>
                          </div>
                          <span style={{ fontSize: "12px" }}>
                            1 <i class="bx bx-star"></i>
                          </span>
                          <div class="progress">
                            <div
                              class="progress-bar bg-danger"
                              role="progressbar"
                              style={{
                                width: review?.reviewData?.verypoor + "%",
                                background: "#DD3D05",
                                color: "white",
                              }}
                            >
                              {review?.reviewData?.verypoor + "%"}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      false
                    )}
                  </div>

                  {/* test */}

                  {Array.isArray(review?.reviews) &&
                  review?.reviews.length !== 0 ? (
                    review?.reviews.map((R) => (
                      <div class="product-review-form">
                        <div class="review-comments mt-5">
                          <div
                            class="review-item"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              borderBottom: "1px solid lightgray",
                              marginBottom: "20px",
                            }}
                          >
                            <div>
                              <div style={{ display: "flex" }}>
                                <img
                                  src={
                                    process.env.REACT_APP_BASE_URL +
                                    R?.customerImage
                                  }
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                    marginRight: "10px",
                                    borderRadius: "30px",
                                  }}
                                />{" "}
                                <h5
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginLeft: "5px",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {R?.customerName}
                                </h5>
                              </div>
                              <span
                                class="rating ml-3 mt-3"
                                style={{ display: "flex" }}
                              >
                                {Array.from({ length: 5 }, (data, i) =>
                                  i < R?.rating ? (
                                    <span style={{ margin: "2px" }}>
                                      <FaStar
                                        style={{
                                          color: "#DD3D05",
                                        }}
                                      />
                                    </span>
                                  ) : (
                                    <span style={{ margin: "2px" }}>
                                      <AiOutlineStar />
                                    </span>
                                  )
                                )}
                                <span
                                  style={{
                                    marginLeft: "10px",
                                    marginTop: "5px",
                                  }}
                                >
                                  {" "}
                                  {R?.rating} out of 5{" "}
                                </span>
                              </span>
                              <span>
                                <strong> </strong>
                              </span>
                              <p style={{ marginLeft: "10px" }}>{R?.review}</p>
                            </div>
                            <div class="reviewClass">
                              <a class="review-report-link">
                                <strong>{R.createdAt}</strong>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <h3>
                      {" "}
                      <center style={{ marginTop: "-50px" }}>
                        <img
                          src={reviews}
                          className="mt-5 mb-5  "
                          style={{ width: "25%" }}
                        />
                      </center>
                      <span
                        style={{
                          fontSize: "16px",
                          fontWeight: "600",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        No Reviews Found
                      </span>
                    </h3>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div class="service-area pb-70">
            <div class="container">
              <div
                class="row mt-3"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div class="col-lg-3 col-md-6 col-sm-6 col-12 mb-30">
                  <div
                    class="service-wrap"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <div class="service-img">
                      <img src="/assets/images/icon-img/car.png" alt="" />
                    </div>
                    <div class="service-content">
                      <h3>Free Shipping</h3>
                      <p>Free shipping on all order</p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 col-12 mb-30">
                  <div
                    class="service-wrap"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    {/* <div class="service-img">
                      <img src="/assets/images/icon-img/time.png" alt="" />
                    </div> */}
                    {/* <div class="service-content">
                      <h3>Support 24/7</h3>
                      <p>Support 24 hours a day</p>
                    </div> */}
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 col-sm-6 col-12 mb-30">
                  <div
                    class="service-wrap"
                    data-aos="fade-up"
                    data-aos-delay="600"
                  >
                    <div class="service-img">
                      <img
                        src="/assets/images/icon-img/rupees.png"
                        alt=""
                        width="60%"
                      />
                    </div>
                    <div class="service-content">
                      <h3>Money Return</h3>
                      <p>Easy Six Days Money Return Policy </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Login  Modal start --> */}

          <div
            class="modal fade quickview-modal-style"
            id="loginModal"
            tabIndex="-1"
            role="dialog"
          >
            <div
              class="modal-dialog modal-dialog-centered"
              role="document"
              style={{ justifyContent: "center" }}
            >
              <div class="modal-content modalLogin" style={{ width: "70%" }}>
                <div class="modal-header">
                  <a
                    href="#"
                    class="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <i class="fa-solid fa-xmark"></i>
                  </a>
                </div>
                <div class="modal-body">
                  <form>
                    <div class="mb-3">
                      <label for="recipient-name" class="col-form-label">
                        Email:
                      </label>
                      <input
                        type="email"
                        class="form-control"
                        placeholder="Enter Your E-mail"
                        name="email"
                        style={{
                          borderRadius: "0px",
                          // width: "450px",
                          // marginLeft: "125px",
                        }}
                        onChange={handleChange}
                        value={user.email}
                        required
                      />
                    </div>
                    <div class="mb-3">
                      <label for="message-text" class="col-form-label">
                        Password:
                      </label>
                      <input
                        type="password"
                        class="form-control"
                        placeholder="Enter Your Password"
                        name="password"
                        style={{
                          borderRadius: "0px",
                          // width: "450px",
                          // marginLeft: "125px",
                        }}
                        onChange={handleChange}
                        value={user.password}
                        required
                      />
                    </div>
                  </form>

                  <p class="forgot-password">
                    <a href="/register">
                      Don't you have an account? <b>Register</b>
                    </a>
                  </p>
                  <div class="button-box btn-hover">
                    <button onClick={handleSubmit}>Login </button>
                  </div>
                  {/* <div class="single-product-cart btn-hover">
                            <a className="cartbtn"
                              onClick={handleSubmit}
                              style={{
                                fontSize: "18px",
                                paddingTop: "10px",
                                paddingBottom: "10px",
                                paddingleft: "20px",
                                borderRadius: "20px",
                              }}
                              href="#"
                            >
                              Log In
                            </a>
                          </div> */}
                  {/* <div class="row gx-0">
                  <div class="col-lg-11 col-md-5 col-12">
                    <form>
                      <div class="row">
                        <div class="col-lg-6 mt-3 mb-3">
                          <div class="form-group">
                            <input
                              type="email"
                              class="form-control"
                              placeholder="Email"
                              name="email"
                              style={{
                                borderRadius: "0px",
                                // width: "450px",
                                // marginLeft: "125px",
                              }}
                              onChange={handleChange}
                              value={user.email}
                              required
                            />
                          </div>
                        </div>
                        <div class="col-lg-6 mt-3">
                          <div class="form-group">
                            <input
                              type="password"
                              class="form-control"
                              placeholder="Password"
                              name="password"
                              style={{
                                borderRadius: "0px",
                                // width: "450px",
                                // marginLeft: "125px",
                              }}
                              onChange={handleChange}
                              value={user.password}
                              required
                            />
                            <span
                              style={{
                                color: "brown",
                                marginLeft: "20px",
                              }}
                            >
                              {passworderror}
                            </span>
                          </div>
                        </div>
                        <div
                          class="para col-lg-12 mb-3"
                          style={{
                            borderRadius: "0px",
                            // width: "850px",
                            // marginLeft: "125px",
                          }}
                        >
                          <p class="forgot-password">
                            <a href="/register">
                              Don't you have an account? <b>Register</b>
                            </a>
                          </p>
                        </div>
                        <div
                          class="btun col-lg-12"
                          style={{
                            borderRadius: "0px",
                            width: "850px",
                            marginLeft: "113px",
                          }}
                        >
                          <div class="single-product-cart btn-hover">
                            <a className="cartbtn"
                              onClick={handleSubmit}
                              style={{
                                fontSize: "18px",
                                paddingTop: "10px",
                                paddingBottom: "10px",
                                paddingleft: "20px",
                                borderRadius: "5px",
                              }}
                              href="#"
                            >
                              Log In
                            </a>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                
                </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Login Modal end --> */}

          {/* <div class="related-product-area pb-95">
            <div class="container">
                {Token?<div class="section-title-2 st-border-center text-center mb-75" data-aos="fade-up" data-aos-delay="200">
                    <h2>Related Products</h2>
                </div>:(
              false
            )}
                
                <div class="related-product-active swiper-container " style={{maxWidth:"100%"}}>
                    <div class="swiper-wrapper" style={{maxWidth:"300px"}} >

                    {Array.isArray(rProducts) && Array.length !== 0
                ? rProducts
                    .filter((data) => data.discountPrice >= 1)
                    ?.map((data) => (
                        <div class="swiper-slide">
                            <div class="product-wrap" data-aos="fade-up" data-aos-delay="200">
                                <div class="product-img img-zoom mb-25">
                                    <a href={"/productDetails/"+data.id} >
                                        <img src={process.env.REACT_APP_BASE_URL+data.productImage} alt="" style={{paddingRight:"15px"}}/ >
                                    </a>
                                    
                                    <div class="product-action-wrap">
                                       
                                        <button class="product-action-btn-1" title="Quick View" data-bs-toggle="modal"onClick={() => SingleProduct(data.id)} data-bs-target="#exampleModal">
                                            <i class="pe-7s-look"></i>
                                        </button>
                                    </div>
                                    <div class="product-action-2-wrap">
                                        <a class="product-action-btn-2" href={"/productDetails/"+data.id}  ><i class="pe-7s-angle-right-circle"></i> View Details</a>
                                    </div>
                                </div>
                                <div class="product-content">
                                    <h3><a href="product-details.html">{data.productName}</a></h3>
                                    <div class="product-price">
                                        <span class="old-price">{data.actualPrice} </span>
                                        <span class="new-price">₹{data.discountPrice}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        ))
                : false}
                    </div>
                </div>
            </div>
        </div> */}
          <div class="product-area pb-60">
            <div class="container">
              {/* {Token && recommendProducts!=="" ? ( */}
              {Array.isArray(recommendProducts) && Array.length !== 0 ? (
                <div
                  class="section-title-2 st-border-center text-center mb-75"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <h2 style={{ textTransform: "uppercase" }}>
                    Recommended Products
                  </h2>
                </div>
              ) : (
                false
              )}

              <div class="tab-content jump">
                <div id="pro-1" class="tab-pane active">
                  <div class="row">
                    {Array.isArray(recommendProducts) && Array.length !== 0
                      ? recommendProducts
                          // .filter((data) => data.discountPrice >= 1)
                          .map((data) => (
                            <div class="col-lg-2 col-md-4 col-sm-6 col-12">
                              <div
                                class="product-wrap mb-35"
                                data-aos="fade-up"
                                data-aos-delay="200"
                              >
                                <div
                                  class="product-img img-zoom mb-25"
                                  style={{ borderRadius: "16px" }}
                                >
                                  <Link to={"/productDetails/" + data.id}>
                                    <img
                                      src={
                                        process.env.REACT_APP_BASE_URL +
                                        data.variantImage
                                      }
                                      alt={data.productName}
                                    />
                                  </Link>
                                  {data.actualPrice ? (
                                    <div class="ribbon ribbon-top-right">
                                      <span>
                                        {" "}
                                        -{" "}
                                        {formatAsPercent(
                                          ((data.actualPrice -
                                            data.discountPrice) /
                                            data.actualPrice) *
                                            100
                                        )}{" "}
                                      </span>
                                    </div>
                                  ) : (
                                    false
                                  )}
                                  <div
                                    class="product-action-wrap "
                                    id="quickview"
                                  >
                                    <button
                                      class="product-action-btn-1"
                                      title="Quick View"
                                      onClick={() => SingleProduct(data.id)}
                                      data-bs-toggle="modal"
                                      data-bs-target="#exampleModal"
                                    >
                                      <i class="pe-7s-look"></i>
                                    </button>
                                  </div>
                                  <div class="product-action-2-wrap">
                                    <a
                                      href={"/productDetails/" + data.id}
                                      class="product-action-btn-2"
                                      title="Add To Cart"
                                    >
                                      <i class="pe-7s-angle-right-circle"></i>{" "}
                                      View Details
                                    </a>
                                  </div>
                                </div>
                                <div class="product-content">
                                  <h3 style={{ textTransform: "capitalize" }}>
                                    <a href={"/productDetails/" + data.id}>
                                      {data.productName}
                                    </a>
                                  </h3>
                                  <div class="product-price">
                                    <span class="old-price">
                                      ₹ {numberWithCommas(data.actualPrice)}{" "}
                                    </span>
                                    <span class="new-price">
                                      ₹ {numberWithCommas(data.discountPrice)}{" "}
                                    </span>
                                  </div>
                                  {/* <div class="product-price">
                                  <span class="old-price">
                                    ₹ {data.actualPrice}{" "}
                                  </span>
                                  <span class="new-price">
                                    ₹ {data.discountPrice}{" "}
                                  </span>
                                </div> */}
                                </div>
                              </div>
                            </div>
                          ))
                      : false}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="product-area pb-60">
            <div class="container">
              {Token ? (
                <div
                  class="section-title-2 st-border-center text-center mb-75"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <h2 style={{ textTransform: "uppercase" }}>
                    Related Products
                  </h2>
                </div>
              ) : (
                false
              )}

              <div class="tab-content jump">
                <div id="pro-1" class="tab-pane active">
                  <div class="row">
                    {Array.isArray(rProducts) && Array.length !== 0
                      ? rProducts
                          .filter((data) => data.discountPrice >= 1)
                          .map((data) => (
                            <div class="col-lg-2 col-md-4 col-sm-6 col-12">
                              <div
                                class="product-wrap mb-35"
                                data-aos="fade-up"
                                data-aos-delay="200"
                              >
                                <div
                                  class="product-img img-zoom mb-25"
                                  style={{ borderRadius: "16px" }}
                                >
                                  <Link to={"/productDetails/" + data.id}>
                                    <img
                                      src={
                                        process.env.REACT_APP_BASE_URL +
                                        data.productImage
                                      }
                                      alt={data.productName}
                                    />
                                  </Link>
                                  {data.actualPrice ? (
                                    <div class="ribbon ribbon-top-right">
                                      <span>
                                        {" "}
                                        -{" "}
                                        {formatAsPercent(
                                          ((data.actualPrice -
                                            data.discountPrice) /
                                            data.actualPrice) *
                                            100
                                        )}{" "}
                                      </span>
                                    </div>
                                  ) : (
                                    false
                                  )}
                                  <div
                                    class="product-action-wrap "
                                    id="quickview"
                                  >
                                    <button
                                      class="product-action-btn-1"
                                      title="Quick View"
                                      onClick={() => SingleProduct(data.id)}
                                      data-bs-toggle="modal"
                                      data-bs-target="#exampleModal"
                                    >
                                      <i class="pe-7s-look"></i>
                                    </button>
                                  </div>
                                  <div class="product-action-2-wrap">
                                    <a
                                      href={"/productDetails/" + data.id}
                                      class="product-action-btn-2"
                                      title="Add To Cart"
                                    >
                                      <i class="pe-7s-angle-right-circle"></i>{" "}
                                      View Details
                                    </a>
                                  </div>
                                </div>
                                <div class="product-content">
                                  <h3 style={{ textTransform: "capitalize" }}>
                                    <a href={"/productDetails/" + data.id}>
                                      {data.productName}
                                    </a>
                                  </h3>
                                  <div class="product-price">
                                    <span class="old-price">
                                      ₹ {numberWithCommas(data.actualPrice)}{" "}
                                    </span>
                                    <span class="new-price">
                                      ₹ {numberWithCommas(data.discountPrice)}{" "}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                      : false}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <!-- Product Modal start --> */}
          <div
            class="modal fade quickview-modal-style"
            id="exampleModal"
            tabIndex="-1"
            role="dialog"
          >
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <a
                    href="#"
                    class="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <i class="fa-solid fa-xmark"></i>
                  </a>
                </div>
                <div className="container p-5">
                  {loader ? (
                    <div
                      style={{
                        height: "500px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <div class="modal-body">
                      <div
                        class="product-details-area pb-80 pt-60 "
                        style={{ marginLeft: "-15px" }}
                      >
                        <div class="container">
                          {Array.isArray(sproduct) && sproduct.length > 0 ? (
                            <div class="row">
                              <div class="col-lg-6">
                                <div
                                  class="product-details-img-wrap"
                                  data-aos="fade-up"
                                  data-aos-delay="200"
                                >
                                  <div class="swiper-container product-details-big-img-slider-2 pd-big-img-style">
                                    <div class="swiper-wrapper">
                                      <div class="swiper-slide">
                                        <div class="easyzoom-style">
                                          <div class="easyzoom easyzoom--overlay">
                                            <a
                                              href={
                                                process.env.REACT_APP_BASE_URL +
                                                JSON.parse(
                                                  sproduct[changeVariant]
                                                    ?.variantImage
                                                )[change]
                                              }
                                            >
                                              <img
                                                src={
                                                  process.env
                                                    .REACT_APP_BASE_URL +
                                                  JSON.parse(
                                                    sproduct[changeVariant]
                                                      ?.variantImage
                                                  )[change]
                                                }
                                                alt=""
                                              />
                                            </a>
                                          </div>
                                          {/* <a class="easyzoom-pop-up img-popup"  target="_blank"href={
                      JSON.parse(sproduct[changeVariant]?.variantImage)[change]
                    }>
                                                <i class="pe-7s-search"></i>
                                            </a> */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-6">
                                <div
                                  class="product-details-content"
                                  data-aos="fade-up"
                                  data-aos-delay="400"
                                >
                                  <h2 style={{ textTransform: "capitalize" }}>
                                    {sproduct[changeVariant].productName}
                                    {/* <span style={{ textTransform: "capitalize" }}>
                                    ({sproduct[changeVariant].variantName})
                                  </span> */}
                                  </h2>

                                  {/* <div className="mt-3 mb-3">
                                  {Array.isArray(sproduct)
                                    ? sproduct?.map((data, index) => (
                                        <button
                                          onClick={() =>
                                            setChangeVariant(index)
                                          }
                                          className="btn btn-light "
                                          style={
                                            sproduct[changeVariant]
                                              ?.variantName ===
                                            data?.variantName
                                              ? {
                                                  border: "1px solid #DD3D05",
                                                  background: "#DD3D05",
                                                  color: "white",
                                                  borderRadius: "20px",
                                                }
                                              : {
                                                  marginRight: "10px",
                                                  textTransform: "capitalize",
                                                  borderRadius: "20px",
                                                }
                                          }
                                        >
                                          {data?.variantName}
                                        </button>
                                      ))
                                    : false}
                                </div> */}
                                  {Array.isArray(sproduct) &&
                                  sproduct.length !== 0 ? (
                                    <div class="product-details-price">
                                      <span class="old-price">
                                        ₹
                                        {numberWithCommas(
                                          sproduct[0].actualPrice
                                        )}
                                      </span>
                                      <span class="new-price">
                                        ₹
                                        {numberWithCommas(
                                          sproduct[0].discountPrice
                                        )}
                                        {/* {
numberWithCommas(JSON.parse(
sproduct.discountPrice
))
} */}
                                      </span>
                                    </div>
                                  ) : (
                                    false
                                  )}

                                  {/* <div class="product-details-review">
                                <div class="product-rating">
                                    <i class=" ti-star"></i>
                                    <i class=" ti-star"></i>
                                    <i class=" ti-star"></i>
                                    <i class=" ti-star"></i>
                                    <i class=" ti-star"></i>
                                </div>
                                <span>( 1 Customer Review )</span>
                            </div> */}
                                  <p style={{ textAlign: "justify" }}>
                                    {" "}
                                    <span
                                      style={{
                                        fontWeight: "600",
                                        textAlign: "justify",
                                      }}
                                    >
                                      Description :{" "}
                                    </span>
                                    {Truncate(
                                      productspecs?.productDescription,
                                      240
                                    )}
                                  </p>
                                  {/* <div class="product-color product-color-active product-details-color">
                                <span>Color :</span>
                                {JSON.parse(sproduct[changeVariant].variantColor).map(
                        (data, i) => (
                          <a
                            style={{ width: "40px" }}
                            className="imageborder active"
                          >
                            <span
                              style={{
                                width: "30px",
                                height: "30px",
                                background: data,
                                border: "1px solid black",
                                borderRadius: "50%",
                              }}
                              onClick={() => setChange(i)}
                            ></span>
                          </a>
                        )
                      )}
                            </div> */}
                                  <div>
                                    {carts ? (
                                      <div
                                        style={{
                                          marginTop: "-20px",
                                          marginLeft: "10px",
                                        }}
                                        class="alert alert-warning alert-dismissible fade show"
                                        role="alert"
                                        mt-3
                                      >
                                        {carts === "Already Added to Cart" ||
                                        carts === "Added to Cart" ? (
                                          <a href="/cart">
                                            {" "}
                                            {carts} -{" "}
                                            <span
                                              style={{
                                                textDecoration: "underline",
                                              }}
                                            >
                                              {" "}
                                              Go to Cart
                                            </span>{" "}
                                          </a>
                                        ) : (
                                          carts
                                        )}
                                        <button
                                          type="button"
                                          class="btn-close"
                                          onClick={() =>
                                            dispatch({
                                              type: addCart.add.success,
                                              data: false,
                                            })
                                          }
                                          aria-label="Close"
                                        ></button>
                                      </div>
                                    ) : (
                                      false
                                    )}
                                  </div>
                                  <div>
                                    {wish ? (
                                      <div
                                        style={{ marginLeft: "10px" }}
                                        class="alert alert-warning alert-dismissible fade show"
                                        role="alert"
                                        mt-3
                                      >
                                        {wish}
                                        <button
                                          type="button"
                                          class="btn-close"
                                          onClick={() =>
                                            dispatch({
                                              type: addWishList.wishList
                                                .success,
                                              data: false,
                                            })
                                          }
                                          aria-label="Close"
                                        ></button>
                                      </div>
                                    ) : (
                                      false
                                    )}
                                  </div>
                                  <div class="product-details-action-wrap mb-5">
                                    <div
                                      class="product-quality"
                                      style={{ textAlign: "center" }}
                                    >
                                      <span
                                        style={{
                                          display: "flex",
                                          padding: "5px 10px",
                                          marginTop: "8px",
                                          marginBottom: "5px",
                                        }}
                                      >
                                        <span
                                          class="minus-btn"
                                          onClick={() => {
                                            return quantity > 1
                                              ? setQuantity(quantity - 1)
                                              : false;
                                          }}
                                        >
                                          <i class="bx bx-minus"></i>
                                        </span>
                                        <input
                                          type="text"
                                          min="1"
                                          disabled
                                          style={{
                                            outline: "none",
                                            border: "none",
                                            background: "none",
                                            textAlign: "center",
                                            marginTop: "-3px",
                                          }}
                                          value={quantity}
                                          onChange={(e) =>
                                            setQuantity(e.target.value)
                                          }
                                        />

                                        <span
                                          class="plus-btn"
                                          onClick={() => {
                                            setQuantity(quantity + 1);
                                          }}
                                        >
                                          <i class="bx bx-plus"></i>
                                        </span>
                                      </span>
                                    </div>
                                    <div class="single-product-cart btn-hover">
                                      {Token ? (
                                        <a
                                          className="cartbtn"
                                          style={{
                                            fontSize: "18px",
                                            paddingTop: "10px",
                                            paddingBottom: "10px",
                                            paddingleft: "20px",
                                            color: "white",
                                            borderRadius: "20px",
                                          }}
                                          onClick={handleClick}
                                        >
                                          Add to Cart
                                          <i
                                            style={{
                                              marginLeft: "5px",
                                              fontSize: "15px",
                                            }}
                                            class="fa fa-shopping-cart"
                                            aria-hidden="true"
                                          ></i>
                                        </a>
                                      ) : (
                                        <a
                                          style={{
                                            fontSize: "18px",
                                            paddingTop: "10px",
                                            paddingBottom: "10px",
                                            paddingleft: "20px",
                                            borderRadius: "20px",
                                          }}
                                          href="/login"
                                        >
                                          Add to Cart
                                          <i
                                            style={{
                                              marginLeft: "5px",
                                              fontSize: "15px",
                                            }}
                                            class="fa fa-shopping-cart"
                                            aria-hidden="true"
                                          ></i>
                                        </a>
                                      )}
                                    </div>
                                    <div class="single-product-wishlist">
                                      <a
                                        title="Wishlist"
                                        onClick={() => {
                                          // setLoad(true);
                                          dispatch(
                                            addWishLists({
                                              productId:
                                                sproduct[changeVariant]
                                                  .productId,
                                            })
                                          ).then((res) => {
                                            // setLoad(false);
                                          });
                                        }}
                                      >
                                        {sproduct[changeVariant]?.favourites ===
                                        0 ? (
                                          <i
                                            class="bx bx-heart"
                                            style={{ fontSize: "26px" }}
                                          ></i>
                                        ) : (
                                          <i
                                            style={{ fontSize: "26px" }}
                                            class="bx bxs-heart"
                                          ></i>
                                        )}
                                      </a>
                                    </div>
                                  </div>
                                  <div>
                                    {Array.isArray(sproduct) ? (
                                      <span>
                                        <a
                                          href={
                                            "/productDetails/" + sproduct[0].id
                                          }
                                          style={{
                                            fontSize: "18px",
                                            textDecoration: "underline",
                                          }}
                                        >
                                          View More Details
                                        </a>
                                      </span>
                                    ) : (
                                      false
                                    )}
                                    {/* <div class="single-product-cart btn-hover mt-5">
                                    <a  style={{fontSize:"18px",paddingTop:"10px",paddingBottom:"10px",paddingleft:"20px",}}href="#">Add to Cart</a>
                                </div> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            false
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* <!-- Product Modal end -->
        <!-- Mobile Menu start --> */}
          <div class="off-canvas-active">
            <a class="off-canvas-close">
              <i class="fa-solid fa-xmark"></i>
            </a>
            <div class="off-canvas-wrap">
              {/* <div class="welcome-text off-canvas-margin-padding">
              <p>Default Welcome Msg! </p>
            </div> */}
              <div class="mobile-menu-wrap off-canvas-margin-padding-2">
                <div id="mobile-menu" class="slinky-mobile-menu text-left">
                  <ul>
                    <li>
                      <a href="/">HOME</a>
                    </li>
                    <li>
                      <a href="/products">SHOP</a>
                    </li>
                    <li>
                      <a href="/categories">CATEGORIES </a>
                    </li>

                    <li>
                      <a href="/aboutus">ABOUT US</a>
                    </li>
                    <li>
                      <a href="/contactus">CONTACT US</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProductDetails;
