import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { addCart, addWishList } from "../Redux/ActionType";
import { FaStar } from "react-icons/fa";
import { AiOutlineStar } from "react-icons/ai";
import "./styles.css";
import "./style.css";
import products from "../images/noproductsfound.svg";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

// import "./product.css"
import {
  getProducts,
  FilterProducts,
  getSingleProduct,
  addCarts,
  getCategories,
  addWishLists,
  authenticateLogin,
} from "../Redux/Action";
const Token = localStorage.getItem("AuthTok");
const Products = () => {
  let navigate = useNavigate();
  const catshop = (id) => {
    navigate("/categoryShop/" + id);
  };
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const [price, setPrice] = useState([]);
  const [message, setMessage] = useState("");
  const [age, setAge] = useState([]);
  const [rating, setRating] = useState([]);
  const [passworderror, setPassworderror] = useState("");
  const [load, setLoad] = useState(true);
  const [loader, setLoader] = useState(true);
  const [sorting, setSorting] = useState("");
  const [productData, setProductData] = useState();

  // const [loader, setLoader] = useState(true);
  const [change, setChange] = useState(0);
  const [changeVariant, setChangeVariant] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const { id } = useParams();
  const [render, setRender] = useState(false);
  const [render1, setRender1] = useState(false);

  function numberWithCommas(x) {
    return x.toString().split(",")[0].length > 3
      ? x
          .toString()
          .substring(0, x.toString().split(".")[0].length - 3)
          .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          "," +
          x.toString().substring(x.toString().split(".")[0].length - 3)
      : x.toString();
  }

  //userlogin form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (user.password.length > 3) {
      dispatch(authenticateLogin(user)).then((res) => {
        if (res.data.data === "Wrong Email/Password. Try Again!") {
          setPassworderror(res.data.data);
        } else {
          window.location.href = "/Products";
        }
      });
    } else {
      setPassworderror("password must be  greater than 3 letters");
    }
  };

  const handleFilter = (e) => {
    dispatch(
      FilterProducts({
        priceRange:
          window.$("#amount").val() === ""
            ? ["200", "15000"]
            : window.$("#amount").val().split("-"),
        filter: true,
      })
    )
      .then((res) => {
        if (res.data.data === "No Products Found..!") {
          setMessage("No Product Found On This Filter !");
          dispatch(getProducts());
        } else {
          setProductData(res?.data?.data);
        }
      })
      .catch((err) => {});
  };
  const handleFilter1 = (e) => {
    dispatch(
      FilterProducts({
        ratings:
          window.$("#amount2").val() === ""
            ? ["1", "5"]
            : window.$("#amount2").val().split("-"),
        filter: true,
      })
    )
      .then((res) => {
        if (res.data.data === "No Products Found..!") {
          setMessage("No Product Found On This Filter !");
          dispatch(getProducts());
        } else {
          setProductData(res?.data?.data);
        }
      })
      .catch((err) => {});
  };
  const handleFilter2 = (e) => {
    dispatch(
      FilterProducts({
        age:
          window.$("#amount1").val() === ""
            ? ["0", "100"]
            : window.$("#amount1").val().split("-"),
        filter: true,
      })
    )
      .then((res) => {
        if (res.data.data === "No Products Found..!") {
          setMessage("No Product Found On This Filter !");
          dispatch(getProducts());
        } else {
          setProductData(res?.data?.data);
        }
      })
      .catch((err) => {});
  };

  window.$(function () {
    // setPrice([JSON.parse(window.$( "#amount" ).val())]);
    window.$("#slider-range").slider({
      range: true,
      min: 200,
      max: 15000,
      values: [10, 15000],
      slide: function (event, ui) {
        window.$("#amount").val(ui.values[0] + "-" + ui.values[1]);
      },
    });
    // window.$( "#amount" ).val( "₹" + window.$( "#slider-range" ).slider( "values", 0 ) +
    //   " - ₹" + window.$( "#slider-range" ).slider( "values", 1 ) );
  });
  window.$(function () {
    // setAge([JSON.parse(window.$( "#amount1" ).val())]);
    window.$("#slider-range1").slider({
      range: true,
      min: 0,
      max: 100,
      values: [10, 100],
      slide: function (event, ui) {
        window.$("#amount1").val(ui.values[0] + "-" + ui.values[1]);
      },
    });
  });
  window.$(function () {
    // setRating([JSON.parse(window.$( "#amount2" ).val())]);
    window.$("#slider-range2").slider({
      range: true,
      min: 1,
      max: 5,
      values: [1, 10],
      slide: function (event, ui) {
        // let val = ui.val[0]

        // window.$( "#amount2" ).val( ui.value );
        window.$("#amount2").val(ui.values[0] + "-" + ui.values[1]);
      },
    });
  });

  function getVals() {
    // Get slider values
    let parent = this.parentNode;
    let slides = parent.getElementsByTagName("input");
    let slide1 = parseFloat(slides[0].value);
    let slide2 = parseFloat(slides[1].value);
    // Neither slider will clip the other, so make sure we determine which is larger
    if (slide1 > slide2) {
      let tmp = slide2;
      slide2 = slide1;
      slide1 = tmp;
    }

    let displayElement = parent.getElementsByClassName("rangeValues")[0];
    displayElement.innerHTML = "$" + slide1 + " - $" + slide2;
  }

  window.onload = function () {
    // Initialize Sliders
    let sliderSections = document.getElementsByClassName("range-slider");
    for (let x = 0; x < sliderSections.length; x++) {
      let sliders = sliderSections[x].getElementsByTagName("input");
      for (let y = 0; y < sliders.length; y++) {
        if (sliders[y].type === "range") {
          sliders[y].oninput = getVals;
          // Manually trigger event first time to display values
          sliders[y].oninput();
        }
      }
    }
  };
  //userLogin handleChange
  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  //userLogin handleChange
  const ratingHandleChange = (e) => {
    setRating(e.target.value);
  };

  const dispatch = useDispatch();

  const product = useSelector(({ getProducts }) => getProducts.payload);

  const Product = useSelector(
    ({ getSingleProduct }) => getSingleProduct.payload
  );

  const category = useSelector(({ getCategories }) => getCategories.payload);
  useEffect(() => {
    dispatch(getCategories()).then((res) => {
      setLoad(false);
    });
  }, []);

  //add to cart
  const carts = useSelector(({ addCarts }) => addCarts.payload);
  const wish = useSelector(({ addWishLists }) => addWishLists.payload);
  const [cartId, getCartId] = useState({
    variantColor: "",
    variantId: "",
    units: 0,
  });

  const handleClick = () => {
    cartId.variantId = Product[changeVariant].id;
    cartId.units = quantity;
    // cartId.variantColor = JSON.parse(Product[changeVariant].variantColor)[
    //   change
    // ];
    dispatch(addCarts(cartId)).then((res) => {});
  };

  useEffect(() => {}, [Product]);
  useEffect(() => {
    dispatch(getProducts())
      .then((res) => {
        setProductData(res?.data?.data);
        setLoad(false);
      })
      .catch(() => {});
  }, []);

  const SingleProduct = (proId) => {
    setLoader(true);
    dispatch(getSingleProduct({ productId: proId })).then((res) =>
      setLoader(false)
    );
  };
  function formatAsPercent(num) {
    return new Intl.NumberFormat("default", {
      style: "percent",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    }).format(num / 100);
  }

  useEffect(() => {
    window.setTimeout(function () {
      dispatch({ type: addCart.add.success });
      window
        .$(".alert")
        .fadeTo(500, 0)
        .slideUp(500, function () {
          window.$(this).remove();
        });
    }, 4000);

    window.setTimeout(function () {
      dispatch({ type: addWishList.wishList.success });
      window
        .$(".alert1")
        .fadeTo(500, 0)
        .slideUp(500, function () {
          window.$(this).remove();
        });
    }, 4000);
  }, [carts, wish]);

  useEffect(() => {}, []);

  const Truncate = (string, number) => {
    if (!string) {
      return null;
    }
    if (string.length <= number) {
      return string;
    }
    return string.slice(0, number) + "...";
  };
  return load ? (
    <div
      style={{
        height: "400px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : (
    <>
      <div className="shop-area shop-page-responsive pt-100 pb-100">
        <div className="container">
          <div id="hp-ctn-howItWorks">
            <a href="/contactUs">
              <p className="c">Bulk&nbsp;Orders </p>
            </a>
          </div>
          <div className="row flex-row-reverse">
            <div className="col-lg-9">
              <div className="shop-topbar-wrapper mb-40">
                <div className="shop-topbar-left">
                  <div className="showing-item">
                    {/* <span>{message}</span> */}
                    {message ? (
                      <span
                        style={{
                          fontWeight: "600",
                          textTransform: "capitalize",
                        }}
                      >
                        {message}
                      </span>
                    ) : (
                      <span
                        style={{
                          fontWeight: "600",
                          textTransform: "capitalize",
                        }}
                      >
                        Showing {productData?.length} results
                      </span>
                    )}
                  </div>
                </div>
                <div className="shop-topbar-right">
                  <div className="shop-sorting-area">
                    <select
                      onChange={(event) => {
                        switch (event.target.value) {
                          case "1":
                            dispatch(
                              FilterProducts({
                                sort: 1,
                              })
                            ).then((res) => {
                              setProductData(res?.data?.data);
                            });
                            break;
                          case "2":
                            dispatch(
                              FilterProducts({
                                sort: 2,
                              })
                            ).then((res) => {
                              setProductData(res?.data?.data);
                            });
                            break;
                          case "3":
                            dispatch(
                              FilterProducts({
                                sort: 3,
                              })
                            ).then((res) => {
                              setProductData(res?.data?.data);
                            });
                            break;
                          case "4":
                            dispatch(
                              FilterProducts({
                                sort: 4,
                              })
                            ).then((res) => {
                              setProductData(res?.data?.data);
                            });
                            break;

                          default:
                            break;
                        }
                      }}
                      value={sorting.sort}
                      className="nice-select nice-select-style-1"
                      name="sort"
                    >
                      <option>Default Sorting</option>
                      <option value="1">Sort by Price High - Low</option>
                      <option value="2">Sort by Price Low - High</option>
                      <option value="3">Sort by A-Z</option>
                      <option value="4">Sort by Z-A</option>
                    </select>
                  </div>

                  <div className="shop-view-mode nav">
                    <a className="active" href="#shop-1" data-bs-toggle="tab">
                      <i className=" ti-layout-grid3 "></i>{" "}
                    </a>
                    <a href="#shop-2" data-bs-toggle="tab" className="">
                      <i className=" ti-view-list-alt "></i>
                    </a>
                  </div>
                </div>
              </div>

              <div className="shop-bottom-area">
                <div className="tab-content jump">
                  <div id="shop-1" className="tab-pane active">
                    <div className="row">
                      {Array.isArray(productData) && productData.length != 0 ? (
                        productData
                          ?.filter((Y) => Y.discountPrice >= 1)
                          .map((data) => (
                            <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                              <div className="product-wrap mb-35">
                                <div
                                  className="product-img img-zoom mb-25"
                                  style={{ borderRadius: "16px" }}
                                >
                                  <Link to={"/productDetails/" + data.id}>
                                    <img
                                      src={
                                        process.env.REACT_APP_BASE_URL +
                                        data.productImage
                                      }
                                      alt={data.productName}
                                    />
                                    {/* <div className="product-badge badge-top badge-right badge-pink">
                                                        <span>-10%</span>
                                                      </div> */}
                                  </Link>
                                  {data.actualPrice ? (
                                    <Link to={"/productDetails/" + data.id}>
                                      <div className="ribbon ribbon-top-right">
                                        <span>
                                          {" "}
                                          -{" "}
                                          {formatAsPercent(
                                            ((data.actualPrice -
                                              data.discountPrice) /
                                              data.actualPrice) *
                                              100
                                          )}{" "}
                                        </span>
                                      </div>
                                    </Link>
                                  ) : (
                                    false
                                  )}

                                  <div
                                    className="product-action-wrap"
                                    id="quickview"
                                  >
                                    <button
                                      className="product-action-btn-1"
                                      title="Quick View"
                                      onClick={() => SingleProduct(data.id)}
                                      data-bs-toggle="modal"
                                      data-bs-target="#exampleModal"
                                    >
                                      <i className="pe-7s-look"></i>
                                    </button>
                                  </div>
                                  <div className="product-action-2-wrap">
                                    <Link
                                      className="product-action-btn-2"
                                      to={"/productDetails/" + data.id}
                                    >
                                      <i className="pe-7s-angle-right-circle"></i>{" "}
                                      View Details
                                    </Link>
                                  </div>
                                </div>
                                <div className="product-content">
                                  <h3 style={{ textTransform: "capitalize" }}>
                                    <Link to={"/productDetails/" + data.id}>
                                      {data.productName}
                                    </Link>
                                  </h3>
                                  <div className="product-price">
                                    <span className="old-price">
                                      ₹{numberWithCommas(data.actualPrice)}{" "}
                                    </span>
                                    <span className="new-price">
                                      ₹{numberWithCommas(data.discountPrice)}{" "}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                      ) : (
                        <h3 className="container">"No Product Found"</h3>
                      )}
                    </div>

                    {/* <div className="pagination-style-1" data-aos="fade-up" data-aos-delay="200">
                                        <ul>
                                            <li><a className="active" href="#">1</a></li>
                                            <li><a href="#">2</a></li>
                                            <li><a href="#">3</a></li>
                                            <li><a className="next" href="#"><i className=" ti-angle-double-right "></i></a></li>
                                        </ul>
                                    </div> */}
                  </div>
                  <div id="shop-2" className="tab-pane">
                    {Array.isArray(productData) && productData.length != 0 ? (
                      productData
                        ?.filter((Y) => Y.discountPrice >= 1)
                        .map((data) => (
                          <div className="shop-list-wrap mb-30">
                            <div className="row">
                              <div className="col-lg-3 col-sm-5">
                                <div className="product-list-img">
                                  <Link to={"/productDetails/" + data.id}>
                                    <img
                                      src={
                                        process.env.REACT_APP_BASE_URL +
                                        data.productImage
                                      }
                                      style={{ borderRadius: "16px" }}
                                      alt=""
                                    />
                                  </Link>
                                  {data.actualPrice ? (
                                    <Link to={"/productDetails/" + data.id}>
                                      <div className="ribbon ribbon-top-right">
                                        <span>
                                          {" "}
                                          -{" "}
                                          {formatAsPercent(
                                            ((data.actualPrice -
                                              data.discountPrice) /
                                              data.actualPrice) *
                                              100
                                          )}{" "}
                                        </span>
                                      </div>
                                    </Link>
                                  ) : (
                                    false
                                  )}
                                  {/* <div className="ribbon ribbon-top-right"><span>{((data.actualPrice-data.discountPrice)/data.actualPrice*100)}%</span></div> */}
                                  <div
                                    className="product-list-quickview"
                                    id="quickview"
                                  >
                                    <button
                                      className="product-action-btn-2"
                                      title="Quick View"
                                      onClick={() => SingleProduct(data.id)}
                                      data-bs-toggle="modal"
                                      data-bs-target="#exampleModal"
                                    >
                                      <i className="pe-7s-look"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-8 col-sm-7">
                                <div className="shop-list-content">
                                  <h3
                                    style={{ textTransform: "capitalize" }}
                                  ></h3>{" "}
                                 <p style={{ textTransform: "capitalize",margin:"8px 0px 0px 0px" }}>
                                    {Truncate(data?.productName, 240)}
                                  </p>  <div className="product-list-rating">
                                    {Array.from({ length: 5 }, (datas, i) =>
                                      i < data?.ratings ? (
                                        <span>
                                          <FaStar
                                            style={{
                                              color: "#DD3D05",
                                            }}
                                          />
                                        </span>
                                      ) : (
                                        <span style={{ margin: "2px" }}>
                                          <AiOutlineStar />
                                        </span>
                                      )
                                    )}
                                  </div>
                                 
                                  <div className="product-price">
                                    <span className="old-price">
                                      {" "}
                                      ₹{numberWithCommas(data.actualPrice)}
                                    </span>
                                    <span className="new-price">
                                      ₹{numberWithCommas(data.discountPrice)}
                                    </span>
                                  </div>
                                  <div className="button-box btn-hover mt-4">
                                    <a
                                      style={{ borderRadius: "20px" }}
                                      href={"/productDetails/" + data.id}
                                      className="product-action-btn-3"
                                      title="Product Details"
                                    >
                                      {" "}
                                      View To Product Details
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                    ) : (
                      <center className="pb-5 mb-5">
                        <img
                          src={products}
                          className="mt-5 mb-5"
                          style={{ width: "25%" }}
                        />
                        <h3 className="container">"No Product Found"</h3>
                      </center>
                    )}

                    {/* <div className="pagination-style-1">
                                        <ul>
                                            <li><a className="active" href="#">1</a></li>
                                            <li><a href="#">2</a></li>
                                            <li><a href="#">3</a></li>
                                            <li><a className="next" href="#"><i className=" ti-angle-double-right "></i></a></li>
                                        </ul>
                                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="sidebar-wrapper" style={{ width: "70%" }}>
                <div
                  className="sidebar-widget mb-40"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div
                    className="sidebar-widget sidebar-widget-border mb-40 pb-35"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <div className="sidebar-widget-title mb-25">
                      <h3>Product Categories</h3>
                    </div>
                    {Array.isArray(category) && category?.length !== 0
                      ? category?.map((data) => (
                          <div className="sidebar-list-style">
                            <ul>
                              <li>
                                <a
                                  className="mt-2"
                                  style={{
                                    textTransform: "capitalize",
                                    fontSize: "15px",
                                  }}
                                  onClick={() => catshop(data?.id)}
                                >
                                  {" "}
                                  {data.categoryName}{" "}
                                </a>
                              </li>
                            </ul>
                          </div>
                        ))
                      : false}
                  </div>
                </div>
                <div
                  className="sidebar-widget sidebar-widget-border mb-40 pb-35"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="sidebar-widget-title mb-30">
                    <h3>Filter By Price</h3>
                  </div>
                  <div className="price-filter">
                    <div id="slider-range"></div>
                    <div className="price-slider-amount">
                      <div className="label-input">
                        <label>Price : ( ₹ ) </label>
                        <input
                          type="text"
                          id="amount"
                          name="price"
                          placeholder="Choose  Price"
                        />
                      </div>
                      <button onClick={handleFilter} type="button">
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className="sidebar-widget sidebar-widget-border mb-40 pb-35"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="sidebar-widget-title mb-30">
                    <h3>Filter By Age</h3>
                  </div>
                  <div className="price-filter">
                    <div id="slider-range1"></div>
                    <div className="price-slider-amount">
                      <div className="label-input">
                        <label>Age:</label>
                        <input
                          type="text"
                          id="amount1"
                          name="price"
                          placeholder="Choose  Age"
                        />
                      </div>
                      <button onClick={handleFilter2} type="button">
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className="sidebar-widget sidebar-widget-border mb-40 pb-35"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="sidebar-widget-title mb-30">
                    <h3>Filter By Ratings</h3>
                  </div>
                  <div className="price-filter">
                    <div id="slider-range2"></div>
                    <div className="price-slider-amount">
                      <div className="label-input">
                        <label>Ratings: ( ☆ )</label>
                        <input
                          type="text"
                          id="amount2"
                          name="price"
                          placeholder="Choose Ratings"
                        />
                      </div>
                      <button onClick={handleFilter1} type="button">
                        Apply
                      </button>
                    </div>
                  </div>
                </div>

                {/* <div
                  className="button-box btn-hover mt-4 mb-5"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <a
                    onClick={handleFilter}
                    className="product-action-btn-3"
                    title="Product Details"
                  >
                    {" "}
                    Apply Changes
                  </a>
                </div> */}
              </div>
            </div>
            {/* <!-- Product Modal start --> */}

            <div
              className="modal fade quickview-modal-style"
              id="exampleModal"
              tabIndex="-1"
              role="dialog"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <a
                      href="#"
                      className="close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      {" "}
                      <i className="fa-solid fa-xmark"></i>
                    </a>
                  </div>
                  <div className="container p-5">
                    {loader ? (
                      <div
                        style={{
                          height: "500px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden"></span>
                        </div>
                      </div>
                    ) : (
                      <div className="modal-body">
                        <div
                          className="product-details-area pb-80 pt-60 "
                          style={{ marginLeft: "-15px" }}
                        >
                          <div className="container">
                            {Array.isArray(Product) && Product.length > 0 ? (
                              <div className="row">
                                <div className="col-lg-6">
                                  <div
                                    className="product-details-img-wrap"
                                    data-aos="fade-up"
                                    data-aos-delay="200"
                                  >
                                    <div className="swiper-container product-details-big-img-slider-2 pd-big-img-style">
                                      <div className="swiper-wrapper">
                                        <div className="swiper-slide">
                                          <div className="easyzoom-style">
                                            <div className="easyzoom easyzoom--overlay">
                                              <a
                                                href={
                                                  JSON.parse(
                                                    Product[changeVariant]
                                                      ?.variantImage
                                                  )[change]
                                                }
                                              >
                                                <img
                                                  src={
                                                    process.env
                                                      .REACT_APP_BASE_URL +
                                                    JSON.parse(
                                                      Product[changeVariant]
                                                        ?.variantImage
                                                    )[change]
                                                  }
                                                  alt=""
                                                />
                                              </a>
                                            </div>
                                            {/* <a target="_blank" className="easyzoom-pop-up img-popup" href={
                      JSON.parse(Product[changeVariant]?.variantImage)[change]
                    }>
                                                <i className="pe-7s-search"></i>
                                            </a> */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div
                                    className="product-details-content mt-2"
                                    data-aos="fade-up"
                                    data-aos-delay="400"
                                  >
                                    <h2 style={{ textTransform: "capitalize" }}>
                                      {Product[changeVariant].productName}
                                      {/* <span style={{textTransform:"capitalize"}}>
                                     ({Product[changeVariant].variantName}) 
                                </span> */}
                                    </h2>

                                    {/* <div className="mt-3 mb-3">
                    {Array.isArray(Product)
                      ? Product?.map((data, index) => (
                          <button
                            onClick={() => setChangeVariant(index)}
                            className="btn btn-light "
                            style={Product[changeVariant]?.variantName===data?.variantName?{border:"1px solid gray",background:"#DD3D05",color:"white",borderRadius:"16px"}:{ marginRight: "10px",textTransform:"capitalize", borderRadius:"0px" }}
                          >
                            {data?.variantName}
                          </button>
                        ))
                      : false}
                  </div> */}
                                    {/* {Array.isArray(Product) &&
                                  Product.map((data) => (
                                    <div className="product-details-price">
                                      <span className="old-price">
                                        ₹
                                        {/* {(JSON.parse(Product[changeVariant].actualPrice)[change]).toLocaleString("en-IN", {
                          maximumFractionDigits: 0,
                        })} */}
                                    {/* {numberWithCommas(data.actualPrice)}
                                      </span>
                                      <span className="new-price">
                                        ₹{numberWithCommas(data.discountPrice)} */}
                                    {/* {(JSON.parse(Product[changeVariant].discountPrice)[change]).toLocaleString("en-IN", {
                          maximumFractionDigits: 0,
                        })} */}
                                    {/* </span>
                                    </div>
                                  ))} */}

                                    {Array.isArray(product) &&
                                    product.length !== 0
                                      ? product
                                          .filter(
                                            (F) =>
                                              F?.id ===
                                              Product[changeVariant].productId
                                          )
                                          .map((pro) => (
                                            <div key={pro.id}>
                                              <p
                                                style={{
                                                  textAlign: "justify",
                                                  marginTop: "40px",
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    fontWeight: "600",
                                                    fontSize: "16px",
                                                  }}
                                                >
                                                  Description:{" "}
                                                </span>
                                                {Truncate(
                                                  pro?.productDescription,
                                                  240
                                                )}
                                              </p>
                                              <div className="shop-list-content mb-5">
                                                <div className="product-price">
                                                  <span className="old-price">
                                                    {" "}
                                                    ₹
                                                    {numberWithCommas(
                                                      pro.actualPrice
                                                    )}
                                                  </span>
                                                  <span className="new-price">
                                                    ₹
                                                    {numberWithCommas(
                                                      pro.discountPrice
                                                    )}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          ))
                                      : null}

                                    {/* <div className="product-details-review">
                                <div className="product-rating">
                                    <i className=" ti-star"></i>
                                    <i className=" ti-star"></i>
                                    <i className=" ti-star"></i>
                                    <i className=" ti-star"></i>
                                    <i className=" ti-star"></i>
                                </div>
                                <span>( 1 Customer Review )</span>
                            </div> */}
                                    {/* <div className="product-color product-color-active product-details-color">
                                <span>Color :</span>
                                {JSON.parse(Product[changeVariant].variantColor).map(
                        (data, i) => (
                          <a
                            style={{ width: "40px" }}
                            className="imageborder active"
                          >
                            <span
                              style={{
                                width: "30px",
                                height: "30px",
                                background: data,
                                border: "1px solid black",
                                borderRadius: "50%",
                              }}
                              onClick={() => setChange(i)}
                            ></span>
                          </a>
                        )
                      )}
                            </div> */}
                                    <div>
                                      {carts ? (
                                        <div
                                          style={{ marginTop: "-20px" }}
                                          className="alert alert-warning alert-dismissible fade show"
                                          role="alert"
                                          mt-3
                                        >
                                          {carts === "Already Added to Cart" ||
                                          carts === "Added to Cart" ? (
                                            <a href="/cart">
                                              {carts} - Go To Carts
                                            </a>
                                          ) : (
                                            carts
                                          )}
                                          <button
                                            type="button"
                                            className="btn-close"
                                            data-dismiss="alert"
                                            aria-label="Close"
                                            // onClick={()=>dispatch({type:addCart.add.success,data:false})}
                                          ></button>
                                        </div>
                                      ) : (
                                        false
                                      )}
                                    </div>
                                    <div>
                                      {wish ? (
                                        <div
                                          className="alert alert-warning alert-dismissible fade show"
                                          role="alert"
                                          mt-3
                                        >
                                          {wish}
                                          <button
                                            type="button"
                                            className="btn-close"
                                            data-dismiss="alert"
                                            // onClick={()=>dispatch({type:addWishList.wishList.success,data:false})}
                                            aria-label="Close"
                                          ></button>
                                        </div>
                                      ) : (
                                        false
                                      )}
                                    </div>
                                    <div className="product-details-action-wrap mb-5">
                                      <div
                                        className="product-quality"
                                        style={{ textAlign: "center" }}
                                      >
                                        <span
                                          style={{
                                            display: "flex",
                                            padding: "5px 10px",
                                            marginTop: "8px",
                                            marginBottom: "5px",
                                          }}
                                        >
                                          {/* <div  style={{display:"flex"}}> */}
                                          <span
                                            className="minus-btn"
                                            onClick={() => {
                                              return quantity > 1
                                                ? setQuantity(quantity - 1)
                                                : false;
                                            }}
                                          >
                                            <i className="bx bx-minus"></i>
                                          </span>
                                          <input
                                            type="text"
                                            disabled
                                            min="1"
                                            style={{
                                              outline: "none",
                                              border: "none",
                                              width: "20px",
                                              marginLeft: "8px",
                                              marginTop: "-5px",
                                              background: "none",
                                            }}
                                            value={quantity}
                                            onChange={(e) =>
                                              setQuantity(e.target.value)
                                            }
                                          />
                                          {/* <input type="text" min="1" value={quantity[i]} onChange={(e)=>setQuantity(e.target.value)} /> */}
                                          <span
                                            className="plus-btn"
                                            onClick={() => {
                                              setQuantity(quantity + 1);
                                            }}
                                          >
                                            <i className="bx bx-plus"></i>
                                          </span>
                                        </span>
                                        {/* </div> */}
                                      </div>
                                      <div className="single-product-cart btn-hover">
                                        {Token ? (
                                          <a
                                            style={{
                                              fontSize: "18px",
                                              paddingTop: "10px",
                                              paddingBottom: "10px",
                                              paddingleft: "20px",
                                              color: "white",
                                              borderRadius: "20px",
                                            }}
                                            onClick={handleClick}
                                          >
                                            Add to Cart
                                          </a>
                                        ) : (
                                          <a
                                            style={{
                                              fontSize: "18px",
                                              paddingTop: "10px",
                                              paddingBottom: "10px",
                                              paddingleft: "20px",
                                              borderRadius: "20px",
                                            }}
                                            href="/login"
                                          >
                                            Add to Cart
                                          </a>
                                        )}
                                      </div>
                                      <div className="single-product-wishlist">
                                        <a
                                          title="Wishlist"
                                          onClick={() => {
                                            // setLoad(true);
                                            dispatch(
                                              addWishLists({
                                                productId:
                                                  Product[changeVariant]
                                                    ?.productId,
                                              })
                                            ).then((res) => {
                                              // setLoad(true);
                                            });
                                          }}
                                        >
                                          {Product[changeVariant]
                                            ?.favourites === 0 ? (
                                            <i
                                              className="bx bx-heart"
                                              style={{ fontSize: "26px" }}
                                            ></i>
                                          ) : (
                                            <i
                                              style={{ fontSize: "26px" }}
                                              className="bx bxs-heart"
                                            ></i>
                                          )}
                                        </a>
                                      </div>
                                    </div>
                                    <div>
                                      <span>
                                        <a
                                          href={
                                            "/productDetails/" +
                                            Product[changeVariant]?.productId
                                          }
                                          style={{
                                            fontSize: "18px",
                                            textDecoration: "underline",
                                          }}
                                        >
                                          View More Details
                                        </a>
                                      </span>

                                      {/* <div className="single-product-cart btn-hover mt-5">
                                    <a  style={{fontSize:"18px",paddingTop:"10px",paddingBottom:"10px",paddingleft:"20px",}}href="#">Add to Cart</a>
                                </div> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              false
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* )} */}

            {/* <!-- Product Modal end --> */}
            <div
              className="modal fade quickview-modal-style"
              id="loginModal"
              tabIndex="-1"
              role="dialog"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div
                  className="modal-content"
                  style={{ width: "70%", marginLeft: "180px" }}
                >
                  <div className="modal-header">
                    <a
                      href="#"
                      className="close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <i className="fa-solid fa-xmark"></i>
                    </a>
                  </div>
                  <div className="modal-body">
                    <div className="row gx-0">
                      <div className="col-lg-5 col-md-5 col-12">
                        <form
                        // onSubmit={handleSubmit}
                        >
                          <div className="row">
                            <div className="col-lg-12 mt-3 mb-3">
                              <div className="form-group">
                                <input
                                  type="email"
                                  className="form-control"
                                  placeholder="Email"
                                  name="email"
                                  style={{
                                    borderRadius: "0px",
                                    width: "450px",
                                    marginLeft: "125px",
                                  }}
                                  onChange={handleChange}
                                  value={user.email}
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-lg-12 mt-3">
                              <div className="form-group">
                                <input
                                  type="password"
                                  className="form-control"
                                  placeholder="Password"
                                  name="password"
                                  style={{
                                    borderRadius: "0px",
                                    width: "450px",
                                    marginLeft: "125px",
                                  }}
                                  onChange={handleChange}
                                  value={user.password}
                                  required
                                />
                                <span
                                  style={{
                                    color: "brown",
                                    marginLeft: "20px",
                                  }}
                                >
                                  {passworderror}
                                </span>
                              </div>
                            </div>
                            <div
                              className="col-lg-12 mb-3"
                              style={{
                                borderRadius: "0px",
                                width: "850px",
                                marginLeft: "125px",
                              }}
                            >
                              <p className="forgot-password">
                                <Link to="/register">
                                  Don't you have an account? <b>Register</b>
                                </Link>
                              </p>
                            </div>
                            <div
                              className="col-lg-12"
                              style={{
                                borderRadius: "0px",
                                width: "850px",
                                marginLeft: "113px",
                              }}
                            >
                              <div className="single-product-cart btn-hover">
                                <a
                                  onClick={handleSubmit}
                                  style={{
                                    fontSize: "18px",
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                    paddingleft: "20px",
                                    borderRadius: "5px",
                                  }}
                                  href="#"
                                >
                                  Log In
                                </a>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Mobile Menu start -->  */}
        <div className="off-canvas-active">
          <a className="off-canvas-close">
            <i className="fa-solid fa-xmark"></i>
          </a>
          <div className="off-canvas-wrap">
            {/* <div className="welcome-text off-canvas-margin-padding">
              <p>Default Welcome Msg! </p>
            </div> */}
            <div className="mobile-menu-wrap off-canvas-margin-padding-2">
              <div id="mobile-menu" className="slinky-mobile-menu text-left">
                <ul>
                  <li>
                    <a href="/">HOME</a>
                  </li>
                  <li>
                    <a href="/products">SHOP</a>
                  </li>
                  <li>
                    <a href="/categories">CATEGORIES </a>
                  </li>

                  <li>
                    <a href="/aboutus">ABOUT US</a>
                  </li>
                  <li>
                    <a href="/contactus">CONTACT US</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;
