import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { addCart, addWishList } from "../Redux/ActionType";
import "./style.css";

import { FaStar } from "react-icons/fa";
import { AiOutlineStar } from "react-icons/ai";
import {
  getProducts,
  authenticateLogin,
  getSingleProduct,
  addCarts,
  addWishLists,
  searchs,
} from "../Redux/Action";
import products from "../images/noproductsfound.svg";
const Token = localStorage.getItem("AuthTok");
const SearchProducts = () => {
  const [load, setLoad] = useState(true);
  const [loader, setLoader] = useState(true);
  const [change, setChange] = useState(0);
  const [changeVariant, setChangeVariant] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [render, setRender] = useState(false);
  const [render1, setRender1] = useState(false);
  const { id } = useParams();
  const [catid, setCatid] = useState({
    categoryId: id,
  });
  function numberWithCommas(x) {
    return x.toString().split(',')[0].length > 3 ? x.toString().substring(0,x.toString().split('.')[0].length-3).replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + x.toString().substring(x.toString().split('.')[0].length-3): x.toString();
}
  useEffect(() => {
    {
      setLoader(true);
      dispatch(searchs({ query: id })).then((res) => setLoad(false));
    }
  }, [id]);
  const dispatch = useDispatch();

  const productSearch = useSelector(({ searchs }) => searchs.payload);
  const Product = useSelector(
    ({ getSingleProduct }) => getSingleProduct.payload
  );

  const carts = useSelector(({ addCarts }) => addCarts.payload);
  const Truncate = (string, number) => {
    if (!string) {
      return null;
    }
    if (string.length <= number) {
      return string;
    }
    return string.slice(0, number) + "...";
  };
  const [cartId, getCartId] = useState({
    variantColor: "",
    variantId: "",
    units: 0,
  });

  const wish = useSelector(({ addWishLists }) => addWishLists.payload);
  const handleClick = () => {
    cartId.variantId = Product[changeVariant].id;
    cartId.units = quantity;
    cartId.variantColor = JSON.parse(Product[changeVariant].variantColor)[
      change
    ];
    dispatch(addCarts(cartId)).then((res) => {
      setRender1(true);
    });
  };
 
  useEffect(() => {}, [Product]);

  //   const [proid, setProId] = useState({
  //     productId: 0,
  //   });

  const SingleProduct = (proId) => {
    setLoader(true);
    dispatch(getSingleProduct({ productId: proId })).then((res) =>
      setLoader(false)
    );
   
  };

  //userlogin form submit
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const [passworderror, setPassworderror] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    if (user.password.length > 3) {
      dispatch(authenticateLogin(user)).then((res) => {
        if (res.data.data === "Wrong Email/Password. Try Again!") {
          setPassworderror(res.data.data);
        } else {
          window.location.href = "/ProductDetails/" + id;
        }
      });
    } else {
      setPassworderror("password must be  greater than 3 letters");
    }
  };

  function formatAsPercent(num) {
    return new Intl.NumberFormat('default', {
      style: 'percent',
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    }).format(num / 100);
  }
 
  //userLogin handleChange
  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    window.setTimeout(function () {
      dispatch({ type: addCart.add.success });
      window
        .$(".alert")
        .fadeTo(500, 0)
        .slideUp(500, function () {
          window.$(this).remove();
        });
    }, 4000);

    window.setTimeout(function () {
      dispatch({ type: addWishList.wishList.success });
      window
        .$(".alert1")
        .fadeTo(500, 0)
        .slideUp(500, function () {
          window.$(this).remove();
        });
    }, 4000);
  }, [carts, wish]);
  return load ? (
    <div
      style={{
        height: "400px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : (
    <>
      <div className="main-wrapper main-wrapper-2">
        <div className="breadcrumb-area bg-gray-4 ">
          <div className="container">
            <div className="breadcrumb-content text-center">
              <h2 data-aos="fade-up" data-aos-delay="200">
                Products
              </h2>
              <ul data-aos="fade-up" data-aos-delay="400">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <i className="ti-angle-right"></i>
                </li>
                <li>Searched Products </li>
              </ul>
            </div>
          </div>
          {/* <div className="breadcrumb-img-1" data-aos="fade-right" data-aos-delay="200">
                <img src="/assets/images/banner/breadcrumb-1.png" alt=""/>
            </div>
            <div className="breadcrumb-img-2" data-aos="fade-left" data-aos-delay="200">
                <img src="/assets/images/banner/breadcrumb-2.png" alt=""/>
            </div> */}
        </div>
        <div id="hp-ctn-howItWorks">
          <a href="/contactUs">
            <p className="c">Bulk&nbsp;Orders </p>
          </a>
        </div>
        <div className="shop-area ">
          <div className="container">
            <div className="row flex-row-reverse">
              <div className="col-12">
                <div className="shop-topbar-wrapper mb-40">
                  <div className="shop-topbar-left">
                    {/* <div className="showing-item">
                                   <span>Showing 1–12 of 60 results</span>
                               </div> */}
                  </div>
                  <div className="shop-topbar-right">
                    {/* <div className="shop-sorting-area">
                                   <select className="nice-select nice-select-style-1">
                                       <option>Default Sorting</option>
                                       <option>Sort by popularity</option>
                                       <option>Sort by average rating</option>
                                       <option>Sort by latest</option>
                                   </select>
                               </div> */}
                    <div className="shop-view-mode nav">
                      <a className="active" href="#shop-1" data-bs-toggle="tab">
                        <i className=" ti-layout-grid3 "></i>{" "}
                      </a>
                      <a href="#shop-2" data-bs-toggle="tab" className="">
                        <i className=" ti-view-list-alt "></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="shop-bottom-area">
                  <div className="tab-content jump">
                    <div id="shop-1" className="tab-pane active">
                      <div
                        className="section-title-2"
                        data-aos="fade-up"
                        data-aos-delay="200"
                      >
                        {Array?.isArray(productSearch) &&
                        productSearch?.length !== 0 ?<h2 style={{ textTransform: "capitalize" }}>
                        products{" "}
                      </h2>:false}
                        
                      </div>
                      <div className="row">
                        {Array?.isArray(productSearch) &&
                        productSearch?.length != 0 ? (
                          productSearch
                            .filter((F) => F?.discountPrice > 1)
                            .map((pro) => (
                              <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                                <div className="section-title-tab-wrap mb-40"></div>
                                <div
                                  className="product-wrap mb-35"
                                  data-aos="fade-up"
                                  data-aos-delay="200"
                                >
                                  <div
                                    className="product-img img-zoom mb-25"
                                    style={{ borderRadius: "15px" }}
                                  >
                                    <Link to={"/productDetails/" + pro?.id}>
                                      <img
                                        src={
                                          process.env.REACT_APP_BASE_URL +
                                          pro?.productImage
                                        }
                                        alt={pro?.productName}
                                      />
                                    </Link>
                                    {pro.actualPrice ? (
                                       <Link to={"/productDetails/" + pro?.id}>
                                      <div className="ribbon ribbon-top-right">
                                        <span> - {formatAsPercent ((pro.actualPrice-pro.discountPrice)/pro.actualPrice*100)} </span>
                                      </div>
                                      </Link>
                                    ) : (
                                      false
                                    )}
                                    <div className="product-action-wrap" id="quickview">
                                      {/* <button
                                        className="product-action-btn-1"
                                        title="Wishlist"
                                        onClick={() => {
                                          setLoad(true);
                                          dispatch(
                                              addWishLists({ productId: pro?.id })
                                          ).then((res) => {
                                            setLoad(false);
                                          });
                                        }}
                                      >
                                        <i className="pe-7s-like"></i>
                                      </button> */}

                                      <button
                                        className="product-action-btn-1"
                                        title="Quick View"
                                        onClick={() => SingleProduct(pro.id)}
                                        data-bs-toggle="modal"
                                        data-bs-target="#exampleModal"
                                      >
                                        <i className="pe-7s-look"></i>
                                      </button>
                                    </div>

                                    <div className="product-action-2-wrap">
                                      <Link
                                        to={"/productDetails/" + pro.id}
                                        className="product-action-btn-2"
                                        title="Add To Cart"
                                      >
                                        <i className="pe-7s-angle-right-circle"></i>
                                        View Product Details
                                      </Link>
                                    </div>
                                  </div>
                                  <div className="product-content">
                                    <h3 style={{ textTransform: "capitalize" }}>
                                      <a href={"/productDetails/" + pro.id}>
                                        {pro?.productName}
                                      </a>
                                    </h3>
                                    <div className="product-price">
                                      <span className="old-price">
                                        ₹ {numberWithCommas(pro.actualPrice)}
                                      </span>
                                      <span className="new-price">
                                        ₹{numberWithCommas(pro?.discountPrice)}{" "}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                        ) : (
                          <center className="pb-5 mb-5">
                            <img
                              src={products}
                              className="mt-5 mb-5"
                              style={{ width: "25%" }}
                            />
                            <h3 className="container">"No Product Found"</h3>
                          </center>
                        )}
                      </div>
                      {/* <div className="pagination-style-1" data-aos="fade-up" data-aos-delay="200">
                                       <ul>
                                           <li><a className="active" href="#">1</a></li>
                                           <li><a href="#">2</a></li>
                                           <li><a href="#">3</a></li>
                                           <li><a className="next" href="#"><i className=" ti-angle-double-right "></i></a></li>
                                       </ul>
                                   </div> */}
                    </div>
                    <div id="shop-2" className="tab-pane">
                      <div className="shop-list-wrap mb-30">
                        {Array.isArray(productSearch) &&
                        productSearch?.length != 0 ? (
                          productSearch
                            .filter((F) => F?.discountPrice > 1)
                            .map((pro) => (
                              <div className="row">
                                <div className="col-lg-2 col-sm-5">
                                  <div className="product-list-img">
                                    <a href={"/productDetails/" + pro.id}>
                                      <img
                                        src={
                                          process.env.REACT_APP_BASE_URL +
                                          pro?.productImage
                                        }
                                        alt="Product Style"
                                        style={{
                                          marginBottom: "20px",
                                          borderRadius: "16px",
                                        }}
                                      />
                                    </a>
                                    {pro.actualPrice ? (
                                      <Link to={"/productDetails/" + pro.id}>
                                      <div className="ribbon ribbon-top-right">
                                        <span> - {formatAsPercent ((pro.actualPrice-pro.discountPrice)/pro.actualPrice*100)} </span>
                                      </div>
                                      </Link>
                                    ) : (
                                      false
                                    )}
                                    <div className="product-list-quickview" id="quickview">
                                      <button
                                        className="product-action-btn-2"
                                        title="Quick View"
                                        data-bs-toggle="modal"
                                        data-bs-target="#exampleModal"
                                      >
                                        <i className="pe-7s-look"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-lg-8 col-sm-7">
                                  <div className="shop-list-content">
                                    <h3 style={{ textTransform: "capitalize" }}>
                                      <Link to={"/productDetails/" + pro.id}>
                                        {pro?.productName}
                                      </Link>
                                    </h3>
                                    <div className="product-price">
                                      <span className="old-price">
                                        ₹ {numberWithCommas(pro.actualPrice)}{" "}
                                      </span>
                                      <span className="new-price">
                                        ₹{numberWithCommas(pro?.discountPrice)}{" "}
                                      </span>
                                    </div>
                                    <div className="product-list-rating">
                                      {Array.from({ length: 5 }, (data, i) =>
                                        i < pro?.ratings ? (
                                          <span>
                                            <FaStar
                                              style={{
                                                color: "#DD3D05",
                                              }}
                                            />
                                          </span>
                                        ) : (
                                          <span style={{ margin: "2px" }}>
                                            <AiOutlineStar />
                                          </span>
                                        )
                                      )}
                                    </div>

                                    <p style={{ textAlign: "justify" }}>
                                      {" "}
                                      {Truncate(pro?.productDescription, 240)}
                                    </p>
                                    <div className="button-box btn-hover mt-4">
                                      <a
                                      style={{ borderRadius: "20px" }}
                                        href={"/productDetails/" + pro.id}
                                        className="product-action-btn-3"
                                        title="Product Details"
                                      >
                                        {" "}
                                        View To Product Details
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                        ) : (
                          <center className="pb-5 mb-5">
                            <img
                              src={products}
                              className="mt-5 mb-5"
                              style={{ width: "25%" }}
                            />
                            <h3 className="container">"No Product Found"</h3>
                          </center>
                        )}
                      </div>
                    </div>
                    {/* <div className="pagination-style-1">
                      <ul>
                        <li>
                          <a className="active" href="#">
                            1
                          </a>
                        </li>
                        <li>
                          <a href="#">2</a>
                        </li>
                        <li>
                          <a href="#">3</a>
                        </li>
                        <li>
                          <a className="next" href="#">
                            <i className=" ti-angle-double-right "></i>
                          </a>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Product Modal start --> */}
        <div
          className="modal fade quickview-modal-style"
          id="exampleModal"
          tabIndex="-1"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <a
                  href="#"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                   <i className="fa-solid fa-xmark"></i>
                </a>
              </div>
              <div className="container p-5">
                {loader ? (
                  <div
                    style={{
                      height: "500px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <div className="modal-body">
                    <div
                      className="product-details-area pb-80 pt-60 "
                      style={{ marginLeft: "-15px" }}
                    >
                      <div className="container">
                        {Array.isArray(Product) && Product.length > 0 ? (
                          <div className="row">
                            <div className="col-lg-6">
                              <div
                                className="product-details-img-wrap"
                                data-aos="fade-up"
                                data-aos-delay="200"
                              >
                                <div className="swiper-container product-details-big-img-slider-2 pd-big-img-style">
                                  <div className="swiper-wrapper">
                                    <div className="swiper-slide">
                                      <div className="easyzoom-style">
                                        <div className="easyzoom easyzoom--overlay">
                                          <a
                                            href={
                                              JSON.parse(
                                                Product[changeVariant]
                                                  ?.variantImage
                                              )[change]
                                            }
                                          >
                                            <img
                                              src={
                                                process.env.REACT_APP_BASE_URL +
                                                JSON.parse(
                                                  Product[changeVariant]
                                                    ?.variantImage
                                                )[change]
                                              }
                                              alt=""
                                            />
                                          </a>
                                        </div>
                                        {/* <a target="_blank" className="easyzoom-pop-up img-popup" href={
                      JSON.parse(Product[changeVariant]?.variantImage)[change]
                    }>
                                                <i className="pe-7s-search"></i>
                                            </a> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div
                                className="product-details-content mt-2"
                                data-aos="fade-up"
                                data-aos-delay="400"
                              >
                                <h2 style={{ textTransform: "capitalize" }}>
                                  {Product[changeVariant].productName}
                                  {/* <span style={{ textTransform: "capitalize" }}>
                                    ({Product[changeVariant].variantName})
                                  </span> */}
                                </h2>

                                {/* <div className="mt-3 mb-3">
                                  {Array.isArray(Product)
                                    ? Product?.map((data, index) => (
                                        <button
                                          onClick={() =>
                                            setChangeVariant(index)
                                          }
                                          className="btn btn-light "
                                          style={
                                            Product[changeVariant]
                                              ?.variantName ===
                                            data?.variantName
                                              ? {
                                                  border: "1px solid gray",
                                                  background: "#DD3D05",
                                                  color: "white",
                                                  borderRadius: "16px",
                                                }
                                              : {
                                                  marginRight: "10px",
                                                  textTransform: "capitalize",
                                                  borderRadius: "0px",
                                                }
                                          }
                                        >
                                          {data?.variantName}
                                        </button>
                                      ))
                                    : false}
                                </div> */}
{Array.isArray(productSearch) && productSearch?.length !== 0?productSearch.map((data)=>
  <div className="product-details-price">
                                  <span className="old-price">
                                    ₹
                                    {numberWithCommas(data.actualPrice)} 
                                  </span>
                                  <span className="new-price">
                                    ₹
                                    {numberWithCommas(data.discountPrice)}
                                  </span>
                                </div>
):false}


                                
                                {Array.isArray(productSearch) &&
                                productSearch?.length != 0
                                  ? productSearch
                                      .filter((F) => F?.discountPrice > 1)
                                      .map((pro) => (
                                        <p style={{ textAlign: "justify" }}>
                                          {" "}
                                          <span style={{ fontWeight: "600" }}>
                                            Description :{" "}
                                          </span>
                                          {Truncate(
                                            pro?.productDescription,
                                            240
                                          )}
                                        </p>
                                      ))
                                  : false}
                                {/* <div className="product-details-review">
                                <div className="product-rating">
                                    <i className=" ti-star"></i>
                                    <i className=" ti-star"></i>
                                    <i className=" ti-star"></i>
                                    <i className=" ti-star"></i>
                                    <i className=" ti-star"></i>
                                </div>
                                <span>( 1 Customer Review )</span>
                            </div> */}
                                {/* <div className="product-color product-color-active product-details-color">
                                <span>Color :</span>
                                {JSON.parse(Product[changeVariant].variantColor).map(
                        (data, i) => (
                          <a
                            style={{ width: "40px" }}
                            className="imageborder active"
                          >
                            <span
                              style={{
                                width: "30px",
                                height: "30px",
                                background: data,
                                border: "1px solid black",
                                borderRadius: "50%",
                              }}
                              onClick={() => setChange(i)}
                            ></span>
                          </a>
                        )
                      )}
                            </div> */}
                                <div>
                                  {carts ? (
                                    <div
                                      style={{ marginTop: "-20px" }}
                                      className="alert alert-warning alert-dismissible fade show"
                                      role="alert"
                                      mt-3
                                    >
                                    {carts=== "Already Added to Cart" || carts=== "Added to Cart" ?<Link to="/cart"> {carts} - <span style={{textDecoration:"underline"}}> Go to Cart</span> </Link>:carts}
                                      <button
                                        type="button"
                                        className="btn-close"
                                        data-dismiss="alert"
                                        aria-label="Close"
                                        // onClick={()=>dispatch({type:addCart.add.success,data:false})}
                                      ></button>
                                    </div>
                                  ) : (
                                    false
                                  )}
                                </div>
                                <div>
                                  {wish ? (
                                    <div
                                      className="alert alert-warning alert-dismissible fade show"
                                      role="alert"
                                      mt-3
                                    >
                                      {wish}
                                      <button
                                        type="button"
                                        className="btn-close"
                                        data-dismiss="alert"
                                        // onClick={()=>dispatch({type:addWishList.wishList.success,data:false})}
                                        aria-label="Close"
                                      ></button>
                                    </div>
                                  ) : (
                                    false
                                  )}
                                </div>
                                <div className="product-details-action-wrap mb-5">
                                  <div
                                    className="product-quality"
                                    style={{ textAlign: "center",width:"20%" }}
                                  >
                                    <span
                                      style={{
                                        display: "flex",
                                        padding: "5px 10px",
                                        marginTop: "8px",
                                        marginBottom: "5px",
                                      }}
                                    >
                                      {/* <div  style={{display:"flex"}}> */}
                                      <span
                                        className="minus-btn"
                                        onClick={() => {
                                          return quantity > 1
                                            ? setQuantity(quantity - 1)
                                            : false;
                                        }}
                                      >
                                        <i className="bx bx-minus"></i>
                                      </span>
                                      <input
                                        type="text"
                                        min="1"
                                        style={{
                                          outline: "none",
                                          border: "none",
                                         
                                         textAlign:"center",
                                          marginTop: "-3px",
                                        }}
                                        value={quantity}
                                        onChange={(e) =>
                                          setQuantity(e.target.value)
                                        }
                                      />
                                      {/* <input type="text" min="1" value={quantity[i]} onChange={(e)=>setQuantity(e.target.value)} /> */}
                                      <span
                                        className="plus-btn"
                                        onClick={() => {
                                          setQuantity(quantity + 1);
                                        }}
                                      >
                                        <i className="bx bx-plus"></i>
                                      </span>
                                    </span>
                                    {/* </div> */}
                                  </div>
                                  <div className="single-product-cart btn-hover">
                                    {Token ? (
                                      <a
                                        style={{
                                          fontSize: "18px",
                                          paddingTop: "10px",
                                          paddingBottom: "10px",
                                          paddingleft: "20px",
                                          color: "white",
                                          borderRadius: "20px"
                                        }}
                                        onClick={handleClick}
                                      >
                                        Add to Cart
                                        <i
                                          style={{
                                            marginLeft: "5px",
                                            fontSize: "15px",
                                          }}
                                          className="fa fa-shopping-cart"
                                          aria-hidden="true"
                                        ></i>
                                      </a>
                                    ) : (
                                      <a
                                        style={{
                                          fontSize: "18px",
                                          paddingTop: "10px",
                                          paddingBottom: "10px",
                                          paddingleft: "20px",
                                          borderRadius: "20px"
                                        }}
                                        href="/login"
                                      >
                                        Add to Cart
                                        <i
                                          style={{
                                            marginLeft: "5px",
                                            fontSize: "15px",
                                          }}
                                          className="fa fa-shopping-cart"
                                          aria-hidden="true"
                                        ></i>
                                      </a>
                                    )}
                                  </div>
                                  <div className="single-product-wishlist">
                                    <a
                                      title="Wishlist"
                                      onClick={() => {
                                        // setLoad(true);
                                        dispatch(
                                          addWishLists({
                                            productId:
                                              Product[changeVariant]?.productId,
                                          })
                                        ).then((res) => {
                                          // setLoad(false);
                                        });
                                      }}
                                    >
                                      {Product[changeVariant]?.favourites ===
                                      0 ? (
                                        <i
                                          className="bx bx-heart"
                                          style={{ fontSize: "26px" }}
                                        ></i>
                                      ) : (
                                        <i
                                          style={{ fontSize: "26px" }}
                                          className="bx bxs-heart"
                                        ></i>
                                      )}
                                    </a>
                                  </div>
                                </div>
                                <div>
                                {Array.isArray(Product)
                                        ? Product?.map((data, index) => (
                                      <span>
                                    <a href={"/productDetails/" + data.id} style={{fontSize:"18px",textDecoration:"underline"}}>View More Details</a>
                                      </span>
                                        ))
                                        : false}
                                  {/* <div className="single-product-cart btn-hover mt-5">
                                    <a  style={{fontSize:"18px",paddingTop:"10px",paddingBottom:"10px",paddingleft:"20px",}}href="#">Add to Cart</a>
                                </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          false
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Product Modal end -->
       <!-- Mobile Menu start --> */}
        <div className="off-canvas-active">
          <a className="off-canvas-close">
          <i className="fa-solid fa-xmark"></i>
          </a>
          <div className="off-canvas-wrap">
            {/* <div className="welcome-text off-canvas-margin-padding">
              <p>Default Welcome Msg! </p>
            </div> */}
            <div className="mobile-menu-wrap off-canvas-margin-padding-2">
              <div id="mobile-menu" className="slinky-mobile-menu text-left">
                <ul>
                  <li>
                    <a href="/">HOME</a>
                  </li>
                  <li>
                    <a href="/products">SHOP</a>
                  </li>
                  <li>
                    <a href="/categories">CATEGORIES </a>
                  </li>

                  <li>
                    <a href="/aboutus">ABOUT US</a>
                  </li>
                  <li>
                    <a href="/contactus">CONTACT US</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchProducts;
