import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { phoneVerify } from "../../Redux/Action";
import Swal from "sweetalert2/dist/sweetalert2.js";

const CartPhoneVerify = () => {
    const { id } = useParams();
     const phone =id;
   
    const dispatch = useDispatch();
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const [otp, setOtp] = useState("");



    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(phoneVerify({otp,phone:id}))
        .then((res) => Swal.fire({
          icon: "success",
          timer: 4000,
          buttons: false,
          showConfirmButton: false,
          text: res.data.data,
        }).then(() => {
          navigate("/checkout", { replace: true });
      }) )
        .catch((err) => {
       
          Swal.fire({
            icon: "error",
            timer: 4000,
            buttons: false,
            showConfirmButton: false,
            text:err.response.data.error.message,
          }).then(() => {
            navigate("/phoneOtp", { replace: true });
        })
          var errMsg = err.response.data.error.message;
          if (errMsg === "Code Expired") {
            navigate("/loginWithPhone");
            var errMsg = "Code Expired, Try Again !";
            localStorage.setItem("Error", errMsg);
          } else {
            setError(err.response.data.error.message);
          }
        });
    }
  return (
    <div>
 <div className="main-wrapper main-wrapper-2">
    
    <div className="login-register-area pb-5 pt-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12 offset-lg-2">
            <div className="login-register-wrapper">
              <div className="login-register-tab-list nav">
              
                <a data-bs-toggle="tab" href="#">
                  <h4> Verify OTP </h4>
                </a>
              </div>
              <div className="tab-content">
                <div id="lg1" className="tab-pane active">
                  <div className="login-form-container">
                    <div className="login-register-form">
                      <form onSubmit={handleSubmit} method="post">
                        
                      <input
                      type="text"
                      onChange={(e)=>setOtp(e.target.value)}
                      name="otp"
                      
                      placeholder="Enter OTP"
                      required={true}
                    />
                   
                    
                           <span style={{ color: "red" }} className="mb-1">
                  {" "}
                  {error ? (
                          <div className="error-text mb-4">  
                          {error }
                        </div>
                          
                          ) : (
                            false
                          )}
                </span>
                        
                        <div className="button-box btn-hover">
                          <button type="submit">Update</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

    </div>
  )
}

export default CartPhoneVerify