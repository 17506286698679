import React,{ useEffect,useState} from 'react'
import { Link,useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {faqs} from "../Redux/Action.js"
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const Faq = () => {
  const [expanded, setExpanded] = React.useState(false);
  const [load, setLoad] = useState(true);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(faqs())
      .then((res)=>{
        setLoad(false);
      })
        .catch(() => {
         
        });
    }, []);
  
    const faqquest = useSelector(({ faqs }) => faqs.payload);
  return load ? (
    <div
      style={{
        height: "400px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : (
    <>
        <div className="main-wrapper main-wrapper-2">
        <div className="breadcrumb-area bg-gray-4 pt-5 pb-5">
            <div className="container">
                <div className="breadcrumb-content text-center">
                    <h2 data-aos="fade-up" data-aos-delay="200">FAQ's</h2>
                    <ul data-aos="fade-up" data-aos-delay="400">
                        <li><Link to="/">Home</Link></li>
                        <li><i className="ti-angle-right"></i></li>
                        <li>FAQ'S  </li>
                    </ul>
                </div>
            </div>
          
        </div>

        <div className="row">
            {Array.isArray(faqquest) && faqquest.length !==0 ?
            faqquest?.map((F,i) => (
              <div className="col-md-6 offset-md-3">
                <div className="accordion accordion-flush p-1" id="faqlist">
                <Accordion
                        
                        className="mt-3"
        expanded={expanded === i}
        onChange={handleChange(i)}
       
      >
         
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh"
        >
           
          <Typography sx={{ width: "83%", flexShrink: 0 }} >
         {/* {info.title} */}
         <span >{F.title} </span>
     
          </Typography>
          
        </AccordionSummary>
        <AccordionDetails>
        <Typography>
           
           {F.answer}
          </Typography>
        
        
       
        </AccordionDetails>

      </Accordion>
                </div>
              </div>
            )):<center><h2>No FAQ's Found </h2></center>}
          </div>
        </div>


    </>
  )
}

export default Faq