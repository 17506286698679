import React, { useEffect, useState } from "react";
import { myReviews, deleteReviews } from "../../Redux/Action";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import reviews from "../../images/reviews found.svg";
import orderimage from "../../images/orders.svg";
import ReviewModal from "./ReviewModal";
import "./style.css";
const ReviewsTotal = () => {
  const token = localStorage.getItem("AuthTok");
  if (token === null || token === "undefined" || token === "") {
    window.location.href = "/login";
  }
  const [load, setLoad] = useState(true);
  const [show, setShow] = useState(true);
  const [render, setRender] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(myReviews())
      .then((res) => {
        setLoad(false);
      })
      .catch(() => {});
  }, [render]);

  const myReview = useSelector(({ myReviews }) => myReviews.payload);
  const handlereview = (G) => {
    dispatch(
      deleteReviews({
        action: "delete",
        id: G.id,
        rating: G.rating,
        review: G.review,
      })
    );
    setRender(!render);
  };

  return load ? (
    <div
      style={{
        height: "400px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : (
    <div>
      <div className="container">
        <div className="breadcrumb-area bg-gray-4 ">
          <div className="container">
            <div className="breadcrumb-content text-center mb-5">
              <h2
                data-aos="fade-up"
                data-aos-delay="200"
                style={{ textTransform: "uppercase" }}
              >
                Orders
              </h2>
              <ul data-aos="fade-up" data-aos-delay="400">
                <li>
                  <Link to="/reviewsTotal">Reviews</Link>
                </li>
                <li>
                  <i className="ti-angle-right"></i>
                </li>
                <li>Reviews List </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="register-in-title">
            <h3 style={{ textAlign: "center", marginBottom: "25px" }}>
              My Reviews
            </h3>
          </div>
          <div className="col-12">
            <form action="#">
              <div className="cart-table-content">
                <div className="table-content table-responsive">
                  <table>
                    {Array.isArray(myReview) && myReview.length !== 0 ? (
                      <thead>
                        <tr>
                          {/* <th className="width-thumbnail">S.No</th> */}

                          {/* {profileList?.profilePic  ? */}
                          <th className="width-thumbnail">S.No</th>

                          {/* : false} */}
                          <th className="width-price">Product Name</th>
                          <th className="width-thumbnail"> Product Image</th>
                          {/* <th className="width-quantity">  District</th> */}
                          <th className="width-price"> Review</th>
                          <th className="width-price"> Rating</th>
                          <th className="width-price"> Action</th>
                        </tr>
                      </thead>
                    ) : (
                      false
                    )}
                    <tbody>
                      <>
                        {Array.isArray(myReview) && myReview.length !== 0 ? (
                          myReview?.map((data, i) => (
                            <tr>
                              <td className="product-name">{i + 1}</td>
                              <td className="product-name">
                                <h5 style={{ textTransform: "capitalize" }}>
                                  <Link to={"/productDetails/" + data.id}>
                                    {data.productName}
                                  </Link>
                                </h5>
                              </td>
                              <td className="product-thumbnail reviewImage">
                                <Link to={"/productDetails/" + data.id}>
                                  {" "}
                                  <img
                                    className="reviewImage"
                                    style={{ width: "17%" }}
                                    src={
                                      // process.env.REACT_APP_BASE_URL+
                                      process.env.REACT_APP_BASE_URL +
                                      data.variantImage
                                    }
                                    alt=""
                                  />
                                </Link>
                              </td>

                              <td className="product-name">
                                <h5>
                                  <a
                                    //   href="product-details.html"
                                    style={{
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {data?.review}
                                  </a>
                                </h5>
                              </td>
                              <td className="product-name">
                                <h5>
                                  <a
                                    //   href="product-details.html"
                                    style={{
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {data?.rating}
                                  </a>
                                </h5>
                              </td>
                              <td
                                className="product-name"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  paddingTop: "44px",
                                }}
                              >
                                {/* <span

                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    > */}
                                <span
                                  //  onClick={ Updated(data.id)}
                                  style={{
                                    cursor: "pointer",
                                    paddingLeft: "10px",
                                  }}
                                  // onClick={() => {
                                  //   if (show !== i)
                                  //     setShow(i);
                                  // }}
                                >
                                  <Link to={"/updateReview/" + data.id}>
                                    <i
                                      style={{
                                        fontSize: "23px",
                                        // backgroundColor: "#0000001c",
                                        borderRadius: "0",
                                        padding: "10px",
                                      }}
                                      className="bx bx-edit-alt"
                                    ></i>
                                  </Link>
                                </span>
                                {/* </span> */}
                                {/* <span onClick={() => handlereview(data)}>
                      <a style={{ cursor: "pointer", paddingLeft: "10px" }}>
                        <i
                          style={{
                            fontSize: "23px",
                            // backgroundColor: "#0000001c",
                            borderRadius: "0",
                            padding: "10px",
                          }}
                          className="bx bx-trash"
                        ></i>
                      </a>
                    </span> */}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <center>
                            <img
                              src={orderimage}
                              className="mt-5 mb-5 pb-5 pt-5"
                              style={{ width: "25%" }}
                            />
                            <h3> No Reviews Found</h3>{" "}
                          </center>
                        )}
                      </>
                    </tbody>
                  </table>
                </div>
                {/* <!-- Modal --> */}
                {/* <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
             Update   Review
              </h5>
              <button
                type="button"
                id="buttin"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {show || show == 0 ? <ReviewModal myreview={myReview?.[show]} /> : false}
            </div>
            <div className="modal-footer">
            </div>
          </div>
        </div>
      </div> */}
                {/* Modal End */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewsTotal;
