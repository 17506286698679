import React, { useState, useEffect } from "react";
import { Link,useNavigate } from "react-router-dom";
import {getUserAddress,deleteUserAddress} from "../../Redux/Action"
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { BsPencil } from "react-icons/bs";
import test from "../../images/test.jpg"
import Swal from "sweetalert2/dist/sweetalert2.js";

const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      paddingTop: "0px",
      paddingBottom: "0px",
    },
    preview: {
      display: "flex",
      flexDirection: "column",
    },
    image: { width: "100px", height: "100px", borderRadius: "50%" },
    // editimage:{
    //   color: "white",
    //   background: "black",
    //   width: "30px",
    //   padding: "7px",
    //   borderRadius: "50%",
    //   display: "flex",
    //   marginTop: "-70px",
    //   marginLeft: "230px",
    // }
  };
  






const UpdateProfile = () => {
  const token =localStorage.getItem("AuthTok");
  if(token === null || token === "undefined" || token === ""){
    window.location.href = "/login";
  }
    const dispatch = useDispatch();
    const { id } = useParams();
    const Cid = id;
    const [load, setLoad] = useState(true);
    const [user, setUser] = useState({
      image: "",
      username: "",
      alaisname: "",
      dob: "",
      sex: "",
    });
    const Address = useSelector(({ getUserAddress }) => getUserAddress.payload);
    const [address, setAddress] = useState({
      state: "",
      city: "",
      street: "",
      landmark: "",
      zipcode: "",
      primary: "",
      district: "",
      addressType: "",
    });
    const navigate = useNavigate();
 

    useEffect(() => {
        dispatch(getUserAddress()).then((res) => {
          res.data.data
            .filter((F) => F.id == Cid)
            .map((A) => {
              setAddress({
                state: A.state,
                city: A.city,
                street: A.street,
                landmark: A.landmark,
                zipcode: A.zipcode,
                primary: A.primary,
                district: A.district,
                addressType: A.addressType,
              });
            });
        });
      }, []);
    
      const handleChange = (e) => {
        setAddress({ ...address, [e.target.name]: e.target.value });
      };
      const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(
            deleteUserAddress({ ...address, action: "edit", addressId: Cid })
        ).then((res) => {
          if(res.data.data === "Update Success"){

            Swal.fire({
              icon: "success",
              timer: 4000,
              buttons: false,
              showConfirmButton: false,
              text: res.data.data,
            }).then(() => {
              navigate("/profile", { replace: true });
          })
          }
          
          
        })
        .catch((err) => {
                              Swal.fire({
                                icon: "error",
                                timer: 4000,
                                buttons: false,
                                showConfirmButton: false,
                                text: err.response.data.error.message,
                              })
                            });
      };


  return  (
    <>
       <div className="breadcrumb-area bg-gray-4  ">
            <div className="container">
                <div className="breadcrumb-content text-center pt-3">
                    <h2 data-aos="fade-up" data-aos-delay="200">UPDATE</h2>
                    <ul data-aos="fade-up" data-aos-delay="400">
                        <li><Link to="/profile">Profile</Link></li>
                        <li><i className="ti-angle-right"></i></li>
                        <li>Update Address  </li>
                    </ul>
                </div>
            </div>
        
        </div>
          <section className="register-area ">
        <div className="container col-lg-4 d-flex justify-content-start">
          <div className="register-form">
          
            
          {/* <div className="register-in-title">
              <h3 style={{ textAlign: "center" ,marginBottom:"20px",marginTop:"20px"}}>Update Address</h3>
            </div> */}

          <form onSubmit={handleSubmit} style={{marginTop:"25px"}}>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <input
                      type="text"
                      name="state"
                      style={{marginBottom:"20px"}}
                      value={address.state}
                      onChange={handleChange}
                      className="form-control"
                      placeholder="State"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <input
                      type="text"
                      name="city"
                      style={{marginBottom:"20px"}}
                      value={address.city}
                      onChange={handleChange}
                      className="form-control"
                      placeholder="City"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <input
                      type="text"
                      name="street"
                      style={{marginBottom:"20px"}}
                      value={address.street}
                      onChange={handleChange}
                      className="form-control"
                      placeholder="Street"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <input
                      type="text"
                      name="landmark"
                      value={address.landmark}
                      style={{marginBottom:"20px"}}
                      onChange={handleChange}
                      className="form-control"
                      placeholder="Landmark"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <input
                      type="number"
                      name="zipcode"
                      value={address.zipcode}
                      style={{marginBottom:"20px"}}
                      onChange={handleChange}
                      className="form-control"
                      placeholder="Zipcode"
                      required
                    />
                  </div>
                </div>
                
                <div className="col-lg-6">
                  <div className="form-group">
                    <input
                      type="text"
                      name="district"
                      value={address.district}
                      style={{marginBottom:"20px"}}
                      onChange={handleChange}
                      className="form-control"
                      placeholder="District"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                  <select
                  className="form-select" 
                  value={address.primary}
                  onChange={handleChange}
                  name="primary"
                  aria-label="Default select example"
                  style={{maxWidth:"529px",marginLeft:"0px",height:"49px",marginBottom:"15px"}}
                  >
                  <option selected>  Primary</option>
                  <option value="yes">Yes</option>
                  <option value="No">No</option>
                </select> 
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                   

<select
                  className="form-select" 
                  value={address.addressType}
                  onChange={handleChange}
                  name="addressType"
                  aria-label="Default select example"
                  style={{maxWidth:"529px",marginLeft:"0px",height:"49px",marginBottom:"15px"}}
                  >
                  <option selected> Choose Address </option>
                  <option value="home">Home </option>
                  <option value="office">Office</option>
                  <option value="other">Others</option>
                </select>


                  </div>
                </div>
                <div className="col-lg-12 pt-3">
                  <div className="send-btn"  style={{ display: "flex", justifyContent: "center" }}>
                    <button type="submit" className="btn btn-dark" style={{background:"#DD3D05",border:"none",marginTop:"5px",marginBottom:"25px",borderRadius: "20px"}}>
                      Update Address
                      <span></span>
                    </button>
                  </div>
                </div>
              </div>
            </form>















            
          </div>
        </div>
      </section>
    </>
  )
}

export default UpdateProfile