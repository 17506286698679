import React from "react";
import { Link } from "react-router-dom";
const TermsCondtions = () => {
  return (
    <div>
      <div className="main-wrapper main-wrapper-2 ">
        <div className="breadcrumb-area bg-gray-4 ">
          <div className="container">
            <div className="breadcrumb-content text-center">
              <h2 data-aos="fade-up" data-aos-delay="200">
                Policies
              </h2>
              <ul data-aos="fade-up" data-aos-delay="400">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <i className="ti-angle-right"></i>
                </li>
                <li>Terms & Condtions </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="my-account-wrapper ">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                {/* <!-- My Account Page Start --> */}
                <div className="myaccount-page-wrapper">
                  {/* <!-- My Account Tab Menu Start --> */}
                  <div
                    className="row pt-5"
                    style={{ justifyContent: "center" }}
                  >
                    <div className="col-lg-3 col-md-4">
                      <div className="myaccount-tab-menu nav" role="tablist">
                        <a
                          href="#terms"
                          className="active"
                          data-bs-toggle="tab"
                        >
                          Terms & Conditions
                        </a>
                        <a href="#privacy" data-bs-toggle="tab">
                          Privacy Policy
                        </a>
                        <a href="#refund" data-bs-toggle="tab">
                          Refund Policy
                        </a>
                      </div>
                    </div>
                    {/* <!-- My Account Tab Menu End -->
                                <!-- My Account Tab Content Start --> */}
                    <div className="col-lg-7 col-md-8">
                      <div className="tab-content" id="myaccountContent">
                        {/* <!-- Single Tab Content Start --> */}

                        {/* <!-- Single Tab Content End -->
                                        <!-- Single Tab Content Start --> */}
                        <div
                          className="tab-pane fade show active"
                          id="terms"
                          role="tabpanel"
                        >
                          <div className="myaccount-content">
                            <h3>Terms & Conditions</h3>
                            <div className="welcome">
                              <p>
                                Updated at <strong>01-05-2022</strong>{" "}
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                <strong>General Terms</strong>
                                <br />
                                <br />
                                By accessing and placing an order with us, you
                                confirm that you are in agreement with and bound
                                by the terms of service contained in the Terms &
                                Conditions outlined below. These terms apply to
                                the entire website and any email or other type
                                of communication between you and Bedwell.
                                <br />
                                <br />
                                Under no circumstances shall We be liable for
                                any direct, indirect, special, incidental or
                                consequential damages, including, but not
                                limited to, loss of data or profit, arising out
                                of the use, or the inability to use, the
                                materials on this site, even if We or an
                                authorized representative has been advised of
                                the possibility of such damages. If your use of
                                materials from this site results in the need for
                                servicing, repair or correction of equipment or
                                data, you assume any costs thereof.
                                <br />
                                <br />
                                We will not be responsible for any outcome that
                                may occur during the course of usage of our
                                resources. We reserve the rights to change
                                prices and revise the resources usage policy at
                                any moment.
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                <strong>Definitions and key terms</strong>
                                <br />
                                <br />
                                For this Terms & Conditions:
                                <br />
                                <br />
                                <strong
                                  style={{
                                    color: "#767373",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  Cookie:{" "}
                                </strong>{" "}
                                small amount of data generated by a website and
                                saved by your web browser. It is used to
                                identify your browser, provide analytics,
                                remember information about you such as your
                                language preference or login information.
                                <br />
                                <br />
                                <strong
                                  style={{
                                    color: "#767373",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  Company:{" "}
                                </strong>{" "}
                                when this policy mentions “Company,” “we,” “us,”
                                or “our,” it refers to BOMBAY PRINTING MILLS, 18
                                SENGOTTAIYAN NAGAR, ERODE 638003 that is
                                responsible for your information under this
                                Privacy Policy.
                                <br />
                                <br />
                                <strong
                                  style={{
                                    color: "#767373",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  Country:{" "}
                                </strong>{" "}
                                where Bedwell or the owners/founders of Bedwell
                                are based, in this case is INDIA.
                                <br />
                                <br />
                                <strong
                                  style={{
                                    color: "#767373",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  Customer:{" "}
                                </strong>{" "}
                                refers to the company, organization or a person
                                that signs up to use the Bedwell
                                Products/Services.
                                <br />
                                <br />
                                <strong
                                  style={{
                                    color: "#767373",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  Device:
                                </strong>{" "}
                                any internet connected device such as a phone,
                                tablet, computer or any other device that can be
                                used to visit Bedwell and use the services.
                                <br />
                                <br />
                                <strong
                                  style={{
                                    color: "#767373",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  IP address:{" "}
                                </strong>{" "}
                                Every device connected to the Internet is
                                assigned a number known as an Internet protocol
                                (IP) address. These numbers are usually assigned
                                in geographic blocks. An IP address can often be
                                used to identify the location from which a
                                device is connecting to the Internet.
                                <br />
                                <br />
                                <strong
                                  style={{
                                    color: "#767373",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  Personnel:{" "}
                                </strong>{" "}
                                refers to those individuals who are employed by
                                Bedwell or are under contract to perform a
                                service on behalf of one of the parties.
                                <br />
                                <br />
                                <strong
                                  style={{
                                    color: "#767373",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  Personal Data:
                                </strong>{" "}
                                any information that directly, indirectly, or in
                                connection with other information — including a
                                personal identification number — allows for the
                                identification or identifiability of a natural
                                person.
                                <br />
                                <br />
                                <strong
                                  style={{
                                    color: "#767373",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {" "}
                                  Products:
                                </strong>{" "}
                                refers to the products provided by Bedwell as
                                described in the relative terms (if available)
                                and on this platform.
                                <br />
                                <br />
                                <strong
                                  style={{
                                    color: "#767373",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {" "}
                                  Service:{" "}
                                </strong>
                                refers to the service provided by Bedwell as
                                described in the relative terms (if available)
                                and on this platform.
                                <br />
                                <br />
                                <strong
                                  style={{
                                    color: "#767373",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  Third-party service:{" "}
                                </strong>{" "}
                                refers to advertisers, contest sponsors,
                                promotional and marketing partners, and others
                                who provide our content or whose products or
                                services we think may interest you.
                                <br />
                                <br />
                                <strong
                                  style={{
                                    color: "#767373",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {" "}
                                  Website:{" "}
                                </strong>
                                Bedwell’s site, which can be accessed via this
                                URL:{" "}
                                <strong>
                                  <a href="https://www.bedwell.in"> Bedwell</a>
                                </strong>{" "}
                                .
                                <br />
                                <br />
                                <strong
                                  style={{
                                    color: "#767373",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  You:{" "}
                                </strong>{" "}
                                a person or entity that is registered with
                                Bedwell to use the Services.
                                <br />
                                <br />
                                You are agreeing to be bound by these Terms &
                                Conditions. If you do not agree to these Terms &
                                Conditions, please do not use the Service. In
                                these Terms & Conditions, "you" refers both to
                                you as an individual and to the entity you
                                represent. If you violate any of these Terms &
                                Conditions, we reserve the right to cancel your
                                account or block access to your account without
                                notice.
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                <strong>Restrictions</strong>
                                <br />
                                <br />
                                You agree not to, and you will not permit others
                                to:
                                <br />
                                <br />
                                1. License, sell, rent, lease, assign,
                                distribute, transmit, host, outsource, disclose
                                or otherwise commercially exploit the service or
                                make the platform available to any third party.
                                <br />
                                <br />
                                2. Modify, make derivative works of,
                                disassemble, decrypt, reverse compile or reverse
                                engineer any part of the service.
                                <br />
                                <br />
                                3. Remove, alter or obscure any proprietary
                                notice (including any notice of copyright or
                                trademark) of or its affiliates, partners,
                                suppliers or the licensors of the service.
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                <strong>Payment</strong>
                                <br />
                                <br />
                                If you pay for any of our one-time payment
                                plans, you agree to pay all fees or charges to
                                your account for the Service in accordance with
                                the fees, charges and billing terms in effect at
                                the time that each fee or charge is due and
                                payable. Your Payment Provider agreement governs
                                your use of the designated account, and you must
                                refer to that agreement and not these Terms to
                                determine your rights and liabilities with
                                respect to your Payment Provider. By providing
                                us with your credit/debit card number and
                                associated payment information, you agree that
                                we are authorized to verify information
                                immediately, and subsequently invoice your
                                account for all fees and charges due and payable
                                to us hereunder and that no additional notice or
                                consent is required. You agree to immediately
                                notify us of any change in your billing address
                                or the credit/debit card used for payment
                                hereunder. We reserve the right at any time to
                                change its prices and billing methods, either
                                immediately upon posting on our Site or by
                                e-mail delivery to your organization’s
                                administrator(s). Any attorney fees, court
                                costs, or other costs incurred in collection of
                                delinquent undisputed amounts shall be the
                                responsibility of and paid for by you. No
                                contract will exist between you and us for the
                                Service until we accept your order by a
                                confirmatory e-mail, SMS/MMS message, or other
                                appropriate means of communication. You are
                                responsible for any third-party fees that you
                                may incur when using the Service.
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                <strong>Your Suggestions</strong>
                                <br />
                                <br />
                                Any feedback, comments, ideas, improvements or
                                suggestions (collectively, "Suggestions")
                                provided by you to us with respect to the
                                service shall remain the sole and exclusive
                                property of us. We shall be free to use, copy,
                                modify, publish, or redistribute the Suggestions
                                for any purpose and in any way without any
                                credit or any compensation to you.
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                <strong>Your Consent</strong>
                                <br />
                                <br />
                                We've updated our Terms & Conditions to provide
                                you with complete transparency into what is
                                being set when you visit our site and how it's
                                being used. By using our service, registering an
                                account, or making a purchase, you hereby
                                consent to our Terms & Conditions.
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                <strong>Links to Other Websites</strong>
                                <br />
                                <br />
                                Our service may contain links to other websites
                                that are not operated by Us. If You click on any
                                third-party link, you will be directed to that
                                third party's site. We strongly advise You to
                                review the Terms & Conditions of every site You
                                visit. We have no control over and assume no
                                responsibility for the content, Terms &
                                Conditions or practices of any third-party sites
                                or services.
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                <strong>Cookies</strong>
                                <br />
                                <br />
                                We use "Cookies" to identify the areas of our
                                website that you have visited. A Cookie is a
                                small piece of data stored on your computer or
                                mobile device by your web browser. We use
                                Cookies to enhance the performance and
                                functionality of our service but are
                                non-essential to their use. However, without
                                these cookies, certain functionality like videos
                                may become unavailable or you would be required
                                to enter your login details every time you visit
                                our platform as we would not be able to remember
                                that you had logged in previously. Most web
                                browsers can be set to disable the use of
                                Cookies. However, if you disable Cookies, you
                                may not be able to access functionality on our
                                website correctly or at all. We never place
                                Personally Identifiable Information in Cookies.
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                <strong>
                                  Changes To Our Terms & Conditions
                                </strong>
                                <br />
                                <br />
                                You acknowledge and agree that we may stop
                                (permanently or temporarily) providing the
                                Product/Service (or any features within the
                                Service) to you or to users generally at our
                                sole discretion, without prior notice to you.
                                You acknowledge and agree that if we disable
                                access to your account, you may be prevented
                                from accessing the Site, your account details or
                                any files or other materials which is contained
                                in your account. If we decide to change our
                                Terms & Conditions, we will post those changes
                                on this page, and/or update the Terms &
                                Conditions modification date below.
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                <strong>Modifications to Our service</strong>
                                <br />
                                <br />
                                We reserve the right to modify, suspend or
                                discontinue, temporarily or permanently, the
                                Product(s) or any service to which it connects,
                                with or without notice and without liability to
                                you.
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                <strong>Updates to Our service</strong>
                                <br />
                                <br />
                                We may from time to time provide enhancements or
                                improvements to the features/ functionality of
                                the service, which may include patches, bug
                                fixes, updates, upgrades and other modifications
                                ("Updates"). Updates may modify or delete
                                certain features and/or functionalities of the
                                service. You agree that we have no obligation to
                                (i) provide any Updates, or (ii) continue to
                                provide or enable any particular features and/or
                                functionalities of the service to you. You
                                further agree that all Updates will be (i)
                                deemed to constitute an integral part of the
                                service, and (ii) subject to the terms and
                                conditions of this Agreement.
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                <strong>Third-Party Services</strong>
                                <br />
                                <br />
                                We may display, include or make available
                                third-party content (including data,
                                information, applications and other products
                                services) or provide links to third-party
                                websites or services ("Third- Party Services").
                                You acknowledge and agree that we shall not be
                                responsible for any Third-Party Services,
                                including their accuracy, completeness,
                                timeliness, validity, copyright compliance,
                                legality, decency, quality or any other aspect
                                thereof. We do not assume and shall not have any
                                liability or responsibility to you or any other
                                person or entity for any Third-Party Services.
                                Third-Party Services and links thereto are
                                provided solely as a convenience to you and you
                                access and use them entirely at your own risk
                                and subject to such third parties' terms and
                                conditions.
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                <strong>Term and Termination</strong>
                                <br />
                                <br />
                                This Agreement shall remain in effect until
                                terminated by you or us. We may, in its sole
                                discretion, at any time and for any or no
                                reason, suspend or terminate this Agreement with
                                or without prior notice. This Agreement will
                                terminate immediately, without prior notice from
                                us, in the event that you fail to comply with
                                any provision of this Agreement. You may also
                                terminate this Agreement by deleting the service
                                and all copies thereof from your computer. Upon
                                termination of this Agreement, you shall cease
                                all use of the service and delete all copies of
                                the service from your computer. Termination of
                                this Agreement will not limit any of our rights
                                or remedies at law or in equity in case of
                                breach by you (during the term of this
                                Agreement) of any of your obligations under the
                                present Agreement.
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                <strong>Term and Termination</strong>
                                <br />
                                <br />
                                If you are a copyright owner or such owner’s
                                agent and believe any material from us
                                constitutes an infringement on your copyright,
                                please contact us setting forth the following
                                information: (a) a physical or electronic
                                signature of the copyright owner or a person
                                authorized to act on his behalf; (b)
                                identification of the material that is claimed
                                to be infringing; (c) your contact information,
                                including your address, telephone number, and an
                                email; (d) a statement by you that you have a
                                good faith belief that use of the material is
                                not authorized by the copyright owners; and (e)
                                the a statement that the information in the
                                notification is accurate, and, under penalty of
                                perjury you are authorized to act on behalf of
                                the owner.
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                <strong>Indemnification</strong>
                                <br />
                                <br />
                                You agree to indemnify and hold us and our
                                parents, subsidiaries, affiliates, officers,
                                employees, agents, partners and licensors (if
                                any) harmless from any claim or demand,
                                including reasonable attorneys' fees, due to or
                                arising out of your: (a) use of the service; (b)
                                violation of this Agreement or any law or
                                regulation; or (c) violation of any right of a
                                third party.
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                <strong>No Warranties</strong>
                                <br />
                                <br />
                                The service is provided to you "AS IS" and "AS
                                AVAILABLE" and with all faults and defects
                                without warranty of any kind. To the maximum
                                extent permitted under applicable law, we, on
                                our own behalf and on behalf of our affiliates
                                and our respective licensors and service
                                providers, expressly disclaims all warranties,
                                whether express, implied, statutory or
                                otherwise, with respect to the service,
                                including all implied warranties of
                                merchantability, fitness for a particular
                                purpose, title and non- infringement, and
                                warranties that may arise out of course of
                                dealing, course of performance, usage or trade
                                practice. Without limitation to the foregoing,
                                we provide no warranty or undertaking, and makes
                                no representation of any kind that the service
                                will meet your requirements, achieve any
                                intended results, be compatible or work with any
                                other software, websites, systems or services,
                                operate without interruption, meet any
                                performance or reliability standards or be error
                                free or that any errors or defects can or will
                                be corrected.
                                <br />
                                <br />
                                Without limiting the foregoing, neither us nor
                                any provider makes any representation or
                                warranty of any kind, express or implied: (i) as
                                to the operation or availability of the service,
                                or the information, content, and materials or
                                products included thereon; (ii) that the service
                                will be uninterrupted or error-free; (iii) as to
                                the accuracy, reliability, or currency of any
                                information or content provided through the
                                service; or (iv) that the service, its servers,
                                the content, or e-mails sent from or on behalf
                                of us are free of viruses, scripts, trojan
                                horses, worms, malware, timebombs or other
                                harmful components. Some jurisdictions do not
                                allow the exclusion of or limitations on implied
                                warranties or the limitations on the applicable
                                statutory rights of a consumer, so some or all
                                of the above exclusions and limitations may not
                                apply to you.
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                <strong>Limitation of Liability</strong>
                                <br />
                                <br />
                                Notwithstanding any damages that you might
                                incur, the entire liability of us and any of our
                                suppliers under any provision of this Agreement
                                and your exclusive remedy for all of the
                                foregoing shall be limited to the amount
                                actually paid by you for the service. To the
                                maximum extent permitted by applicable law, in
                                no event shall we or our suppliers be liable for
                                any special, incidental, indirect, or
                                consequential damages whatsoever (including, but
                                not limited to, damages for loss of profits, for
                                loss of data or other information, for business
                                interruption, for personal injury, for loss of
                                privacy arising out of or in any way related to
                                the use of or inability to use the service,
                                third-party software and/or third-party hardware
                                used with the service, or otherwise in
                                connection with any provision of this
                                Agreement), even if we or any supplier has been
                                advised of the possibility of such damages and
                                even if the remedy fails of its essential
                                purpose.
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                <strong>Severability</strong>
                                <br />
                                <br />
                                If any provision of this Agreement is held to be
                                unenforceable or invalid, such provision will be
                                changed and interpreted to accomplish the
                                objectives of such provision to the greatest
                                extent possible under applicable law and the
                                remaining provisions will continue in full force
                                and effect.
                                <br />
                                <br />
                                This Agreement, together with the Privacy Policy
                                and any other legal notices published by us on
                                the Services, shall constitute the entire
                                agreement between you and us concerning the
                                Services. If any provision of this Agreement is
                                deemed invalid by a court of competent
                                jurisdiction, the invalidity of such provision
                                shall not affect the validity of the remaining
                                provisions of this Agreement, which shall remain
                                in full force and effect. No waiver of any term
                                of this Agreement shall be deemed a further or
                                continuing waiver of such term or any other
                                term, and our failure to assert any right or
                                provision under this Agreement shall not
                                constitute a waiver of such right or provision.
                                YOU AND US AGREE THAT ANY CAUSE OF ACTION
                                ARISING OUT OF OR RELATED TO THE SERVICES MUST
                                COMMENCE WITHIN ONE (1) WEEK AFTER THE CAUSE OF
                                ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION
                                IS PERMANENTLY BARRED.
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                <strong>Waiver</strong>
                                <br />
                                <br />
                                Except as provided herein, the failure to
                                exercise a right or to require performance of an
                                obligation under this Agreement shall not affect
                                a party's ability to exercise such right or
                                require such performance at any time thereafter
                                nor shall be the waiver of a breach constitute
                                waiver of any subsequent breach.
                                <br />
                                <br />
                                No failure to exercise, and no delay in
                                exercising, on the part of either party, any
                                right or any power under this Agreement shall
                                operate as a waiver of that right or power. Nor
                                shall any single or partial exercise of any
                                right or power under this Agreement preclude
                                further exercise of that or any other right
                                granted herein. In the event of a conflict
                                between this Agreement and any applicable
                                purchase or other terms, the terms of this
                                Agreement shall govern.
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                <strong>Amendments to this Agreement</strong>
                                <br />
                                <br />
                                We reserve the right, at its sole discretion, to
                                modify or replace this Agreement at any time. If
                                a revision is material, we will provide at least
                                30 days' notice prior to any new terms taking
                                effect. What constitutes a material change will
                                be determined at our sole discretion. By
                                continuing to access or use our service after
                                any revisions become effective, you agree to be
                                bound by the revised terms. If you do not agree
                                to the new terms, you are no longer authorized
                                to use our service.
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                <strong>Entire Agreement</strong>
                                <br />
                                <br />
                                The Agreement constitutes the entire agreement
                                between you and us regarding your use of the
                                service and supersedes all prior and
                                contemporaneous written or oral agreements
                                between you and us. You may be subject to
                                additional terms and conditions that apply when
                                you use or purchase other services from us,
                                which we will provide to you at the time of such
                                use or purchase.
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                <strong>Updates to Our Terms</strong>
                                <br />
                                <br />
                                We may change our Service and policies, and we
                                may need to make changes to these Terms so that
                                they accurately reflect our Service and
                                policies. Unless otherwise required by law, we
                                will notify you (for example, through our
                                Service) before we make changes to these Terms
                                and give you an opportunity to review them
                                before they go into effect. Then, if you
                                continue to use the Service, you will be bound
                                by the updated Terms. If you do not want to
                                agree to these or any updated Terms, you can
                                delete your account.
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                <strong>Intellectual Property</strong>
                                <br />
                                <br />
                                Our platform and its entire contents, features
                                and functionality (including but not limited to
                                all information, software, text, displays,
                                images, video and audio, and the design,
                                selection and arrangement thereof), are owned by
                                us, its licensors or other providers of such
                                material and are protected by and international
                                copyright, trademark, patent, trade secret and
                                other intellectual property or proprietary
                                rights laws. The material may not be copied,
                                modified, reproduced, downloaded or distributed
                                in any way, in whole or in part, without the
                                express prior written permission of us, unless
                                and except as is expressly provided in these
                                Terms & Conditions. Any unauthorized use of the
                                material is prohibited.
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                <strong>Agreement to Arbitrate</strong>
                                <br />
                                <br />
                                This section applies to any dispute EXCEPT IT
                                DOESN’T INCLUDE A DISPUTE RELATING TO CLAIMS FOR
                                INJUNCTIVE OR EQUITABLE RELIEF REGARDING THE
                                ENFORCEMENT OR VALIDITY OF YOUR OR ’s
                                INTELLECTUAL PROPERTY RIGHTS. The term “dispute”
                                means any dispute, action, or other controversy
                                between you and us concerning the Services or
                                this agreement, whether in contract, warranty,
                                tort, statute, regulation, ordinance, or any
                                other legal or equitable basis. “Dispute” will
                                be given the broadest possible meaning allowable
                                under law.
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                <strong>Notice of Dispute</strong>
                                <br />
                                <br />
                                In the event of a dispute, you or us must give
                                the other a Notice of Dispute, which is a
                                written statement that sets forth the name,
                                address, and contact information of the party
                                giving it, the facts giving rise to the dispute,
                                and the relief requested. You must send any
                                Notice of Dispute via email to: . We will send
                                any Notice of Dispute to you by mail to your
                                address if we have it, or otherwise to your
                                email address. You and us will attempt to
                                resolve any dispute through informal negotiation
                                within sixty (60) days from the date the Notice
                                of Dispute is sent. After sixty (60) days, you
                                or us may commence arbitration.
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                <strong>Binding Arbitration</strong>
                                <br />
                                <br />
                                If you and us don’t resolve any dispute by
                                informal negotiation, any other effort to
                                resolve the dispute will be conducted
                                exclusively by binding arbitration as described
                                in this section. You are giving up the right to
                                litigate (or participate in as a party or class
                                member) all disputes in court before a judge or
                                jury. The dispute shall be settled by binding
                                arbitration in accordance with the commercial
                                arbitration rules of the Indian Arbitration Act.
                                Either party may seek any interim or preliminary
                                injunctive relief from any court of competent
                                jurisdiction, as necessary to protect the
                                party’s rights or property pending the
                                completion of arbitration. Any and all legal,
                                accounting, and other costs, fees, and expenses
                                incurred by the prevailing party shall be borne
                                by the non-prevailing party.
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                <strong>Submissions and Privacy</strong>
                                <br />
                                <br />
                                In the event that you submit or post any ideas,
                                creative suggestions, designs, photographs,
                                information, advertisements, data or proposals,
                                including ideas for new or improved products,
                                services, features, technologies or promotions,
                                you expressly agree that such submissions will
                                automatically be treated as non- confidential
                                and non-proprietary and will become the sole
                                property of us without any compensation or
                                credit to you whatsoever. We and our affiliates
                                shall have no obligations with respect to such
                                submissions or posts and may use the ideas
                                contained in such submissions or posts for any
                                purposes in any medium in perpetuity, including,
                                but not limited to, developing, manufacturing,
                                and marketing products and services using such
                                ideas.
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                <strong>Promotions</strong>
                                <br />
                                <br />
                                We may, from time to time, include contests,
                                promotions, sweepstakes, or other activities
                                (“Promotions”) that require you to submit
                                material or information concerning yourself.
                                Please note that all Promotions may be governed
                                by separate rules that may contain certain
                                eligibility requirements, such as restrictions
                                as to age and geographic location. You are
                                responsible to read all Promotions rules to
                                determine whether or not you are eligible to
                                participate. If you enter any Promotion, you
                                agree to abide by and to comply with all
                                Promotions Rules. Additional terms and
                                conditions may apply to purchases of goods or
                                services on or through the Services, which terms
                                and conditions are made a part of this Agreement
                                by this reference.
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                <strong>Typographical Errors</strong>
                                <br />
                                <br />
                                In the event a product and/or service is listed
                                at an incorrect price or with incorrect
                                information due to typographical error, we shall
                                have the right to refuse or cancel any orders
                                placed for the product and/ or service listed at
                                the incorrect price. We shall have the right to
                                refuse or cancel any such order whether or not
                                the order has been confirmed and your credit
                                card charged. If your credit card has already
                                been charged for the purchase and your order is
                                canceled, we shall immediately issue a credit to
                                your credit card account or other payment
                                account in the amount of the charge.
                              </p>
                            </div>

                            <div className="welcome">
                              <p>
                                <strong>Miscellaneous</strong>
                                <br />
                                <br />
                                If for any reason a court of competent
                                jurisdiction finds any provision or portion of
                                these Terms & Conditions to be unenforceable,
                                the remainder of these Terms & Conditions will
                                continue in full force and effect. Any waiver of
                                any provision of these Terms & Conditions will
                                be effective only if in writing and signed by an
                                authorized representative of us. We will be
                                entitled to injunctive or other equitable relief
                                (without the obligations of posting any bond or
                                surety) in the event of any breach or
                                anticipatory breach by you. We operate and
                                control our Service from our offices in Erode.
                                The Service is not intended for distribution to
                                or use by any person or entity in any
                                jurisdiction or country where such distribution
                                or use would be contrary to law or regulation.
                                Accordingly, those persons who choose to access
                                our Service from other locations do so on their
                                own initiative and are solely responsible for
                                compliance with local laws, if and to the extent
                                local laws are applicable. These Terms &
                                Conditions (which include and incorporate our
                                Privacy Policy) contains the entire
                                understanding, and supersedes all prior
                                understandings, between you and us concerning
                                its subject matter, and cannot be changed or
                                modified by you. The section headings used in
                                this Agreement are for convenience only and will
                                not be given any legal import.
                              </p>
                            </div>

                            <div className="welcome">
                              <p>
                                <strong>Disclaimer</strong>
                                <br />
                                <br />
                                We are not responsible for any content, code or
                                any other imprecision. We do not provide
                                warranties or guarantees. In no event shall we
                                be liable for any special, direct, indirect,
                                consequential, or incidental damages or any
                                damages whatsoever, whether in an action of
                                contract, negligence or other tort, arising out
                                of or in connection with the use of the Service
                                or the contents of the Service. We reserve the
                                right to make additions, deletions, or
                                modifications to the contents on the Service at
                                any time without prior notice.
                                <br />
                                <br />
                                Our Products/Service and its contents are
                                provided "as is" and "as available" without any
                                warranty or representations of any kind, whether
                                express or implied. We are a distributor and not
                                a publisher of the content supplied by third
                                parties; as such, our exercises no editorial
                                control over such content and makes no warranty
                                or representation as to the accuracy,
                                reliability or currency of any information,
                                content, service or merchandise provided through
                                or accessible via our Service. Without limiting
                                the foregoing, We specifically disclaim all
                                warranties and representations in any content
                                transmitted on or in connection with our Service
                                or on sites that may appear as links on our
                                Service, or in the products provided as a part
                                of, or otherwise in connection with, our
                                Service, including without limitation any
                                warranties of merchantability, fitness for a
                                particular purpose or non-infringement of third
                                party rights. No oral advice or written
                                information given by us or any of its
                                affiliates, employees, officers, directors,
                                agents, or the like will create a warranty.
                                Price and availability information is subject to
                                change without notice. Without limiting the
                                foregoing, we do not warrant that our Service
                                will be uninterrupted, uncorrupted, timely, or
                                error-free.
                              </p>
                            </div>

                            <div className="welcome">
                              <p>
                                <strong>Contact Us</strong>
                                <br />
                                <br />
                                Contact us if you have any questions.
                                <br />
                                Via Email:{" "}
                                <strong>
                                  <a href="Support@bedwell.in ">
                                    Support@bedwell.in
                                  </a>{" "}
                                </strong>
                              </p>
                            </div>
                            {/* <p className="mb-0">From your account dashboard. you can easily check & view your recent orders, manage your shipping and billing addresses and edit your password and account details.</p> */}
                          </div>
                        </div>

                        <div
                          className="tab-pane fade show active"
                          id="privacy"
                          role="tabpanel"
                        >
                          <div className="myaccount-content">
                            <h3>Privacy Policy</h3>
                            <div className="welcome">
                              <p>
                                Updated on <strong>01-05-2022</strong>{" "}
                              </p>
                            </div>

                            <p className="mb-0">
                              Bedwell. ("we," "our," or "us") is committed to
                              protecting your privacy. This Privacy Policy
                              explains how your personal information is
                              collected, used, and disclosed by Bedwell.
                            </p>
                            <br />
                            <p className="mb-0">
                              This Privacy Policy applies to our website,
                              <strong>
                                <a
                                  className="welcome"
                                  href="https://www.bedwell.in"
                                >
                                  Bedwell
                                </a>
                              </strong>{" "}
                              and its associated subdomains (collectively, our
                              "Service").
                            </p>
                            <br />

                            <p className="mb-0">
                              By accessing or using our Service, you signify
                              that you have read, understood, and agree to our
                              collection, storage, use, and disclosure of your
                              personal information as described in this Privacy
                              Policy and our Terms of Service.
                            </p>
                            <br />
                            <div className="welcome">
                              <p>
                                {" "}
                                <strong>Definitions and key terms</strong>{" "}
                              </p>
                            </div>
                            <p className="mb-0">For this Privacy Policy:</p>
                            <br />
                            <p className="mb-0">
                              <strong>Cookie: </strong>
                              small amount of data generated by a website and
                              saved by your web browser. It is used to identify
                              your browser, provide analytics, remember
                              information about you such as your language
                              preference or login information.
                            </p>
                            <br />
                            <p className="mb-0">
                              <strong> Company: </strong>when this policy
                              mentions “Company,” “we,” “us,” or “our,” it
                              refers to BOMBAY PRINTING MILLS, 18 SENGOTTAIYAN
                              NAGAR, ERODE 638003, that is responsible for your
                              information under this Privacy Policy.
                            </p>
                            <br />
                            <p className="mb-0">
                              <strong> Country: </strong> where Bedwell or the
                              owners/founders of Bedwell are based, in this case
                              is India.
                              <br />
                              <br />
                              <strong>Customer: </strong> refers to the company,
                              organization or person that signs up to use the
                              Bedwell Service to manage the relationships with
                              your consumers or service users.
                              <br />
                              <br />
                              <strong>Device: </strong> any internet connected
                              device such as a phone, tablet, computer or any
                              other device that can be used to visit Bedwell and
                              use the services.
                              <br />
                              <br />
                              <strong>IP address: </strong> Every device
                              connected to the Internet is assigned a number
                              known as an Internet protocol (IP) address. These
                              numbers are usually assigned in geographic blocks.
                              An IP address can often be used to identify the
                              location from which a device is connecting to the
                              Internet.
                              <br />
                              <br />
                              <strong>Personnel: </strong> refers to those
                              individuals who are employed by Bedwell or are
                              under contract to perform a service on behalf of
                              one of the parties.
                              <br />
                              <br />
                              <strong>Personal Data: </strong> any information
                              that directly, indirectly, or in connection with
                              other information — including a personal
                              identification number — allows for the
                              identification or identifiability of a natural
                              person.
                              <br />
                              <br />
                              <strong>Service: </strong> refers to the service
                              provided by Bedwell as described in the relative
                              terms (if available) and on this platform.
                              <br />
                              <br />
                              <strong>Third-party service: </strong> refers to
                              advertisers, contest sponsors, promotional and
                              marketing partners, and others who provide our
                              content or whose products or services we think may
                              interest you. Website: Bedwell’s site, which can
                              be accessed via this URL:{" "}
                              <strong>
                                <a href="https://www.bedwell.in">Bedwell</a>
                              </strong>
                              . You: a person or entity that is registered with
                              Bedwell to use the Services.
                            </p>
                            <br />
                            <div className="welcome">
                              <p>
                                {" "}
                                <strong>
                                  What Information Do We Collect?
                                </strong>{" "}
                                <br />
                                <br />
                                We collect information from you when you visit
                                our service, register, place an order, subscribe
                                to our newsletter, respond to a survey or fill
                                out a form.
                                <br />
                                <br />
                                A. Name / Username
                                <br />
                                B. Phone Numbers
                                <br />
                                C. Email Addresses
                                <br />
                                D. Date of Birth
                                <br />
                                E. Billing Addresses
                                <br />
                                F. Debit/credit card numbers
                                <br />
                                G. Wallet ID
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                {" "}
                                <strong>
                                  How Do We Use The Information We Collect?
                                </strong>{" "}
                                <br />
                                <br />
                                Any of the information we collect from you may
                                be used in one of the following ways:
                                <br />
                                <br />
                                1. To personalize your experience (your
                                information helps us to better respond to your
                                individual needs)
                                <br />
                                2. To improve our service (we continually strive
                                to improve our service offerings based on the
                                information and feedback we receive from you)
                                <br />
                                3. To improve customer service (your information
                                helps us to more effectively respond to your
                                customer service requests and support needs)
                                <br />
                                4. To process transactions
                                <br />
                                5. To administer a contest, promotion, survey or
                                other site feature
                                <br />
                                6. Debit/credit card numbers
                                <br />
                                7. To send periodic emails
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                {" "}
                                <strong>
                                  When do we use customer information from third
                                  parties?
                                </strong>{" "}
                                <br />
                                <br />
                                We receive some information from the third
                                parties when you contact us. For example, when
                                you submit your email address to us to show
                                interest in becoming our customer, we receive
                                information from a third party that provides
                                automated fraud detection services to us. We
                                also occasionally collect information that is
                                made publicly available on social media
                                websites. You can control how much of your
                                information social media websites make public by
                                visiting these websites and changing your
                                privacy settings.
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                {" "}
                                <strong>
                                  Do we share the information we collect with
                                  third parties?
                                </strong>{" "}
                                <br />
                                <br />
                                We may share the information that we collect,
                                both personal and non-personal, with third
                                parties such as advertisers, contest sponsors,
                                promotional and marketing partners, and others
                                who provide our content or whose products or
                                services we think may interest you. We may also
                                share it with our current and future affiliated
                                companies and business partners, and if we are
                                involved in a merger, asset sale or other
                                business reorganization, we may also share or
                                transfer your personal and non-personal
                                information to our successors-in-interest. We
                                may engage trusted third party service providers
                                to perform functions and provide services to us,
                                such as hosting and maintaining our servers and
                                our service, database storage and management,
                                e-mail management, storage marketing, credit
                                card processing, customer service and fulfilling
                                orders for products and services you may
                                purchase through our platform. We will likely
                                share your personal information, and possibly
                                some non-personal information, with these third
                                parties to enable them to perform these services
                                for us and for you.
                                <br />
                                <br />
                                We may share portions of our log file data,
                                including IP addresses, for analytics purposes
                                with third parties such as web analytics
                                partners, application developers, and ad
                                networks. If your IP address is shared, it may
                                be used to estimate general location and other
                                technographics such as connection speed, whether
                                you have visited the service in a shared
                                location, and type of device used to visit the
                                service. They may aggregate information about
                                our advertising and what you see on the service
                                and then provide auditing, research and
                                reporting for us and our advertisers.
                                <br />
                                <br />
                                We may also disclose personal and non-personal
                                information about you to government or law
                                enforcement officials or private parties as we,
                                in our sole discretion, believe necessary or
                                appropriate in order to respond to claims, legal
                                process (including subpoenas), to protect our
                                rights and interests or those of a third party,
                                the safety of the public or any person, to
                                prevent or stop any illegal, unethical, or
                                legally actionable activity, or to otherwise
                                comply with applicable court orders, laws, rules
                                and regulations.
                              </p>
                            </div>
                            <br />

                            <div className="welcome">
                              <p>
                                {" "}
                                <strong>
                                  Where and when is information collected from
                                  customers and end users?
                                </strong>{" "}
                                <br />
                                <br />
                                We will collect personal information that you
                                submit to us. We may also receive personal
                                information about you from third parties as
                                described above.
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                {" "}
                                <strong>
                                  How Do We Use Your Email Address?
                                </strong>{" "}
                                <br />
                                <br />
                                By submitting your email address on this
                                website, you agree to receive emails from us.
                                You can cancel your participation in any of
                                these email lists at any time by clicking on the
                                opt-out link or other unsubscribe option that is
                                included in the respective email. We only send
                                emails to people who have authorized us to
                                contact them, either directly, or through a
                                third party. We do not send unsolicited
                                commercial emails, because we hate spam as much
                                as you do. By submitting your email address, you
                                also agree to allow us to use your email address
                                for customer audience targeting on sites like
                                Facebook, where we display custom advertising to
                                specific people who have opted-in to receive
                                communications from us. Email addresses
                                submitted only through the order processing page
                                will be used for the sole purpose of sending you
                                information and updates pertaining to your
                                order. If, however, you have provided the same
                                email to us through another method, we may use
                                it for any of the purposes stated in this
                                Policy. Note: If at any time you would like to
                                unsubscribe from receiving future emails, we
                                include detailed unsubscribe instructions at the
                                bottom of each email.
                              </p>
                            </div>
                            <br />

                            <div className="welcome">
                              <p>
                                {" "}
                                <strong>
                                  Could my information be transferred to other
                                  countries?
                                </strong>{" "}
                                <br />
                                <br />
                                We are incorporated in India. Information
                                collected via our website, through direct
                                interactions with you, or from use of our help
                                services may be transferred from time to time to
                                our offices or personnel, or to third parties,
                                located throughout the world, and may be viewed
                                and hosted anywhere in the world, including
                                countries that may not have laws of general
                                applicability regulating the use and transfer of
                                such data. To the fullest extent allowed by
                                applicable law, by using any of the above, you
                                voluntarily consent to the trans- border
                                transfer and hosting of such information.
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                {" "}
                                <strong>
                                  Is the information collected through our
                                  service secure?
                                </strong>{" "}
                                <br />
                                <br />
                                We take precautions to protect the security of
                                your information. We have physical, electronic,
                                and managerial procedures to help safeguard,
                                prevent unauthorized access, maintain data
                                security, and correctly use your information.
                                However, neither people nor security systems are
                                foolproof, including encryption systems. In
                                addition, people can commit intentional crimes,
                                make mistakes or fail to follow policies.
                                Therefore, while we use reasonable efforts to
                                protect your personal information, we cannot
                                guarantee its absolute security. If applicable
                                law imposes any non-disclaimable duty to protect
                                your personal information, you agree that
                                intentional misconduct will be the standards
                                used to measure our compliance with that duty.
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                {" "}
                                <strong>
                                  Can I update or correct my information?
                                </strong>{" "}
                                <br />
                                <br />
                                Personnel may update or correct their
                                information as detailed in our internal company
                                employment policies. Customers have the right to
                                request the restriction of certain uses and
                                disclosures of personally identifiable
                                information as follows. You can contact us in
                                order to (1) update or correct your personally
                                identifiable information, (2) change your
                                preferences with respect to communications and
                                other information you receive from us, or (3)
                                delete the personally identifiable information
                                maintained about you on our systems (subject to
                                the following paragraph), by cancelling your
                                account. Such updates, corrections, changes and
                                deletions will have no effect on other
                                information that we maintain, or information
                                that we have provided to third parties in
                                accordance with this Privacy Policy prior to
                                such update, correction, change or deletion. To
                                protect your privacy and security, we may take
                                reasonable steps (such as requesting a unique
                                password) to verify your identity before
                                granting you profile access or making
                                corrections. You are responsible for maintaining
                                the secrecy of your unique password and account
                                information at all times. You should be aware
                                that it is not technologically possible to
                                remove each and every record of the information
                                you have provided to us from our system. The
                                need to back up our systems to protect
                                information from inadvertent loss means that a
                                copy of your information may exist in a
                                non-erasable form that will be difficult or
                                impossible for us to locate. Promptly after
                                receiving your request, all personal information
                                stored in databases we actively use, and other
                                readily searchable media will be updated,
                                corrected, changed or deleted, as appropriate,
                                as soon as and to the extent reasonably and
                                technically practicable. If you are an end user
                                and wish to update, delete, or receive any
                                information we have about you, you may do so by
                                contacting the organization of which you are a
                                customer. The rights you have to request updates
                                or corrections to the information we collect
                                depend on your relationship with us.
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                {" "}
                                <strong>Sale of Business</strong> <br />
                                <br />
                                We reserve the right to transfer information to
                                a third party in the event of a sale, merger or
                                other transfer of all or substantially all of
                                the assets of us or any of its Corporate
                                Affiliates (as defined herein), or that portion
                                of us or any of its Corporate Affiliates to
                                which the Service relates, or in the event that
                                we discontinue our business or file a petition
                                or have filed against us a petition in
                                bankruptcy, reorganization or similar
                                proceeding, provided that the third party agrees
                                to adhere to the terms of this Privacy Policy
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                {" "}
                                <strong>Affiliates</strong> <br />
                                <br />
                                We may disclose information (including personal
                                information) about you to our Corporate
                                Affiliates. For purposes of this Privacy Policy,
                                "Corporate Affiliate" means any person or entity
                                which directly or indirectly controls, is
                                controlled by or is under common control with
                                us, whether by ownership or otherwise. Any
                                information relating to you that we provide to
                                our Corporate Affiliates will be treated by
                                those Corporate Affiliates in accordance with
                                the terms of this Privacy Policy.
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                {" "}
                                <strong>
                                  How Long Do We Keep Your Information?
                                </strong>{" "}
                                <br />
                                <br />
                                We keep your information only so long as we need
                                it to provide service to you and fulfill the
                                purposes described in this policy. This is also
                                the case for anyone that we share your
                                information with and who carries out services on
                                our behalf. When we no longer need to use your
                                information and there is no need for us to keep
                                it to comply with our legal or regulatory
                                obligations, we'll either remove it from our
                                systems or depersonalize it so that we can't
                                identify you.
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                {" "}
                                <strong>
                                  How Do We Protect Your Information?
                                </strong>{" "}
                                <br />
                                <br />
                                We implement a variety of security measures to
                                maintain the safety of your personal information
                                when you place an order or enter, submit, or
                                access your personal information. We offer the
                                use of a secure server. All supplied
                                sensitive/credit information is transmitted via
                                Secure Socket Layer (SSL) technology and then
                                encrypted into our Payment gateway providers
                                database only to be accessible by those
                                authorized with special access rights to such
                                systems, and are required to keep the
                                information confidential. After a transaction,
                                your private information (credit cards, social
                                security numbers, financials, etc.) is never
                                kept on file. We cannot, however, ensure or
                                warrant the absolute security of any information
                                you transmit to us or guarantee that your
                                information on the Service may not be accessed,
                                disclosed, altered, or destroyed by a breach of
                                any of our physical, technical, or managerial
                                safeguards.
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                {" "}
                                <strong>Governing Law</strong> <br />
                                <br />
                                The laws of India, excluding its conflicts of
                                law rules, shall govern this Agreement and your
                                use of our service. Your use of our service may
                                also be subject to other local, state, national,
                                or international laws.
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                {" "}
                                <strong>Your Consent</strong> <br />
                                <br />
                                By using our service, registering an account, or
                                making a purchase, you consent to this Privacy
                                Policy.
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                {" "}
                                <strong>Links to Other Websites</strong> <br />
                                <br />
                                This Privacy Policy applies only to the
                                Services. The Services may contain links to
                                other websites not operated or controlled by Us.
                                We are not responsible for the content, accuracy
                                or opinions expressed in such websites, and such
                                websites are not investigated, monitored or
                                checked for accuracy or completeness by us.
                                Please remember that when you use a link to go
                                from the Services to another website, our
                                Privacy Policy is no longer in effect. Your
                                browsing and interaction on any other website,
                                including those that have a link on our
                                platform, is subject to that website’s own rules
                                and policies. Such third parties may use their
                                own cookies or other methods to collect
                                information about you.
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                {" "}
                                <strong>Cookies</strong> <br />
                                <br />
                                We use "Cookies" to identify the areas of our
                                website that you have visited. A Cookie is a
                                small piece of data stored on your computer or
                                mobile device by your web browser. We use
                                Cookies to personalize the Content that you see
                                on our website. Most web browsers can be set to
                                disable the use of Cookies. However, if you
                                disable Cookies, you may not be able to access
                                functionality on our website correctly or at
                                all. We never place Personally Identifiable
                                Information in Cookies.
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                {" "}
                                <strong>Remarketing Services</strong> <br />
                                <br />
                                We use remarketing services. What Is
                                Remarketing? In digital marketing, remarketing
                                (or retargeting) is the practice of serving ads
                                across the internet to people who have already
                                visited your website. It allows your company to
                                seem like they're “following” people around the
                                internet by serving ads on the websites and
                                platforms they use most.
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                {" "}
                                <strong>Payment Details</strong> <br />
                                <br />
                                In respect to any credit card or other payment
                                processing details you have provided us, we
                                commit that this confidential information will
                                be stored in the most secure manner possible.
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                {" "}
                                <strong>Kids' Privacy</strong> <br />
                                <br />
                                We collect information from kids under the age
                                of 13 just to better our services. If You are a
                                parent or guardian and You are aware that Your
                                child has provided Us with Personal Data without
                                your permission, please contact Us. If We become
                                aware that We have collected Personal Data from
                                anyone under the age of 13 without verification
                                of parental consent, we shall take steps to
                                remove that information from our servers.
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                {" "}
                                <strong>
                                  Changes To Our Privacy Policy
                                </strong>{" "}
                                <br />
                                <br />
                                If we decide to change our privacy policy, we
                                will post those changes on this page, and/or
                                update the Privacy Policy modification date
                                below.
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                {" "}
                                <strong>Third-Party Services</strong> <br />
                                <br />
                                We may display, include or make available
                                third-party content (including data,
                                information, applications and other products
                                services) or provide links to third-party
                                websites or services ("Third- Party Services").
                                <br />
                                <br />
                                You acknowledge and agree that we shall not be
                                responsible for any Third-Party Services,
                                including their accuracy, completeness,
                                timeliness, validity, copyright compliance,
                                legality, decency, quality or any other aspect
                                thereof. We do not assume and shall not have any
                                liability or responsibility to you or any other
                                person or entity for any Third-Party Services.
                                <br />
                                <br />
                                Third-Party Services and links thereto are
                                provided solely as a convenience to you and you
                                access and use them entirely at your own risk
                                and subject to such third parties' terms and
                                conditions.
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                {" "}
                                <strong>Facebook Pixel</strong> <br />
                                <br />
                                Facebook pixel is an analytics tool that allows
                                you to measure the effectiveness of your
                                advertising by understanding the actions people
                                take on your website. You can use the pixel to:
                                Make sure your ads are shown to the right
                                people. Facebook pixel may collect information
                                from your device when you use the service.
                                Facebook pixel collects information that is held
                                in accordance with its Privacy Policy.
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                {" "}
                                <strong>Tracking Technologies</strong> <br />
                                <br />
                                <strong style={{ color: "#767373" }}>
                                  Cookies :
                                </strong>
                                We use Cookies to enhance the performance and
                                functionality of our service but are
                                non-essential to their use. However, without
                                these cookies, certain functionality like videos
                                may become unavailable or you would be required
                                to enter your login details every time you visit
                                our service as we would not be able to remember
                                that you had logged in previously.
                                <br />
                                <br />
                                <strong style={{ color: "#767373" }}>
                                  Local Storage :
                                </strong>
                                Local Storage sometimes known as DOM storage,
                                provides web apps with methods and protocols for
                                storing client-side data. Web storage supports
                                persistent data storage, similar to cookies but
                                with a greatly enhanced capacity and no
                                information stored in the HTTP request header.
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                {" "}
                                <strong>
                                  Information about General Data Protection
                                  Regulation (GDPR)
                                </strong>{" "}
                                <br />
                                <br />
                                We may be collecting and using information from
                                you if you are from the European Economic Area
                                (EEA), and in this section of our Privacy Policy
                                we are going to explain exactly how and why is
                                this data collected, and how we maintain this
                                data under protection from being replicated or
                                used in the wrong way.
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                {" "}
                                <strong>What is GDPR?</strong> <br />
                                <br />
                                GDPR is an EU-wide privacy and data protection
                                law that regulates how EU residents' data is
                                protected by companies and enhances the control
                                the EU residents have, over their personal data.
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                {" "}
                                <strong>What is personal data?</strong> <br />
                                <br />
                                Any data that relates to an identifiable or
                                identified individual. GDPR covers a broad
                                spectrum of information that could be used on
                                its own, or in combination with other pieces of
                                information, to identify a person. Personal data
                                extends beyond a person’s name or email address.
                                Some examples include financial information,
                                political opinions, genetic data, biometric
                                data, IP addresses, physical address, sexual
                                orientation, and ethnicity. The Data Protection
                                Principles include requirements such as:
                                <br />
                                <br />
                                1. Personal data collected must be processed in
                                a fair, legal, and transparent way and should
                                only be used in a way that a person would
                                reasonably expect.
                                <br />
                                2. Personal data should only be collected to
                                fulfil a specific purpose and it should only be
                                used for that purpose. Organizations must
                                specify why they need the personal data when
                                they collect it.
                                <br />
                                3. Personal data should be held no longer than
                                necessary to fulfil its purpose.
                                <br />
                                4. People covered by the GDPR have the right to
                                access their own personal data. They can also
                                request a copy of their data, and that their
                                data be updated, deleted, restricted, or moved
                                to another organization.
                                <br />
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                {" "}
                                <strong>Why is GDPR important?</strong> <br />
                                <br />
                                GDPR adds some new requirements regarding how
                                companies should protect individuals' personal
                                data that they collect and process. It also
                                raises the stakes for compliance by increasing
                                enforcement and imposing greater fines for
                                breach. Beyond these facts it's simply the right
                                thing to do. At Help Scout we strongly believe
                                that your data privacy is very important and we
                                already have solid security and privacy
                                practices in place that go beyond the
                                requirements of this new regulation.
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                {" "}
                                <strong>
                                  Individual Data Subject's Rights - Data
                                  Access, Portability and Deletion
                                </strong>{" "}
                                <br />
                                <br />
                                We are committed to helping our customers meet
                                the data subject rights requirements of GDPR. We
                                process or store all personal data in fully
                                vetted, DPA compliant vendors. We do store all
                                conversation and personal data for up to 6 years
                                unless your account is deleted. In which case,
                                we dispose of all data in accordance with our
                                Terms of Service and Privacy Policy, but we will
                                not hold it longer than 60 days.
                                <br />
                                <br />
                                We are aware that if you are working with EU
                                customers, you need to be able to provide them
                                with the ability to access, update, retrieve and
                                remove personal data. We got you! We've been set
                                up as self service from the start and have
                                always given you access to your data and your
                                customers data. Our customer support team is
                                here for you to answer any questions you might
                                have about working with the API.
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                {" "}
                                <strong>Contact Us</strong> <br />
                                <br />
                                In case of any questions/queries you can connect
                                with us,
                                <br />
                                Via Email: <a href="Support@bedwell.in "></a>{" "}
                                Support@bedwell.in
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* <!-- Single Tab Content End -->
                                        <!-- Single Tab Content Start --> */}

                        {/* <!-- Single Tab Content End -->
                                        <!-- Single Tab Content Start --> */}

                        {/* <!-- Single Tab Content End -->
                                        <!-- Single Tab Content Start --> */}

                        {/* <!-- Single Tab Content End --> */}
                        {/* <!-- Single Tab Content Start --> */}
                        <div
                          className="tab-pane fade"
                          id="refund"
                          role="tabpanel"
                        >
                          <div className="myaccount-content">
                            <h3>Refund Policy</h3>
                            <div className="welcome">
                              <p>
                                Updated at <strong>01-05-2022</strong>{" "}
                              </p>
                            </div>
                            <div className="welcome">
                              <p>
                                {" "}
                                <strong>Definitions and key terms</strong>{" "}
                              </p>
                              <strong style={{ color: "#767373" }}>
                                Company:{" "}
                              </strong>{" "}
                              when this policy mentions “Company,” “we,” “us,”
                              or “our,” it refers to BOMBAY PRINTING MILLS, 18
                              SENGOTTAIYAN NAGAR, ERODE 3 that is responsible
                              for your information under this Return & Refund
                              Policy.
                              <br />
                              <br />
                              <strong style={{ color: "#767373" }}>
                                Customer:{" "}
                              </strong>{" "}
                              refers to the company, organization or a person
                              that signs up to use the Service to manage the
                              relationships with your consumers or service
                              users.
                              <br />
                              <br />
                              <strong style={{ color: "#767373" }}>
                                Device:{" "}
                              </strong>{" "}
                              any internet connected device such as a phone,
                              tablet, computer or any other device that can be
                              used to visit and use the services.
                              <br />
                              <br />
                              <strong style={{ color: "#767373" }}>
                                Products:{" "}
                              </strong>{" "}
                              refers to the products provided by Bedwell as
                              described in the relative terms (if available) and
                              on this platform.
                              <br />
                              <br />
                              <strong style={{ color: "#767373" }}>
                                Service:{" "}
                              </strong>{" "}
                              refers to the service provided by Bedwell as
                              described in the relative terms (if available) and
                              on this platform.
                              <br />
                              <br />
                              <strong style={{ color: "#767373" }}>
                                Website:{" "}
                              </strong>{" "}
                              Bedwell’s site, which can be accessed via this
                              URL:{" "}
                              <strong>
                                <a href="https://www.bedwell.in.">Bedwell</a>
                              </strong>
                              <br />
                              <br />
                              <strong style={{ color: "#767373" }}>
                                You:{" "}
                              </strong>{" "}
                              a person or entity that is registered with Bedwell
                              to use the Services.
                            </div>

                            <div className="welcome">
                              <br />
                              <p>
                                <strong>Return & Refund Policy</strong>
                                <br />
                                <br />
                                Thanks for shopping at Bedwell. We appreciate
                                the fact that you like to buy the stuff we
                                build. We also want to make sure you have a
                                rewarding experience while you’re exploring,
                                evaluating, and purchasing our products.
                                <br />
                                <br />
                                As with any shopping experience, there are terms
                                and conditions that apply to transactions at
                                Bedwell. The main thing to remember is that by
                                placing an order or making a purchase at
                                Bedwell, you agree to the terms set forth below
                                along with Bedwell’s Privacy Policy.
                                <br />
                                <br />
                                If there’s something wrong with the product you
                                bought, or if you are not happy with it, you
                                have to issue a return request for refund and
                                return of your item, on the website.
                                <br />
                                <br />
                                If you would like to return a product, the only
                                way would be if you follow the next guidelines:
                                <br />
                                <br />
                                1. The package needs to be sent back with the
                                original tags, packaging items and contents as
                                that arrived, at the time of the purchase. The
                                Website offers certain products customized to
                                customer’s personal usage, based on input values
                                taken in the course of order generation and such
                                products are customized to fit exact dimensions.
                                These products are non – returnable, in any
                                case, as it modified from the original for
                                customer’s better experience
                              </p>
                            </div>
                            <div className="welcome">
                              <br />
                              <p>
                                <strong>Refunds</strong>
                                <br />
                                <br />
                                We at Bedwell commit ourselves to serving our
                                customers with the best products. Every single
                                product that you choose is thoroughly inspected,
                                checked for defects and packaged with utmost
                                care. We do this to ensure that you fall in love
                                with our products.
                                <br />
                                <br />
                                Sadly, there are times when we may not have the
                                product(s) that you choose in stock, or may face
                                some issues with our inventory and quality
                                check. In such cases, we may have to cancel your
                                order. You will be intimated about it in advance
                                so that you don't have to worry unnecessarily
                                about your order. If you have purchased via
                                Online payment (not Cash on Delivery), then you
                                will be refunded once our team confirms your
                                request.
                                <br />
                                <br />
                                We carry out thorough quality check before
                                processing the ordered item. We take utmost care
                                while packing the product. At the same time, we
                                ensure that the packing is good such that the
                                items won’t get damaged during transit. Please
                                note that Bddwell is not liable for damages that
                                are caused to the items during transit or
                                transportation.
                                <br />
                                <br />
                                We will examine your returned product as soon as
                                we receive it and if it follows the guidelines
                                addressed above, we will proceed to issue a
                                refund of your purchase. Your refund may take a
                                couple of days to process but you will be
                                notified when you receive your money.
                              </p>
                            </div>
                            <div className="welcome">
                              <br />
                              <p>
                                <strong>Shipping</strong>
                                <br />
                                <br />
                                All Return shipment from the customer will be
                                charged with a minimum flat return fee as
                                indicated on the website and will be borne by
                                the customer.
                              </p>
                            </div>
                            <div className="welcome">
                              <br />
                              <p>
                                <strong>
                                  Product Availability and Limitations
                                </strong>
                                <br />
                                <br />
                                Given the popularity and/or supply constraints
                                of some of our products, Bedwell may have to
                                limit the number of products available for
                                purchase. Trust us, we’re building them as fast
                                as we can. Bedwell reserves the right to change
                                quantities available for purchase at any time,
                                even after you place an order. Furthermore,
                                there may be occasions when Bedwell confirms
                                your order but subsequently learns that it
                                cannot supply the ordered product. In the event
                                we cannot supply a product you ordered, Bedwell
                                will cancel the order and refund your purchase
                                price in full.
                              </p>
                            </div>
                            <div className="welcome">
                              <br />
                              <p>
                                <strong>Your Consent</strong>
                                <br />
                                <br />
                                By using our platform, registering an account,
                                or making a purchase, you hereby consent to our
                                Return & Refund Policy and agree to its terms.
                              </p>
                            </div>
                            <div className="welcome">
                              <br />
                              <p>
                                <strong>
                                  Changes To Our Return & Refund Policy
                                </strong>
                                <br />
                                <br />
                                Should we update, amend or make any changes to
                                this document so that they accurately reflect
                                our Service and policies. Unless otherwise
                                required by law, those changes will be
                                prominently posted here. Then, if you continue
                                to use the Service, you will be bound by the
                                updated Return & Refund Policy. If you do not
                                want to agree to this or any updated Return &
                                Refund Policy, you can delete your account.
                              </p>
                            </div>
                            <div className="welcome">
                              <br />
                              <p>
                                <strong>Contact Us</strong>
                                <br />
                                <br />
                                If, for any reason, You are not completely
                                satisfied with any product or service that we
                                provide, don't hesitate to contact us and we
                                will discuss any of the issues you are going
                                through with our product.
                                <br />
                                Via Email:{" "}
                                <strong>
                                  <a href=" Support@bedwell.in "> Bedwell</a>
                                </strong>
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* <!-- Single Tab Content End --> */}
                      </div>
                    </div>
                    {/* <!-- My Account Tab Content End --> */}
                  </div>
                </div>
                {/* <!-- My Account Page End --> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsCondtions;
