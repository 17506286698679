import React,{Suspense, lazy} from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Categories from '../Components/Categories';
import Footer from '../Components/Common/Footer';
import Header from '../Components/Common/Header';

import Home from '../Components/Home';
import Products from '../Components/Products';
import Blog from "../Components/blogs/Blog"
import BlogDetails from "../Components/blogs/BlogDetails"
import ContactUs from '../Components/ContactUs';
import AboutUs from '../Components/AboutUs';
import Login from '../Components/Authentications/Login';
// import LoginPage from '../Components/Authentications/LoginPage';
import Register from '../Components/Authentications/Register';
import ForgetPassword from '../Components/Authentications/ForgetPassword';
import VerifyPassword from '../Components/Authentications/VerifyPassword';
import Profile from "../Components/Profiles/Profile.jsx";
import UpdateProfile  from "../Components/Profiles/UpdateProfile";
import OrdersDetails  from "../Components/Orders/OrdersDetails";

import UpdateAddress from "../Components/Profiles/UpdateAddress";
import SearchProducts from '../Components/SearchProducts';
import CategoryShop from '../Components/CategoryShop';
import Cart from '../Components/Cart';
import WishList from '../Components/WishList';
import ProductDetails from '../Components/ProductDetails';
import Faq from '../Components/Faq';
import PrivacyPolicy from '../Components/Informations/PrivacyPolicy';
import TermsCondtions from '../Components/Informations/TermsCondtions';
import RefundPolicy from '../Components/Informations/RefundPolicy';
import Checkout from '../Components/Checkout';
import OrderSuccess from '../Components/Orders/OrderSuccess';
import Orders from '../Components/Profiles/Orders';
import NotFound from '../Components/NotFound';
import ReviewsTotal from '../Components/Orders/ReviewsTotal';
import AddAddress from '../Components/Profiles/AddAddress';
import LoginWithPhone from '../Components/Authentications/LoginWithPhone';
import VerifyPhone from '../Components/Authentications/VerifyPhone';
import UpdateReview from '../Components/Orders/UpdateReview';
import ReviewsList from '../Components/Orders/ReviewsList';
import RefundOrder from '../Components/Orders/RefundOrder';
import ReturnsList from '../Components/Orders/ReturnsList';
import BankDetails from '../Components/Orders/BankDetails';
import CartPhoneOtp from '../Components/Authentications/CartPhoneOtp';
import CartPhoneVerify from '../Components/Authentications/CartPhoneVerify';



const MainRoutes = () => {
  return (
    <>
        



        <Router>
        <Suspense
          fallback={
            <div className="flone-preloader-wrapper">
              <div className="flone-preloader">
                <span></span>
                <span></span>
              </div>
            </div>
          }
        >
          <Header />
          <Routes>
           
            <Route exact path={"/"} element={<Home />} />
            
          
            <Route exact path={"/products"} element={<Products />} />
            <Route exact path={"/orderDetails/:id"} element={<OrdersDetails />} />
            <Route exact path={"/returnDetails/:id"} element={<RefundOrder />} />
            <Route exact path={"/returns"} element={<ReturnsList />} />
            <Route exact path={"/productDetails/:id"} element={<ProductDetails />} />
            <Route exact path={"/search/:id"} element={<SearchProducts />} />
            <Route exact path={"/searchProduct"} element={<SearchProducts />} />
            <Route exact path={"/categories"} element={<Categories />} />
            <Route exact path={"/categoryShop/:id"} element={<CategoryShop />} />
            <Route exact path={"/blog"} element={<Blog />} />
            <Route exact path={"/blogDetails"} element={<BlogDetails />} />
            <Route exact path={"/contactUs"} element={<ContactUs />} />
            <Route exact path={"/aboutUs"} element={<AboutUs />} />
            <Route exact path={"/login"} element={<Login />} />
            {/* <Route exact path={"/loginPage"} element={<LoginPage />} /> */}
            <Route exact path={"/register"} element={<Register />} />
            <Route exact path={"/forget"} element={<ForgetPassword />} />
            <Route exact path={"/verify/:id"} element={<VerifyPassword />} />
            <Route exact path={"/profile"} element={<Profile />} />
            <Route exact path={"/updateProfile/:id"} element={<UpdateProfile />} />
            <Route exact path={"/updateAddress/:id"} element={<UpdateAddress />} />
            <Route exact path={"/cart"} element={<Cart />} />
            <Route exact path={"/wishList"} element={<WishList />} />
            <Route exact path={"/faq"} element={<Faq />} />
            <Route path={"/privacy"} element={<PrivacyPolicy />} />
            <Route path={"/terms"} element={<TermsCondtions />} />
            <Route path={"/refund"} element={<RefundPolicy />} />
            <Route path={"/checkout"} element={<Checkout />} />
            <Route path={"/orderSuccess"} element={<OrderSuccess />} />
            <Route path={"/orders"} element={<Orders />} />
            <Route path={"/reviewstotal"} element={<ReviewsTotal />} />
            <Route path={"/addAddress"} element={<AddAddress />} />
            <Route path={"/loginWithPhone"} element={<LoginWithPhone />} />
            <Route path={"/verifyPhone/:id"} element={<VerifyPhone />} />
            {/* cart phone verify */}
            <Route path={"/phoneOtp"} element={<CartPhoneOtp />} />
            <Route path={"/verifyPhoneOtp/:id"} element={<CartPhoneVerify />} />
            {/* end cart phone roues */}
            <Route path={"/reviewstotal"} element={<ReviewsList />} />
            <Route path={"/bankDetails/:id/:type"} element={<BankDetails />} />
            {/* <Route path={"/test"} element={<TestData />} /> */}
            <Route path={"/updateReview/:id"} element={<UpdateReview />} />
           
            <Route path="*" element={<NotFound/>} />
         
            
            
          </Routes>
          <Footer />
        </Suspense>
      </Router>

    </>
  )
}

export default MainRoutes
