import React,{ useEffect,useState} from 'react'
import { Link,useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {getCategories} from "../Redux/Action"
import img from "../images/No.jpg"

const Categories = () => {
    const [load,setLoad]=useState(true);
    const dispatch = useDispatch();
    const category = useSelector(({getCategories}) =>getCategories.payload);
  
    useEffect(() => {
      dispatch(getCategories())
      .then(res => setLoad(false));
    }, []);
  
      let navigate = useNavigate();
  
      const catshop = (id) =>{
          navigate("/categoryShop/"+id)
      }
  return load ? (
    <div
      style={{
        height: "400px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : (
    <>
       <div class="banner-area " >
          <div class="container">
            <div class="section-title-tab-wrap mb-40 mt-5">
              <div
                class="section-title-2 "
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <h2 style={{textTransform:"uppercase"}}>Categories</h2>
              </div>
            </div>
            <div id="hp-ctn-howItWorks"  >
        <a href="/contactUs">
          <p class="c" >Bulk&nbsp;Orders </p>
          </a>
        </div>
                
                <div class="row">
                {Array.isArray(category) && category?.length !==0 ? category?.map((data)=>(
                    <div class="col-lg-2 col-md-4 col-sm-4 col-12">
                        <div class="single-team-wrap text-center mb-30" data-aos="fade-up" data-aos-delay="200">
                        <a  onClick={()=>catshop(data?.id)}>
                            <img src={process.env.REACT_APP_BASE_URL+data?.categoryImage} alt={data?.categoryName}
                            onError={ ({ currentTarget }) => {
                              currentTarget.onerror = null; 
                              currentTarget.src=img
                            }}
                            />
                            </a>
                            <div class="team-info-position">
                                <div class="team-info">
                                    <h5>
                                      
                                    <a style={{textTransform: "capitalize",fontSize:"15px",fontWeight:"600"}} onClick={()=>catshop(data?.id)}>
                            {data.categoryName}
                          </a>
                                      </h5>
                                    {/* <span>Sales Man</span> */}
                                </div>
                            </div>
                        </div>
                    </div>
                     )):(
                      <center className="pb-5 mb-5">
                        <img
                          src={img}
                          className="mt-5 mb-5"
                          style={{ width: "25%" }}
                        />
                        <h3 className="container">"No Categories Found"</h3>
                      </center>
                    )}
                </div>
         
          </div>
        </div>
    </>
  )
}

export default Categories
