import React, { useState, useEffect } from "react";
import { getOrderReturns } from "../../Redux/Action";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import orderimage from "../../images/orders.svg";
const ReturnsList = () => {
  function numberWithCommas(x) {
    return x.toString().split(',')[0].length > 3 ? x.toString().substring(0,x.toString().split('.')[0].length-3).replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + x.toString().substring(x.toString().split('.')[0].length-3): x.toString();
}
    const dispatch = useDispatch();
    const [load, setLoad] = useState(true);
    const token =localStorage.getItem("AuthTok");
    if(token === null || token === "undefined" || token === ""){
      window.location.href = "/login";
    }
    useEffect(() => {
      dispatch(getOrderReturns())
        .then((res) => setLoad(false))
        .catch((res) => setLoad(false));
    }, []);
    const order = useSelector(({ getOrderReturns }) => getOrderReturns.payload);

  return load ? (
    <div
      style={{
        height: "400px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : (
    <div>
      <div className="container">
        {Array.isArray(order) && order.length !== 0 ?<h3 style={{ textAlign: "center",marginBottom:"25px" }}>Returns List</h3>:false}
      
        <div className="row">
          <div className="col-12">
            <form action="#">
              <div className="cart-table-content">
                <div className="table-content table-responsive">
                  <table>
                    {Array.isArray(order) && order.length !== 0 ? (
                      <thead>
                        <tr>
                          {/* <th className="width-thumbnail">S.No</th> */}

                          {/* {profileList?.profilePic  ? */}
                          <th className="width-thumbnail">S.No</th>

                          {/* : false} */}
                          <th className="width-price">Return Id</th>
                          <th className="width-subtotal"> Product Name</th>
                          <th className="width-thumbnail"> Product Image</th>
                          <th className="width-quantity">  Units</th>
                          <th className="width-subtotal"> Status</th>
                          <th className="width-subtotal"> Amount</th>
                          <th className="width-price">Timestamp</th>
                          <th className="width-subtotal"> Return Details</th>
                        </tr>
                      </thead>
                    ) : (
                      false
                    )}
                    <tbody>
                      <>
                        {Array.isArray(order) && order.length !== 0 ? (
                          order?.map((data, i) => (
                            <tr>
                              <td className="product-name">{i + 1}</td>
                              <td className="product-name">
                                <h5>
                                 
                                    {data.returnId}
                                
                                </h5>
                              </td>
                              <td className="product-name">
                                <h5>
                                <Link
                                    to={"/returnDetails/"+data.returnId}
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {data?.productName}
                                    </Link>
                                </h5>
                              </td>
                              <td className="product-thumbnail">
                                <Link to={"/returnDetails/"+data.returnId}>
                                  <img
                                    src={
                                      // process.env.REACT_APP_BASE_URL+
                                      process.env.REACT_APP_BASE_URL +
                                      data.variantImages
                                    }
                                    alt=""
                                  />
                                </Link>
                              </td>

                              <td className="product-name">
                                <h5>
                                  
                                    {data?.units}
                                
                                </h5>
                              </td>
                              <td className="product-name">
                                <h5 style={{
                                      textTransform: "capitalize",
                                    }}>
                                  
                                    <span style={{fontSize:"14px",fontWeight:"600"}} className="badge rounded-pill bg-success"> {data?.orderStatus}</span>
                                   
                                 
                                </h5>
                              </td>
                              <td className="product-name">
                                <h5>
                                  {/* <a
                                    style={{
                                      textTransform: "capitalize",
                                    }}
                                  > */}
                                   ₹  {numberWithCommas(data?.paidAmount-40)}
                                  {/* </a> */}
                                </h5>
                              </td>
                              <td className="product-name">
                                <h5>
                                  
                                    {data.timestamp}
                                  {/* </Link> */}
                                </h5>
                              </td>
                              {/* <td className="product-name">
                                <h5>
                                  <a
                                    href="product-details.html"
                                    style={{
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {data?.timestamp}
                                  </a>
                                </h5>
                              </td> */}
                              <td>
                                {/* <div className="single-product-cart btn-hover">
                                  <a
                                    href={"/returnDetails/" + data.returnId}
                                    style={{
                                      // fontSize: "15px",
                                      // paddingTop: "5px",
                                      // paddingBottom: "5px",
                                      // paddingleft: "20px",
                                      // textAlign: "center",
                                      color: "white",
                                      width: "100%",
                                      borderRadius:"20px"
                                    }}
                                  >
                                    <span
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      Track
                                    </span>{" "}
                                  </a>
                                </div> */}
                                <span style={{fontSize:"14px",fontWeight:"bold"}} className="badge rounded-pill bg-danger"> 
                                
                                <a href={"/returnDetails/"+data.returnId} style={{color:"white"}}> Track</a>
                                </span>
                                {/* <div className="row" style={{justifyContent: "center"}}>
                                        <div className="col-lg-12">
                                        <span className="badge bg-danger">
                          <Link to={"/orderDetails/" + data.orderId} style={{ color: "white" }} title="OrderDetails">
                         
                          <i className="pe-7s-look" style={{fontSize:"20px"}}></i>
                          </Link>
                        </span>
                                        </div>
                                        
                                      </div> */}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <center>
                            <img
                              src={orderimage}
                              className="mt-5 mb-5 pb-5 pt-5"
                              style={{ width: "25%" }}
                            />
                            <h3> No Returns Found</h3>{" "}
                          </center>
                        )}
                      </>
                    </tbody>
                  </table>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReturnsList