import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { addCart } from "../Redux/ActionType";
import { getWishLists, addWishLists, addCarts } from "../Redux/Action";
import wishlistimage from "../images/wishlist.svg";
const WishList = () => {
  function numberWithCommas(x) {
    return x.toString().split(",")[0].length > 3
      ? x
          .toString()
          .substring(0, x.toString().split(".")[0].length - 3)
          .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          "," +
          x.toString().substring(x.toString().split(".")[0].length - 3)
      : x.toString();
  }
  const [load, setLoad] = useState(true);
  const [render, setRender] = useState(true);
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(1);
  const wish = useSelector(({ getWishLists }) => getWishLists.payload);
  const carts = useSelector(({ addCarts }) => addCarts.payload);
  const [cartId, getCartId] = useState({
    productId: "",
    units: 0,
  });
  const handleClick = () => {
    cartId.productId = wish.id;
    cartId.units = quantity;

    dispatch(addCarts(cartId)).then((res) => {});
  };
  useEffect(() => {
    dispatch(getWishLists())
      .then((res) => setLoad(false))
      .catch((res) => setLoad(false));
  }, [render]);
  return load ? (
    <div
      style={{
        height: "400px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : (
    <div>
      <div className="main-wrapper main-wrapper-2">
        <div className="breadcrumb-area bg-gray-4 pt-5">
          <div className="container">
            <div className="breadcrumb-content text-center">
              <h2 style={{ textTransform: "uppercase" }}>Wishlist</h2>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <i className="ti-angle-right"></i>
                </li>
                <li>Wishlist</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="wishlist-area">
          <div className="container">
            <div id="hp-ctn-howItWorks">
              <a href="/contactUs">
                <p className="c">Bulk&nbsp;Orders </p>
              </a>
            </div>

            <div className="row">
              <div className="row mb-55">
                {Array.isArray(wish?.data) ? (
                  wish?.data?.map((pro, i) => (
                    <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                      <div className="section-title-tab-wrap mb-40"></div>
                      <div
                        className="product-wrap mb-35"
                        data-aos="fade-up"
                        data-aos-delay="200"
                      >
                        <div
                          className="product-img img-zoom mb-25"
                          style={{ borderRadius: "16px" }}
                        >
                          <Link to={"/productDetails/" + pro?.id}>
                            <img
                              style={{ textTransform: "capitalize" }}
                              src={
                                process.env.REACT_APP_BASE_URL +
                                pro?.productImage
                              }
                              alt={pro?.productName}
                            />
                          </Link>

                          <div className="product-action-wrap">
                            <button
                              className="product-action-btn-1"
                              title="Delete"
                              // onClick={() => SingleProduct(pro.id)}
                            >
                              <a
                                onClick={() => {
                                  setLoad(true);
                                  dispatch(
                                    addWishLists({ productId: pro.id })
                                  ).then(
                                    (res) => setRender(!render),
                                    setLoad(false)
                                  );
                                }}
                              >
                                {" "}
                                <i className=" ti-trash "></i>{" "}
                              </a>
                            </button>
                            {/* <button
                                        className="product-action-btn-1"
                                        title="Add To Cart"
                                        
                                       
                                        
                                      >
                                          <a  onClick={handleClick}>  <i className='bx bx-cart'></i> </a>
                                       
                                      </button> */}
                          </div>
                        </div>

                        <div className="product-content">
                          <h3 style={{ textTransform: "capitalize" }}>
                            <Link to={"/productDetails/" + pro?.id}>
                              {pro?.productName}
                            </Link>
                          </h3>
                          <div className="product-price">
                            <span className="old-price">
                              {" "}
                              ₹{numberWithCommas(pro?.actualPrice)}
                            </span>
                            <span className="new-price">
                              ₹{numberWithCommas(pro?.discountPrice)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <center>
                    <img
                      src={wishlistimage}
                      className="mt-5 mb-5"
                      style={{ width: "25%" }}
                    />
                    <h3 className="mb-5 pb-3"> No Wishlist Found</h3>
                  </center>
                )}
              </div>

              {/* <div className="col-12">
                        <form action="#">
                            <div className="wishlist-table-content">
                                <div className="table-content table-responsive">
                                    <table>
                                        <thead>
                                        {wish?.data && wish?.data?.length !== 0 ? (
                                            <tr>
                                                
                                                <th className="width-thumbnail"></th>
                                                <th className="width-name">Product</th>
                                                <th className="width-price"> Unit price </th>
                                                <th className="width-price"> </th>
                                             
                                            </tr>
                                               ) : (
                                                false
                                              )}  
                                        </thead>
                                        {wish?.data && wish?.data?.length !== 0 ? (
                      wish?.data?.map((w, i) => (
                                        <tbody>
                                            <tr>
                                                
                                                <td className="product-thumbnail">
                                                    <Link to={"/productDetails/" + w.id }>
                                                        <img src={process.env.REACT_APP_BASE_URL+w.productImage} alt=""/></Link>
                                                       
                                                </td>
                                                <td className="product-name">
                                                    <h5><Link to={"/productDetails/" + w.id }>
                                                        {w.productName}
                                                      
                                                        </Link></h5>
                                                </td>
                                                <td className="product-wishlist-price">
                                                    <span className="amount">
                                                
                                                    
                                                    {typeof w.discountPrice == "number" ? (
                                <>
                                ${w.discountPrice}
                                </>   
                                ) : (
                                  <span
                                    style={{ color: "red", fontWeight: "bold" }}
                                  >
                                    Product Currently Not Available
                                  </span>
                                )}
                              </span>             
                                                    </td>
                                                    <td className="product-remove"><a  onClick={() => {
                                  setLoad(true);
                                  dispatch(
                                    addWishLists({ productId: w.id })
                                  ).then((res) => setRender(!render), setLoad(false))
                                }}>   <i className=" ti-trash "></i> </a></td>
                                               
                                            </tr>
                                        
                                        </tbody>
                                        ))
                                        ) : (
                                          <center>
                                            <img
                                              src={wishlistimage}
                                              className="mt-5 mb-5"
                                              style={{ width: "25%" }}
                                            />
                                            <h3 className="mb-5 pb-3"> No Wishlist Found</h3>
                                          </center>
                                        )}
                                    </table>
                                </div>
                            </div>
                        </form>
                    </div> */}
            </div>
          </div>
        </div>

        {/* <!-- Product Modal start --> */}
        <div
          className="modal fade quickview-modal-style"
          id="exampleModal"
          tabIndex="-1"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <a
                  href="#"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className=" ti-close "></i>
                </a>
              </div>
              <div className="modal-body">
                <div className="row gx-0">
                  <div className="col-lg-5 col-md-5 col-12">
                    <div className="modal-img-wrap">
                      <img src="assets/images/product/quickview.png" alt="" />
                    </div>
                  </div>
                  <div className="col-lg-7 col-md-7 col-12">
                    <div className="product-details-content quickview-content">
                      <h2>New Modern Chair</h2>
                      <div className="product-details-price">
                        <span className="old-price">$25.89 </span>
                        <span className="new-price">$20.25</span>
                      </div>
                      <div className="product-details-review">
                        <div className="product-rating">
                          <i className=" ti-star"></i>
                          <i className=" ti-star"></i>
                          <i className=" ti-star"></i>
                          <i className=" ti-star"></i>
                          <i className=" ti-star"></i>
                        </div>
                        <span>( 1 Customer Review )</span>
                      </div>
                      <div className="product-color product-color-active product-details-color">
                        <span>Color :</span>
                        <ul>
                          <li>
                            <a title="Pink" className="pink" href="#">
                              pink
                            </a>
                          </li>
                          <li>
                            <a
                              title="Yellow"
                              className="active yellow"
                              href="#"
                            >
                              yellow
                            </a>
                          </li>
                          <li>
                            <a title="Purple" className="purple" href="#">
                              purple
                            </a>
                          </li>
                        </ul>
                      </div>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Integer ornare tincidunt neque vel semper. Cras placerat
                        enim sed nisl mattis eleifend.
                      </p>
                      <div className="product-details-action-wrap">
                        <div className="product-quality">
                          <input
                            className="cart-plus-minus-box input-text qty text"
                            name="qtybutton"
                            value="1"
                          />
                        </div>
                        <div className="single-product-cart btn-hover">
                          <a href="#">Add to cart</a>
                        </div>
                        <div className="single-product-wishlist">
                          <a title="Wishlist" href="#">
                            <i className="pe-7s-like"></i>
                          </a>
                        </div>
                        <div className="single-product-compare">
                          <a title="Compare" href="#">
                            <i className="pe-7s-shuffle"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Product Modal end -->
        <!-- Mobile Menu start --> */}
        <div className="off-canvas-active">
          <a className="off-canvas-close">
            <i className=" ti-close "></i>
          </a>
          <div className="off-canvas-wrap">
            {/* <div className="welcome-text off-canvas-margin-padding">
              <p>Default Welcome Msg! </p>
            </div> */}
            <div className="mobile-menu-wrap off-canvas-margin-padding-2">
              <div id="mobile-menu" className="slinky-mobile-menu text-left">
                <ul>
                  <li>
                    <a href="/">HOME</a>
                  </li>
                  <li>
                    <a href="/products">SHOP</a>
                  </li>
                  <li>
                    <a href="/categories">CATEGORIES </a>
                  </li>

                  <li>
                    <a href="/aboutus">ABOUT US</a>
                  </li>
                  <li>
                    <a href="/contactus">CONTACT US</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WishList;
