import React, { useState, useEffect } from "react";
import { Link, useNavigate,useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { phoneNumberLogin } from "../../Redux/Action";
import "./style.css"

const LoginWithPhone = () => {
  const [phone, setPhone] = useState("");
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Swal = require("sweetalert2");
  useEffect(() => {
    setError(localStorage.getItem("Error"));
  }, []);
  window.$('body').on('keyup', '.js-input-mobile', function () {
    var $input = window.$(this),
        value = $input.val(),
        length = value.length,
        inputCharacter = parseInt(value.slice(-1));

    if (!((length > 0 && inputCharacter >= 0 && inputCharacter <= 10) || (length === 1 && inputCharacter >= 7 && inputCharacter <= 10))) {
        $input.val(value.substring(0, length - 1));
     }
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    e.currentTarget.reset();
    dispatch(phoneNumberLogin({ phone: phone }))
      .then((res) => {
        Swal.fire({
          icon: "success",
          timer: 4000,
          buttons: false,
          showConfirmButton: false,
          text: res.data.data,
        }).then(() => {
          navigate("/verifyPhone/"+phone, { replace: true });
      })
      })
      .catch((err) => 
      {
        Swal.fire({
          icon: "error",
          timer: 4000,
          buttons: false,
          showConfirmButton: false,
          text: "Mobile Number Is Not Valid!",
        }).then(() => {
          navigate("/loginWithPhone", { replace: true });
      })
        // setError(err.response.data.error.message)
        // setError("Mobile Number Is Not Valid")
        // setTimeout(() => {
        //   setError("");
        // }, 5000);
      }
      );
  };
  return (
    <div className="main-wrapper main-wrapper-2">
    
    <div className="login-register-area pb-5 ">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12 offset-lg-2">
            <div className="login-register-wrapper">
              <div className="login-register-tab-list nav" style={{marginTop:"50px"}}>
       
                <a data-bs-toggle="tab" href="#">
                  <h4 style={{textTransform: "uppercase"}}> Login With Phone </h4>
                </a>
               
              </div>
              <div className="tab-content">
                <div id="lg1" className="tab-pane active">
                  <div className="login-form-container">
                    <div className="login-register-form">
                      <form
                       onSubmit={handleSubmit} 
                       method="post">
                        
                      <input
                           type="tel" maxlength="10" className="js-input-mobile"
                           id="zipCode"
                            onChange={(e) => setPhone(e.target.value)}
                       
                            placeholder="Enter Your Phone Number"
                            required
                          />
                        
                          {error?<div className="error-text1 " style={{marginBottom:"-6px"}}>{error + " ⚠️ "} </div>:false}  
                          <div className="login-toggle-btn">
                        
                        <Link to="/login">Login With Email</Link>
                      </div>
                        <div className="button-box btn-hover">
                          {error?<button type="submit" style={{marginTop:"30px"}}>Next</button>:<button type="submit" >Send OTP</button>}
                          
                          
                        </div>

                      </form>
                    </div>
                  </div>
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>


  )
}

export default LoginWithPhone