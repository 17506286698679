import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
// import login from '../../images/'
import "./style.css";

import { getWelcomeMessages } from "../../Redux/Action";
import bedwell_logo from "../../images/bedwell_logo.png";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const Truncate = (string, number) => {
    if (!string) {
      return null;
    }
    if (string.length <= number) {
      return string;
    }
    return string.slice(0, number) + "...";
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getWelcomeMessages());
  }, []);
  const welcome = useSelector(
    ({ getWelcomeMessages }) => getWelcomeMessages.payload
  );
  return (
    <>
      <footer className="footer-area">
        <div className="bg-gray-2">
          <div className="container">
            <div className="footer-top pt-80 pb-35">
              <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                  <div className="footer-widget footer-about mb-40">
                    <div className="footer-logo">
                      <Link to="/">
                        <img
                          src={bedwell_logo}
                          alt="logo"
                          style={{
                            width: "100%",
                            maxWidth: "200px",
                          }}
                        />
                      </Link>
                    </div>

                    <p style={{ textAlign: "justify" }}>
                      {Truncate(welcome?.content, 150)}
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                  <div className="footer-widget footer-widget-margin-1 footer-list mb-40">
                    <h3
                      className="footer-title"
                      style={{ textTransform: "uppercase" }}
                    >
                      Quick Links
                    </h3>
                    <ul>
                      {/* <li><Link to="/aboutus">About Us</Link></li> */}
                      <li>
                        <Link to="/faq">FAQ's</Link>
                      </li>
                      <li>
                        <a href="/privacy">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="/terms">Terms & Conditions</a>
                      </li>
                      <li>
                        <a href="/refund">Refund Policy</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                  <div className="footer-widget footer-widget-margin-2 footer-address mb-40">
                    <h3
                      className="footer-title"
                      style={{ textTransform: "uppercase" }}
                    >
                      Get in touch
                    </h3>
                    <ul>
                      <li>
                        <span> </span>{" "}
                        <a href="mailto:Support@bedwell.in ">
                          Support@bedwell.in
                        </a>{" "}
                      </li>
                      {/* <li>
                        <span></span>{" "}
                        <a href="Tel: +91 7200775000">+91 72007 75000</a>{" "}
                      </li> */}
                      <div className="social-icon-style-4 mt-4">
                        <a
                          href="https://www.facebook.com/profile.php?id=100082606232785"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fa fa-facebook"></i>
                        </a>
                        <a
                          href="https://www.instagram.com/beddwell?r=nametag"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fa fa-instagram"></i>
                        </a>
                        <a
                          href="https://youtube.com/channel/UCJstMZMkkVwZMtl-EUNEC_w"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <i className="fa fa-youtube"></i>
                        </a>
                        {/* <a href="#"><i className="fa fa-twitter"></i></a> */}
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray-3 ">
          <div className="container ">
            <div className="footer-bottom  copyright text-center bg-gray-3">
              {/* Copyright © {currentYear} Eat 'N' Fit Naturals All rights reserved. */}
              <p>
                Copyright © {currentYear}{" "}
                <a href="/" className="me-2">
                OWNED BY BOMBAY PRINTING MILLS
                </a>{" "}
                {""}All rights reserved.{" "}
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
