import React, { useState, useEffect } from "react";
import { getOrders } from "../../Redux/Action";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import orderimage from "../../images/orders.svg";

const OrdersList = () => {
  const token =localStorage.getItem("AuthTok");
  if(token === null || token === "undefined" || token === ""){
    window.location.href = "/login";
  }
  const dispatch = useDispatch();
  const [load, setLoad] = useState(true);

  useEffect(() => {
    dispatch(getOrders())
      .then((res) => setLoad(false))
      .catch((res) => setLoad(false));
  }, []);
  const order = useSelector(({ getOrders }) => getOrders.payload);

  const styles = {
    textAlign: "center",
    color: "black",
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <form action="#">
              <div className="cart-table-content">
                <div className="table-content table-responsive">
                  <table>
                    {Array.isArray(order) && order.length !== 0 ? (
                      <thead>
                        <tr>
                          {/* <th className="width-thumbnail">S.No</th> */}

                          {/* {profileList?.profilePic  ? */}
                          <th className="width-thumbnail">S.No</th>

                          {/* : false} */}
                          <th className="width-price">Order Id</th>
                          <th className="width-thumbnail"> Order Image</th>
                          {/* <th className="width-quantity">  District</th> */}
                          <th className="width-subtotal"> Order Status</th>
                          <th className="width-subtotal"> Order Date</th>
                          <th className="width-subtotal"> Order Details</th>
                        </tr>
                      </thead>
                    ) : (
                      false
                    )}
                    <tbody>
                      <>
                        {Array.isArray(order) && order.length !== 0 ? (
                          order?.map((data, i) => (
                            <tr>
                              <td className="product-name">{i + 1}</td>
                              <td className="product-name">
                                <h5>
                                  <Link
                                    to={"/orderDetails/" + data.orderId}
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {data.orderId}
                                  </Link>
                                </h5>
                              </td>
                              <td className="product-thumbnail">
                                <Link to={"/orderDetails/" + data.orderId}>
                                  <img
                                    src={
                                      // process.env.REACT_APP_BASE_URL+
                                      process.env.REACT_APP_BASE_URL +
                                      data.variantImages[0]
                                    }
                                    alt=""
                                  />
                                </Link>
                              </td>

                              <td className="product-name">
                                <h5>
                                  <a
                                    style={{
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {data?.orderStatus}
                                  </a>
                                </h5>
                              </td>
                              <td className="product-name">
                                <h5>
                                  <a
                                
                                    style={{
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {data?.timestamp}
                                  </a>
                                </h5>
                              </td>
                              <td>
                              <span style={{fontSize:"14px",fontWeight:"bold"}} className="badge rounded-pill bg-danger"> 
                                
                                <a href={"/orderDetails/"+data.orderId} style={{color:"white"}}> Track</a>
                                </span>
                                {/* <div className="row" style={{justifyContent: "center"}}>
                                        <div className="col-lg-12">
                                        <span className="badge bg-danger">
                          <Link to={"/orderDetails/" + data.orderId} style={{ color: "white" }} title="OrderDetails">
                         
                          <i className="pe-7s-look" style={{fontSize:"20px"}}></i>
                          </Link>
                        </span>
                                        </div>
                                        
                                      </div> */}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <center>
                            <img
                              src={orderimage}
                              className="mt-5 mb-5 pb-5 pt-5"
                              style={{ width: "25%" }}
                            />
                            <h3> No Orders Found</h3>{" "}
                          </center>
                        )}
                      </>
                    </tbody>
                  </table>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrdersList;
