import React, { useEffect, useState } from "react";
import { checkOuts, payNows, getUserAddress, getCarts } from "../Redux/Action";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import cartimage from "../images/shopping_cart.svg";
import { CircularProgress } from "@mui/material";
import "./style.css";
import axios from "axios";
import { apiCalls } from "../Axios/Services";
const Checkout = () => {
  const formatter = new Intl.NumberFormat("en-IN", {
    maximumSignificantDigits: 3,
  });

  function numberWithCommas(x) {
    return x.toString().split(",")[0].length > 3
      ? x
          .toString()
          .substring(0, x.toString().split(".")[0].length - 3)
          .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          "," +
          x.toString().substring(x.toString().split(".")[0].length - 3)
      : x.toString();
  }
  const [error, setError] = useState("");
  const [load, setLoad] = useState(true);
  const [paymentLoad, setPaymentLoad] = useState(false);
  const [paymentError, setPaymentError] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [totalPrice, setTotalPrice] = useState("");
  const navigate = useNavigate();
  const [addressId, setAddressId] = useState("");
  const [deliveryType, setDeliveryType] = useState("");
  const [payment, setPayment] = useState({ addressId: "", deliveryType: "" });

  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState(false);
  const [render, setRender] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  useEffect(() => {
    dispatch(getUserAddress());
  }, []);

  useEffect(() => {
    dispatch(getCarts())
      .then((res) => {
        setLoad(false);
      })
      .catch((res) => setLoad(false));
  }, [render]);
  // useEffect(() => {
  //   apiCalls("get", "/user/cart").then((res)=>{
  //     console.log("ressss",typeof res.data.data.discount);
  //     setTotalPrice(res.data.data.totalPrice+40)
  //   })
  // }, [])

  const Address = useSelector(({ getUserAddress }) => getUserAddress.payload);
  const getCart = useSelector(({ getCarts }) => getCarts.payload);
  //  console.log("get",getCart.totalPrice);

  // const handleAddress = (e) => {
  //   setAddressId({  [e.target.name]: e.target.value });
  // };
  // const handleDelivery = (e) => {
  //   setDeliveryType({  [e.target.name]: e.target.value });
  // };
  const handlePayment = (e) => {
    setPayment({ ...payment, [e.target.name]: e.target.value });
  };
  // const totalPrice =getCart.totalPrice+40;
  const checkOut = () => {
    setPaymentLoad(true);
    const Swal = require("sweetalert2");
    if (payment.addressId === "") {
      setPaymentLoad(false);
      setError("Address is Required");
      setTimeout(() => {
        setError("");
      }, 3000);
    } else if (payment.deliveryType === "") {
      setPaymentLoad(false);
      setError("Payment Method is Required");
      setTimeout(() => {
        setError("");
      }, 3000);
    } else {
      dispatch(checkOuts())
        .then((res) => {
          // console.log("response", JSON.parse(res.data.data.price));
          // console.log("orderId",res.data.data.orderId);
          // console.log("token",res.data.data.txnToken);
          // console.log("mid",process.env.REACT_APP_MID_URL);
          // setOrderId(res.data.data.orderId)

          if (payment.deliveryType === "COD") {
            setPaymentLoad(false);

            dispatch(payNows({ ...payment, orderId: res.data.data.orderId }))
              .then((res) => {
                window.location.href = "/orderSuccess";
              })
              .catch((res) => {
                setPaymentLoad(false);
                window.location.reload();
              });
          } else {
            // console.log("payment is working");
            setPaymentLoad(false);
            var config = {
              root: "",
              style: {
                bodyBackgroundColor: "#fafafb",
                bodyColor: "",
                themeBackgroundColor: "#0FB8C9",
                themeColor: "#ffffff",
                headerBackgroundColor: "#284055",
                headerColor: "#ffffff",
                errorColor: "",
                successColor: "",
                card: {
                  padding: "",
                  backgroundColor: "",
                },
              },
              data: {
                orderId: res.data.data.orderId,
                token: res.data.data.txnToken,
                tokenType: "TXN_TOKEN",
                amount: res.data.data.price /* update amount */,
              },
              payMode: {
                labels: {},
                filter: {
                  exclude: [],
                },
                order: ["CC", "DC", "NB", "UPI", "PPBL", "PPI", "BALANCE"],
              },
              website: "WEBSTAGING",
              flow: "DEFAULT",
              merchant: {
                mid: process.env.REACT_APP_MID_URL,
                redirect: false,
              },
              handler: {
                transactionStatus: function transactionStatus(paymentStatus) {
                  if (paymentStatus.STATUS === "TXN_SUCCESS") {
                    dispatch(
                      payNows({ ...payment, orderId: paymentStatus.ORDERID })
                    ).then((res) => {
                      window.location.href = "/ordersuccess";
                    });
                    // navigate("/ordersuccess")
                    // window.location.reload();
                  }
                },
                notifyMerchant: function notifyMerchant(eventName, data) {
                  // console.log("Closed");
                },
              },
            };

            if (window.Paytm && window.Paytm.CheckoutJS) {
              // console.log("test2")

              window.Paytm.CheckoutJS.init(config)
                .then(function onSuccess() {
                  window.Paytm.CheckoutJS.invoke();
                })
                .catch(function onError(error) {
                  setPaymentError("Payment Failed...");
                  setTimeout(() => {
                    setPaymentError("");
                  }, 5000);
                  console.log("test => ", error);
                });
            } else {
              // console.log("eror")
            }
          }
        })
        .catch((err) => {
          // console.log("error",err)
          if (err.response.data.error.message === "Phone Number Not Verified") {
            navigate("/phoneOtp");
          }
        });
    }
  };

  return load ? (
    <div
      style={{
        height: "400px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : (
    <div style={{ background: "white" }}>
      <div className="checkout-main-area pt-5 pb-5">
        {Array.isArray(getCart?.cart) && getCart?.cart?.length != 0 ? (
          <div className="container">
            <form>
              <div className="checkout-wrap pt-30">
                <div className="row">
                  <div className="col-lg-7">
                    <div className="billing-info-wrap">
                      <h3>Billing Details</h3>
                      <div className="cart-table-content">
                        <div className="table-content table-responsive">
                          <table>
                            <thead>
                              <tr>
                                {/* <th className="width-thumbnail">S.No</th> */}

                                {/* {profileList?.profilePic  ? */}
                                <th className="width-price">S.No</th>

                                {/* : false} */}
                                <th className="width-price"> Address Type</th>
                                <th className="width-price"> Address</th>
                                {/* <th className="width-quantity">  District</th> */}

                                <th className="width-price"> Primary</th>
                                <th className="width-price">Select Address</th>
                              </tr>
                            </thead>
                            <tbody>
                              <>
                                {Array.isArray(Address) &&
                                Address.length !== 0 ? (
                                  Address?.map((data, i) => (
                                    <tr>
                                      <td className="product-name">{i + 1}</td>
                                      <td className="product-name">
                                        <h5>
                                          <a
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {data?.addressType}
                                          </a>
                                        </h5>
                                      </td>
                                      <td className="product-name">
                                        <h5
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          <a>
                                            {data?.city +
                                              " ," +
                                              data?.district +
                                              " ," +
                                              data?.landmark +
                                              " " +
                                              data?.state +
                                              ", " +
                                              data?.street +
                                              ", " +
                                              data?.zipcode}
                                          </a>
                                        </h5>
                                      </td>

                                      <td className="product-name">
                                        <h5>
                                          <a
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {data?.primary}
                                          </a>
                                        </h5>
                                      </td>
                                      <td className="product-name">
                                        <input
                                          type="radio"
                                          name="addressId"
                                          onChange={handlePayment}
                                          value={data?.id}
                                          required
                                        />
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td
                                      colspan="7"
                                      style={{
                                        color: "red",
                                        marginLeft: "50px",
                                        textAlign: "center",
                                        fontWeight: "600",
                                      }}
                                    >
                                      <span> No Address Found </span>
                                      <div
                                        className="button-box btn-hover mt-4 mb-5"
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <a
                                          style={{ borderRadius: "20px" }}
                                          href="/addAddress"
                                          className="product-action-btn-3"
                                          title="Product Details"
                                        >
                                          {" "}
                                          Add Address
                                        </a>
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* {Array.isArray(getCart?.cart)&& getCart?.cart.map((c,i)=>{ */}
                  {Array.isArray(getCart?.cart) &&
                  getCart?.cart?.length != 0 ? (
                    <div className="col-lg-5">
                      <div className="your-order-area">
                        <h3>Your order</h3>
                        <div className="your-order-wrap gray-bg-4">
                          <div className="your-order-info-wrap">
                            <div className="your-order-info">
                              <ul>
                                <li>
                                  Product <span>Price</span>
                                </li>
                              </ul>
                            </div>
                            {getCart?.cart.map((C, i) => (
                              <div className="your-order-middle">
                                <ul>
                                  <li style={{ textTransform: "capitalize" }}>
                                    {C.productName}{" "}
                                    <span>
                                      {" "}
                                      ₹ {formatter.format(C.totalPrice)}
                                    </span>
                                  </li>
                                  <li style={{ textTransform: "capitalize" }}>
                                    Quantity <span> {C.units}</span>
                                  </li>
                                  {/* <li style={{ textTransform: "capitalize" }}>
          Shipping Fee   <span> ₹ 40</span>
        </li>
        */}
                                </ul>
                              </div>
                            ))}

                            <>
                              <div className="your-order-info order-total">
                                <ul>
                                  <Accordion
                                    elevation={0}
                                    expanded={expanded === "panel1"}
                                    onChange={handleChange("panel1")}
                                  >
                                    <AccordionSummary
                                      style={{ marginRight: "-20px" }}
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1bh-content"
                                      id="panel1bh-header"
                                    >
                                      <Typography
                                        sx={{ flexShrink: 0 }}
                                        style={{
                                          color: "black",
                                          fontSize: "14px",
                                        }}
                                      >
                                        <span style={{ marginLeft: "-17px" }}>
                                          Price BreakDown
                                        </span>
                                        {/* ₹ {getCart?.subtotal} */}
                                      </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <Typography
                                        style={{
                                          color: "black",
                                          fontSize: "14px",
                                          marginLeft: "-17px",
                                        }}
                                      >
                                        Actual Price : ₹{" "}
                                        {formatter.format(getCart?.price)}
                                      </Typography>
                                      <Typography
                                        style={{
                                          color: "black",
                                          fontSize: "14px",
                                          marginLeft: "-17px",
                                        }}
                                      >
                                        Discount Amount : ₹
                                        {(getCart?.discount).toLocaleString(
                                          "en-IN",
                                          {
                                            maximumFractionDigits: 0,
                                          }
                                        )}
                                      </Typography>
                                      <Typography
                                        style={{
                                          color: "black",
                                          fontSize: "14px",
                                          marginLeft: "-17px",
                                        }}
                                      >
                                        Discount Price : ₹{" "}
                                        {formatter.format(getCart?.totalPrice)}
                                      </Typography>
                                      <Typography
                                        style={{
                                          color: "black",
                                          fontSize: "14px",
                                          marginLeft: "-17px",
                                        }}
                                      >
                                        Inclusive GST : ₹{" "}
                                        {formatter.format(getCart?.gst)}
                                      </Typography>
                                      {/* <Typography style={{color:"black",fontSize:"14px",marginLeft:"-17px"}}>
                 Shipping Fee : ₹ 40
                 
                 </Typography> */}
                                    </AccordionDetails>
                                  </Accordion>
                                </ul>
                              </div>

                              {/* <div className="your-order-info order-total">
      <ul>
        <li>
          Shipping Fee <span>₹ 40 </span>
        </li>
      </ul>
    </div> */}
                              <div className="your-order-info order-total">
                                <ul>
                                  <li>
                                    Total{" "}
                                    <span>
                                      ₹
                                      {getCart.totalPrice.toLocaleString(
                                        "en-IN",
                                        {
                                          maximumFractionDigits: 0,
                                        }
                                      )}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </>
                          </div>
                          <div className="payment-method">
                            <div className="pay-top sin-payment">
                              <input
                                type="radio"
                                id="payment-method-3" // This should match the "for" attribute of the label
                                name="deliveryType"
                                value="PAYNOW"
                                onChange={handlePayment}
                                required
                              />
                              <label htmlFor="payment-method-3">
                                Pay Online
                              </label>
                            </div>
                            <div className="pay-top sin-payment">
                              <input
                                type="radio"
                                id="payment-method-4" // This should match the "for" attribute of the label
                                name="deliveryType"
                                onChange={handlePayment}
                                value="COD"
                                required
                              />
                              <label for="payment-method-4">
                                Cash on Delivery{" "}
                              </label>
                            </div>
                          </div>
                        </div>
                        {error ? (
                          <div className="error-text">{error}</div>
                        ) : (
                          false
                        )}
                        {paymentError ? (
                          <div
                            className="Place-order btn-hover"
                            style={{ color: "white" }}
                          >
                            <a
                              style={{ borderRadius: "20px" }}
                              onClick={checkOut}
                            >
                              {" "}
                              {paymentError}
                            </a>
                          </div>
                        ) : (
                          <div
                            className="Place-order btn-hover"
                            style={{ color: "white" }}
                          >
                            <a
                              style={{ borderRadius: "20px" }}
                              onClick={checkOut}
                            >
                              {" "}
                              {paymentLoad ? false : <span>Place Order</span>}
                              {paymentLoad ? (
                                <div>
                                  <div className="spinner-border" role="status">
                                    <span className="visually-hidden"></span>
                                  </div>
                                </div>
                              ) : (
                                false
                              )}{" "}
                            </a>
                          </div>
                        )}

                        {/* })} */}
                      </div>
                    </div>
                  ) : (
                    <center>
                      <img
                        src={cartimage}
                        className="mt-5 mb-5  pt-5"
                        style={{ width: "25%" }}
                      />
                      <h3 className="mb-5 pb-3">
                        {" "}
                        No Products Found On the Cart
                      </h3>{" "}
                    </center>
                  )}
                </div>
              </div>
            </form>
          </div>
        ) : (
          <center>
            <img
              src={cartimage}
              className="mt-5 mb-5  pt-5"
              style={{ width: "25%" }}
            />
            <h3 className="mb-5 pb-3"> No Products Found On the Cart</h3>{" "}
          </center>
        )}
      </div>
    </div>
  );
};

export default Checkout;
