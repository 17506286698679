import React, { useState,useEffect } from "react";
import { AiOutlineStar } from "react-icons/ai";
import reviews from '../../images/NoReview.svg'
import { Link, useNavigate, } from "react-router-dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
// import { propTypes } from "react-bootstrap/esm/Image";
import { FaStar } from "react-icons/fa";
import { useDispatch,useSelector } from "react-redux";
import { myReviews,deleteReviews } from "../../Redux/Action";
const colors = {
  orange: "#DD3D05",
  grey: "#a9a9a9",
};

let tid = 0

function ReviewModal(props) {
  const navigate = useNavigate();
  const [load, setLoad] = useState(true);
  const [reviewData,setReviewData]=useState(false);
  const [render, setRender] = useState(false)
  const [currentValue, setCurrentValue] = useState(0);
  const [hoverValue, setHoverValue] = useState(undefined);
  const [review, setReview] = useState("");
  const [reviewRating, setReviewRating] = useState("");
  const dispatch = useDispatch();
  tid= props.myreview != undefined ? props.myreview.id : 0 
  useEffect(() => {
    dispatch(myReviews())
    .then((res)=>{
      setReview(res?.data?.data[0]?.review)
      setReviewRating(res.data.data)
  
      setLoad(false);
    })
      .catch(() => {
      
      });
  }, []);
  // const myReview = useSelector(({ myReviews }) => myReviews.payload);

  
  
  // const stars = Array(5).fill(0);
  // const handleClick = (value) => {
  //   setCurrentValue(value);
  // };

  // const handleMouseOver = (newHoverValue) => {
  //   setHoverValue(newHoverValue);
  // };

  // const handleMouseLeave = () => {
  //   setHoverValue(undefined);
  // };



  const Editreview = () => {
  
    dispatch(deleteReviews({ action:"edit",id: "2", review: "review" }))
    .then((res)=>{
      
        Swal.fire({
          icon: "success",
          timer: 4000,
          buttons: false,
          showConfirmButton: false,
          text: "Review Updated Succcessfully!",
        }).then(() => {
          window.location.href="/profile"
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          timer: 4000,
          buttons: false,
          showConfirmButton: false,
          text: "Somethong Went Wrong!",
        }).then(() => {
          navigate("/profile", { replace: true });
      })
      });

    //   setRender(true);
     
    // let element = document.getElementById("buttin");
    // element.click();
   
   

 
      // if(props.data){
      //   props.data(true);
      // }
  
    })
    
  };
 
  return (
    <div style={styles.container}>
      <h2> Ratings </h2>
      {Array.isArray(reviewRating)
                               ? (
                                reviewRating?.map((R) => (

      <div style={styles.stars}>
      {Array.from(
                                            { length: 5 },
                                            (data, i) =>
                                              i < R?.rating ? (
                                                <span style={{ margin: "2px" }}>
                                                  <FaStar
                                                    style={{
                                                      color: "#DD3D05",
                                                    }}
                                                  />
                                                </span>
                                              ) : (
                                                <span style={{ margin: "2px" }}>
                                                  <AiOutlineStar />
                                                </span>
                                              )
                                          )}
        {/* {stars.map((data, index) => {
          return (
            <FaStar
              key={index}
              size={20}
              onClick={() => handleClick(index + 1)}
              onMouseOver={() => handleMouseOver(index + 1)}
              onMouseLeave={handleMouseLeave}
              color={
                (hoverValue || currentValue) > index
                  ? colors.orange
                  : colors.grey
              }
              style={{
                marginRight: 10,
                cursor: "pointer",
              }}
            />
          );
        })} */}
      </div>
        ))
        ) : (
          <h3>  <center style={{marginTop:"-50px"}}><img src={reviews} className='mt-5 mb-5  ' style={{width:"25%"}} />
</center></h3>
        )}
      <textarea
        placeholder="What's your experience?"
        name="review"
        onChange={(e) => setReview(e.target.value)}
        value={review}
        style={styles.textarea}
      />
      <button style={styles.button} onClick={Editreview} >
        Update
      </button>
     
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  stars: {
    display: "flex",
    flexDirection: "row",
  },
  textarea: {
    border: "1px solid #a9a9a9",
    borderRadius: 5,
    padding: 10,
    margin: "20px 0",
    minHeight: 100,
    width: 300,
  },
  button: {
    border: "1px solid #a9a9a9",
    borderRadius: "20px",
    width: 300,
    padding: 10,
    background:"#DD3D05",
    fontWeight: 600,
    color:"white"
  },
};

export default ReviewModal;
