import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { addCart, addWishList } from "../Redux/ActionType";
import {
  hotDeals,
  getCategories,
  getSingleProduct,
  addCarts,
  addWishLists,
  getBanner,
  getWelcomeMessages,
  getAllReviews,
} from "../Redux/Action";
import img from "../images/No.jpg";
import { FaStar } from "react-icons/fa";
import { AiOutlineStar } from "react-icons/ai";
import tes1 from "../images/profilepictrue.png";
// import "./style.css"
import "./testimonials.css";

import theme from "../images/theme.svg";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

//testimonial sliders start

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./testimonials.css";
import { Avatar } from "@material-ui/core";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";

//testimonial sliders send
const Home = () => {
  //testimonials start
  const PreviousBtn = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <ArrowBackIos style={{ color: "gray", fontSize: "45px" }} />
      </div>
    );
  };
  const NextBtn = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <ArrowForwardIos style={{ color: "gray", fontSize: "45px" }} />
      </div>
    );
  };
  // testimonials End

  var vert = "vertical".split("").join("<br/>");
  window.$("#vert").html(vert);

  const [load, setLoad] = useState(true);
  const [loader, setLoader] = useState(true);
  const [orderError, setOrderError] = useState("");
  const [orderData, setOrderData] = useState({
    companyName: "",
    companyEmail: "",
    companyContact: "",
    quantity: "",
  });

  function numberWithCommas(x) {
    return x.toString().split(",")[0].length > 3
      ? x
          .toString()
          .substring(0, x.toString().split(".")[0].length - 3)
          .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          "," +
          x.toString().substring(x.toString().split(".")[0].length - 3)
      : x.toString();
  }

  window.$(document).ready(function () {
    window.$(".owl-carousel").owlCarousel({
      loop: true,
      margin: 10,
      nav: false,
      autoplay: true,
      autoplayTimeout: 1600,
      autoplayHoverPause: true,
      responsive: {
        0: {
          items: 1,
        },
        600: {
          items: 2,
        },
        1000: {
          items: 3,
        },
      },
    });
  });

  // const [carousal, setCarousal] = useState(0);

  // const [render, setRender] = useState(false);
  const dispatch = useDispatch();
  const Token = localStorage.getItem("AuthTok");
  const [change, setChange] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [changeVariant, setChangeVariant] = useState(0);
  const banner = useSelector(({ getBanner }) => getBanner.payload);

  const category = useSelector(({ getCategories }) => getCategories.payload);
  const deal = useSelector(({ hotDeals }) => hotDeals.payload);

  const Product = useSelector(
    ({ getSingleProduct }) => getSingleProduct.payload
  );
  console.log("id", Product?.[changeVariant]);

  const SingleProduct = (proId) => {
    setLoader(true);
    dispatch(getSingleProduct({ productId: proId })).then((res) =>
      setLoader(false)
    );
  };
  //Phone Number Validation
  window.$("body").on("keyup", ".js-input-mobile", function () {
    var $input = window.$(this),
      value = $input.val(),
      length = value.length,
      inputCharacter = parseInt(value.slice(-1));

    if (
      !(
        (length > 0 && inputCharacter >= 0 && inputCharacter <= 10) ||
        (length === 1 && inputCharacter >= 7 && inputCharacter <= 10)
      )
    ) {
      $input.val(value.substring(0, length - 1));
    }
  });

  const Truncate = (string, number) => {
    if (!string) {
      return null;
    }
    if (string.length <= number) {
      return string;
    }
    return string.slice(0, number) + "...";
  };
  //add to cart
  const carts = useSelector(({ addCarts }) => addCarts.payload);
  const wish = useSelector(({ addWishLists }) => addWishLists.payload);
  const [cartId, getCartId] = useState({
    variantColor: "",
    variantId: "",
    units: 0,
  });
  const [render1, setRender1] = useState(false);
  const handleClick = () => {
    cartId.variantId = Product[changeVariant].id;
    cartId.units = quantity;
    // cartId.variantColor = JSON.parse(Product[changeVariant].variantColor)[change];
    dispatch(addCarts(cartId)).then((res) => {});
  };

  function formatAsPercent(num) {
    return new Intl.NumberFormat("default", {
      style: "percent",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    }).format(num / 100);
  }

  useEffect(() => {
    dispatch(getWelcomeMessages());
    setLoad(false);
  }, []);

  const welcome = useSelector(
    ({ getWelcomeMessages }) => getWelcomeMessages.payload
  );

  useEffect(() => {
    dispatch(getAllReviews());
    setLoad(false);
  }, []);

  const totalReviews = useSelector(
    ({ getAllReviews }) => getAllReviews.payload
  );

  useEffect(() => {
    Promise.allSettled([
      dispatch(getBanner()),
      // dispatch(getWelcomeMessages()),
      dispatch(hotDeals()),
      dispatch(getCategories()),
    ])
      .then((res) => {
        const jquery = window.$;

        const slider = () => {
          if (jquery)
            var sliderActive = new window.Swiper(".slider-active", {
              spaceBetween: 130,
              centeredSlides: true,

              // speed: 25000,
              // effect: "fade",
              slidesPerView: 1,

              autoplay: {
                delay: 2500,
                disableOnInteraction: false,
              },
              pagination: {
                el: ".swiper-pagination",
                clickable: true,
              },
              // navigation: {
              //   nextEl: ".home-slider-next",
              //   prevEl: ".home-slider-prev"
              // },
            });
        };
        setTimeout(slider, 1000);
        setLoad(false);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    localStorage.removeItem("Error");
  }, []);

  let navigate = useNavigate();
  const catshop = (id) => {
    navigate("/categoryShop/" + id);
  };

  useEffect(() => {
    window.setTimeout(function () {
      dispatch({ type: addCart.add.success });
      window
        .$(".alert")
        .fadeTo(500, 0)
        .slideUp(500, function () {
          window.$(this).remove();
        });
    }, 4000);

    window.setTimeout(function () {
      dispatch({ type: addWishList.wishList.success });
      window
        .$(".alert1")
        .fadeTo(500, 0)
        .slideUp(500, function () {
          window.$(this).remove();
        });
    }, 4000);
  }, [carts, wish]);

  const TotalReviews = useSelector(
    ({ getAllReviews }) => getAllReviews.payload
  );

  useEffect(() => {
    if (document.cookie !== "true") {
      document.cookie = true;
      setTimeout(
        () =>
          window.$(document).ready(function () {
            window.$("#test").modal("show");
          }),
        1000
      );
    }
  }, []);

  if (window.$) {
    var sliderActiveSeven = new window.Swiper(".testimonial-active", {
      loop: true,
      spaceBetween: 30,
      centeredSlides: true,
      breakpoints: {
        320: {
          slidesPerView: 1,
        },
        576: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        992: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        1200: {
          slidesPerView: 3,
        },
      },
    });
  }
  console.log(Product, "sgry");
  return load ? (
    <div
      style={{
        height: "500px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="spinner-border" role="status">
        <span className="visually-hidden"></span>
      </div>
    </div>
  ) : (
    <>
      <div className="main-wrapper main-wrapper-2">
        <div
          className="modal fade quickview-modal-style"
          id="test"
          aria-labelledby="test"
          tabIndex="-1"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-centered"
            role="document"
            // style={{ width:"329px"}}
          >
            <div className="modal-content">
              <div className="modal-header">
                <a
                  href="#"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="fa-solid fa-xmark"></i>
                </a>
              </div>
              <div className="modal-body">
                <div
                  className="row gx-0"
                  // style={{display: 'flex', justifyContent: "center"}}
                >
                  <div className="col-lg-12 col-md-12 col-12">
                    <div className="modal-img-wrap ">
                      <img
                        src={process.env.REACT_APP_BASE_URL + welcome?.popUp}
                        alt=""
                        height="100%"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sidebar-cart-active">
          <div className="sidebar-cart-all">
            <a className="cart-close" href="#">
              <i className="pe-7s-close"></i>
            </a>
            <div className="cart-content">
              <h3>Shopping Cart</h3>
              <ul>
                <li>
                  <div className="cart-img">
                    <a href="#">
                      <img src="assets/images/cart/cart-1.jpg" alt="" />
                    </a>
                  </div>
                  <div className="cart-title">
                    <h4>
                      <a href="#">Stylish Swing Chair</a>
                    </h4>
                    <span> 1 × $49.00 </span>
                  </div>
                  <div className="cart-delete">
                    <a href="#">×</a>
                  </div>
                </li>
                <li>
                  <div className="cart-img">
                    <a href="#">
                      <img src="assets/images/cart/cart-2.jpg" alt="" />
                    </a>
                  </div>
                  <div className="cart-title">
                    <h4>
                      <a href="#">Modern Chairs</a>
                    </h4>
                    <span> 1 × $49.00 </span>
                  </div>
                  <div className="cart-delete">
                    <a href="#">×</a>
                  </div>
                </li>
              </ul>
              <div className="cart-total">
                <h4>
                  Subtotal: <span>$170.00</span>
                </h4>
              </div>
              <div className="cart-btn btn-hover">
                <a className="theme-color" href="cart.html">
                  view cart
                </a>
              </div>
              <div className="checkout-btn btn-hover">
                <a className="theme-color" href="checkout.html">
                  checkout
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div className="carousel-inner">
  {Array.isArray(banner) && banner.length !== 0 ? (
         banner?.map((Banner) => (
    <div className="carousel-item active">
      <img src={process.env.REACT_APP_BASE_URL+ Banner.bannerImage}  className="d-block w-100" alt="..."/>
    </div>
    ))
    ) : (
    <div className="spinner-border" role="status">
     <span className="sr-only"> Loading...</span>
    </div>
    )} 
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div> */}
        <div className="slider-area">
          <Swiper
            style={{
              display: "flex",
              justifyContent: "center",
              flexWrap: "wrap-reverse",
            }}
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 4500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            // navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
          >
            {Array.isArray(banner) && banner.length !== 0 ? (
              banner?.map((Banner, i) => (
                <SwiperSlide>
                  {/* slide{i} */}
                  <a href={Banner.bannerLink}>
                    <div
                      className="intro-section slider-height-1 slider-content-center bg-img single-animation-wrap slider-bg-color-1"
                      style={{
                        backgroundImage: `url(${
                          process.env.REACT_APP_BASE_URL + Banner.bannerImage
                        })`,
                      }}
                    >
                      <div className="container">
                        <div className="row align-items-center">
                          <div className="col-lg-6 col-md-6">
                            <div className="slider-content-1 slider-animated-1">
                              {/* <h3 className="animated" style={{color:"white"}}>new arrival</h3> */}
                              <h1
                                className="animated"
                                style={{
                                  background: "rgb(242 242 242 / 60%)",
                                  paddingLeft: "20px",
                                  textTransform: "uppercase",
                                }}
                              >
                                {Banner.bannerText}
                              </h1>
                              <div className="slider-btn btn-hover">
                                <a
                                  href={Banner.bannerLink}
                                  className="btn animated"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Shop Now <i className="ti-arrow-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="hero-slider-img-1 slider-animated-1">
                              <img
                                className="animated animated-slider-img-1"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </SwiperSlide>
              ))
            ) : (
              // false
              <div className="spinner-border" role="status">
                <span className="sr-only"> </span>
              </div>
            )}
          </Swiper>
        </div>
        <div className="banner-area pt-100 pb-70">
          <div className="container">
            <div className="section-title-tab-wrap mb-40">
              <div
                className="section-title-2"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                {Array.isArray(category) && Array.length !== 0 ? (
                  <h2 style={{ textTransform: "uppercase" }}>Categories</h2>
                ) : (
                  false
                )}
              </div>
            </div>

            <div className="team-area pt-50 pb-30">
              <div className="container">
                <div className="row">
                  {Array.isArray(category) && category?.length !== 0
                    ? category?.map((data) => (
                        <div className="col-lg-2 col-md-4 col-sm-4 col-12">
                          <div
                            className="single-team-wrap text-center mb-30"
                            data-aos="fade-up"
                            data-aos-delay="200"
                          >
                            <a onClick={() => catshop(data?.id)}>
                              <img
                                src={
                                  process.env.REACT_APP_BASE_URL +
                                  data?.categoryImage
                                }
                                alt={data?.categoryName}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = img;
                                }}
                              />
                            </a>
                            <div className="team-info-position">
                              <div className="team-info">
                                <h5>
                                  <a
                                    style={{
                                      textTransform: "capitalize",
                                      fontSize: "15px",
                                      fontWeight: "600",
                                    }}
                                    onClick={() => catshop(data?.id)}
                                  >
                                    {data.categoryName}
                                  </a>
                                </h5>
                                {/* <span>Sales Man</span> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    : false}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="hp-ctn-howItWorks">
          <a href="/contactUs">
            <p className="c">Bulk&nbsp;Orders </p>
          </a>
        </div>
        {/* <div className="button-container">
<input type="button" value="Rotated text" id="rotate" />

</div> */}

        <div className="service-area pb-70">
          <div className="container">
            <div className="row" style={{justifyContent:"center"}}>
              <div className="col-lg-3 col-md-6 col-sm-6 col-12 mb-30">
                <div
                  className="service-wrap"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="service-img">
                    <img src="assets/images/icon-img/car.png" alt="" />
                  </div>
                  <div className="service-content">
                    <h3>Free Shipping</h3>
                    <p>Free shipping on all order</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-12 mb-30">
                <div
                  className="service-wrap"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  {/* <div className="service-img">
                    <img src="assets/images/icon-img/time.png" alt="" />
                  </div> */}
                  {/* <div className="service-content">
                    <h3>Support 24/7</h3>
                    <p>Support 24 hours a day</p>
                  </div> */}
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-12 mb-30">
                <div
                  className="service-wrap"
                  data-aos="fade-up"
                  data-aos-delay="600"
                >
                  <div className="service-img">
                    <img
                      src="assets/images/icon-img/rupees.png"
                      alt=""
                      width="60%"
                    />
                  </div>
                  <div className="service-content">
                    <h3>Money Return</h3>
                    <p>Easy Six Days Money Return Policy </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <img src="assets/images/icon-img/rupees.png" alt="" width="60%" /> */}

        <div className="product-area pb-60 ">
          <div className="container">
            <div className="section-title-tab-wrap mb-75">
              <div
                className="section-title-2"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                {Array.isArray(deal) && Array.length !== 0 ? (
                  <h2 style={{ textTransform: "uppercase" }}>Hot Deals</h2>
                ) : (
                  false
                )}
              </div>
              {/* <div
                className="tab-style-1 nav"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <a className="active" href="#pro-1" data-bs-toggle="tab">
                  New Arrivals{" "}
                </a>
                <a href="#pro-2" data-bs-toggle="tab" className="">
                  {" "}
                  Best Sellers{" "}
                </a>
                <a href="#pro-3" data-bs-toggle="tab" className="">
                  {" "}
                  Sale Items{" "}
                </a>
              </div> */}
            </div>
            <div className="tab-content jump">
              <div id="pro-1" className="tab-pane active">
                <div className="row">
                  {Array.isArray(deal) && Array.length !== 0
                    ? deal
                        .filter((data) => data.discountPrice >= 1)
                        .map((data) => (
                          <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                            <div
                              className="product-wrap mb-35"
                              data-aos="fade-up"
                              data-aos-delay="200"
                            >
                              <div
                                className="product-img img-zoom mb-25"
                                style={{ borderRadius: "16px" }}
                              >
                                <Link to={"/productDetails/" + data.id}>
                                  <img
                                    src={
                                      process.env.REACT_APP_BASE_URL +
                                      data.productImage
                                    }
                                    alt={data.productName}
                                  />
                                </Link>
                                <Link to={"/productDetails/" + data.id}>
                                  {data.actualPrice ? (
                                    <div className="ribbon ribbon-top-right">
                                      <span>
                                        {" "}
                                        -{" "}
                                        {formatAsPercent(
                                          ((data.actualPrice -
                                            data.discountPrice) /
                                            data.actualPrice) *
                                            100
                                        )}{" "}
                                      </span>
                                    </div>
                                  ) : (
                                    false
                                  )}
                                </Link>

                                <div
                                  className="product-action-wrap"
                                  id="quickview"
                                >
                                  {/* <button 
                          className="product-action-btn-1"
                           title="Wishlist"
                           onClick={() => {
                            setLoad(true);
                            dispatch(
                                addWishLists({ productId: data?.id })
                            ).then((res) => {
                              setLoad(false);
                            });
                          }}
                          
                          >
                            <i className="pe-7s-like"></i>
                          </button> */}
                                  <button
                                    className="product-action-btn-1"
                                    title="Quick View"
                                    onClick={() => SingleProduct(data.id)}
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModal"
                                  >
                                    <i className="pe-7s-look"></i>
                                  </button>
                                </div>

                                <div className="product-action-2-wrap">
                                  <Link
                                    to={"/productDetails/" + data.id}
                                    className="product-action-btn-2"
                                    title="Add To Cart"
                                  >
                                    <i className="pe-7s-angle-right-circle"></i>{" "}
                                    View Details
                                  </Link>
                                </div>
                              </div>
                              <div className="product-content">
                                <h3 style={{ textTransform: "capitalize" }}>
                                  <Link to={"/productDetails/" + data.id}>
                                    {data.productName}
                                  </Link>
                                </h3>
                                <div className="product-price">
                                  <span className="old-price">
                                    ₹{" "}
                                    {data.actualPrice.toLocaleString("en-IN", {
                                      maximumFractionDigits: 0,
                                    })}{" "}
                                  </span>
                                  <span className="new-price">
                                    ₹{" "}
                                    {data.discountPrice.toLocaleString(
                                      "en-IN",
                                      {
                                        maximumFractionDigits: 0,
                                      }
                                    )}{" "}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                    : false}
                </div>
              </div>
            </div>

            <div
              className="section-title-2 "
              data-aos="fade-up"
              data-aos-delay="200"
            >
              {Array.isArray(totalReviews) && Array.length !== 0 ? (
                <h2 className="mt-5" style={{ textTransform: "uppercase" }}>
                  Testimonials
                </h2>
              ) : (
                false
              )}
            </div>

            <OwlCarousel
              items={3}
              style={{ background: "none", marginTop: "4rem" }}
              className="owl-theme"
              margin={10}
              autoplay={true}
            >
              {
                Array.isArray(totalReviews) && totalReviews.length !== 0
                  ? totalReviews?.map((data, i) => (
                      <div className="item">
                        <div
                          className="card"
                          style={{ width: "18rem", border: "none" }}
                        >
                          <div>
                            <img
                              src={
                                process.env.REACT_APP_BASE_URL +
                                data?.customerImage
                              }
                              className="card-img-top"
                              alt="Customer Image"
                              style={{ width: "40%" }}
                            />
                          </div>
                          <div
                            className="card-body"
                            // style={{textAlign:"center"}}
                          >
                            {Array.from({ length: 5 }, (datas, i) =>
                              i < data?.rating ? (
                                <span className="mb-5 mt-5">
                                  <FaStar
                                    style={{
                                      color: "#DD3D05",
                                    }}
                                  />
                                </span>
                              ) : (
                                <span
                                  className="mb-5"
                                  style={{ margin: "2px" }}
                                >
                                  <AiOutlineStar />
                                </span>
                              )
                            )}
                            <h5
                              className="card-title mt-3"
                              style={{ textTransform: "capitalize" }}
                            >
                              {data?.customerName}
                            </h5>
                            <p
                              className="card-text"
                              style={{
                                textTransform: "capitalize",
                                textAlign: "justify",
                              }}
                            >
                              {data?.review}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))
                  : false
                //    (
                //  <span>"No Data Found"</span>
                //    )
              }
            </OwlCarousel>

            {/* <Carousel 
        showArrows={false}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={2100}
      >
        {Array.isArray(totalReviews) && totalReviews.length !== 0 ? (
         totalReviews?.map((data,i) => (
        <div>
          <img src={data?.customerImage} className="mb-3"/>
          <div className="myCarousel ">
            <h3 className="mb-3"style={{ textTransform: "capitalize" }}>{data?.customerName}</h3>
            {Array.from({ length: 5 }, (datas, i) =>
                                        i < rating ? (
                                          <span className="mb-5">
                                            <FaStar
                                              style={{
                                                color: "#DD3D05",
                                              }}
                                            />
                                          </span>
                                        ) : (
                                          <span className="mb-5" style={{ margin: "2px" }}>
                                            <AiOutlineStar />
                                          </span>
                                        )
                                      )}
            <p className="mt-3">
              {data?.review}
            </p>
          </div>
        </div>
        ))
      ) : (
    <span>"No Data Found"</span>
      )} 
    
      </Carousel> */}
          </div>
        </div>

        {/* product modal start */}
        <div
          className="modal fade quickview-modal-style"
          id="exampleModal"
          tabIndex="-1"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <a
                  href="#"
                  className="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  {" "}
                  <i className="fa-solid fa-xmark"></i>
                </a>
              </div>
              <div className="container p-5">
                {loader ? (
                  <div
                    style={{
                      height: "500px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden"></span>
                    </div>
                  </div>
                ) : (
                  <div className="modal-body">
                    <div
                      className="product-details-area pb-80 pt-60 "
                      style={{ marginLeft: "-15px" }}
                    >
                      <div className="container">
                        {Array.isArray(Product) && Product.length > 0 ? (
                          <div className="row">
                            <div className="col-lg-6">
                              <div
                                className="product-details-img-wrap"
                                data-aos="fade-up"
                                data-aos-delay="200"
                              >
                                <div className="swiper-container product-details-big-img-slider-2 pd-big-img-style">
                                  <div className="swiper-wrapper">
                                    <div className="swiper-slide">
                                      <div className="easyzoom-style">
                                        <div className="easyzoom easyzoom--overlay">
                                          <a
                                            href={
                                              JSON.parse(
                                                Product[changeVariant]
                                                  ?.variantImage
                                              )[change]
                                            }
                                          >
                                            <img
                                              src={
                                                process.env.REACT_APP_BASE_URL +
                                                JSON.parse(
                                                  Product[changeVariant]
                                                    ?.variantImage
                                                )[change]
                                              }
                                              alt=""
                                            />
                                          </a>
                                        </div>
                                        {/* <a target="_blank" className="easyzoom-pop-up img-popup" href={
                      JSON.parse(Product[changeVariant]?.variantImage)[change]
                    }>
                                                <i className="pe-7s-search"></i>
                                            </a> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div
                                className="product-details-content mt-2"
                                data-aos="fade-up"
                                data-aos-delay="400"
                              >
                                <h2 style={{ textTransform: "capitalize" }}>
                                  {Product[changeVariant].productName}
                                  {/* <span style={{textTransform:"capitalize"}}>
                                     ({Product[changeVariant].variantName}) 
                                </span> */}
                                </h2>

                                {/* <div className="mt-3 mb-3">
                    {Array.isArray(Product)
                      ? Product?.map((data, index) => (
                          <button
                            onClick={() => setChangeVariant(index)}
                            className="btn btn-light "
                            style={Product[changeVariant]?.variantName===data?.variantName?{border:"1px solid gray",background:"#DD3D05",color:"white",borderRadius:"16px"}:{ marginRight: "10px",textTransform:"capitalize", borderRadius:"0px" }}
                          >
                            {data?.variantName}
                          </button>
                        ))
                      : false}
                  </div> */}
                                {/* {Array.isArray(Product) &&
                                  Product.map((data) => (
                                    <div className="product-details-price">
                                      <span className="old-price">
                                        ₹
                                        {/* {(JSON.parse(Product[changeVariant].actualPrice)[change]).toLocaleString("en-IN", {
                          maximumFractionDigits: 0,
                        })} */}
                                {/* {numberWithCommas(data.actualPrice)}
                                      </span>
                                      <span className="new-price">
                                        ₹{numberWithCommas(data.discountPrice)} */}
                                {/* {(JSON.parse(Product[changeVariant].discountPrice)[change]).toLocaleString("en-IN", {
                          maximumFractionDigits: 0,
                        })} */}
                                {/* </span>
                                    </div>
                                  ))} */}

                                {Array.isArray(deal) && deal.length !== 0
                                  ? deal
                                      .filter(
                                        (F) =>
                                          F?.id ===
                                          Product[changeVariant].productId
                                      )
                                      .map((pro) => (
                                        <div key={pro.id}>
                                          <p
                                            style={{
                                              textAlign: "justify",
                                              marginTop: "40px",
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontWeight: "600",
                                                fontSize: "16px",
                                              }}
                                            >
                                              Description:{" "}
                                            </span>
                                            {Truncate(
                                              pro?.productDescription,
                                              240
                                            )}
                                          </p>
                                          <div className="shop-list-content mb-5">
                                            <div className="product-price">
                                              <span className="old-price">
                                                ₹
                                                {numberWithCommas(
                                                  pro.actualPrice
                                                )}
                                              </span>
                                              <span className="new-price">
                                                ₹
                                                {numberWithCommas(
                                                  pro.discountPrice
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                          {console.log(pro)}
                                        </div>
                                      ))
                                  : null}

                                {/* <div className="product-details-review">
                                <div className="product-rating">
                                    <i className=" ti-star"></i>
                                    <i className=" ti-star"></i>
                                    <i className=" ti-star"></i>
                                    <i className=" ti-star"></i>
                                    <i className=" ti-star"></i>
                                </div>
                                <span>( 1 Customer Review )</span>
                            </div> */}
                                {/* <div className="product-color product-color-active product-details-color">
                                <span>Color :</span>
                                {JSON.parse(Product[changeVariant].variantColor).map(
                        (data, i) => (
                          <a
                            style={{ width: "40px" }}
                            className="imageborder active"
                          >
                            <span
                              style={{
                                width: "30px",
                                height: "30px",
                                background: data,
                                border: "1px solid black",
                                borderRadius: "50%",
                              }}
                              onClick={() => setChange(i)}
                            ></span>
                          </a>
                        )
                      )}
                            </div> */}
                                <div>
                                  {carts ? (
                                    <div
                                      style={{ marginTop: "-20px" }}
                                      className="alert alert-warning alert-dismissible fade show"
                                      role="alert"
                                      mt-3
                                    >
                                      {carts === "Already Added to Cart" ||
                                      carts === "Added to Cart" ? (
                                        <a href="/cart">
                                          {carts} - Go To Carts
                                        </a>
                                      ) : (
                                        carts
                                      )}
                                      <button
                                        type="button"
                                        className="btn-close"
                                        data-dismiss="alert"
                                        aria-label="Close"
                                        // onClick={()=>dispatch({type:addCart.add.success,data:false})}
                                      ></button>
                                    </div>
                                  ) : (
                                    false
                                  )}
                                </div>
                                <div>
                                  {wish ? (
                                    <div
                                      className="alert alert-warning alert-dismissible fade show"
                                      role="alert"
                                      mt-3
                                    >
                                      {wish}
                                      <button
                                        type="button"
                                        className="btn-close"
                                        data-dismiss="alert"
                                        // onClick={()=>dispatch({type:addWishList.wishList.success,data:false})}
                                        aria-label="Close"
                                      ></button>
                                    </div>
                                  ) : (
                                    false
                                  )}
                                </div>
                                <div className="product-details-action-wrap mb-5">
                                  <div
                                    className="product-quality"
                                    style={{ textAlign: "center" }}
                                  >
                                    <span
                                      style={{
                                        display: "flex",
                                        padding: "5px 10px",
                                        marginTop: "8px",
                                        marginBottom: "5px",
                                      }}
                                    >
                                      {/* <div  style={{display:"flex"}}> */}
                                      <span
                                        className="minus-btn"
                                        onClick={() => {
                                          return quantity > 1
                                            ? setQuantity(quantity - 1)
                                            : false;
                                        }}
                                      >
                                        <i className="bx bx-minus"></i>
                                      </span>
                                      <input
                                        type="text"
                                        disabled
                                        min="1"
                                        style={{
                                          outline: "none",
                                          border: "none",
                                          width: "20px",
                                          marginLeft: "8px",
                                          marginTop: "-5px",
                                          background: "none",
                                        }}
                                        value={quantity}
                                        onChange={(e) =>
                                          setQuantity(e.target.value)
                                        }
                                      />
                                      {/* <input type="text" min="1" value={quantity[i]} onChange={(e)=>setQuantity(e.target.value)} /> */}
                                      <span
                                        className="plus-btn"
                                        onClick={() => {
                                          setQuantity(quantity + 1);
                                        }}
                                      >
                                        <i className="bx bx-plus"></i>
                                      </span>
                                    </span>
                                    {/* </div> */}
                                  </div>
                                  <div className="single-product-cart btn-hover">
                                    {Token ? (
                                      <a
                                        style={{
                                          fontSize: "18px",
                                          paddingTop: "10px",
                                          paddingBottom: "10px",
                                          paddingleft: "20px",
                                          color: "white",
                                          borderRadius: "20px",
                                        }}
                                        onClick={handleClick}
                                      >
                                        Add to Cart
                                      </a>
                                    ) : (
                                      <a
                                        style={{
                                          fontSize: "18px",
                                          paddingTop: "10px",
                                          paddingBottom: "10px",
                                          paddingleft: "20px",
                                          borderRadius: "20px",
                                        }}
                                        href="/login"
                                      >
                                        Add to Cart
                                      </a>
                                    )}
                                  </div>
                                  <div className="single-product-wishlist">
                                    <a
                                      title="Wishlist"
                                      onClick={() => {
                                        // setLoad(true);
                                        dispatch(
                                          addWishLists({
                                            productId:
                                              Product[changeVariant]?.productId,
                                          })
                                        ).then((res) => {
                                          // setLoad(true);
                                        });
                                      }}
                                    >
                                      {Product[changeVariant]?.favourites ===
                                      0 ? (
                                        <i
                                          className="bx bx-heart"
                                          style={{ fontSize: "26px" }}
                                        ></i>
                                      ) : (
                                        <i
                                          style={{ fontSize: "26px" }}
                                          className="bx bxs-heart"
                                        ></i>
                                      )}
                                    </a>
                                  </div>
                                </div>
                                <div>
                                  <span>
                                    <a
                                      href={
                                        "/productDetails/" +
                                        Product[changeVariant]?.productId
                                      }
                                      style={{
                                        fontSize: "18px",
                                        textDecoration: "underline",
                                      }}
                                    >
                                      View More Details
                                    </a>
                                  </span>

                                  {/* <div className="single-product-cart btn-hover mt-5">
                                    <a  style={{fontSize:"18px",paddingTop:"10px",paddingBottom:"10px",paddingleft:"20px",}}href="#">Add to Cart</a>
                                </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          false
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Product Modal start --> */}

        {/* <!-- Product Modal end --> */}

        {/* <!-- Bulk order Modal start --> */}
        {/* <div className="modal fade quickview-modal-style" id="bulkOrderModal" tabIndex="-1" role="dialog">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <a href="#" className="close" data-bs-dismiss="modal" aria-label="Close"><i className=" ti-close "></i></a>
                    </div>
             <div className="container p-5">
           
             <div className="login-register-form">
                        <form onSubmit={bulkOrderSubmit} method="post">
                          <div className="row">
                            <div className="form-group">
                            <input
                            type="text"
                            name="companyName"
                            placeholder="Enter your Company Name"
                            onChange={bulkOrderChange}
                            value={orderData.companyName}
                            required
                          />
                            </div>
                          </div>
                         
                          <input
                            type="email"
                            name="companyEmail"
                            placeholder="Enter Your Company Email"
                            onChange={bulkOrderChange}
                            value={orderData.companyEmail}
                            required
                          />
                            <input
                                 
                                 type="tel" maxlength="10" className="js-input-mobile"
                                   id="zipCode"
                                  name="companyContact"
                                  value={orderData.companyContact}
                                  onChange={bulkOrderChange}
                                  placeholder="Enter Your Company Contact No"
                                  required
                                />
                                  <input
                            type="text"
                            name="quantity"
                            placeholder="Enter Your Quantity"
                            onChange={bulkOrderChange}
                            value={orderData.qunatity}
                            required
                          />
                       
                          {orderError ? (
                          <div className="error-text">  
                          {orderError }
                        </div>
                          
                          ) : (
                            false
                          )}
                          
                        

                          
                        
                          <div className="button-box btn-hover">
                            <button type="submit">Submit Enquiry </button>
                          </div>
                           
                        </form>
                      </div>
             </div>
                   
                </div>
            </div>
        </div> */}

        {/* <!-- Bulk order Modal end --> */}

        {/* <section className="home-testimonial">
    <div className="container-fluid">
        <div className="row d-flex justify-content-center testimonial-pos">
            <div className="col-md-12 pt-4 d-flex justify-content-center">
                <h3>Testimonials</h3>
            </div>
            <div className="col-md-12 d-flex justify-content-center">
                <h2>Explore the students experience</h2>
            </div>
        </div>
        <section className="home-testimonial-bottom">
            <div className="container testimonial-inner">
                <div className="row d-flex justify-content-center">
                    <div className="col-md-4 style-3">
                        <div className="tour-item ">
                            <div className="tour-desc bg-white">
                                <div className="tour-text color-grey-3 text-center">&ldquo;At this School, our mission is to balance a rigorous comprehensive college preparatory curriculum with healthy social and emotional development.&rdquo;</div>
                                <div className="d-flex justify-content-center pt-2 pb-2"><img className="tm-people" src="https://images.pexels.com/photos/6625914/pexels-photo-6625914.jpeg" alt=""/></div>
                                <div className="link-name d-flex justify-content-center">Balbir Kaur</div>
                                <div className="link-position d-flex justify-content-center">Student</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 style-3">
                        <div className="tour-item ">
                            <div className="tour-desc bg-white">
                                <div className="tour-text color-grey-3 text-center">&ldquo;At this School, our mission is to balance a rigorous comprehensive college preparatory curriculum with healthy social and emotional development.&rdquo;</div>
                                <div className="d-flex justify-content-center pt-2 pb-2"><img className="tm-people" src="https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" alt=""/></div>
                                <div className="link-name d-flex justify-content-center">Balbir Kaur</div>
                                <div className="link-position d-flex justify-content-center">Student</div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 style-3">
                        <div className="tour-item ">
                            <div className="tour-desc bg-white">
                                <div className="tour-text color-grey-3 text-center">&ldquo;At this School, our mission is to balance a rigorous comprehensive college preparatory curriculum with healthy social and emotional development.&rdquo;</div>
                                <div className="d-flex justify-content-center pt-2 pb-2"><img className="tm-people" src="https://images.pexels.com/photos/4946604/pexels-photo-4946604.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" alt=""/></div>
                                <div className="link-name d-flex justify-content-center">Balbir Kaur</div>
                                <div className="link-position d-flex justify-content-center">Student</div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
        </section>
        </div>
</section> */}
        {/* <div className="testimonial-area pb-100">
            <div className="container">
                <div className="section-title-2 st-border-center text-center mb-75" data-aos="fade-up" data-aos-delay="200">
                    <h2>Testimonial</h2>
                </div>
                <div className="testimonial-active swiper-container">
                {load ? 
    <div
      style={{
        height: "400px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>


   :
   
   <Swiper
   spaceBetween={30}
   centeredSlides={true}
   autoplay={{
     delay: 4500,
     disableOnInteraction: false,
   }}
   pagination={{
     clickable: true,
   }}
   // navigation={true}
   modules={[Autoplay, Pagination, Navigation]}
   className="mySwiper"
 >
   <div className="swiper-wrapper">
   {Array.isArray(totalReviews) && totalReviews.length !== 0 ? (
totalReviews?.map((data,i) => (
   <div className="swiper-slide">
  <SwiperSlide>
       <div className="single-testimonial" data-aos="fade-up" data-aos-delay="200">
           <div className="testimonial-img">
               <img src={data.customerImage} width="30%" alt=""/>
           </div>
           {Array.from({ length: 5 }, (datas, i) =>
                 i < data?.rating ? (
                   <span>
                     <FaStar
                       style={{
                         color: "#DD3D05",
                       }}
                     />
                   </span>
                 ) : (
                   <span style={{ margin: "2px" }}>
                     <AiOutlineStar />
                   </span>
                 )
               )}
           <p>{data.review}</p>
           <div className="testimonial-info">
               <h4>{data.customerName}</h4>
               <span> Our Client.</span>
           </div>
       </div>
       </SwiperSlide>
   </div>
    ))
    ) : false

    }     
  
  
  
</div>
</Swiper>
}
                    
                </div>
            </div>
        </div> */}

        <div></div>
      </div>
    </>
  );
};
const Card = ({ img, review, customer, rating }) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          textAlign: "center",
          color: "gray",
        }}
      >
        <Avatar
          imgProps={{ style: { borderRadius: "50%" } }}
          src={img}
          style={{
            width: 120,
            height: 120,
            border: "1px solid lightgray",
            padding: 7,
            marginBottom: 20,
            marginTop: 25,
          }}
        />

        <p>{review}</p>
        <div>
          {Array.from({ length: 5 }, (datas, i) =>
            i < rating ? (
              <span className="mb-5">
                <FaStar
                  style={{
                    color: "#DD3D05",
                  }}
                />
              </span>
            ) : (
              <span className="mb-5" style={{ margin: "2px" }}>
                <AiOutlineStar />
              </span>
            )
          )}
        </div>
        <p style={{ marginTop: 15, marginBottom: 50 }}>
          <span
            style={{
              fontWeight: 600,
              color: "green",
              textTransform: "capitalize",
            }}
          >
            {" "}
            - {customer}
          </span>
        </p>
      </div>
    </>
  );
};
export default Home;
