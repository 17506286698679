import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { addCart, addWishList } from "../Redux/ActionType";
import { FaStar } from "react-icons/fa";
import { AiOutlineStar } from "react-icons/ai";
import "./style.css";
import {
  getCategoryProducts,
  getSingleProduct,
  addCarts,
  addWishLists,
  authenticateLogin,
} from "../Redux/Action";
import products from "../images/noproductsfound.svg";
const Token = localStorage.getItem("AuthTok");
const CategoryShop = () => {
  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const [passworderror, setPassworderror] = useState("");
  const [load, setLoad] = useState(true);
  const [loader, setLoader] = useState(true);
  const [change, setChange] = useState(0);
  const [changeVariant, setChangeVariant] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const { id } = useParams();
  console.log("iddd", id);
  const [render, setRender] = useState(false);
  const [render1, setRender1] = useState(false);

  //userlogin form submit

  // useEffect(() => {
  //   if(id > id){
  //     window.location.href="/"
  //   }
  // }, [])

  const handleSubmit = (e) => {
    e.preventDefault();
    if (user.password.length > 3) {
      dispatch(authenticateLogin(user)).then((res) => {
        if (res.data.data === "Wrong Email/Password. Try Again!") {
          setPassworderror(res.data.data);
        } else {
          window.location.href = "/Products";
        }
      });
    } else {
      setPassworderror("password must be  greater than 3 letters");
    }
  };

  function formatAsPercent(num) {
    return new Intl.NumberFormat("default", {
      style: "percent",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    }).format(num / 100);
  }

  //userLogin handleChange
  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const [catid, setCatid] = useState({
    categoryId: id,
  });
  const dispatch = useDispatch();

  const catProduct = useSelector(
    ({ getCategoryProducts }) => getCategoryProducts.payload
  );

  const Product = useSelector(
    ({ getSingleProduct }) => getSingleProduct.payload
  );
  function numberWithCommas(x) {
    return x.toString().split(",")[0].length > 3
      ? x
          .toString()
          .substring(0, x.toString().split(".")[0].length - 3)
          .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          "," +
          x.toString().substring(x.toString().split(".")[0].length - 3)
      : x.toString();
  }

  const carts = useSelector(({ addCarts }) => addCarts.payload);
  const wish = useSelector(({ addWishLists }) => addWishLists.payload);
  const [cartId, getCartId] = useState({
    variantColor: "",
    variantId: "",
    units: 0,
  });
  const handleClick = () => {
    cartId.variantId = Product[changeVariant].id;
    cartId.units = quantity;
    // cartId.variantColor = JSON.parse(Product[changeVariant].variantColor)[
    //   change
    // ];
    dispatch(addCarts(cartId)).then((res) => {
      setRender1(true);
    });
  };

  useEffect(() => {}, [Product]);

  useEffect(() => {
    dispatch(getCategoryProducts({ categoryId: id }))
      .then((res) => {
        // console.log("res",res)
        setLoad(false);
      })
      .catch((err) => {
        console.log("error", err);
        setLoad(false);
      });
  }, [id]);

  //   const [proid, setProId] = useState({
  //     productId: 0,
  //   });

  const SingleProduct = (proId) => {
    setLoader(true);
    dispatch(getSingleProduct({ productId: proId })).then((res) => {
      setLoader(false);
    });
  };

  useEffect(() => {
    window.setTimeout(function () {
      dispatch({ type: addCart.add.success });
      window
        .$(".alert")
        .fadeTo(500, 0)
        .slideUp(500, function () {
          window.$(this).remove();
        });
    }, 4000);

    window.setTimeout(function () {
      dispatch({ type: addWishList.wishList.success });
      window
        .$(".alert1")
        .fadeTo(500, 0)
        .slideUp(500, function () {
          window.$(this).remove();
        });
    }, 4000);
  }, [carts, wish]);

  const Truncate = (string, number) => {
    if (!string) {
      return null;
    }
    if (string.length <= number) {
      return string;
    }
    return string.slice(0, number) + "...";
  };

  return load ? (
    <div
      style={{
        height: "400px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : (
    <>
      <div class="main-wrapper main-wrapper-2">
        <div class="breadcrumb-area bg-gray-4">
          <div class="container">
            <div class="breadcrumb-content text-center">
              <h2 data-aos="fade-up" data-aos-delay="200">
                Products
              </h2>
              <ul data-aos="fade-up" data-aos-delay="400">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <i class="ti-angle-right"></i>
                </li>
                <li>Products </li>
              </ul>
            </div>
          </div>
        </div>
        <div id="hp-ctn-howItWorks">
          <a href="/contactUs">
            <p class="c">Bulk&nbsp;Orders </p>
          </a>
        </div>
        <div class="shop-area ">
          <div class="container">
            <div class="row flex-row-reverse">
              <div class="col-12">
                <div class="shop-topbar-wrapper mb-40">
                  <div class="shop-topbar-left">
                    {/* <div class="showing-item">
                                   <span>Showing 1–12 of 60 results</span>
                               </div> */}
                  </div>
                  <div class="shop-topbar-right">
                    {/* <div class="shop-sorting-area">
                                   <select class="nice-select nice-select-style-1">
                                       <option>Default Sorting</option>
                                       <option>Sort by popularity</option>
                                       <option>Sort by average rating</option>
                                       <option>Sort by latest</option>
                                   </select>
                               </div> */}
                    <div class="shop-view-mode nav">
                      <a class="active" href="#shop-1" data-bs-toggle="tab">
                        <i class=" ti-layout-grid3 "></i>{" "}
                      </a>
                      <a href="#shop-2" data-bs-toggle="tab" class="">
                        <i class=" ti-view-list-alt "></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="shop-bottom-area">
                  <div class="tab-content jump">
                    <div id="shop-1" class="tab-pane active">
                      <div
                        class="section-title-2"
                        data-aos="fade-up"
                        data-aos-delay="200"
                      >
                        <h2 style={{ textTransform: "capitalize" }}>
                          products{" "}
                        </h2>
                      </div>
                      <div class="row">
                        {Array?.isArray(catProduct) &&
                        catProduct?.length != 0 ? (
                          catProduct
                            // .filter((F) => F?.discountPrice > 1)
                            .map((pro) => (
                              <div class="col-lg-2 col-md-4 col-sm-6 col-12">
                                <div class="section-title-tab-wrap mb-40"></div>
                                <div
                                  class="product-wrap mb-35"
                                  data-aos="fade-up"
                                  data-aos-delay="200"
                                >
                                  <div
                                    class="product-img img-zoom mb-25"
                                    style={{
                                      borderRadius: "16px",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <Link to={"/productDetails/" + pro?.id}>
                                      <img
                                        style={{
                                          textTransform: "capitalize",
                                          cursor: "pointer",
                                        }}
                                        src={
                                          process.env.REACT_APP_BASE_URL +
                                          pro?.productImage
                                        }
                                        alt={pro?.productName}
                                      />
                                    </Link>
                                    {pro.actualPrice ? (
                                      <Link to={"/productDetails/" + pro?.id}>
                                        <div class="ribbon ribbon-top-right">
                                          <span>
                                            {" "}
                                            -{" "}
                                            {formatAsPercent(
                                              ((pro.actualPrice -
                                                pro.discountPrice) /
                                                pro.actualPrice) *
                                                100
                                            )}{" "}
                                          </span>
                                        </div>
                                      </Link>
                                    ) : (
                                      false
                                    )}
                                    {/* <div class="product-badge badge-top badge-right badge-pink">
                                                       <span>-10%</span>
                                                   </div> */}
                                    <div
                                      class="product-action-wrap"
                                      id="quickview"
                                    >
                                      {/* <button
                                        class="product-action-btn-1"
                                        title="Wishlist"
                                        onClick={() => {
                                          setLoad(true);
                                          dispatch(
                                              addWishLists({ productId: pro?.id })
                                          ).then((res) => {
                                            setLoad(false);
                                          });
                                        }}
                                      >
                                        {pro?.favourites === 0 ? <i class='bx bx-heart' style={{fontSize:"26px"}}></i>:<i style={{fontSize:"26px"}} class='bx bxs-heart' ></i>}
                                      </button> */}

                                      <button
                                        class="product-action-btn-1"
                                        title="Quick View"
                                        onClick={() => SingleProduct(pro.id)}
                                        data-bs-toggle="modal"
                                        data-bs-target="#exampleModal"
                                      >
                                        <i class="pe-7s-look"></i>
                                      </button>
                                    </div>

                                    <div class="product-action-2-wrap">
                                      <Link
                                        class="product-action-btn-2"
                                        to={"/productDetails/" + pro.id}
                                      >
                                        <i class="pe-7s-angle-right-circle"></i>{" "}
                                        View Product Details
                                      </Link>
                                    </div>
                                  </div>
                                  {/* <div>
                    {wish? (
                      <div
    
                        class="alert alert-warning alert-dismissible fade show"
                        role="alert"
                        mt-3
                      >
                        {wish}
                        <button
                       
                          type="button"
                          class="btn-close"
                          data-dismiss="alert"
                        //   onClick={()=>dispatch({type:addWishList.wishList.success,data:false})}
                          aria-label="Close"
                        ></button>
                      </div>
                    ) :(false)}
                  </div> */}
                                  <div class="product-content">
                                    <h3 style={{ textTransform: "capitalize" }}>
                                      <Link to={"/productDetails/" + pro?.id}>
                                        {pro?.productName}
                                      </Link>
                                    </h3>
                                    <div class="product-price">
                                      <span class="old-price">
                                        ₹ {numberWithCommas(pro.actualPrice)}
                                      </span>
                                      <span class="new-price">
                                        ₹{numberWithCommas(pro?.discountPrice)}{" "}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                        ) : (
                          <center className="pb-5 mb-5">
                            <img
                              src={products}
                              className="mt-5 mb-5"
                              style={{ width: "25%" }}
                            />
                            <h3 className="container">"No Product Found"</h3>
                          </center>
                        )}
                      </div>
                      {/* <div class="pagination-style-1" data-aos="fade-up" data-aos-delay="200">
                                       <ul>
                                           <li><a class="active" href="#">1</a></li>
                                           <li><a href="#">2</a></li>
                                           <li><a href="#">3</a></li>
                                           <li><a class="next" href="#"><i class=" ti-angle-double-right "></i></a></li>
                                       </ul>
                                   </div> */}
                    </div>
                    <div id="shop-2" class="tab-pane">
                      <div class="shop-list-wrap mb-30">
                        {Array.isArray(catProduct) &&
                        catProduct?.length != 0 ? (
                          catProduct
                            .filter((F) => F?.discountPrice > 1)
                            .map((pro) => (
                              <div class="row">
                                <div class="col-lg-2 col-sm-5">
                                  <div class="product-list-img">
                                    <Link to={"/productDetails/" + pro.id}>
                                      <img
                                        src={
                                          process.env.REACT_APP_BASE_URL +
                                          pro?.productImage
                                        }
                                        alt="Product Style"
                                        style={{
                                          marginBottom: "20px",
                                          borderRadius: "16px",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </Link>
                                    {pro.actualPrice ? (
                                      <div class="ribbon ribbon-top-right">
                                        <span>
                                          {" "}
                                          -{" "}
                                          {Math.round(
                                            ((pro.actualPrice -
                                              pro.discountPrice) /
                                              pro.actualPrice) *
                                              100
                                          )}
                                          %{" "}
                                        </span>
                                      </div>
                                    ) : (
                                      false
                                    )}
                                    <div
                                      class="product-list-quickview"
                                      id="quickview"
                                    >
                                      <button
                                        class="product-action-btn-2"
                                        onClick={() => SingleProduct(pro.id)}
                                        data-bs-toggle="modal"
                                        data-bs-target="#exampleModal"
                                      >
                                        <i class="pe-7s-look"></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>

                                <div class="col-lg-8 col-sm-7">
                                  <div class="shop-list-content">
                                    <h3 style={{ textTransform: "capitalize" }}>
                                      <Link to={"/productDetails/" + pro?.id}>
                                        {pro?.productName}
                                      </Link>
                                    </h3>
                                    <div class="product-price">
                                      <span class="old-price">
                                        ₹ {numberWithCommas(pro.actualPrice)}{" "}
                                      </span>
                                      <span class="new-price">
                                        ₹{numberWithCommas(pro?.discountPrice)}{" "}
                                      </span>
                                    </div>
                                    <div class="product-list-rating">
                                      {Array.from({ length: 5 }, (datas, i) =>
                                        i < pro?.ratings ? (
                                          <span>
                                            <FaStar
                                              style={{
                                                color: "#DD3D05",
                                              }}
                                            />
                                          </span>
                                        ) : (
                                          <span style={{ margin: "2px" }}>
                                            <AiOutlineStar />
                                          </span>
                                        )
                                      )}
                                    </div>
                                    <p>
                                      {Truncate(pro?.productDescription, 240)}
                                    </p>

                                    <div class="button-box btn-hover mt-4">
                                      <a
                                        style={{ borderRadius: "20px" }}
                                        href={"/productDetails/" + pro.id}
                                        class="product-action-btn-3"
                                        title="Product Details"
                                      >
                                        {" "}
                                        View To Product Details
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                        ) : (
                          <center className="pb-5 mb-5">
                            <img
                              src={products}
                              className="mt-5 mb-5"
                              style={{ width: "25%" }}
                            />
                            <h3 className="container">"No Product Found"</h3>
                          </center>
                        )}
                      </div>
                    </div>
                    {/* <div class="pagination-style-1">
                      <ul>
                        <li>
                          <a class="active" href="#">
                            1
                          </a>
                        </li>
                        <li>
                          <a href="#">2</a>
                        </li>
                        <li>
                          <a href="#">3</a>
                        </li>
                        <li>
                          <a class="next" href="#">
                            <i class=" ti-angle-double-right "></i>
                          </a>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Product Modal start --> */}
        <div
          class="modal fade quickview-modal-style"
          id="exampleModal"
          tabIndex="-1"
          role="dialog"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <a
                  href="#"
                  class="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i class="fa-solid fa-xmark"></i>
                </a>
              </div>
              <div className="container p-5">
                {loader ? (
                  <div
                    style={{
                      height: "500px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div class="spinner-border" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <div class="modal-body">
                    <div
                      class="product-details-area pb-80 pt-60 "
                      style={{ marginLeft: "-15px" }}
                    >
                      <div class="container">
                        {Array.isArray(Product) && Product.length > 0 ? (
                          <div class="row">
                            <div class="col-lg-6">
                              <div
                                class="product-details-img-wrap"
                                data-aos="fade-up"
                                data-aos-delay="200"
                              >
                                <div class="swiper-container product-details-big-img-slider-2 pd-big-img-style">
                                  <div class="swiper-wrapper">
                                    <div class="swiper-slide">
                                      <div class="easyzoom-style">
                                        <div class="easyzoom easyzoom--overlay">
                                          <a
                                            href={
                                              JSON.parse(
                                                Product[changeVariant]
                                                  ?.variantImage
                                              )[change]
                                            }
                                          >
                                            <img
                                              src={
                                                process.env.REACT_APP_BASE_URL +
                                                JSON.parse(
                                                  Product[changeVariant]
                                                    ?.variantImage
                                                )[change]
                                              }
                                              alt=""
                                            />
                                          </a>
                                        </div>
                                        {/* <a target="_blank" class="easyzoom-pop-up img-popup" href={
                      JSON.parse(Product[changeVariant]?.variantImage)[change]
                    }>
                                                <i class="pe-7s-search"></i>
                                            </a> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-lg-6">
                              <div
                                class="product-details-content mt-2"
                                data-aos="fade-up"
                                data-aos-delay="400"
                              >
                                <h2 style={{ textTransform: "capitalize" }}>
                                  {Product[changeVariant].productName}
                                  {/* <span style={{ textTransform: "capitalize" }}>
                                    ({Product[changeVariant].variantName})
                                  </span> */}
                                </h2>

                                {/* <div className="mt-3 mb-3">
                                  {Array.isArray(Product)
                                    ? Product?.map((data, index) => (
                                        <button
                                          onClick={() =>
                                            setChangeVariant(index)
                                          }
                                          className="btn btn-light "
                                          style={
                                            Product[changeVariant]
                                              ?.variantName ===
                                            data?.variantName
                                              ? {
                                                  border: "1px solid gray",
                                                  background: "#DD3D05",
                                                  color: "white",
                                                  borderRadius: "16px",
                                                }
                                              : {
                                                  marginRight: "10px",
                                                  textTransform: "capitalize",
                                                  borderRadius: "0px",
                                                }
                                          }
                                        >
                                          {data?.variantName}
                                        </button>
                                      ))
                                    : false}
                                </div> */}
                                {/* {Array.isArray(catProduct) &&
                                catProduct?.length !== 0
                                  ? catProduct.map((data) => (
                                      <div class="product-details-price">
                                        <span class="old-price">
                                          ₹{numberWithCommas(data.actualPrice)}
                                        </span>
                                        <span class="new-price">
                                          ₹
                                          {numberWithCommas(data.discountPrice)}
                                        </span>
                                      </div>
                                    ))
                                  : false} */}

                                {Array.isArray(catProduct) &&
                                catProduct.length !== 0
                                  ? catProduct
                                      .filter(
                                        (F) =>
                                          F?.id ===
                                          Product[changeVariant].productId
                                      )
                                      .map((pro) => (
                                        <div key={pro.id}>
                                          <p
                                            style={{
                                              textAlign: "justify",
                                              marginTop: "40px",
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontWeight: "600",
                                                fontSize: "16px",
                                              }}
                                            >
                                              Description:{" "}
                                            </span>
                                            {Truncate(
                                              pro?.productDescription,
                                              240
                                            )}
                                          </p>
                                          <div className="shop-list-content mb-5">
                                            <div className="product-price">
                                              <span className="old-price">
                                                {" "}
                                                ₹
                                                {numberWithCommas(
                                                  pro.actualPrice
                                                )}
                                              </span>
                                              <span className="new-price">
                                                ₹
                                                {numberWithCommas(
                                                  pro.discountPrice
                                                )}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      ))
                                  : null}
                                {/* <div class="product-details-review">
                                <div class="product-rating">
                                    <i class=" ti-star"></i>
                                    <i class=" ti-star"></i>
                                    <i class=" ti-star"></i>
                                    <i class=" ti-star"></i>
                                    <i class=" ti-star"></i>
                                </div>
                                <span>( 1 Customer Review )</span>
                            </div> */}
                                {/* <div class="product-color product-color-active product-details-color">
                                <span>Color :</span>
                                {JSON.parse(Product[changeVariant].variantColor).map(
                        (data, i) => (
                          <a
                            style={{ width: "40px" }}
                            className="imageborder active"
                          >
                            <span
                              style={{
                                width: "30px",
                                height: "30px",
                                background: data,
                                border: "1px solid black",
                                borderRadius: "50%",
                              }}
                              onClick={() => setChange(i)}
                            ></span>
                          </a>
                        )
                      )}
                            </div> */}
                                <div>
                                  {carts ? (
                                    <div
                                      style={{ marginTop: "-20px" }}
                                      class="alert alert-warning alert-dismissible fade show"
                                      role="alert"
                                      mt-3
                                    >
                                      {carts === "Already Added to Cart" ||
                                      carts === "Added to Cart" ? (
                                        <Link to="/cart">
                                          {" "}
                                          {carts} -{" "}
                                          <span
                                            style={{
                                              textDecoration: "underline",
                                            }}
                                          >
                                            {" "}
                                            Go to Cart
                                          </span>{" "}
                                        </Link>
                                      ) : (
                                        carts
                                      )}
                                      <button
                                        type="button"
                                        class="btn-close"
                                        data-dismiss="alert"
                                        aria-label="Close"
                                        // onClick={()=>dispatch({type:addCart.add.success,data:false})}
                                      ></button>
                                    </div>
                                  ) : (
                                    false
                                  )}
                                </div>
                                <div>
                                  {wish ? (
                                    <div
                                      class="alert alert-warning alert-dismissible fade show"
                                      role="alert"
                                      mt-3
                                    >
                                      {wish}
                                      <button
                                        type="button"
                                        class="btn-close"
                                        data-dismiss="alert"
                                        // onClick={()=>dispatch({type:addWishList.wishList.success,data:false})}
                                        aria-label="Close"
                                      ></button>
                                    </div>
                                  ) : (
                                    false
                                  )}
                                </div>
                                <div class="product-details-action-wrap mb-5">
                                  <div
                                    class="product-quality"
                                    style={{ textAlign: "center" }}
                                  >
                                    <span
                                      style={{
                                        display: "flex",
                                        padding: "5px 10px",
                                        marginTop: "8px",
                                        marginBottom: "5px",
                                      }}
                                    >
                                      {/* <div  style={{display:"flex"}}> */}
                                      <span
                                        class="minus-btn"
                                        onClick={() => {
                                          return quantity > 1
                                            ? setQuantity(quantity - 1)
                                            : false;
                                        }}
                                      >
                                        <i class="bx bx-minus"></i>
                                      </span>
                                      <input
                                        type="text"
                                        min="1"
                                        disabled
                                        style={{
                                          outline: "none",
                                          border: "none",
                                          background: "none",
                                          textAlign: "center",
                                          marginTop: "-3px",
                                        }}
                                        value={quantity}
                                        onChange={(e) =>
                                          setQuantity(e.target.value)
                                        }
                                      />
                                      {/* <input type="text" min="1" value={quantity[i]} onChange={(e)=>setQuantity(e.target.value)} /> */}
                                      <span
                                        class="plus-btn"
                                        onClick={() => {
                                          setQuantity(quantity + 1);
                                        }}
                                      >
                                        <i class="bx bx-plus"></i>
                                      </span>
                                    </span>
                                    {/* </div> */}
                                  </div>
                                  <div class="single-product-cart btn-hover">
                                    {Token ? (
                                      <a
                                        style={{
                                          fontSize: "18px",
                                          paddingTop: "10px",
                                          paddingBottom: "10px",
                                          paddingleft: "20px",
                                          color: "white",
                                          borderRadius: "20px",
                                        }}
                                        onClick={handleClick}
                                      >
                                        Add to Cart{" "}
                                        <i
                                          style={{
                                            marginLeft: "5px",
                                            fontSize: "15px",
                                          }}
                                          class="fa fa-shopping-cart"
                                          aria-hidden="true"
                                        ></i>
                                      </a>
                                    ) : (
                                      <a
                                        style={{
                                          fontSize: "18px",
                                          paddingTop: "10px",
                                          paddingBottom: "10px",
                                          paddingleft: "20px",
                                          borderRadius: "20px",
                                        }}
                                        href="/login"
                                      >
                                        Add to Cart{" "}
                                        <i
                                          style={{
                                            marginLeft: "5px",
                                            fontSize: "15px",
                                          }}
                                          class="fa fa-shopping-cart"
                                          aria-hidden="true"
                                        ></i>
                                      </a>
                                    )}
                                  </div>
                                  <div class="single-product-wishlist">
                                    <a
                                      title="Wishlist"
                                      onClick={() => {
                                        // setLoad(true);
                                        dispatch(
                                          addWishLists({
                                            productId:
                                              Product[changeVariant]?.productId,
                                          })
                                        ).then((res) => {
                                          // setLoad(false);
                                        });
                                      }}
                                    >
                                      {Product[changeVariant]?.favourites ===
                                      0 ? (
                                        <i
                                          class="bx bx-heart"
                                          style={{ fontSize: "26px" }}
                                        ></i>
                                      ) : (
                                        <i
                                          style={{ fontSize: "26px" }}
                                          class="bx bxs-heart"
                                        ></i>
                                      )}
                                    </a>
                                  </div>
                                </div>
                                <div>
                                  <span>
                                    <a
                                      href={
                                        "/productDetails/" +
                                        Product[changeVariant]?.productId
                                      }
                                      style={{
                                        fontSize: "18px",
                                        textDecoration: "underline",
                                      }}
                                    >
                                      View More Details
                                    </a>
                                  </span>

                                  {/* <div class="single-product-cart btn-hover mt-5">
                                    <a  style={{fontSize:"18px",paddingTop:"10px",paddingBottom:"10px",paddingleft:"20px",}}href="#">Add to Cart</a>
                                </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          false
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Product Modal end --> */}
        <div
          class="modal fade quickview-modal-style"
          id="loginModal"
          tabIndex="-1"
          role="dialog"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div
              class="modal-content"
              style={{ width: "70%", marginLeft: "180px" }}
            >
              <div class="modal-header">
                <a
                  href="#"
                  class="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i class="fa-solid fa-xmark"></i>
                </a>
              </div>
              <div class="modal-body">
                <div class="row gx-0">
                  <div class="col-lg-5 col-md-5 col-12">
                    <form
                    // onSubmit={handleSubmit}
                    >
                      <div class="row">
                        <div class="col-lg-12 mt-3 mb-3">
                          <div class="form-group">
                            <input
                              type="email"
                              class="form-control"
                              placeholder="Email"
                              name="email"
                              style={{
                                borderRadius: "0px",
                                width: "450px",
                                marginLeft: "125px",
                              }}
                              onChange={handleChange}
                              value={user.email}
                              required
                            />
                          </div>
                        </div>
                        <div class="col-lg-12 mt-3">
                          <div class="form-group">
                            <input
                              type="password"
                              class="form-control"
                              placeholder="Password"
                              name="password"
                              style={{
                                borderRadius: "0px",
                                width: "450px",
                                marginLeft: "125px",
                              }}
                              onChange={handleChange}
                              value={user.password}
                              required
                            />
                            <span
                              style={{
                                color: "brown",
                                marginLeft: "20px",
                              }}
                            >
                              {passworderror}
                            </span>
                          </div>
                        </div>
                        <div
                          class="col-lg-12 mb-3"
                          style={{
                            borderRadius: "0px",
                            width: "850px",
                            marginLeft: "125px",
                          }}
                        >
                          <p class="forgot-password">
                            <Link to="/login">
                              Don't you have an account? <b>Register</b>
                            </Link>
                          </p>
                        </div>
                        <div
                          class="col-lg-12"
                          style={{
                            borderRadius: "0px",
                            width: "850px",
                            marginLeft: "113px",
                          }}
                        >
                          <div class="single-product-cart btn-hover">
                            <a
                              onClick={handleSubmit}
                              style={{
                                fontSize: "18px",
                                paddingTop: "10px",
                                paddingBottom: "10px",
                                paddingleft: "20px",
                                borderRadius: "5px",
                              }}
                              href="#"
                            >
                              Log In
                            </a>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Mobile Menu start -->  */}
        <div class="off-canvas-active">
          <a class="off-canvas-close">
            <i class="fa-solid fa-xmark"></i>
          </a>
          <div class="off-canvas-wrap">
            {/* <div class="welcome-text off-canvas-margin-padding">
              <p>Default Welcome Msg! </p>
            </div> */}
            <div class="mobile-menu-wrap off-canvas-margin-padding-2">
              <div id="mobile-menu" class="slinky-mobile-menu text-left">
                <ul>
                  <li>
                    <a href="/">HOME</a>
                  </li>
                  <li>
                    <a href="/products">SHOP</a>
                  </li>
                  <li>
                    <a href="/categories">CATEGORIES </a>
                  </li>

                  <li>
                    <a href="/aboutus">ABOUT US</a>
                  </li>
                  <li>
                    <a href="/contactus">CONTACT US</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoryShop;
