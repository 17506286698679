import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link, useLocation, useMatch } from "react-router-dom";
import { GrSearch } from "react-icons/gr";
import { AiOutlineClose } from "react-icons/ai";
import { FaRegHeart } from "react-icons/fa";
import { FiShoppingBag } from "react-icons/fi";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { IoMdPower } from "react-icons/io";
import { GiHamburgerMenu } from "react-icons/gi";
import { BiUser } from "react-icons/bi";
import bedwell_logo from "../../images/bedwell_logo.png";

import "./style.css";

import {
  getCategories,
  getCartCounts,
  logoutUser,
  getWelcomeMessages,
} from "../../Redux/Action";

const Header = () => {
  const [show, setShow] = useState(true);
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [err, setErr] = useState("");
  const dispatch = useDispatch();
  const jquery = window.$;
  const Count = useSelector(({ getCartCounts }) => getCartCounts.payload);
  const Token = localStorage.getItem("AuthTok");
  let location = useLocation();
  const match = useMatch("search/:id");

  const handleClick = (e) => {
    e.preventDefault();
    if (search === "") {
      setErr("Search Box is Empty");
      setTimeout(() => {
        setErr("");
      }, 3000);
    } else {
      navigate(`${"/search/" + search}`);
    }
  };

  const category = useSelector(({ getCategories }) => getCategories.payload);

  const catshop = (id) => {
    navigate("/categoryShop/" + id);
  };

  useEffect(() => {
    dispatch(getCategories());
    dispatch(getWelcomeMessages());
  }, []);
  const welcome = useSelector(
    ({ getWelcomeMessages }) => getWelcomeMessages.payload
  );

  useEffect(() => {
    if (window.$ && window.$(".currency-wrap").length) {
      var $body2 = window.$("body"),
        $urdanDropdown2 = window.$(".currency-wrap"),
        $urdanDropdownMenu2 = $urdanDropdown2.find(".currency-dropdown");
      $urdanDropdown2.on("click", ".currency-active", function (e) {
        e.preventDefault();
        var $this = window.$(this);
        if (!$this.parent().hasClass("show")) {
          $this
            .siblings(".currency-dropdown")
            .addClass("show")
            .slideDown()
            .parent()
            .addClass("show");
        } else {
          $this
            .siblings(".currency-dropdown")
            .removeClass("show")
            .slideUp()
            .parent()
            .removeClass("show");
        }
      });
      /*Close When Click Outside*/
      $body2.on("click", function (e) {
        var $target = e.target;
        if (
          !window.$($target).is(".currency-wrap") &&
          !window.$($target).parents().is(".currency-wrap") &&
          $urdanDropdown2.hasClass("show")
        ) {
          $urdanDropdown2.removeClass("show");
          $urdanDropdownMenu2.removeClass("show").slideUp();
        }
      });
    }

    if (window.$ && window.$(".language-wrap").length) {
      var $body3 = window.$("body"),
        $urdanDropdown3 = window.$(".language-wrap"),
        $urdanDropdownMenu3 = $urdanDropdown3.find(".language-dropdown");
      $urdanDropdown3.on("click", ".language-active", function (e) {
        e.preventDefault();
        var $this = window.$(this);
        if (!$this.parent().hasClass("show")) {
          $this
            .siblings(".language-dropdown")
            .addClass("show")
            .slideDown()
            .parent()
            .addClass("show");
        } else {
          $this
            .siblings(".language-dropdown")
            .removeClass("show")
            .slideUp()
            .parent()
            .removeClass("show");
        }
      });
      /*Close When Click Outside*/
      $body3.on("click", function (e) {
        var $target = e.target;
        if (
          !window.$($target).is(".language-wrap") &&
          !window.$($target).parents().is(".language-wrap") &&
          $urdanDropdown3.hasClass("show")
        ) {
          $urdanDropdown3.removeClass("show");
          $urdanDropdownMenu3.removeClass("show").slideUp();
        }
      });
    }

    var header = window.$(".sticky-bar");
    var $window = window.$(window);
    $window.on("scroll", function () {
      var scroll = $window.scrollTop();
      if (scroll < 200) {
        header.removeClass("stick");
      } else {
        header.addClass("stick");
      }
    });

    if (window.$) {
      var searchToggle = window.$(".search-toggle");
      searchToggle.on("click", function (e) {
        e.preventDefault();

        if (window.$(this).hasClass("open")) {
          window.$(this).removeClass("open");
          window.$(this).siblings(".search-wrap-1").removeClass("open");
        } else {
          window.$(this).addClass("open");
          window.$(this).siblings(".search-wrap-1").addClass("open");
        }
      });
    }
    function mobileMainMenu() {
      if (window.$) {
        var navbarTrigger = window.$(".mobile-menu-active-button"),
          endTrigger = window.$(".off-canvas-close"),
          container = window.$(".off-canvas-active"),
          wrapper = window.$(".main-wrapper-2");

        wrapper.prepend('<div className="body-overlay-2"></div>');

        navbarTrigger.on("click", function (e) {
          e.preventDefault();
          container.addClass("inside");
          wrapper.addClass("overlay-active-2");
        });

        endTrigger.on("click", function () {
          container.removeClass("inside");
          wrapper.removeClass("overlay-active-2");
        });

        window.$(".body-overlay-2").on("click", function () {
          container.removeClass("inside");
          wrapper.removeClass("overlay-active-2");
        });
      }
    }
    mobileMainMenu();
    dispatch(getCartCounts());
  }, [jquery]);

  //logout user
  const logout = () => {
    dispatch(logoutUser());
    localStorage.removeItem("AuthTok");

    window.location.href = "/";
  };
  return (
    <>
      <header className="header-area header-responsive-padding header-height-1">
        {show ? (
          <>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                className=" mb-3"
                style={{
                  width: "100%",
                  height: "40px",
                  backgroundColor: "	#F5F5F5",
                  borderColor: "#F5F5F5",
                  padding: "0px 10px",
                  textAlign: "center",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <span></span>
                <span style={{ textAlign: "center" }}>
                  {welcome?.message !== 0 ? welcome?.message : false}
                </span>
                <span onClick={() => setShow(false)}>
                  <AiOutlineCloseCircle
                    style={{ fontSize: "25px", cursor: "pointer" }}
                  />
                </span>
              </div>
            </div>
          </>
        ) : (
          false
        )}
        <div className="header-top d-none d-lg-block bg-gray">
          <div className="container">
            <div className="row align-items-center">
              {show ? false : <div className="col-lg-12 px-5 mt-5"></div>}
              <div className="col-lg-6 col-6">
                <div className="language-currency-wrap">
                  {/* <div className="currency-wrap border-style">
                                    <a className="currency-active" href="#">$ Dollar (US) <i className=" ti-angle-down "></i></a>
                                    <div className="currency-dropdown">
                                        <ul>
                                            <li><a href="#">Taka (BDT) </a></li>
                                            <li><a href="#">Riyal (SAR) </a></li>
                                            <li><a href="#">Rupee (INR) </a></li>
                                        </ul>
                                    </div>
                                </div> */}
                  <div className="language-wrap">
                    <a
                      style={{ display: "none" }}
                      className="language-active"
                      href="#"
                    >
                      <img src="assets/images/icon-img/flag.png" alt="" />{" "}
                      English <i className=" ti-angle-down "></i>
                    </a>
                    <div className="language-dropdown">
                      <ul style={{ display: "none" }}>
                        <li>
                          <a href="#">
                            <img src="assets/images/icon-img/flag.png" alt="" />
                            English{" "}
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <img
                              src="assets/images/icon-img/spanish.png"
                              alt=""
                            />
                            Spanish
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <img
                              src="assets/images/icon-img/arabic.png"
                              alt=""
                            />
                            Arabic{" "}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header-bottom sticky-bar ">
          {/* {show?
                            <div className="sticky" style={{width:"100%",display: 'none',justifyContent: 'center'}}>
                            <div className="mt-3 mb-3" style={{width:"50%",height:"40px",backgroundColor:"#fff3cd",borderColor:"#ffecb5",padding:"0px 10px",textAlign:"center",borderRadius:"5px",display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                              <span style={{textAlign: 'center',fontWeight:"600"}}>{welcome?.message!==0 ? welcome?.message :
                                
                                false}</span>
                              <span onClick={()=>setShow(false)}><AiOutlineCloseCircle style={{fontSize:"25px",cursor:"pointer"}}/></span>
                            </div>
                            </div>
                            : false} */}
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-2 col-md-6 col-6">
                <div className="">
                  <Link to="/">
                    <img
                      src={bedwell_logo}
                      alt="logo"
                      style={{
                        width: "100%",
                        maxWidth: "200px",
                      }}
                    />
                  </Link>
                </div>
              </div>
              <div className="col-lg-7 d-none d-lg-block d-flex justify-content-center">
                <div className="main-menu text-center">
                  <nav>
                    <ul>
                      <li>
                        {" "}
                        <a href="/">
                          <i
                            className="bx bxs-home"
                            style={{ padding: "5px", fontSize: "18px" }}
                          ></i>
                          <span
                            style={{ fontSize: "16px", fontWeight: "bold" }}
                          >
                            {" "}
                            HOME
                          </span>
                        </a>
                      </li>
                      <li>
                        <Link to="/products">
                          <i
                            className="bx bxs-cart"
                            style={{ padding: "5px", fontSize: "18px" }}
                          ></i>
                          <span
                            style={{ fontSize: "16px", fontWeight: "bold" }}
                          >
                            {" "}
                            SHOP
                          </span>{" "}
                        </Link>
                      </li>
                      <li>
                        <Link to="/categories">
                          <i
                            className="bx bxs-category"
                            style={{
                              padding: "5px",
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          ></i>
                          <span
                            style={{ fontSize: "16px", fontWeight: "bold" }}
                          >
                            CATEGORIES
                          </span>{" "}
                        </Link>

                        <ul className="sub-menu-style">
                          {Array.isArray(category) && category?.length !== 0
                            ? category?.map((data, index) => (
                                <li key={index}>
                                  <a
                                    style={{ textTransform: "capitalize" }}
                                    onClick={() => catshop(data?.id)}
                                  >
                                    {data?.categoryName}{" "}
                                  </a>
                                </li>
                              ))
                            : false}
                        </ul>
                      </li>
                      <li>
                        <Link to="/blog">
                          <i
                            className="bx bxl-blogger"
                            style={{ padding: "5px", fontSize: "18px" }}
                          ></i>
                          <span
                            style={{ fontSize: "16px", fontWeight: "bold" }}
                          >
                            {" "}
                            BLOGS
                          </span>{" "}
                        </Link>
                      </li>
                      {/* <li><Link to="/aboutus">
                        <i className='bx bxs-detail' style={{padding:"5px"}} ></i>
                          ABOUT US</Link></li> */}
                      <li>
                        <Link to="/contactus">
                          <i
                            className="bx bxs-contact"
                            style={{ padding: "5px", fontSize: "18px" }}
                          ></i>
                          <span
                            style={{ fontSize: "16px", fontWeight: "bold" }}
                          >
                            {" "}
                            CONTACT US{" "}
                          </span>{" "}
                        </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-6">
                <div className="header-action-wrap">
                  {location.pathname === "/" || match ? (
                    <div
                      className="header-action-style header-search-1"
                      style={{ padding: "3px" }}
                    >
                      <a className="search-toggle" href="#">
                        {/* <i className="pe-7s-search s-open"></i> */}
                        <GrSearch className="s-open" />
                        <AiOutlineClose
                          className="s-close"
                          style={{ marginLeft: "-24px" }}
                        />
                        {/* <i className="pe-7s-close s-close"></i> */}
                      </a>

                      <div className="search-wrap-1" style={{ width: "198px" }}>
                        <form onSubmit={handleClick}>
                          <input
                            placeholder="Search products…"
                            type="text"
                            //  className="form-control"
                            onChange={(e) => setSearch(e.target.value)}
                          />
                          <button className="button-search">
                            <i className="pe-7s-search"></i>
                          </button>
                        </form>
                        <span style={{ color: "red", fontWeight: "600" }}>
                          {err ? err : false}
                        </span>
                      </div>
                    </div>
                  ) : (
                    false
                  )}
                  <div
                    className="header-action-style"
                    style={{ padding: "3px" }}
                  >
                    {Token ? (
                      <Link
                        to="/profile"
                        title="Profile"
                        style={{ fontSize: "25px" }}
                      >
                        {/* <FaUserCircle/> */}
                        <BiUser />
                        {/* <i className="pe-7s-user"></i> */}
                      </Link>
                    ) : (
                      <Link
                        to="/login"
                        title="Login"
                        style={{ fontSize: "25px" }}
                      >
                        {/* <FaUserCircle/> */}
                        <BiUser />
                        {/* <i className="pe-7s-user"></i> */}
                        {/* <i className="pe-7s-angle-right-circle"></i> */}
                      </Link>
                    )}
                  </div>
                  <div
                    className="header-action-style"
                    style={{ padding: "3px" }}
                  >
                    <Link to="/wishList" title="wishList">
                      <FaRegHeart />
                      {/* <i className="pe-7s-like"></i> */}
                    </Link>
                  </div>
                  <div
                    className="header-action-style header-action-cart"
                    style={{ padding: "3px" }}
                  >
                    <Link to="/cart" title="Cart">
                      <FiShoppingBag />
                      {/* <i className="pe-7s-shopbag"></i> */}
                      {Count?.count > 0 ? (
                        <span className="product-count bg-black">
                          {Count.count}
                        </span>
                      ) : (
                        false
                      )}
                      {/* <span className="product-count bg-black">01</span> */}
                    </Link>
                  </div>
                  <div
                    className="header-action-style log "
                    style={{ marginLeft: "10px", padding: "3px" }}
                  >
                    {Token ? (
                      <a onClick={logout} title="Log Out">
                        <IoMdPower />
                        {/* <i className="pe-7s-power"></i> */}
                      </a>
                    ) : (
                      false
                    )}
                  </div>
                  <div
                    className="header-action-style d-block d-lg-none"
                    style={{ padding: "3px" }}
                  >
                    <a className="mobile-menu-active-button" href="#">
                      {/* <i className="pe-7s-menu"></i> */}
                      <GiHamburgerMenu />
                    </a>
                  </div>
                  <div className="off-canvas-active">
                    <a className="off-canvas-close">
                      <i className=" ti-close "></i>
                    </a>
                    <div className="off-canvas-wrap">
                      {/* <div className="welcome-text off-canvas-margin-padding">
              <p>Default Welcome Msg! </p>
            </div> */}
                      <div className="mobile-menu-wrap off-canvas-margin-padding-2">
                        <div
                          id="mobile-menu"
                          className="slinky-mobile-menu text-left"
                        >
                          {/* {welcome?  <div className="welcome-text mt-5">
                                <p style={{textAlign: 'center',fontWeight:"600",fontSize:"14px",}}>{welcome?.message!==0 ? welcome?.message :
                                
                                false} </p>
                            </div>:false} */}
                          {/* {show?
                            <div className="mt-3 mb-3" style={{width:"100%",height:"40px",backgroundColor:"#fff3cd",borderColor:"#ffecb5",textAlign:"center",borderRadius:"5px",display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                              <span>test</span>
                              <span onClick={()=>setShow(false)}><AiOutlineCloseCircle style={{fontSize:"25px",cursor:"pointer"}}/></span>
                            </div> : false} */}
                          <ul>
                            <li>
                              <a href="/">HOME</a>
                              <hr />
                            </li>
                            <li>
                              <a href="/products">SHOP</a>
                              <hr />
                            </li>
                            <li>
                              <a href="/categories">CATEGORIES </a>
                              <hr />
                            </li>

                            <li>
                              <a href="/aboutus">ABOUT US</a>
                              <hr />
                            </li>
                            <li>
                              <a href="/contactus">CONTACT US</a>
                              {Token ? <hr /> : <hr />}
                              {/* <hr /> */}
                            </li>
                            <li>
                              {Token ? (
                                <a onClick={logout}>LOGOUT</a>
                              ) : (
                                <a href="/login">LOGIN</a>
                              )}
                              {/* {Token?
                  <hr/>:false
                  } */}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
