import React, { useState, useEffect } from "react";
import AddressList from '../Profiles/AddressList'
import { addUserAddress } from '../../Redux/Action'
import { useSelector, useDispatch } from "react-redux";
const AddressForm = () => {
  const token =localStorage.getItem("AuthTok");
  if(token === null || token === "undefined" || token === ""){
    window.location.href = "/login";
  }
    // const[render,setRender]=useState(false);
    const dispatch = useDispatch();
    const Swal = require("sweetalert2");
    const [address, setAddress] = useState({
      state: "",
      city: "",
      street: "",
      landmark: "",
      zipcode: "",
      primary: "",
      district: "",
      addressType: "",
    });
    const handleChange = (e) => {
      setAddress({ ...address, [e.target.name]: e.target.value });
    };
    const handleSubmit = (e) => { 
      // console.log("test");
      e.preventDefault();
      dispatch(addUserAddress(address)).then((res)=>{
        Swal.fire({
            icon: "success",
            timer: 4000,
            buttons: false,
            showConfirmButton: false,
            text: "Address Added Succcessfully!",
          }).then(() => {
            window.location.href="/profile"
        })
        .catch((err) => {
            Swal.fire({
              icon: "error",
              timer: 4000,
              buttons: false,
              text: "Somethong Went Wrong!",
            }).then(() => {
                window.location.href="/profile"
          })
          });
     })
    //  setRender(true);
    };


  return (
    <div>
  <section className="register-area">
        <div className="container col-lg-6 d-flex justify-content-start">
          <div className="register-form">
            <div className="register-in-title">
              <h3 style={{ textAlign: "center",marginBottom:"50px",marginTop:"18px" }}>Add Address</h3>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <input
                      type="text"
                      name="street"
                      style={{marginBottom:"15px"}}
                      value={address.street}
                      onChange={handleChange}
                      className="form-control form-control-lg"
                      placeholder="Street"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <input
                      type="text"

                      name="city"
                      style={{marginBottom:"15px"}}
                      value={address.city}
                      onChange={handleChange}
                      className="form-control form-control-lg"
                      placeholder="City"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <input
                      type="text"
                      name="district"
                      value={address.district}
                      style={{marginBottom:"15px"}}
                      onChange={handleChange}
                      className="form-control form-control-lg"
                      placeholder="District"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <input
                      type="text"
                      name="landmark"
                      style={{marginBottom:"15px"}}
                      value={address.landmark}
                      onChange={handleChange}
                      className="form-control form-control-lg"
                      placeholder="Landmark"
                      required
                    />
                  </div>
                </div>
                  
                {/* <label className="mb-3" style={{fontWeight:"bold"}}>Gender</label> */}
                {/* <div className="col-lg-6">
                  <div className="form-group">
                    <input
                      type="text"
                      name="primary"
                      value={address.primary}
                      onChange={handleChange}
                      className="form-control"
                      placeholder="Primary"
                      required
                    />
                  </div>
                </div> */}
                <div className="col-lg-6">
                  <div className="form-group">
                    <input
                      type="text"
                      name="state"
                      style={{marginBottom:"15px"}}
                      value={address.state}
                      onChange={handleChange}
                      className="form-control form-control-lg"
                      placeholder="State"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <input
                      type="text"
                      style={{marginBottom:"15px"}}
                      name="zipcode"
                      value={address.zipcode}
                      onChange={handleChange}
                      className="form-control form-control-lg"
                      placeholder="zipcode"
                      required
                    />
                  </div>
                </div>
             
                <div className="form-group">
                <select
                  className="form-select" 
                  value={address.primary}
                  onChange={handleChange}
                  name="primary"
                  aria-label="Default select example"
                  style={{marginLeft:"0px",height:"49px",marginBottom:"15px"}}
                  // style={{maxWidth:"529px",marginLeft:"0px",height:"49px",marginBottom:"15px"}}
                  >
                  <option selected> Is Primary</option>
                  <option value="yes">Yes</option>
                  <option value="No">No</option>
                </select> </div>
                <div className="form-group">
                <select
                  className="form-select" 
                  value={address.addressType}
                  onChange={handleChange}
                  name="addressType"
                  aria-label="Default select example"
                  style={{marginLeft:"0px",height:"49px",marginBottom:"15px"}}
                  >
                  <option selected> Choose Address </option>
                  <option value="home">Home </option>
                  <option value="office">Office</option>
                  <option value="other">Others</option>
                </select></div>
                <div className="col-lg-12">
                  <div
                    className="send-btn"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <button type="submit" className="btn btn-dark" style={{background:"#DD3D05",border:"none",marginTop:"15px",marginBottom:"25px",borderRadius:"20px"}}>
                      Add Address
                      <span></span>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
<AddressList/>
    </div>
  )
}

export default AddressForm