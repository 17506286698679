import React, { useEffect, useState } from "react";
import "boxicons";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getCarts, updateCarts, removeCarts } from "../Redux/Action";
import cartimage from "../images/shopping_cart.svg";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Cart = () => {
  const formatter = new Intl.NumberFormat("en-IN", {
    maximumSignificantDigits: 3,
  });
  const [load, setLoad] = useState(true);
  const [quantity, setQuantity] = useState("");
  const getCart = useSelector(({ getCarts }) => getCarts.payload);
  // console.log("🚀 ~ file: Cart.jsx ~ line 19 ~ Cart ~ getCart", getCart?.cart[0]?.customizationDetails)
  const [render, setRender] = useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const navigate = useNavigate();
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const dispatch = useDispatch();
  useEffect(() => {}, [getCart]);

  useEffect(() => {
    dispatch(getCarts())
      .then((res) => setLoad(false))
      .catch((res) => setLoad(false));
  }, [render]);

  const eventClick = () => {
    navigate("/products", { replace: true });
  };
  const eventClick1 = () => {
    navigate("/checkout", { replace: true });
  };

  useEffect(() => {
    if (getCart?.cart) {
      setQuantity(getCart?.cart?.map((A) => A.units));
    }
  }, [getCart]);

  const handleClickplus = (i, C) => {
    quantity[i] = quantity[i] + 1;
    if (quantity[i] < 1) {
    } else {
      dispatch(updateCarts({ units: "add", cartId: C.id }));

      setRender(!render);
    }
  };
  const handleClickminus = (i, C) => {
    quantity[i] = quantity[i] - 1;
    if (quantity[i] < 1) {
    } else {
      dispatch(updateCarts({ units: "sub", cartId: C.id }));

      setRender(!render);
    }
  };

  // }

  // function checkData(data) {

  //   console.log(JSON.parse(data));

  //   let width = data ? Object.keys(JSON.parse(data)).includes("width") : null
  //   let height = data ? Object.keys(JSON.parse(data)).includes("height") : null
  //   let length = data ? Object.keys(JSON.parse(data)).includes("length") : null

  //  return (

  //   data ? (
  //     ( width ? ( <span> width :  { JSON.parse(data).width }  </span> ) : '')+
  //     ( height ? ( <span> height : { JSON.parse(data).height } </span> ) : '')+
  //     ( length ? ( <span> length : { JSON.parse(data).length } </span> ) : '')
  //   ) : (
  //     false
  //   ))
  // }

  return load ? (
    <div
      style={{
        height: "400px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : (
    <>
      <div className="main-wrapper main-wrapper-2">
        <div className="breadcrumb-area bg-gray-4 pt-5 pb-5">
          <div className="container">
            <div className="breadcrumb-content text-center">
              <h2
                data-aos="fade-up"
                data-aos-delay="200"
                style={{ textTransform: "uppercase" }}
              >
                Cart
              </h2>
              <ul data-aos="fade-up" data-aos-delay="400">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <i className="ti-angle-right"></i>
                </li>
                <li>Cart </li>
              </ul>
            </div>
          </div>
        </div>
        <div id="hp-ctn-howItWorks">
          <a href="/contactUs">
            <p className="c">Bulk&nbsp;Orders </p>
          </a>
        </div>
        <div className="cart-area ">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <form action="#">
                  <div className="cart-table-content">
                    <div className="table-content table-responsive">
                      {Array.isArray(getCart?.cart) &&
                      getCart?.cart?.length != 0 ? (
                        <table>
                          <thead>
                            <tr>
                              <th className="width-thumbnail">Product Image</th>
                              <th className="width-name">Product</th>
                              <th className="width-price"> Unit Price</th>
                              <th className="width-quantity">Quantity</th>
                              <th className="width-subtotal">Subtotal</th>
                              <th className="width-remove"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {Array.isArray(getCart?.cart) &&
                            getCart?.cart?.length != 0 ? (
                              getCart?.cart.map((C, i) => (
                                <tr>
                                  <td className="product-thumbnail">
                                    <a href={"/productDetails/" + C.productId}>
                                      <img
                                        src={
                                          process.env.REACT_APP_BASE_URL +
                                          C.variantImage
                                        }
                                        alt=""
                                        style={{ borderRadius: "10px" }}
                                      />
                                    </a>
                                  </td>
                                  <td className="product-name">
                                    <h5>
                                      <a
                                        href={"/productDetails/" + C.productId}
                                      >
                                        <span
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {C?.productName} <br /> <br />
                                        </span>
                                        {JSON.parse(C.customizationDetails) ===
                                        null ? (
                                          false
                                        ) : (
                                          <span>
                                            {JSON.parse(C.customizationDetails)
                                              .width ||
                                            JSON.parse(C.customizationDetails)
                                              .width1
                                              ? "Width : "
                                              : false}
                                            {Math.round(
                                              JSON.parse(C.customizationDetails)
                                                .width
                                            ) ||
                                              Math.round(
                                                JSON.parse(
                                                  C.customizationDetails
                                                ).width1
                                              ) + " inch"}{" "}
                                          </span>
                                        )}
                                        {JSON.parse(C.customizationDetails) ===
                                        null ? (
                                          false
                                        ) : (
                                          <span>
                                            {JSON.parse(C.customizationDetails)
                                              .height ||
                                            JSON.parse(C.customizationDetails)
                                              .height1
                                              ? "Height : "
                                              : false}{" "}
                                            {JSON.parse(C.customizationDetails)
                                              .height ||
                                            JSON.parse(C.customizationDetails)
                                              .height1
                                              ? Math.round(
                                                  JSON.parse(
                                                    C.customizationDetails
                                                  ).height
                                                ) ||
                                                Math.round(
                                                  JSON.parse(
                                                    C.customizationDetails
                                                  ).height1
                                                ) + "inch"
                                              : ""}
                                          </span>
                                        )}
                                        {JSON.parse(C.customizationDetails) ===
                                        null ? (
                                          false
                                        ) : (
                                          <span>
                                            {" "}
                                            {JSON.parse(C.customizationDetails)
                                              .length ||
                                            JSON.parse(C.customizationDetails)
                                              .length1
                                              ? "Length : "
                                              : false}{" "}
                                            {Math.round(
                                              JSON.parse(C.customizationDetails)
                                                .length
                                            ) ||
                                              Math.round(
                                                JSON.parse(
                                                  C.customizationDetails
                                                ).length1
                                              ) + " inch"}
                                          </span>
                                        )}
                                        {/* { checkData(C.customizationDetails) } */}
                                        {
                                          // Object.keys(
                                          //   JSON.parse(C.customizationDetails)
                                          // ).includes("height") ? (
                                          //   <span>
                                          //     height :{" "}
                                          //     {
                                          //       JSON.parse(C.customizationDetails)
                                          //         .height
                                          //     }{" "}
                                          //   </span>
                                          // ) : (
                                          //   false
                                          // )
                                        }
                                      </a>
                                    </h5>
                                  </td>
                                  <td className="product-cart-price">
                                    <span className="amount">
                                      {" "}
                                      ₹ {formatter.format(C.singleProductPrice)}
                                    </span>
                                  </td>
                                  {/* <td
                                    className="cart-quality"
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      marginTop: "20px",
                                    }}
                                  >
                                    <div
                                      className="product-quality"
                                      style={{ textAlign: "center",width:"34%" }}
                                    >
                                      <span
                                        style={{
                                          display: "flex",
                                          padding: "5px 10px",
                                          marginTop: "8px",
                                          marginBottom: "5px",
                                        }}
                                      >
                                        <span
                                          className="minus-btn"
                                          onClick={() => handleClickminus(i, C)}
                                        >
                                          <i className="bx bx-minus"></i>
                                        </span>
                                        <input
                                          type="text"
                                          min="1"
                                       
                                          style={{
                                            outline: "none",
                                            border: "none",
                                            width: "40%",
                                            marginLeft: "8px",
                                            marginTop: "-5px",
                                            
                                          }}
                                          value={C.units}
                                          onChange={(e) =>
                                            setQuantity(e.target.value)
                                          }
                                        />

                                        <span
                                          className="plus-btn"
                                          onClick={() => handleClickplus(i, C)}
                                        >
                                          <i className="bx bx-plus"></i>
                                        </span>
                                      </span>
                                    </div>
                                  </td> */}

                                  <td className="cart-quality">
                                    <div
                                      className="product-quality"
                                      style={{
                                        textAlign: "center",
                                        marginLeft: "80px",
                                      }}
                                    >
                                      <span
                                        style={{
                                          display: "flex",
                                          padding: "5px 10px",
                                          marginTop: "8px",
                                          marginBottom: "5px",
                                        }}
                                      >
                                        <span
                                          className="minus-btn"
                                          onClick={() => handleClickminus(i, C)}
                                        >
                                          <i
                                            className="bx bx-minus"
                                            style={{ cursor: "pointer" }}
                                          ></i>
                                        </span>
                                        <input
                                          type="text"
                                          disabled
                                          min="1"
                                          style={{
                                            outline: "none",
                                            border: "none",
                                            background: "none",
                                            width: "20px",
                                            marginLeft: "8px",
                                            marginTop: "-5px",
                                          }}
                                          value={C.units}
                                          onChange={(e) =>
                                            setQuantity(e.target.value)
                                          }
                                        />

                                        <span
                                          className="plus-btn"
                                          onClick={() => handleClickplus(i, C)}
                                        >
                                          <i
                                            className="bx bx-plus"
                                            style={{ cursor: "pointer" }}
                                          ></i>
                                        </span>
                                      </span>
                                    </div>
                                  </td>
                                  <td className="product-total">
                                    <span>
                                      ₹{formatter.format(C.totalPrice)}
                                    </span>
                                  </td>
                                  <td className="product-remove">
                                    <a
                                      onClick={() => {
                                        setLoad(true);
                                        dispatch(
                                          removeCarts({ cartId: C.id })
                                        ).then((res) => setLoad(false));
                                      }}
                                      href="#"
                                    >
                                      <i className=" ti-trash "></i>
                                    </a>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <center>
                                <h3 className="mt-5 mb-5 pb-5 pt-5">
                                  "No Product Found!"
                                </h3>{" "}
                              </center>
                            )}
                          </tbody>
                        </table>
                      ) : (
                        <center>
                          <img
                            src={cartimage}
                            className="mt-5 mb-5  pt-5"
                            style={{ width: "25%" }}
                          />
                          <h3 className="mb-5 pb-3"> No Products Found</h3>{" "}
                        </center>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="cart-shiping-update-wrapper">
                        <button
                          type="button"
                          className="btn btn-danger mt-2"
                          onClick={eventClick}
                          style={{
                            background: "#DD3D05",
                            height: "42px",
                            cursor: "pointer",
                            borderRadius: "20px",
                            marginLeft: "11px",
                          }}
                        >
                          {" "}
                          <span style={{ fontWeight: "600" }}>
                            Continue Shopping
                          </span>
                        </button>

                        {/* <div className="cart-clear-wrap mb-4 " style={{marginTop:"-15px"}}>
                                        {Array.isArray(getCart?.cart) &&
                          getCart?.cart?.length != 0 ? (
                        <Accordion
                        
                        className="mt-3"
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
         
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
           
          <Typography sx={{ flexShrink: 0 }} >
        
         <span >Total : </span>
       ₹{getCart.totalPrice}
          </Typography>
          
        </AccordionSummary>
        <AccordionDetails>
        <Typography>
           GST : ₹{getCart?.gst}
        
          </Typography>
          <Typography>
           SubTotal : ₹{getCart?.subtotal}
            
          </Typography>
        
          <Typography>
           Discount : ₹{getCart?.discount}
            
          </Typography>
          <Typography>
           Price : ₹{getCart?.price}
            
          </Typography>
        </AccordionDetails>

      </Accordion>
       ) : false}
                                        </div> */}

                        {/* <div className="cart-clear btn"> */}
                        {Array.isArray(getCart?.cart) &&
                        getCart?.cart?.length != 0 ? (
                          <button
                            type="button"
                            className="btn btn-danger mt-2"
                            onClick={eventClick1}
                            style={{
                              background: "#DD3D05",
                              cursor: "pointer",
                              borderRadius: "20px",
                              marginLeft: "11px",
                            }}
                          >
                            {" "}
                            <span style={{ fontWeight: "600" }}>
                              Proceed to Checkout
                            </span>
                          </button>
                        ) : (
                          false
                        )}
                        {/* {Array.isArray(getCart?.cart) &&
                            getCart?.cart?.length != 0 ? (
                            <Link
                              style={{
                                color: "white",
                                background: "#DD3D05",
                                borderRadius: "20px",
                              }}
                              to="/checkout"
                            >
                              Proceed to Checkout
                            </Link>
                          ) : (
                            false
                          )} */}
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Mobile Menu start --> */}
        <div className="off-canvas-active">
          <a className="off-canvas-close">
            <i className=" ti-close "></i>
          </a>
          <div className="off-canvas-wrap">
            {/* <div className="welcome-text off-canvas-margin-padding">
              <p>Default Welcome Msg! </p>
            </div> */}
            <div className="mobile-menu-wrap off-canvas-margin-padding-2">
              <div id="mobile-menu" className="slinky-mobile-menu text-left">
                <ul>
                  <li>
                    <a href="/">HOME</a>
                  </li>
                  <li>
                    <a href="/products">SHOP</a>
                  </li>
                  <li>
                    <a href="/categories">CATEGORIES </a>
                  </li>

                  <li>
                    <a href="/aboutus">ABOUT US</a>
                  </li>
                  <li>
                    <a href="/contactus">CONTACT US</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Cart;
