import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetPasswords } from "../../Redux/Action";
import "./style.css"
const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    setError(localStorage.getItem("Error"));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    e.currentTarget.reset();
    dispatch(resetPasswords({ email: email }))
      .then(() => {
        navigate("/verify/" + email);
      })
      .catch((err) =>{

        setError(err.response.data.error.message);
        setTimeout(() => {
          setError("");
          
        }, 5000);
      }
       
       );
  };
  return (
    <>


<div className="main-wrapper main-wrapper-2">
    
    <div className="login-register-area pb-5 ">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12 offset-lg-2">
            <div className="login-register-wrapper">
              <div className="login-register-tab-list nav" style={{marginTop:"50px"}}>
       
                <a data-bs-toggle="tab" href="#">
                  <h4> ForgotPassword </h4>
                </a>
               
              </div>
              <div className="tab-content">
                <div id="lg1" className="tab-pane active">
                  <div className="login-form-container">
                    <div className="login-register-form">
                      <form onSubmit={handleSubmit} method="post">
                        
                      <input
                            type="email"
                            onChange={(e) => setEmail(e.target.value)}
                       
                            placeholder="Enter Your Email"
                            required
                          />
                        <div>
                          
                          {error?<div className="error-text " style={{marginLeft:"10px",textAlign:"center"}}>{error} </div>:false}  
                        </div>
                        
                        <div className="button-box btn-hover">
                          {error?<button type="submit" style={{marginTop:"30px"}}>Next</button>:<button type="submit" >Next</button>}
                          
                          
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>








    </>
  );
};

export default ForgetPassword;
