import React, { useEffect, useState } from "react";
import "./RefundOrder.css";
import { useParams } from "react-router-dom";
import {getReturnDetails} from "../../Redux/Action"
import { useSelector, useDispatch } from "react-redux";
import ReturnsDetails from "./ReturnsDetails";
function RefundOrder() {
  const [load, setLoad] = useState(true);
  const { id } = useParams();
  const [getid, setGetid] = useState({
    returnId: id,
  });
  const token =localStorage.getItem("AuthTok");
  if(token === null || token === "undefined" || token === ""){
    window.location.href = "/login";
  }
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getReturnDetails(getid))
    .then((res)=>{
      setLoad(false);
    })
      .catch(() => {
       
      });
  }, []);
  const getdetails = useSelector(
    ({ getReturnDetails }) => getReturnDetails.payload
  );
 
  return load ? (
    <div
      style={{
        height: "400px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : (



    <div className="container">
     <div className="row d-flex align-item-center justify-content-center">
              <div className="col-12 col-md-10 hh-grayBox pt45 pb20">
                <div className="row justify-content-between">
                  <div className={
                 getdetails?.orderStatus === "initiated"  || getdetails?.orderStatus ==="accepted" || getdetails?.orderStatus ==="received" || getdetails?.orderStatus ==="processing"|| getdetails?.orderStatus ==="refunded"? "orders-tracking completed": "orders-tracking"
                }>
                    <span className="is-complete" ></span>
                    <p> 1 <br/><span>{  getdetails.orderStatus === "initiated"?getdetails?.createdAt:false}</span></p>
                  </div>
                  <div  className={
                 getdetails.orderStatus === "accepted" || getdetails?.orderStatus ===  "received" ||getdetails?.orderStatus ==="processing" ||getdetails?.orderStatus ==="refunded"  ?"orders-tracking completed": "orders-tracking"
                }>
                    <span className="is-complete"></span>
                    <p> 2 <br/><span>{  getdetails?.orderStatus === "accepted"?getdetails?.createdAt:false}</span></p>
                  </div>
                  <div className={
                 getdetails?.orderStatus ===  "received" ||getdetails?.orderStatus ==="processing" ||getdetails?.orderStatus ==="refunded"  ?"orders-tracking completed": "orders-tracking"
                }>
                    <span className="is-complete"></span>
                    <p> 3 <br/><span>{  getdetails?.orderStatus === "received"?getdetails?.createdAt:false}</span></p>
                  </div>
                  <div className={
                getdetails?.orderStatus ==="processing" ||getdetails?.orderStatus ==="refunded"  ?"orders-tracking completed": "orders-tracking"
                }>
                    <span className="is-complete"></span>
                    <p> 4 <br/><span>{  getdetails?.orderStatus === "processing"?getdetails?.createdAt:false}</span></p>
                  </div>
                  <div className={
              getdetails?.orderStatus ==="refunded"  ?"orders-tracking completed": "orders-tracking"
                }>
                    <span className="is-complete"></span>
                    <p> 5 <br/><span>{  getdetails?.orderStatus === "refunded"?getdetails?.createdAt:false}</span></p>
                  </div>
                </div>
                <div style={{textAlign: "center",fontWeight: "600"}}>
                <span> 1 - Intiated , </span>
                <span> 2 - Accepted , </span>
                <span> 3 - Received ,</span>
                <span> 4 - Processing & </span>
                <span> 5 - Refunded  </span>
                </div>
              </div>
            </div>

            <ReturnsDetails/>
  </div>



  );
}

export default RefundOrder;
