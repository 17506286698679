import React from 'react'
import { Link } from "react-router-dom";
const BlogDetails = () => {
  return (
    <>
      <div className="main-wrapper main-wrapper-2 mt-5">

      <div className="blog-details-area  ">
            <div className="container">
            <div id="hp-ctn-howItWorks"  >
        <a href="/contactUs">
          <p className="c" >Bulk&nbsp;Orders </p>
          </a>
        </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="blog-details-wrapper">
                            <div className="blog-details-img-date-wrap mb-35" data-aos="fade-up" data-aos-delay="200">
                                <div className="blog-details-img">
                                    <img src="assets/images/blog/blog2Le.webp" alt="" style={{width:"50%"}}/>
                                </div>
                                <div className="blog-details-date">
                                    <h5>01 <span>May</span></h5>
                                </div>
                            </div>
                            <div className="blog-meta-2" data-aos="fade-up" data-aos-delay="200">
                                <ul>
                                    <li><a href="#">Furniture</a>,</li>
                                    <li>By:<a href="#"> Admin</a></li>
                                </ul>
                            </div>
                            <h1 data-aos="fade-up" data-aos-delay="200">Lorem ipsum dolor sit amet consec.</h1>
                            <p data-aos="fade-up" data-aos-delay="200">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut lab et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitatio ullamco laboris nisi ut aliquip ex ea commodo conse Duis aute irure dolor in reprehenderit in voluptate velit esse cillumlt dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa quiq officia deserunt mollit anim id est laborum. </p>
                            <blockquote className="blockquote-wrap" data-aos="fade-up" data-aos-delay="200">
                                <div className="quote-img">
                                    <img src="assets/images/icon-img/quote.png" alt=""/>
                                </div>
                                <h2>Lorem ipsum dolor sit amet, con adipis elit sed do eiusmod tempor incididunt ut labore et dolore.</h2>
                                <h4>Jont utdo</h4>
                            </blockquote>
                            <p data-aos="fade-up" data-aos-delay="200">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut lab et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitatio ullamco laboris nisi ut aliquip ex ea commodo conse Duis aute irure dolor.</p>
                            {/* <div className="blog-details-middle-img-wrap">
                                <div className="row">
                                    <div className="col-md-6 col-sm-6 col-12">
                                        <div className="blog-details-middle-img mb-30" data-aos="fade-up" data-aos-delay="200">
                                            <img src="assets/images/blog/blog-details-2.png" alt=""/>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-12">
                                        <div className="blog-details-middle-img mb-30" data-aos="fade-up" data-aos-delay="400">
                                            <img src="assets/images/blog/blog-details-3.png" alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <p data-aos="fade-up" data-aos-delay="200">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut lab et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitatio ullamco laboris nisi ut aliquip ex ea commodo conse Duis aute irure dolor in reprehenderit in voluptate velit esse cillumlt dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa quiq officia deserunt mollit anim id est laborum. </p>
                            {/* <div className="tag-social-wrap">
                                <div className="tag-wrap" data-aos="fade-up" data-aos-delay="200">
                                    <span>Tags:</span>
                                    <ul>
                                        <li><a href="#">Furniture</a>,</li>
                                        <li><a href="#">Electronic</a>,</li>
                                        <li><a href="#">Fashion</a></li>
                                    </ul>
                                </div>
                                <div className="social-comment-digit-wrap" data-aos="fade-up" data-aos-delay="400">
                                    <div className="social-icon-style-2">
                                        <a href="#"><i className="fa fa-facebook"></i></a>
                                        <a href="#"><i className="fa fa-dribbble"></i></a>
                                        <a href="#"><i className="fa fa-pinterest-p"></i></a>
                                    </div>
                                    <div className="comment-digit">
                                        <a href="#">2 <i className="fa fa-comments"></i></a>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="blog-author-wrap-2" data-aos="fade-up" data-aos-delay="200">
                                <div className="blog-author-img-2">
                                    <img src="assets/images/blog/blog-author-2.png" alt=""/>
                                </div>
                                <div className="blog-author-content-2">
                                    <h2>Admin Name</h2>
                                    <p>Lorem ipsum dolor sit amet, consecteg adipisicing elit sed do eius tempor incididunt ut lab et dolore magna aliqua. Ut enim ad minikl veniam, quis nostrud exercitatio.</p>
                                    <div className="social-icon-style-3">
                                        <a href="#"><i className="fa fa-facebook"></i></a>
                                        <a href="#"><i className="fa fa-dribbble"></i></a>
                                        <a href="#"><i className="fa fa-pinterest-p"></i></a>
                                        <a href="#"><i className="fa fa-twitter"></i></a>
                                        <a href="#"><i className="fa fa-glide-g"></i></a>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="blog-next-previous-post" data-aos="fade-up" data-aos-delay="200">
                                <div className="blog-prev-post-wrap">
                                    <div className="blog-prev-post-icon">
                                        <a href="#"><i className="fa fa-angle-left"></i></a>
                                    </div>
                                    <div className="blog-prev-post-content">
                                        <h3><a href="#">Lorem ipsum dolor sit amet cons ectetur</a></h3>
                                        <span>June 25, 2021</span>
                                    </div>
                                </div>
                                <div className="blog-next-post-wrap">
                                    <div className="blog-next-post-icon">
                                        <a href="#"> <i className="fa fa-angle-right"></i> </a>
                                    </div>
                                    <div className="blog-next-post-content">
                                        <h3><a href="#">Lorem ipsum dolor sit amet cons ectetur</a></h3>
                                        <span>June 25, 2021</span>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="blog-comment-wrapper">
                                <h4 className="blog-dec-title" data-aos="fade-up" data-aos-delay="200">Comments (02)</h4>
                                <div className="single-comment-wrapper single-comment-border" data-aos="fade-up" data-aos-delay="400">
                                    <div className="blog-comment-img">
                                        <img src="assets/images/blog/blog-comment-1.png" alt=""/>
                                    </div>
                                    <div className="blog-comment-content">
                                        <div className="comment-info-reply-wrap">
                                            <div className="comment-info">
                                                <span>October 14, 2020 </span>
                                                <h4>Anthony Stephens</h4>
                                            </div>
                                            <div className="comment-reply">
                                                <a href="#">Reply</a>
                                            </div>
                                        </div>
                                        <p>Lorem ipsum dolor sit amet, consecteg adipisicing elit sed do eius tempor incididunt ut lab et dolore magna aliqua. Ut enim ad minikl veniam, quis nostrud exercitatio. </p>
                                    </div>
                                </div>
                                <div className="single-comment-wrapper single-comment-ml" data-aos="fade-up" data-aos-delay="600">
                                    <div className="blog-comment-img">
                                        <img src="assets/images/blog/blog-comment-2.png" alt=""/>
                                    </div>
                                    <div className="blog-comment-content">
                                        <div className="comment-info-reply-wrap">
                                            <div className="comment-info">
                                                <span>October 14, 2020 </span>
                                                <h4>DX Joxova</h4>
                                            </div>
                                            <div className="comment-reply">
                                                <a href="#">Reply</a>
                                            </div>
                                        </div>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmodl tempor incididunt ut labore et dolore </p>
                                    </div>
                                </div>
                            </div> */}
                            {/* <div className="blog-comment-form-wrap">
                                <div className="blog-comment-form-title">
                                    <h2 data-aos="fade-up" data-aos-delay="200">Leave a Reply</h2>
                                    <p data-aos="fade-up" data-aos-delay="400">Your email address will not be published. please fill out this form.</p>
                                </div>
                                <div className="blog-comment-form">
                                    <form action="#">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6">
                                                <div className="single-blog-comment-form" data-aos="fade-up" data-aos-delay="200">
                                                    <input type="text" placeholder="Your Name"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6">
                                                <div className="single-blog-comment-form" data-aos="fade-up" data-aos-delay="300">
                                                    <input type="email" placeholder="Your Mail"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12">
                                                <div className="single-blog-comment-form" data-aos="fade-up" data-aos-delay="400">
                                                    <input type="text" placeholder="Your Website"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12">
                                                <div className="single-blog-comment-form" data-aos="fade-up" data-aos-delay="500">
                                                    <textarea placeholder="Comment"></textarea>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12">
                                                <div className="single-blog-comment-checkbox" data-aos="fade-up" data-aos-delay="600">
                                                    <input type="checkbox"/>
                                                    <label>Save my name, email, and website in this browser for the next time I comment.</label>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12">
                                                <div className="comment-submit-btn btn-hover" data-aos="fade-up" data-aos-delay="700">
                                                    <button className="submit" type="submit">Post Comment <i className=" ti-arrow-right"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    {/* <div className="col-lg-4">
                        <div className="sidebar-wrapper blog-sidebar-mt">
                            <div className="sidebar-widget mb-50" data-aos="fade-up" data-aos-delay="200">
                                <div className="search-wrap-3">
                                    <form className="search-3-form" action="#">
                                        <input placeholder="Search*" type="text"/>
                                        <button className="button-search"><i className="fa fa-search"></i></button>
                                    </form>
                                </div>
                            </div>
                            <div className="sidebar-widget mb-50" data-aos="fade-up" data-aos-delay="200">
                                <div className="blog-author-content text-center">
                                    <a href="blog-details.html"><img src="assets/images/blog/blog-author.png" alt=""/></a>
                                    <h2>Writer Name</h2>
                                    <h4>Blog Writer</h4>
                                    <div className="social-icon-style-1">
                                        <a href="#"><i className="fa fa-facebook"></i></a>
                                        <a href="#"><i className="fa fa-dribbble"></i></a>
                                        <a href="#"><i className="fa fa-pinterest-p"></i></a>
                                        <a href="#"><i className="fa fa-twitter"></i></a>
                                        <a href="#"><i className="fa fa-glide-g"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="sidebar-widget mb-40" data-aos="fade-up" data-aos-delay="200">
                                <div className="sidebar-widget-title-2 mb-25">
                                    <h3>Categories</h3>
                                </div>
                                <div className="sidebar-list-style-2">
                                    <ul>
                                        <li><a href="blog-sidebar.html">Blog Grid View (09)</a></li>
                                        <li><a href="blog-sidebar.html">Latest blog (02)</a></li>
                                        <li><a href="blog-sidebar.html">Our Blog (12)</a></li>
                                        <li><a href="blog-sidebar.html">Uncategorized (107)</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="sidebar-widget mb-40" data-aos="fade-up" data-aos-delay="200">
                                <div className="sidebar-widget-title-2 mb-30">
                                    <h3>Latest Post</h3>
                                </div>
                                <div className="latest-post-wrap">
                                    <div className="single-latest-post">
                                        <div className="latest-post-img">
                                            <a href="blog-details.html"><img src="assets/images/blog/latest-post-1.png" alt=""/></a>
                                        </div>
                                        <div className="latest-post-content">
                                            <span>01 May 2021</span>
                                            <h4><a href="blog-details.html">Lorem ipsum dolor sit am conse ctetur adipis</a></h4>
                                            <div className="latest-post-btn">
                                                <a href="blog-details.html">Continue Reading...</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="single-latest-post">
                                        <div className="latest-post-img">
                                            <a href="blog-details.html"><img src="assets/images/blog/latest-post-2.png" alt=""/></a>
                                        </div>
                                        <div className="latest-post-content">
                                            <span>08 Dec 2020</span>
                                            <h4><a href="blog-details.html">Phasellus vehicula sit amet nibh vitae posuere</a></h4>
                                            <div className="latest-post-btn">
                                                <a href="blog-details.html">Continue Reading...</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="single-latest-post">
                                        <div className="latest-post-img">
                                            <a href="blog-details.html"><img src="assets/images/blog/latest-post-3.png" alt=""/></a>
                                        </div>
                                        <div className="latest-post-content">
                                            <span>01 Feb 2021</span>
                                            <h4><a href="blog-details.html">Class aptent taciti sociosqu ad litora torquent</a></h4>
                                            <div className="latest-post-btn">
                                                <a href="blog-details.html">Continue Reading...</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="sidebar-widget mb-35" data-aos="fade-up" data-aos-delay="200">
                                <div className="sidebar-widget-title-2 mb-30">
                                    <h3>Tags</h3>
                                </div>
                                <div className="sidebar-widget-tag-2">
                                    <a href="#">Furniture</a>
                                    <a href="#">Electronic</a>
                                    <a href="#">Home Decore</a>
                                    <a href="#">Design Home</a>
                                    <a href="#">Furniture</a>
                                </div>
                            </div>
                            <div className="sidebar-widget" data-aos="fade-up" data-aos-delay="200">
                                <div className="sidebar-banner">
                                    <a href="product-details.html"><img src="assets/images/banner/banner-29.png" alt=""/></a>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
      </div>
      
    </>
  )
}

export default BlogDetails