import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const AboutUs = () => {
  useEffect(() => {
    if (window.$) {
      var sliderActiveSeven = new window.Swiper(".testimonial-active", {
        loop: true,
        spaceBetween: 30,
        centeredSlides: true,
        breakpoints: {
          320: {
            slidesPerView: 1,
          },
          576: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          1200: {
            slidesPerView: 3,
          },
        },
      });
    }
  }, []);
  useEffect(() => {
    if (
      window.$ &&
      window.$(".count").counterUp({
        delay: 10,
        time: 2000,
      })
    );
  }, [window.$]);
  return (
    <>
      <div className="main-wrapper main-wrapper-2">
        {/* <!-- mini cart start --> */}
        <div className="sidebar-cart-active">
          <div className="sidebar-cart-all">
            <a className="cart-close" href="#">
              <i className="pe-7s-close"></i>
            </a>
            <div className="cart-content">
              <h3>Shopping Cart</h3>
              <ul>
                <li>
                  <div className="cart-img">
                    <a href="#">
                      <img src="assets/images/cart/cart-1.jpg" alt="" />
                    </a>
                  </div>
                  <div className="cart-title">
                    <h4>
                      <a href="#">Stylish Swing Chair</a>
                    </h4>
                    <span> 1 × $49.00 </span>
                  </div>
                  <div className="cart-delete">
                    <a href="#">×</a>
                  </div>
                </li>
                <li>
                  <div className="cart-img">
                    <a href="#">
                      <img src="assets/images/cart/cart-2.jpg" alt="" />
                    </a>
                  </div>
                  <div className="cart-title">
                    <h4>
                      <a href="#">Modern Chairs</a>
                    </h4>
                    <span> 1 × $49.00 </span>
                  </div>
                  <div className="cart-delete">
                    <a href="#">×</a>
                  </div>
                </li>
              </ul>
              <div className="cart-total">
                <h4>
                  Subtotal: <span>$170.00</span>
                </h4>
              </div>
              <div className="cart-btn btn-hover">
                <a className="theme-color" href="cart.html">
                  view cart
                </a>
              </div>
              <div className="checkout-btn btn-hover">
                <a className="theme-color" href="checkout.html">
                  checkout
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="breadcrumb-area bg-gray-4 breadcrumb-padding-1"> */}
        {/* <div className="container">
                <div className="breadcrumb-content text-center">
                    <h2 data-aos="fade-up" data-aos-delay="200">About Us</h2>
                    <ul data-aos="fade-up" data-aos-delay="400">
                        <li><Link to="/">HOME</Link></li>
                        <li><i className="ti-angle-right"></i></li>
                        <li>About Us</li>
                    </ul>
                </div>
            </div> */}
        <div
          className="breadcrumb-img-1"
          data-aos="fade-right"
          data-aos-delay="200"
        >
          {/* <img src="assets/images/banner/breadcrumb-1.png" alt=""/> */}
        </div>
        <div
          className="breadcrumb-img-2"
          data-aos="fade-left"
          data-aos-delay="200"
        >
          {/* <img src="assets/images/banner/breadcrumb-2.png" alt=""/> */}
        </div>
        {/* </div> */}
        <div className="about-us-area pt-100 pb-100">
          <div className="container">
            <div className="row align-items-center flex-row-reverse">
              <div className="col-lg-6">
                <div className="about-content text-center">
                  <h2 data-aos="fade-up" data-aos-delay="200">
                    Furniture
                  </h2>
                  <h1 data-aos="fade-up" data-aos-delay="300">
                    Best Furniture
                  </h1>
                  <p data-aos="fade-up" data-aos-delay="400">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                    sed do eiusmod tempor incidi ut labore et dolore magna
                    aliqua. Ut enim ad minim venia quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit
                  </p>
                  <p
                    className="mrg-inc"
                    data-aos="fade-up"
                    data-aos-delay="500"
                  >
                    in voluptate velit esse cillum dolore eu fugiat nulla
                    pariatur. Excepteur sint occaecat cupidatat non proident,
                    sunt in culpa qui officia deserunt mollit anim id est
                    laborum.
                  </p>
                  <div
                    className="btn-style-3 btn-hover"
                    data-aos="fade-up"
                    data-aos-delay="600"
                  >
                    <a
                      className="btn border-radius-none"
                      href="/products"
                    >
                      Shop Now
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="about-img"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <img src="assets/images/banner/about-us.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="banner-area pb-100">
          <div
            className="bg-img bg-padding-2"
            style={{ backgroundImage: "url(assets/images/bg/bg-2.png)" }}
          >
            <div className="container">
              <div className="banner-content-5 banner-content-5-static">
                <span data-aos="fade-up" data-aos-delay="200">
                  Up To 40% Off
                </span>
                <h1 data-aos="fade-up" data-aos-delay="400">
                  New Furniture <br />
                  Sofa Set
                </h1>
                <div
                  className="btn-style-3 btn-hover"
                  data-aos="fade-up"
                  data-aos-delay="600"
                >
                  <a
                    className="btn border-radius-none"
                    href="/products"
                  >
                    Shop Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="testimonial-area pb-100">
          <div className="container">
            <div
              className="section-title-2 st-border-center text-center mb-75"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <h2>Testimonial</h2>
            </div>
            <div className="testimonial-active swiper-container">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div
                    className="single-testimonial"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <div className="testimonial-img">
                      <img
                        src="assets/images/testimonial/client-1.png"
                        alt=""
                      />
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet, consectet adipisicing elit,
                      sed do eiusmod tempo incididunt ut labore et dolore.
                    </p>
                    <div className="testimonial-info">
                      <h4>Amrita Sha</h4>
                      <span> Our Client.</span>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div
                    className="single-testimonial"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <div className="testimonial-img">
                      <img
                        src="assets/images/testimonial/client-2.png"
                        alt=""
                      />
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet, consectet adipisicing elit,
                      sed do eiusmod tempo incididunt ut labore et dolore.
                    </p>
                    <div className="testimonial-info">
                      <h4>Andi Lane</h4>
                      <span> Designer.</span>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div
                    className="single-testimonial"
                    data-aos="fade-up"
                    data-aos-delay="600"
                  >
                    <div className="testimonial-img">
                      <img
                        src="assets/images/testimonial/client-1.png"
                        alt=""
                      />
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet, consectet adipisicing elit,
                      sed do eiusmod tempo incididunt ut labore et dolore.
                    </p>
                    <div className="testimonial-info">
                      <h4>Ted Ellison</h4>
                      <span> Developer.</span>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide">
                  <div className="single-testimonial">
                    <div className="testimonial-img">
                      <img
                        src="assets/images/testimonial/client-2.png"
                        alt=""
                      />
                    </div>
                    <p>
                      Lorem ipsum dolor sit amet, consectet adipisicing elit,
                      sed do eiusmod tempo incididunt ut labore et dolore.
                    </p>
                    <div className="testimonial-info">
                      <h4>Aliah Pitts</h4>
                      <span> Customer.</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="funfact-area bg-img pt-100 pb-70"
          style={{ backgroundImage: "url(assets/images/bg/bg-4.png)" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                <div
                  className="single-funfact text-center mb-30"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <div className="funfact-img">
                    <img src="assets/images/icon-img/client.png" alt="" />
                  </div>
                  <h2 className="count">120</h2>
                  <span>Happy Clients</span>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                <div
                  className="single-funfact text-center mb-30"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <div className="funfact-img">
                    <img src="assets/images/icon-img/award.png" alt="" />
                  </div>
                  <h2 className="count">90</h2>
                  <span>Award Winning</span>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                <div
                  className="single-funfact text-center mb-30"
                  data-aos="fade-up"
                  data-aos-delay="600"
                >
                  <div className="funfact-img">
                    <img src="assets/images/icon-img/item.png" alt="" />
                  </div>
                  <h2 className="count">230</h2>
                  <span>Totel Items</span>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                <div
                  className="single-funfact text-center mb-30 mrgn-none"
                  data-aos="fade-up"
                  data-aos-delay="800"
                >
                  <div className="funfact-img">
                    <img src="assets/images/icon-img/cup.png" alt="" />
                  </div>
                  <h2 className="count">350</h2>
                  <span>Cups of Coffee</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="team-area pt-100 pb-70">
          <div className="container">
            <div
              className="section-title-2 st-border-center text-center mb-75"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <h2>Our Staff</h2>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                <div
                  className="single-team-wrap text-center mb-30"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  <img src="assets/images/team/team-1.png" alt="" />
                  <div className="team-info-position">
                    <div className="team-info">
                      <h3>Kari Rasmus</h3>
                      <span>Sales Man</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                <div
                  className="single-team-wrap text-center mb-30"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <img src="assets/images/team/team-2.png" alt="" />
                  <div className="team-info-position">
                    <div className="team-info">
                      <h3>Kari Rasmus</h3>
                      <span>Sales Man</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                <div
                  className="single-team-wrap text-center mb-30"
                  data-aos="fade-up"
                  data-aos-delay="600"
                >
                  <img src="assets/images/team/team-3.png" alt="" />
                  <div className="team-info-position">
                    <div className="team-info">
                      <h3>Kari Rasmus</h3>
                      <span>Sales Man</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="subscribe-area pb-100">
          <div className="container">
            <div
              className="section-title-3 text-center mb-55"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <h2>Join With Us!</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit seddo
                eiusmod tempor incididunt ut labore{" "}
              </p>
            </div>
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <div
                  id="mc_embed_signup"
                  className="subscribe-form"
                  data-aos="fade-up"
                  data-aos-delay="400"
                >
                  <form
                    id="mc-embedded-subscribe-form"
                    className="validate subscribe-form-style"
                    novalidate=""
                    target="_blank"
                    name="mc-embedded-subscribe-form"
                    method="post"
                    action="https://devitems.us11.list-manage.com/subscribe/post?u=6bbb9b6f5827bd842d9640c82&amp;id=05d85f18ef"
                  >
                    <div id="mc_embed_signup_scroll" className="mc-form">
                      <input
                        className="email"
                        type="email"
                        required=""
                        placeholder="Email address…"
                        name="EMAIL"
                        value=""
                      />
                      <div className="mc-news" aria-hidden="true">
                        <input
                          type="text"
                          value=""
                          tabIndex="-1"
                          name="b_6bbb9b6f5827bd842d9640c82_05d85f18ef"
                        />
                      </div>
                      <div className="clear">
                        <input
                          id="mc-embedded-subscribe"
                          className="button"
                          type="submit"
                          name="subscribe"
                          value="Subscribe Now"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Mobile Menu start --> */}
        <div className="off-canvas-active">
          <a className="off-canvas-close">
            <i className=" ti-close "></i>
          </a>
          <div className="off-canvas-wrap">
            {/* <div className="welcome-text off-canvas-margin-padding">
              <p>Default Welcome Msg! </p>
            </div> */}
            <div className="mobile-menu-wrap off-canvas-margin-padding-2">
              <div id="mobile-menu" className="slinky-mobile-menu text-left">
                <ul>
                  <li>
                    <a href="/">HOME</a>
                  </li>
                  <li>
                    <a href="/products">SHOP</a>
                  </li>
                  <li>
                    <a href="/categories">CATEGORIES </a>
                  </li>

                  <li>
                    <a href="/aboutus">ABOUT US</a>
                  </li>
                  <li>
                    <a href="/contactus">CONTACT US</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
