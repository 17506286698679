import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { orderReturns } from "../../Redux/Action";
import { useSelector, useDispatch } from "react-redux";
import "./TrackingOrder.css";
const BankDetails = (props) => {
  const token = localStorage.getItem("AuthTok");
  if (token === null || token === "undefined" || token === "") {
    window.location.href = "/login";
  }
  const { id } = useParams();
  const { type } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Swal = require("sweetalert2");
  const [load, setLoad] = useState(true);
  // const [bankDetails, setBankDetails] = useState(
  //   {
  //   accountNo:"",
  //   accountName:"",
  //   ifscCode:""
  // });
  const [reason, setReason] = useState("");
  const [accountNo, setAccountNo] = useState("");
  const [accountName, setAccountName] = useState("");
  const [ifscCode, setIfscCode] = useState("");

  const [error, setError] = useState("");
  const returns = useSelector(({ orderReturns }) => orderReturns.payload);

  // const handleChange = (e) => {
  //   setBankDetails({ ...bankDetails, [e.target.name]: e.target.value });
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      orderReturns({
        orderId: id,
        orderIndex: type,
        bankDetails: accountNo + "," + accountName + "," + ifscCode,
        reason: reason,
      })
    );
    // .then((res) => {
    //   Swal.fire({
    //     icon: "success",
    //     timer: 4000,
    //     buttons: false,
    //     showConfirmButton: false,
    //     text: res.data.data,
    //   }).then(() => {
    //     navigate("/returns", { replace: true });
    //   });
    //  setAccountNo("");
    //  setAccountName("");
    //  setIfscCode("");
    //   setReason("")

    // })
    // .catch((err)=>{

    //   Swal.fire({
    //     icon: "error",
    //     timer: 4000,
    //     buttons: false,
    //     showConfirmButton: false,
    //     text:err.response.data.error.message ,
    //   })

    // }
    // )
  };

  return (
    <>
      <div className="login-register-area pb-5 pt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12 offset-lg-2">
              <div className="login-register-wrapper">
                <div className="login-register-tab-list nav">
                  <a className="active" data-bs-toggle="tab" href="#lg1">
                    <h4 style={{ textTransform: "uppercase" }}>
                      {" "}
                      bank Details{" "}
                    </h4>
                  </a>
                </div>
                <div className="tab-content">
                  <div id="lg1" className="tab-pane active">
                    <div className="login-form-container">
                      {/* <div className="login-register-form">
                        <form onSubmit={handleSubmit} method="post">
                       <label for="" className="mb-2"  style={{fontWeight:"500",fontSize:"18px"}}> Bank Details</label>
  
                        <textarea
                            name="bankDetails"
                            placeholder="Enter your Bank Details"
                            onChange={(e) => setBankDetails(e.target.value)}
                            value={bankDetails}
                            required
                          />

<label for=""  className="mt-3 mb-2" style={{fontWeight:"500",fontSize:"18px"}}> Reason</label>
                          <textarea
                         
                            name="reason"
                            placeholder="Enter your Reason"
                            onChange={(e) => setReason(e.target.value)}
                            value={reason}
                            required
                          />

                          {error ? (
                            <div className="error-text">{error}</div>
                          ) : (
                            false
                          )}

                          <div className="button-box btn-hover mt-4">
                            <button type="submit">Submit </button>
                          </div>
                        </form>
                      </div> */}

                      <div className="login-register-form">
                        <form onSubmit={handleSubmit} method="post">
                          <input
                            type="text"
                            name="accountNo"
                            placeholder="Enter your Account Number"
                            onChange={(e) => setAccountNo(e.target.value)}
                            value={accountNo}
                            required
                          />
                          <input
                            type="text"
                            name="accountName"
                            placeholder="Account Holder's Name"
                            onChange={(e) => setAccountName(e.target.value)}
                            value={accountName}
                            required
                          />
                          <input
                            type="text"
                            name="ifscCode"
                            placeholder="Enter Your IFSC Code"
                            onChange={(e) => setIfscCode(e.target.value)}
                            value={ifscCode}
                            required
                          />

                          <textarea
                            style={{ marginLeft: "10px" }}
                            className="form-control mb-4 shadow-none textArea"
                            id="exampleFormControlTextarea1"
                            name="reason"
                            placeholder="Enter your Reason"
                            onChange={(e) => setReason(e.target.value)}
                            value={reason}
                            required
                            rows="3"
                          />
                          {/* <span
                              style={{ color: "brown", marginLeft: "20px" }}
                            >
                              {passworderror}
                            </span> */}
                          {error ? (
                            <div className="error-text">{error}</div>
                          ) : (
                            false
                          )}

                          {/* {tempAlert(passworderror,1000)} */}

                          <div
                            style={{
                              textAlign: "center",
                              marginBottom: "25px",
                            }}
                          >
                            <ul>
                              <li>
                                <span style={{ color: "#DD3D05" }}>
                                  Thanks for shopping at Bedwell.For easy
                                  returns and faster refunds, kindly keep all
                                  tags and product related items in PACKED
                                  condition and handover the same to our
                                  shipping partner.An unpacked product might
                                  lead to damages(s), which can effect the
                                  refunds
                                </span>
                              </li>
                              <li className="mt-3">
                                <span style={{ color: "#DD3D05" }}>
                                  {" "}
                                  A flat fee of Rs. 50/- per product will be
                                  deducted from the user's refund amount.
                                </span>
                              </li>
                            </ul>
                          </div>

                          <div
                            className="button-box btn-hover"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <button type="submit">Agree & Submit </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BankDetails;
