import React, { useState, useEffect } from "react";
import { getOrderReturns } from "../../Redux/Action";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import orderimage from "../../images/orders.svg";
const ReturnsList = () => {
  function numberWithCommas(x) {
    return x.toString().split(',')[0].length > 3 ? x.toString().substring(0,x.toString().split('.')[0].length-3).replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + x.toString().substring(x.toString().split('.')[0].length-3): x.toString();
}
    const dispatch = useDispatch();
    const [load, setLoad] = useState(true);
    const token =localStorage.getItem("AuthTok");
    if(token === null || token === "undefined" || token === ""){
      window.location.href = "/login";
    }
    useEffect(() => {
      dispatch(getOrderReturns())
        .then((res) => setLoad(false))
        .catch((res) => setLoad(false));
    }, []);
    const order = useSelector(({ getOrderReturns }) => getOrderReturns.payload);

  return load ? (
    <div
      style={{
        height: "400px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : (
    <div>
      <div className="container">
      <h3 style={{ textAlign: "center",marginBottom:"25px" }}>Returns Details</h3>
        <div className="row">
          <div className="col-12">
            <form action="#">
              <div className="cart-table-content">
                <div className="table-content table-responsive">
                  <table>
                    {Array.isArray(order) && order.length !== 0 ? (
                      <thead>
                        <tr>
                          {/* <th className="width-thumbnail">S.No</th> */}

                          {/* {profileList?.profilePic  ? */}
                          <th className="width-thumbnail">S.No</th>

                          {/* : false} */}
                          <th className="width-price">Return Id</th>
                          <th className="width-subtotal"> Product Name</th>
                          <th className="width-thumbnail"> Product Image</th>
                          <th className="width-quantity">  Units</th>
                          {/* <th className="width-subtotal"> Status</th> */}
                          <th className="width-subtotal"> Amount</th>
                          {/* <th className="width-price">Timestamp</th> */}
                          
                        </tr>
                      </thead>
                    ) : (
                      false
                    )}
                    <tbody>
                      <>
                        {Array.isArray(order) && order.length !== 0 ? (
                          order?.map((data, i) => (
                            <tr>
                              <td className="product-name">{i + 1}</td>
                              <td className="product-name">
                                <h5>
                                  <Link
                                    to={"/orderDetails/" + data.orderId}
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {data.returnId}
                                  </Link>
                                </h5>
                              </td>
                              <td className="product-name">
                                <h5>
                                  <a
                                    style={{
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {data?.productName}
                                  </a>
                                </h5>
                              </td>
                              <td className="product-thumbnail">
                                <Link to={"/orderDetails/" + data.orderId}>
                                  <img
                                    src={
                                      // process.env.REACT_APP_BASE_URL+
                                      process.env.REACT_APP_BASE_URL +
                                      data.variantImages
                                    }
                                    alt=""
                                  />
                                </Link>
                              </td>

                              <td className="product-name">
                                <h5>
                                  <a
                                    style={{
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {data?.units}
                                  </a>
                                </h5>
                              </td>
                              {/* <td className="product-name">
                                <h5>
                                  <a
                                    style={{
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    <span style={{fontSize:"14px",fontWeight:"600"}} className="badge rounded-pill bg-success"> {data?.orderStatus}</span>
                                   
                                  </a>
                                </h5>
                              </td> */}
                              <td className="product-name">
                                <h5>
                                  <a
                                    style={{
                                      textTransform: "capitalize",
                                    }}
                                  >
                                   ₹  {numberWithCommas(data?.paidAmount)}
                                  </a>
                                </h5>
                              </td>
                              {/* <td className="product-name">
                                <h5>
                                  <Link
                                    to={"/orderDetails/" + data.orderId}
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {data.timestamp}
                                  </Link>
                                </h5>
                              </td> */}
                              {/* <td className="product-name">
                                <h5>
                                  <a
                                    href="product-details.html"
                                    style={{
                                      textTransform: "capitalize",
                                    }}
                                  >
                                    {data?.timestamp}
                                  </a>
                                </h5>
                              </td> */}
                           
                            </tr>
                          ))
                        ) : (
                          <center>
                            <img
                              src={orderimage}
                              className="mt-5 mb-5 pb-5 pt-5"
                              style={{ width: "25%" }}
                            />
                            <h3> No Returns Found</h3>{" "}
                          </center>
                        )}
                      </>
                    </tbody>
                  </table>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReturnsList