import React, { useEffect,useState } from "react";
import "./style.css"
import { bulkOrderEnquiry } from "../Redux/Action"
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate, } from "react-router-dom";
const ContactUs = () => {
  const dispatch = useDispatch();
  const [orderError, setOrderError] = useState("");
  const [orderData, setOrderData] = useState({
    companyName:"",
    companyEmail:"",
    companyContact:"",
    message:"",
    bulkOrder:"",


  });
  const Swal = require("sweetalert2");
  const navigate = useNavigate();
  const bulkOrderChange = (e) => {
    setOrderData({ ...orderData, [e.target.name]: e.target.value });
  };
  const bulkOrderSubmit = (e) => {
    e.preventDefault();
   
      dispatch(bulkOrderEnquiry(orderData))
        .then((res) => {
          Swal.fire({
            icon: "success",
            timer: 4000,
            
            text: "Enqueries Submitted Succcessfully!",
          }).then(() => {
            navigate("/", { replace: true });
        })
          if (res.data.data === "Wrong Email / Password. Try Again  !") {
            setOrderError(res.data.data);
          } else {
            // window.location.href = "/";
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            timer: 4000,
           
            text: "Somethong Went Wrong!",
          }).then(() => {
            navigate("/", { replace: true });
        })
          setOrderError("Email Id and Password Are Invalid");
        });
    
  };
  return (
    <>
 <div className="main-wrapper main-wrapper-2">

 <div className="contact-form-area pt-90 pb-100" style={{background:""}}>
            <div className="container">
                <div className="section-title-4 text-center mb-55" data-aos="fade-up" data-aos-delay="200">
                    <h2 style={{textTransform:"uppercase"}}>Ask Us Anything Here</h2>
                </div>
                <div className="contact-form-wrap" data-aos="fade-up" data-aos-delay="200">
                    <form className="contact-form-style" id="contact-form" action="https://htmlmail.hasthemes.com/tasnim/urdan/mail.php" method="post">
                        <div className="row">
                            <div className="col-lg-4">
                                <input type="text"
                            name="companyName"
                            placeholder="Enter your Company Name"
                            onChange={bulkOrderChange}
                            value={orderData.companyName}
                            required/>
                                <input  type="email"
                            name="companyEmail"
                            placeholder="Enter Your Company Email"
                            onChange={bulkOrderChange}
                            value={orderData.companyEmail}
                            required/>
                                <select
                  className="form-select  shadow-none test" 
                  id="drop"
                  value={orderData.bulkOrder}
                  onChange={bulkOrderChange}
                  name="bulkOrder"
                  aria-label="Default select example"
                  style={{marginLeft:"0px",height:"49px",marginBottom:"15px",border:"#f9f9f9",backgroundColor:"#f9f9f9"}}
                  >
                  <option selected> Bulk Orders </option>
                  <option value="yes">Yes </option>
                  <option value="no">No</option>
                </select>
                                <input type="tel" maxlength="10" className="js-input-mobile"
                                   id="zipCode"
                                  name="companyContact"
                                  value={orderData.companyContact}
                                  onChange={bulkOrderChange}
                                  placeholder="Enter Your Company Contact No"
                                  required/>
                                {/* <input name="phone" type="text" placeholder="Phone*"/> */}
                            </div>
                            <div className="col-lg-8">
                                <textarea name="message"
                            placeholder="Enter Your Message"
                            onChange={bulkOrderChange}
                           
                            value={orderData.message}
                            required></textarea>
                            </div>
                            <div className="col-lg-12 col-md-12 col-12 contact-us-btn btn-hover">
                                <button onClick={bulkOrderSubmit} type="submit">Send Message</button>
                            </div>
                        </div>
                    </form>
                    <p className="form-messege"></p>
                </div>
            </div>
        </div>
 </div>
 <div className="off-canvas-active">
          <a className="off-canvas-close">
            <i className=" ti-close "></i>
          </a>
          <div className="off-canvas-wrap">
            {/* <div className="welcome-text off-canvas-margin-padding">
              <p>Default Welcome Msg! </p>
            </div> */}
            <div className="mobile-menu-wrap off-canvas-margin-padding-2">
              <div id="mobile-menu" className="slinky-mobile-menu text-left">
                <ul>
                  <li>
                    <a href="/">HOME</a>
                  </li>
                  <li>
                    <a href="/products">SHOP</a>
                  </li>
                  <li>
                    <a href="/categories">CATEGORIES </a>
                  </li>

                  <li>
                    <a href="/aboutus">ABOUT US</a>
                  </li>
                  <li>
                    <a href="/contactus">CONTACT US</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
    </>
  )
}

export default ContactUs