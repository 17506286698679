import React, { useState, useEffect,useRef } from "react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Link, useNavigate, } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";
import { AiOutlineEyeInvisible } from "react-icons/ai";


import {
  authenticateLogin,
  registerUser,
  logoutUser,
} from "../../Redux/Action";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import "./style.css";

const Login = () => {
  const today = moment();
  const[show,setshow]=useState(false)
  const[show1,setshow1]=useState(false)
  const pass = useRef();
  const pass2 = useRef();

  // const disableFutureDt = current => {
  //   return current.isBefore(today)
  // }
  //login
  window.$('body').on('keyup', '.js-input-mobile', function () {
    var $input = window.$(this),
        value = $input.val(),
        length = value.length,
        inputCharacter = parseInt(value.slice(-1));

    if (!((length > 0 && inputCharacter >= 0 && inputCharacter <= 10) || (length === 1 && inputCharacter >= 7 && inputCharacter <= 10))) {
        $input.val(value.substring(0, length - 1));
     }
  });

  const dispatch = useDispatch();
  const [load, setLoad] = useState(true);
  const [count, setCount] = useState(true);
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();
  const [passworderror, setPassworderror] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    setshow(false);
    // if (user.password.length >= 3 && user.password.length <= 8) {
      dispatch(authenticateLogin(user))
        .then((res) => {
          if (res.data.data === "Wrong Email / Password. Try Again  !") {
            setPassworderror(res.data.data);
            setTimeout(() => {
              setPassworderror("");
            }, 3000);
          } else {
            window.location.href = "/";
          }
        })
        .catch((err) => {
          setPassworderror( err.response.data.error.message);
             setTimeout(() => {
        setPassworderror("");
      }, 5000);
        });
    // } else {
    //   setPassworderror("Invalid Password");
    //   setTimeout(() => {
    //     setPassworderror("");
    //   }, 5000);
    // }
  };

  
const showpassword = () =>{

  setshow(!show)
  pass.current.type = show ? 'password':'text';
  
  }
const showpassword1 = () =>{

  setshow1(!show1)
  pass2.current.type = show1 ? 'password':'text';
  
  }


  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  const handleClickcouont = () => {
    setCount(!count);
  };

  //register user

  const [userDetails, setUserDetails] = useState({
    userName: "",
    email: "",
    password: "",
    phone: "",
    dob: "",
    gender: "",
    fcmToken: "wertyuio45frtgyh",
  });
  const [err, setErr] = useState("");
  const [errL, setErrL] = useState("");
  const Swal = require("sweetalert2");
  const handleRegisterSubmit = (e) => {
    e.preventDefault();
    e.currentTarget.reset();
   
    if (userDetails.phone.length !== 10) {
      setErr("Mobile Number Should be 10");
      setTimeout(() => {
        setErrL("");
      }, 3000);
    } else if (userDetails.password.length < 8) {
      setErrL("Min Password length should be 8 letters");
      setTimeout(() => {
        setErrL("");
      }, 5000);
    } else {
      dispatch(registerUser(userDetails))
        
    }
  };
  const handleRegisterChange = (e) => {
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
  };

  //logout user
  const logout = () => {
    dispatch(logoutUser());
    localStorage.removeItem("AuthTok");

    window.location.href = "/";
  };

  return (
    <>
      <div className="login-register-area pb-5 pt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12 offset-lg-2">
              <div className="login-register-wrapper">
                <div className="login-register-tab-list nav">
                  <a className="active" data-bs-toggle="tab" href="#lg1">
                    <h4 style={{textTransform:"uppercase"}}> login </h4>
                  </a>
                  <a data-bs-toggle="tab" href="#lg2">
                    <h4 style={{textTransform:"uppercase"}}>  register </h4>
                  </a>
                </div>
                <div className="tab-content">
                  <div id="lg1" className="tab-pane active">
                    <div className="login-form-container">
                      <div className="login-register-form">
                        <form onSubmit={handleSubmit} method="post">
                          <div className="eye">
                            
                          <input
                            type="text"
                            name="email"
                            placeholder="Enter your Email Id"
                            onChange={handleChange}
                            value={user.email}
                            required
                          />
                          </div>





                          <div className="eye">
                          <input
                          ref={pass}
                            type="password"
                            name="password"
                            placeholder="Enter Your Password"
                            onChange={handleChange}
                            value={user.password}
                            required
                          />


{/* <input ref={pass} type = "password" placeholder="Enter Password"value={password} name="password" onChange={change}></input> */}

{show ? <AiOutlineEye onClick={showpassword} style={{fontSize:"25px",position:"absolute",top:"12px",right:"25px",cursor:"pointer"}}/>:<AiOutlineEyeInvisible onClick={showpassword} style={{fontSize:"25px",position:"absolute",top:"12px",right:"25px",cursor:"pointer"}}/>}



</div>
                          {/* <span
                              style={{ color: "brown", marginLeft: "20px" }}
                            >
                              {passworderror}
                            </span> */}
                          {passworderror ? (
                          <div className="error-text">  
                          {passworderror }
                        </div>
                          
                          ) : (
                            false
                          )}
                          
                          {/* {tempAlert(passworderror,1000)} */}

                          <div className="login-toggle-btn">
                        
                            <Link to="/forget">Forgot Password?</Link>
                          </div>
                          <div className="mb-3" style={{marginTop:"-25px"}}>
                              <Link to="/loginWithPhone" style={{marginLeft:"8px"}}>Login With Phone?</Link>
                            </div>
                          <div className="button-box btn-hover">
                            <button type="submit">Login </button>
                          </div>
                           
                        </form>
                      </div>
                    </div>
                  </div>
                  <div id="lg2" className="tab-pane">
                    <div className="login-form-container">
                      <div className="login-register-form">
                        <form onSubmit={handleRegisterSubmit} method="post">
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="userName"
                                  value={userDetails.userName}
                                  onChange={handleRegisterChange}
                                  placeholder="Username"
                                  required
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <input
                                  type="email"
                                  name="email"
                                  value={userDetails.email}
                                  onChange={handleRegisterChange}
                                  placeholder="Email"
                                  required
                                />
                              </div>
                            </div>

                            <div className="col-lg-6 mt-3">
                              <div className="form-group">
                              {/* <span style={{color:"#DD3D05",marginLeft:"10px"}}>Password Length  should be 8</span> */}
                                {/* <input
                                  type="password"
                                  name="password"
                                  value={userDetails.password}
                                  onChange={handleRegisterChange}
                                  placeholder="Password"
                                  required
                                /> */}

                          <div className="eye">
                          <input
                          ref={pass2}
                            type="password"
                            name="password"
                            placeholder="Enter Your Password"
                            value={userDetails.password}
                            onChange={handleRegisterChange}
                            required
                          />


{/* <input ref={pass} type = "password" placeholder="Enter Password"value={password} name="password" onChange={change}></input> */}

{show1 ? <AiOutlineEye onClick={showpassword1} style={{fontSize:"25px",position:"absolute",top:"12px",right:"25px",cursor:"pointer"}}/>:<AiOutlineEyeInvisible onClick={showpassword1} style={{fontSize:"25px",position:"absolute",top:"12px",right:"25px",cursor:"pointer"}}/>}



</div>
                                <span className="mb-3" style={{display:"flex", color: "red",marginLeft:"10px" }}>{errL} </span>
           
                               
                              </div>
                            </div>
                            <div className="col-lg-6 mt-3">
                              <div className="form-group">
                                <input
                                 
                                 type="tel" maxlength="10" className="js-input-mobile"
                                   id="zipCode"
                                  name="phone"
                                  value={userDetails.phone}
                                  onChange={handleRegisterChange}
                                  placeholder="Phone"
                                  required
                                />
                                <span
                                  style={{ color: "red", marginTop: "15px" }}
                                >
                                  {err}{" "}
                                </span>
                              </div>
                            </div>
                            <div className="col-lg-12 ">
                              <div className="form-group">
                                <input
                                  type="date"
                                  max={today.format("YYYY-MM-DD")}
                                  min="1940-01-02"
                                  name="dob"
                                  value={userDetails.dob}
                                  onChange={handleRegisterChange}
                                  placeholder="Date Of Birth"
                                  required
                                />
                              </div>
                            </div>

                            <div style={{ marginBottom: "20px",marginLeft:"11px" }}>
                              <input
                                type="checkbox"
                                style={{ marginTop: "-20px" }}
                                required
                              />

                              <span style={{ marginLeft: "20px" }}>
                               I have read, understood and agree with{" "}
                                <span
                                  style={{ color: "#e97730", fontSize: "15px" }}
                                >
                                  
                                  <a
                                    style={{ cursor: "pointer",textDecoration:"underline" }}
                                    href="/privacy"
                                    
                                  >
                                    {" "}
                                    Privacy Policy{" "} 
                                  </a>
                                  <a
                                    style={{ cursor: "pointer",textDecoration:"underline" }}
                                    href="/refund"
                                  >
                                    ,  Refund Policy &
                                  </a>
                                  <a
                                    style={{ cursor: "pointer",textDecoration:"underline" }}
                                    href="/terms"
                                  >
                                    &nbsp; Terms & Conditions.
                                  </a>
                                </span>
                              </span>
                            </div>

                            <div className="button-box btn-hover">
                              <button type="submit">Register</button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
