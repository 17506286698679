import React, { useState, useEffect,useRef  } from "react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Link, useNavigate, useParams} from "react-router-dom";
import { verifyPasswords } from "../../Redux/Action";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineEye } from "react-icons/ai";
import { AiOutlineEyeInvisible } from "react-icons/ai";
const VerifyPassword = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const[show,setshow]=useState(false)
  const[show1,setshow1]=useState(false)
  const navigate = useNavigate();
  const pass1 = useRef();
  const pass2 = useRef();
  
  const [data, setData] = useState({
    email: id,
    code: "",
    password: "",
  });

  const showpassword = () =>{

    setshow(!show)
    pass1.current.type = show ? 'password':'text';
    
    }
  const showpassword1 = () =>{
  
    setshow1(!show1)
    pass2.current.type = show1 ? 'password':'text';
    
    }


  const [pass, setPass] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    if (data.password !== pass) {
      setError("New Password and Confirm Password Doesn't Match");
      setTimeout(() => {
        setError("");
        
      }, 5000);
    } else {
      dispatch(verifyPasswords(data))
        .then((res) => Swal.fire({
          icon: "success",
          timer: 4000,
          buttons: false,
          showConfirmButton: false,
          text: res.data.data,
        }).then(() => {
          navigate("/login", { replace: true });
      }) )
        .catch((err) => {
          Swal.fire({
            icon: "error",
            timer: 4000,
            buttons: false,
            showConfirmButton: false,
            text: err.response.data.error.message,
          })
          var errMsg = err.response.data.error.message;
          if (errMsg === "Code Expired") {
            navigate("/forget");
            var errMsg = "Code Expired, Try Again !";
            localStorage.setItem("Error", errMsg);
          } else {
            setError(err.response.data.error.message);
            setTimeout(() => {
              setError("");
              
            }, 5000);
          }
        });
    }
  };
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  return (
    <>
    

<div className="main-wrapper main-wrapper-2">
    
    <div className="login-register-area pb-5 pt-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-12 offset-lg-2">
            <div className="login-register-wrapper">
              <div className="login-register-tab-list nav">
              
                <a data-bs-toggle="tab" href="#">
                  <h4 style={{textTransform: "uppercase"}}> VerifyPassword </h4>
                </a>
              </div>
              <div className="tab-content">
                <div id="lg1" className="tab-pane active">
                  <div className="login-form-container">
                    <div className="login-register-form">
                      <form onSubmit={handleSubmit} method="post">
                        
                      <input
                      type="text"
                      onChange={handleChange}
                      name="code"
                      
                      placeholder="Verify Code"
                      required={true}
                    />
                      <div className="eye">

                    <input
                      ref={pass1}
                      type="password"
                      onChange={handleChange}
                      name="password"
                    
                      placeholder="New Password"
                      required={true}
                    />
                    {show ? <AiOutlineEye onClick={showpassword} style={{fontSize:"25px",position:"absolute",top:"12px",right:"25px",cursor:"pointer"}}/>:<AiOutlineEyeInvisible onClick={showpassword} style={{fontSize:"25px",position:"absolute",top:"12px",right:"25px",cursor:"pointer"}}/>}
                      </div>
                      <div className="eye">

                    <input
                     ref={pass2}
                      type="password"
                     
                      onChange={(e) => setPass(e.target.value)}
                      name="verifypassword"
                      placeholder="Confirm Password"
                      required={true}
                    />
                    {show1 ? <AiOutlineEye onClick={showpassword1} style={{fontSize:"25px",position:"absolute",top:"12px",right:"25px",cursor:"pointer"}}/>:<AiOutlineEyeInvisible onClick={showpassword1} style={{fontSize:"25px",position:"absolute",top:"12px",right:"25px",cursor:"pointer"}}/>}
                      </div>
                           <span style={{ color: "red" }} className="mb-1">
                  {" "}
                  {error ? (
                          <div className="error-text mb-4">  
                          {error }
                        </div>
                          
                          ) : (
                            false
                          )}
                </span>
                        
                        <div className="button-box btn-hover">
                          <button type="submit">Update</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
    </>
  );
};

export default VerifyPassword;
